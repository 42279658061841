import React from "react";

const CrateVideo = ({ card }) => {
  return (
    <video
      id="desktop-video"
      width="100%"
      height="100%"
      style={{
        position: "absolute",
        objectFit: "contain",
        backgroundColor: card?.backgroundColor ? card?.backgroundColor : "",
      }}
      autoPlay
      muted
      loop
      poster={card.image}
    >
      <source src={card.image} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default CrateVideo;
