import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "react-tabs/style/react-tabs.css";

import getSpaceByCustomUrl from "../../api/spaces/getSpaceByCustomURL";
import getUser from "../../api/user/getUser.js";
import Spinner from "../../components/layouts/Spinner/Spinner";
import { useApi } from "../../hooks/useApi";
import { useS3Url } from "../../hooks/useS3Url";
import ItemsByArtist from "./ItemsByArtist";

import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { getBaseItemsByUser } from "../../api/Item/baseItems";
import getCollectionsByUserId from "../../api/collections/getCollectionsByUserId";
import Layout from "../../components/layouts/Layout";
import {
  IS_FOLLOW_ENABLED,
  IS_OTHER_OPTIONS_ENABLED,
  IS_SHARE_ENABLED,
  IS_TESTING_DESCRIPTION_UI,
  PLACEHOLDER_DESCRIPTION,
  REACT_APP_API_URL,
} from "../../constants/constants";
import { activitiesData } from "../../constants/fakeData";
import { useRole } from "../../hooks/useRole";
import CollectionByArtist from "./CollectionByArtist";
import { AuthorsSection } from "./styles";

const Authors02 = () => {
  const { isGallery } = useRole();
  const { customUrl } = useParams();
  const { executeApiRequestTwo } = useApi();

  const [loading, setLoading] = useState(true);
  const [spaceData, setSpaceData] = useState({});
  const [cover, setCover] = useState();
  const [likes, setLikes] = useState(0);
  const [collections, setCollections] = useState([]);
  const [collectionsCount, setCollectionsCount] = useState(0);
  const [items, setItems] = useState([]);
  const [itemsCount, setItemsCount] = useState(0);
  const [activities, setActivities] = useState([]);

  /* Filters related */
  const [categoriesEnabled, setCategoriesEnabled] = useState(false);
  const [blockchainsEnabled, setBlockchainsEnabled] = useState(false);
  const [statusFiltersEnabled, setStatusFiltersEnabled] = useState(false);
  const [activitiesFiltersEnabled, setActivitiesFiltersEnabled] =
    useState(false);

  const [userData, setUserData] = useState({});
  const { getS3Url } = useS3Url();

  const profile = getS3Url(userData.profileFileName, "profilePicture");

  useEffect(() => {
    if (!userData.coverFileName) return;
    const cover = getS3Url(userData.coverFileName, "coverImage");
    setCover(cover);
  }, [userData, getS3Url]);

  const getDatabyUserId = async (id) => {
    const response = await getUser(id);
    response && setUserData(response);
    setLoading(false);
    return response;
  };

  const getItems = async (userId) => {
    const items = await getBaseItemsByUser(userId);
    setItems(items);
    setItemsCount(items.length);
  };

  const getActivities = async (userId, userData) => {
    try {
      // const activitiesData = await getActivitiesByUserId(userId);
      setActivities(activitiesData);
    } catch (error) {
      console.log(error);
    }
  };
  const getCollections = async (userId, userData) => {
    try {
      const collections = await getCollectionsByUserId(userId);
      const responseCollections = collections.map((c) => ({
        ...c,
        profileFileName: userData.profileFileName,
        displayName: userData.displayName,
      }));
      setCollections(responseCollections);
      setCollectionsCount(responseCollections.length);
    } catch (error) {
      console.log(error);
    }
  };

  const getSpace = async (id) => {
    try {
      const response = await getSpaceByCustomUrl(id);
      if (response) {
        response && setSpaceData(response);
        setLikes(response.numberOfLikes);
        const userData = await getDatabyUserId(response.userId);
        await getItems(response.userId);
        await getCollections(response.userId, userData);
        await getActivities(response.userId, userData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSpace(customUrl);
  }, []);

  const likeSpace = async () => {
    try {
      await executeApiRequestTwo(
        `${REACT_APP_API_URL}space/like/${spaceData.id}`,
        {
          method: "PUT",
        }
      );
    } catch (e) {
      console.log(e);
    } finally {
      getSpace(customUrl);
    }
  };

  const statsData = [
    { statName: "Collections", statResult: collectionsCount },
    { statName: "Items", statResult: itemsCount },
    { statName: "Followers", statResult: 0 },
    { statName: "Likes", statResult: likes },
  ];

  return (
    <Layout>
      <div className="authors-2">
        <AuthorsSection className="tf-section authors" cover={cover}>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center w-100 vh-100">
              <Spinner />
            </div>
          ) : (
            <>
              <div className="top-section">
                <div className="row cover-image"></div>
                <div className="profile-image-container">
                  <div className="profile-image">
                    <img src={profile} alt="profile" />
                  </div>
                </div>
              </div>

              <div className="actions-container">
                {spaceData?.id && (
                  <i
                    onClick={likeSpace}
                    className={
                      (spaceData.liked ? "fa-solid" : "fa-regular") +
                      " fa-heart"
                    }
                    style={{ fontStyle: "normal", cursor: "pointer" }}
                  ></i>
                )}

                {IS_SHARE_ENABLED && <span className="fa-share-alt"></span>}
                {IS_OTHER_OPTIONS_ENABLED && (
                  <span className="fa-elipsis-stroke"></span>
                )}
              </div>

              <div className="container-fluid pb-5 top-container">
                <section className="creator-info">
                  <div className="row name-container">
                    <div>
                      <h2 className="artist-name title">
                        {userData.displayName}
                      </h2>
                    </div>

                    {IS_FOLLOW_ENABLED && (
                      <div>
                        <button className="outlined-button button__bottom">
                          <span>follow</span>
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="row m-0">
                    <div className="creator-description">
                      {spaceData.bio}
                      {!spaceData.bio &&
                        IS_TESTING_DESCRIPTION_UI &&
                        PLACEHOLDER_DESCRIPTION}
                    </div>
                  </div>
                </section>

                <div className="row m-0 stats-container">
                  <div className="stat-row">
                    {statsData &&
                      statsData.map((stat, i) => (
                        <div className="stat-container" key={"stat" + i}>
                          <p className="stat-title">{stat.statResult}</p>
                          <h3 className="stat-number">{stat.statName}</h3>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <section className="row m-0">
                <div className="col-xl-9 col-lg-9 col-md-12">
                  <div className="form-create-item">
                    <div className="flat-tabs themesflat-tabs">
                      <Tabs
                        onSelect={(index) => {
                          switch (index) {
                            case 0:
                              setBlockchainsEnabled(false);
                              setStatusFiltersEnabled(false);
                              setCategoriesEnabled(false);
                              setActivitiesFiltersEnabled(false);
                              break;
                            case 1:
                              setBlockchainsEnabled(false);
                              setStatusFiltersEnabled(false);
                              setCategoriesEnabled(false);
                              setActivitiesFiltersEnabled(false);
                              break;
                            case 2:
                              setBlockchainsEnabled(false);
                              setStatusFiltersEnabled(false);
                              setCategoriesEnabled(false);
                              setActivitiesFiltersEnabled(true);
                              break;
                            default:
                          }
                        }}
                      >
                        <TabList>
                          <Tab>Items</Tab>
                          <Tab>Collections</Tab>
                        </TabList>

                        <TabPanel>
                          <ItemsByArtist
                            items={items}
                            userId={spaceData.userId}
                          />
                        </TabPanel>

                        <TabPanel>
                          <CollectionByArtist
                            collections={collections}
                            userId={spaceData.userId}
                          />
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </AuthorsSection>
      </div>
    </Layout>
  );
};

export default Authors02;
