import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth0 } from "./Auth";

const withAuthenticationRequired = (Component, options) => {
  return function WithAuthenticationRequired(props) {
    const { user, isAuthenticated } = useAuth0();
    const location = useLocation();

    const defaultOnRedirecting = () => {
      return (
        <Navigate
          to="/login"
          replace
          state={{ path: location.pathname, ...location.state }}
        />
      );
    };

    const { onRedirecting = defaultOnRedirecting, claimCheck = () => true } =
      options;

    /**
     * The route is authenticated if the user has valid auth and there are no
     * JWT claim mismatches.
     */
    const routeIsAuthenticated = isAuthenticated && claimCheck(user);

    return routeIsAuthenticated ? <Component {...props} /> : onRedirecting();
  };
};

export default withAuthenticationRequired;
