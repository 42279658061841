import {
  AccountId,
  TokenAssociateTransaction,
  TransactionId,
  TransactionReceipt,
} from "@hashgraph/sdk";

import { HashConnect } from "hashconnect";
import { useDispatch } from "react-redux";
import {
  HASH_CONNECT_APP_METADATA,
  REACT_APP_API_URL,
  REACT_APP_HASH_CONNECT_NETWORK,
} from "../constants/constants";
import {
  logoutHashpackWallet,
  updateHashPackWallets,
} from "../store/slices/user/user";
import { generateHmac } from "../utils/sha256Encription";
import { useApi } from "./useApi";

let hashconnect, availableExtension, topic, pairingString, pairingData;

const useHashconnect = () => {
  const dispatch = useDispatch();
  const { executeApiRequest, executeApiRequestTwo } = useApi();

  const getPairingData = () => {
    return pairingString;
  };

  const detectExtension = () => {
    return availableExtension;
  };

  const initHashconnect = async () => {
    // create the hashconnect instance
    hashconnect = new HashConnect(false);

    //register events
    setUpHashConnectEvents();

    //initialize and use returned data
    let initData = await hashconnect.init(
      HASH_CONNECT_APP_METADATA,
      REACT_APP_HASH_CONNECT_NETWORK,
      false
    );

    topic = initData.topic;
    pairingString = initData.pairingString;

    //Saved pairings will return here, generally you will only have one unless you are doing something advanced
    pairingData = initData.savedPairings[initData.savedPairings.length - 1];
    if (initData.savedPairings[initData.savedPairings.length - 1]) {
      dispatch(updateHashPackWallets(pairingData));
    }
  };

  const setUpHashConnectEvents = () => {
    //This is fired when a extension is found
    hashconnect.foundExtensionEvent.on((data) => {
      /* Here we detect if there is an extension */
      availableExtension = data;
    });

    //This is fired when a wallet approves a pairing
    hashconnect.pairingEvent.on((data) => {
      pairingData = data.pairingData;
      dispatch(updateHashPackWallets(pairingData));
    });

    //This is fired when HashConnect loses connection, pairs successfully, or is starting connection
    // hashconnect.connectionStatusChangeEvent.on((state) => {
    //   console.log("hashconnect state change event", state);
    //   this.state = state;
    // });
  };

  const getConnectedWallet = async () => {
    if (!pairingData) return;
    const fromAccount = pairingData?.accountIds[0];
    const balance = await getBalance();
    return {
      walletDetails: {
        description: fromAccount,
        balance,
      },
    };
  };

  function truncateDecimals(num, digits) {
    var numS = num.toString(),
      decPos = numS.indexOf("."),
      substrLength = decPos == -1 ? numS.length : 1 + decPos + digits,
      trimmedResult = numS.substr(0, substrLength),
      finalResult = isNaN(trimmedResult) ? 0 : trimmedResult;

    return parseFloat(finalResult);
  }

  const payWithHashconnect = async ({ user, toAccount, amount, memo = "" }) => {
    if (!pairingData) return;

    const fromAccount = pairingData?.accountIds[0];
    // const topic = pairingData?.topic;
    try {
      const { hString, epochTimestamp } = generateHmac({
        userId: user.id,
        id: fromAccount,
      });

      /* Backend would fail if we sent an amount with more than 8 decimal places
              with Error: Hbar in tinybars contains decimals */
      const requestBody = {
        signingAcctId: fromAccount,
        transactionDetails: [
          {
            action: "addHbarTransfer",
            accountId: fromAccount,
            amount: -truncateDecimals(amount, 2),
          },
          {
            action: "addHbarTransfer",
            accountId: toAccount,
            amount: truncateDecimals(amount, 2),
          },
        ],
        hString,
        epochTimestamp,
        memo,
      };
      // trans.addHbarTransfer(acct,Hbar.from(amount, HbarUnit.Hbar));
      // trans.setTransactionMemo("you'll get an nft");
      // trans.addNftTransfer("0.0.3456699", "5", this.configService.get<string>('HEDERA_ACCOUNT_ID'), signingAcctId);
      // trans.addNftTransfer("0.0.3456699", "3", this.configService.get<string>('HEDERA_ACCOUNT_ID'), signingAcctId);
      // trans.addNftTransfer("0.0.3456699", "6", this.configService.get<string>('HEDERA_ACCOUNT_ID'), signingAcctId);

      const { result } = await executeApiRequestTwo(
        `${REACT_APP_API_URL}hashpack/sign-and-make-bytes`,
        {
          method: "POST",
          body: JSON.stringify(requestBody),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const transaction = {
        topic,
        byteArray: result?.data,
        metadata: {
          accountToSign: fromAccount,
          returnTransaction: false,
          hideNft: false,
        },
      };

      let res = await hashconnect.sendTransaction(topic, transaction);
      return res;
      // const retdata = {
      //   transactionHash:
      //     "63951edea25354524ab8568f269f4065d57f625a04afac5fcbc3493a19b0a6de036ba17d3572e3586772046d51fdb759",
      //   transactionId: "0.0.24049@1675409915.318242999",
      // };
    } catch (e) {
      console.log(e);
    }
  };

  const transferNFTWithHashconnect = async ({
    toAccount,
    tokenId,
    serialNumber,
    user,
  }) => {
    if (!pairingData) return;
    const fromAccount = pairingData?.accountIds[0];
    const topic = pairingData?.topic;

    try {
      const nftTrans = {
        tokenId,
        serialNumber,
        sender: fromAccount,
        receiver: toAccount,
      };

      const { hString, epochTimestamp } = generateHmac({
        userId: user.id,
        id: fromAccount,
      });

      const requestBody = {
        signingAcctId: fromAccount,
        transactionDetails: [
          {
            action: "addNftTransfer",
            tokenId: nftTrans.tokenId,
            itemSerial: nftTrans.serialNumber,
            sender: nftTrans.sender,
            receiver: nftTrans.receiver,
          },
        ],
        hString,
        epochTimestamp,
      };

      const { result } = await executeApiRequestTwo(
        `${REACT_APP_API_URL}hashpack/sign-and-make-bytes`,
        {
          method: "POST",
          body: JSON.stringify(requestBody),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const transaction = {
        topic,
        byteArray: result?.data,
        metadata: {
          accountToSign: fromAccount,
          returnTransaction: false,
          hideNft: false,
        },
      };
      let res = await hashconnect.sendTransaction(topic, transaction);
      return res;
    } catch (e) {
      console.log(e);
    }
  };

  const makeBytes = async ({ trans, signingAcctId }) => {
    let transId = TransactionId.generate(signingAcctId);
    trans.setTransactionId(transId);
    trans.setNodeAccountIds([new AccountId(3)]);
    await trans.freeze();
    let transBytes = trans.toBytes();

    return transBytes;
  };

  const associateCollectionHashpack = async ({ collectionId }) => {
    const res = await executeApiRequest(
      `${REACT_APP_API_URL}collection/associationState/${collectionId}`,
      {
        method: "PUT",
      }
    );
  };

  const associateToken = async ({ tokenId }) => {
    if (!pairingData) return;
    let trans = await new TokenAssociateTransaction();
    let tokenIds = [tokenId];
    const fromAccount = pairingData?.accountIds[0];
    const topic = pairingData?.topic;

    try {
      trans.setTokenIds(tokenIds);
      trans.setAccountId(fromAccount);

      let transactionBytes = await makeBytes({
        trans,
        signingAcctId: fromAccount,
      });

      const transaction = {
        topic,
        byteArray: transactionBytes,
        metadata: {
          accountToSign: fromAccount,
          returnTransaction: false,
          hideNft: false,
        },
      };

      let res = await hashconnect.sendTransaction(topic, transaction);
      //handle response
      let responseData = {
        response: res,
        receipt: null,
      };
      if (res.success)
        responseData.receipt = TransactionReceipt.fromBytes(res.receipt);

      return responseData;
    } catch (e) {
      console.log(e);
    }
  };

  const connectToExtension = async () => {
    hashconnect.connectToLocalWallet();
  };

  const disconnect = () => {
    if (pairingData?.topic) {
      hashconnect.disconnect(pairingData?.topic);
    }
    pairingData = null;
    dispatch(logoutHashpackWallet());
  };

  const getBalance = async () => {
    if (!pairingData) return;

    const accountId = pairingData.accountIds[0];
    const topic = pairingData?.topic;

    let provider = hashconnect.getProvider(
      REACT_APP_HASH_CONNECT_NETWORK,
      topic,
      accountId
    );

    let balance = await provider.getAccountBalance(accountId);
    return balance;
    // let accountInfo = await provider.getAccountRecords(accountId)
  };

  return {
    initHashconnect,
    payWithHashconnect,
    associateToken,
    associateCollectionHashpack,
    transferNFTWithHashconnect,
    disconnect,
    connectToExtension,
    getBalance,
    getPairingData,
    getConnectedWallet,
    detectExtension,
  };
};

export default useHashconnect;
