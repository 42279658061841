import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import ItemsByCollection from "./ItemsByCollection";
// import Filters from '../Explore/Filters';
import { getBaseItemsByCollection } from "../../api/collections/baseCollection";
import getCollection from "../../api/collections/getCollection";
import Spinner from "../../components/layouts/Spinner/Spinner";
import Filters from "../ArtistPage/Filters";
import InfoCollection from "./InfoCollection";
import { CollectionSection } from "./styles";

const Collection = ({ alias }) => {
  const { collectionId } = useParams();
  const [loading, setLoading] = useState(true);
  const [collectionData, setCollectionData] = useState({});
  const [items, setItems] = useState([]);

  /* Filters related */
  const categoriesEnabled = false;
  const blockchainsEnabled = false;
  const statusFiltersEnabled = false;
  const activitiesFiltersEnabled = false;

  const getOneCollection = async (id) => {
    let response = {};
    try {
      response = await getCollection(id);
    } catch (error) {
      console.log(error);
    } finally {
      return response;
    }
  };

  const getItemsOfCollection = async (id, collectionData) => {
    try {
      const response = await getBaseItemsByCollection(id);
      const finalCollectionInfo = {
        ...collectionData,
        itemsCount: response?.length ?? 0,
      };
      setCollectionData(finalCollectionInfo);

      response && setItems(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (collectionId) {
      (async () => {
        const collectionData = await getOneCollection(collectionId);
        await getItemsOfCollection(collectionData?.id, collectionData);
      })();
    }
  }, [collectionId]);

  useEffect(() => {
    if (alias) {
      (async () => {
        const collectionData = await getOneCollection(alias);
        await getItemsOfCollection(collectionData?.id, collectionData);
      })();
    }
  }, []);

  return (
    <Fragment>
      <Header />
      <CollectionSection>
        <div id="spacer"></div>

        {loading ? (
          <div className="d-flex justify-content-center align-items-center vh-100 w-100">
            <Spinner />
          </div>
        ) : (
          <div className="themesflat-container">
            <div className="row m-0 top-container ">
              <div className="col-md-12">
                <InfoCollection collectionInfo={collectionData} />
              </div>
            </div>

            <hr className="divider-line" />

            <div className="row m-0 bottom-container">
              <div className="col-xl-3 col-lg-3 col-md-12 p-0">
                <div className="filters-container">
                  <Filters
                    categories={categoriesEnabled}
                    blockchains={blockchainsEnabled}
                    statusFiltersEnabled={statusFiltersEnabled}
                    activitiesFiltersEnabled={activitiesFiltersEnabled}
                    action={(r) => {
                      // console.log(r);
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-12">
                <ItemsByCollection
                  collectionId={collectionId}
                  items={items}
                  stop={6}
                  measures={{ md: 4, sm: 6, lg: 4 }}
                />
              </div>
            </div>
          </div>
        )}
      </CollectionSection>

      <Footer />
    </Fragment>
  );
};

export default Collection;
