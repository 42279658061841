import React from "react";
import styled from "styled-components";
import Layout from "../components/layouts/Layout";
import { HEADER_HEIGHT } from "../constants/constants";

const StyledFaqPage = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }
  .tf-section.wrap-accordion {
    padding-top: 0;
  }
  .faq-container {
    padding: 40px;
  }
  .faq-content {
    width: 100%;
  }
`;

const FAQ = () => {
  const faqCMSLink = "https://cms.acmelabs.xyz/faq/";
  const iframe = () => {
    return {
      __html: `'<iframe src="${faqCMSLink}" width="100%" height="500" style="overflow-y: scroll;" scrolling="yes"></iframe>'`,
    };
  };

  return (
    <Layout>
      <StyledFaqPage>
        <div id="spacer"></div>
        <div>
          <div className="faq-container">
            {faqCMSLink && (
              <div className="faq-content" dangerouslySetInnerHTML={iframe()} />
            )}
          </div>
        </div>
      </StyledFaqPage>
    </Layout>
  );
};

export default FAQ;
