import styled from "styled-components";

export const VerificationEmailPage = styled.div`
  @media (max-width: 768px) {
    .left-background {
      display: none;
    }

    .tf-verification-email-sent.tf-section .right-container {
      padding: 40px;
      /* 40 px more for beta banner */
      padding-top: 80px
    }

    .tf-verification-email-sent.tf-section .page-title-heading .heading {
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: #011126;
    }

    .tf-verification-email-sent.tf-section .instructions-container h4 {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      color: #011126;
    }

    .login-btn-link a {
      font-size: 16px;
      font-weight: 500;
      color: #1b70d0;
      position: relative;
    }

    .tf-verification-email-sent.tf-section .instructions-container h4,
    .resend-btn-link a {
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
      text-align: center;
    }

    .tf-verification-email-sent.tf-section .instructions-container h4 {
      margin-bottom: 0;
    }
    .tf-verification-email-sent.tf-section .instructions-container.style2 {
      margin-bottom: 0 !important;
    }
  }
`;

export const EmailVerifiedPage = styled.div`
  @media (max-width: 768px) {
    .tf-email-verified.tf-section .main-content {
      width: 80%;
    }
    .tf-email-verified.tf-section .page-title-heading .heading {
      font-size: 32px;
      line-height: 32px;
    }

    .tf-email-verified.tf-section .instructions-container {
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
    }

    .tf-email-verified.tf-section .left-background .title,
    .tf-email-verified.tf-section .right-background .title {
      font-size: 18px;
      line-height: 18px;
    }
    .tf-email-verified.tf-section .right-background,
    .tf-email-verified.tf-section .left-background {
      height: 30vw;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      align-content: center;
      align-items: center;
    }
  }
`;
