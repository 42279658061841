import styled from "styled-components";
import { COMMON_STYLES } from "../../constants/genericStyles";
import { HEADER_HEIGHT } from "../../constants/constants";

export const StyledCreateActivityPage = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }

  section#create-activity-page {
    padding: 2%;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .page-title-heading {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 30px;
    .vertical-line {
      height: 30px;
      border-left: 6px solid #ffd061;
    }

    .heading {
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
      color: #011126;
    }
  }
  .explanation {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #011126;
    margin-bottom: 20px;
  }

  /* form related */
  form select {
    padding: 0px 20px;
    margin-bottom: 24px;
    line-height: 44px;
  }
  form fieldset {
    margin-bottom: 24px;
  }

  .field-label,
  .form-field-label {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 8px;
  }

  .error-message,
  .card-error {
    color: #e42c2c;
    font-weight: 400;
    font-size: 15px;
  }

  .filled-button {
    ${COMMON_STYLES.FILLED_BUTTON({
      fillColor: "#ff7b7c",
      borderSize: "10px",
    })}
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    width: 100%;
  }

  /* progress overlay css */
  .progress-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    right: 0;
    bottom: 0;
    background: rgba(205, 205, 205, 0.8);
    z-index: 7777;
    position: fixed;

    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    .progess-status {
      display: block;
      width: 100%;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 8px;
      text-align: center;
      color: #011126;
    }
    .spinner {
      width: 50px;
      height: 50px;
    }
  }
`;
