import styled from "styled-components";
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from "../../constants/constants";

export const StyledAboutUsPage = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }
  section#about-us {
    padding-top: 2%;
  }

  .page-title-heading {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 10px;
    .vertical-line {
      height: 38px;
      border-left: 6px solid #ffd061;
    }

    .heading {
      font-weight: 700;
      font-size: 48px;
      line-height: 48px;
      color: #011126;
    }
  }

  /* who are we section */
  .who-are-we-container {
    display: flex;
    justify-content: space-between;
    padding: 2%;
    .left-section {
      width: 40%;
    }
    .page-title-heading {
      margin-bottom: 20px;
    }
    .right-section {
      display: flex;
      align-items: center;
      width: 50%;
    }
    .image-container {
      width: 100%;
    }
    .image-container img {
      width: 100%;
      height: auto;
      border-radius: 17px;
    }
    .who-are-we {
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.1em;
      color: #000000;
      div {
        margin-bottom: 20px;
      }
    }
  }

  /* our mission section */
  .our-mission-container {
    padding: 2%;
    display: flex;
    justify-content: space-between;
    background-color: #2159a2;
    padding-top: 40px;
    padding-bottom: 40px;
    .right-section {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
    }
    .left-section {
      width: 50%;
    }
    .image-container {
      width: 100%;
    }
    .image-container img {
      width: 100%;
      height: auto;
      border-radius: 31px;
    }
    .page-title-heading {
      margin-bottom: 20px;
      width: 100%;
      .heading {
        color: #ffffff;
      }
    }
    .our-mission {
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.1em;
      color: #ffffff;
      div {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 768px) {
    #spacer {
      padding-top: ${MOBILE_HEADER_HEIGHT.toString() + "px"};
    }

    section#about-us {
      padding-top: 6%;
    }

    .who-are-we-container {
      padding: 0% 8%;
      flex-wrap: wrap;
      .left-section {
        width: 100%;
      }
      .right-section {
        width: 100%;
      }
      .who-are-we {
        font-size: 12px;
        line-height: 12px;
        div {
          margin-bottom: 10px;
        }
      }
      .image-section {
        margin-bottom: 24px;
        .image-container img {
          border-radius: 10px;
        }
      }
    }

    .our-mission-container {
      padding: 0% 8%;
      padding-top: 6%;
      flex-wrap: wrap;
      .left-section {
        width: 100%;
      }
      .right-section {
        width: 100%;
      }
      .our-mission {
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 20px;
        div {
          margin-bottom: 10px;
        }
      }
      .image-section {
        margin-bottom: 24px;
        .image-container img {
          border-radius: 16px;
        }
      }
    }

    .page-title-heading {
      display: flex;
      align-items: center;
      align-content: center;
      margin-bottom: 10px;
      .vertical-line {
        height: 24px;
        border-left: 4px solid #ffd061;
      }

      .heading {
        font-size: 24px;
        line-height: 24px;
      }
    }
  }
`;
