import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { useApi } from '../../hooks/useApi';

const ConfirmationDescription = ({ userName, order, action }) => {
  console.log(
    '🚀 ~ file: ConfirmationDescription.jsx ~ line 6 ~ ConfirmationDescription ~ order',
    order
  );
  const navigate = useNavigate();
  const textStyle = {
    fontSize: '22px',
    color: '#000',
  };

  const email = JSON.parse(sessionStorage.getItem('email'));

  return (
    <div className='d-flex flex-column justify-content-between'>
      <h2>
        Thank you, {userName}! Your order number is{' '}
        <span style={{ color: '#6899FA' }}>{order.id}</span>{' '}
      </h2>
      <div className='my-5'>
        <p style={textStyle}>
          You will receive an email confirmation shortly.
        </p>
        <br />
        <span style={{ fontSize: '14px' }}>
          Didn't receive an email?
          <button
            onClick={() => action(email, order.id)}
            style={{
              fontSize: '16px',
              color: '#6899FA',
              padding: 10,
              backgroundColor: 'transparent',
              fontWeight: 'normal',
              border: 'none',
              textDecoration: 'underline',
            }}
          >
            Resend Confirmation Email
          </button>
          <p style={textStyle}>
            Need help? 
          <button
            onClick={() => window.open("https://cms.acmelabs.xyz/feedback/")}
            style={{
              fontSize: '16px',
              color: '#6899FA',
              padding: 10,
              backgroundColor: 'transparent',
              fontWeight: 'normal',
              border: 'none',
              textDecoration: 'underline',
            }}
          >
            Submit an issue
          </button>
          </p>
        </span>
      </div>
      <div className='d-flex flex-column'>
        <button className='sc-button-4' onClick={() => navigate('/my-items')}>
          Return to My Items
        </button>
        <button
          style={{ background: '#fff', color: '#000' }}
          className='sc-button-4'
          onClick={() => navigate('/')}
        >
          Return to Home Page
        </button>
      </div>
    </div>
  );
};

export default ConfirmationDescription;
