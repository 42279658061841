import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { getMyOrders, getMySoldOrders } from "../../api/order/order";
import Layout from "../../components/layouts/Layout";
import Spinner from "../../components/layouts/Spinner/Spinner";
import { useRole } from "../../hooks/useRole";
import OrderList from "./OrderList";
import { StyledMyOrdersPage } from "./styles";

const MyOrders = () => {
  const { isGallery } = useRole();
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [boughtOrders, setBoughtOrders] = useState([]);
  const [soldOrders, setSoldOrders] = useState([]);

  const onChangeFilters = (obj) => {
    if (selectedTab !== 0) return;
  };

  const fetchMyOrders = async () => {
    const ordersData = await getMyOrders();
    const soldOrdersData = await getMySoldOrders();
    setBoughtOrders(ordersData);
    setSoldOrders(soldOrdersData);
  };

  useEffect(() => {
    fetchMyOrders();
  }, []);

  return (
    <Layout>
      <StyledMyOrdersPage>
        <div id="spacer"></div>
        <section id="my-orders">
          <div className="header-container">
            <div className="page-title-heading">
              <div className="vertical-line"></div>
              <h1 className="heading">My Orders</h1>
            </div>
          </div>

          <div className="row m-0">
            <div className="col-xl-2 col-lg-2 col-md-12">
              {/* <Filters
                categories={false}
                blockchains={false}
                action={onChangeFilters}
              /> */}
            </div>

            <div className="col-xl-10 col-lg-10 col-md-12 p-0">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "400px",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <Spinner />
                </div>
              ) : (
                <div className="form-create-item">
                  <div className="flat-tabs themesflat-tabs">
                    <Tabs
                      onSelect={(index) => {
                        setSelectedTab(index);
                        // switch (index) {
                        //   case 0:
                        //     setFilteredItems(items);
                        //     break;
                        //   case 1:
                        //     setFilteredItems(prodigiItems);
                        //     break;
                        //   case 2:
                        //     setFilteredItems(pendingApprovalItems);
                        //     break;
                        //   default:
                        // }
                      }}
                    >
                      <TabList>
                        <Tab>Items Bought</Tab>
                        <Tab>Items Sold</Tab>
                      </TabList>
                      <TabPanel>
                        <OrderList orders={boughtOrders} />
                      </TabPanel>
                      <TabPanel>
                        <OrderList orders={soldOrders} orderType={"SOLD"} />
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </StyledMyOrdersPage>
    </Layout>
  );
};

export default MyOrders;
