import { useState } from "react";
import { useDispatch } from "react-redux";
import { removeFromArr } from "../helpers/commonOperations";
import {
  addToCart,
  initializeCart,
  modifyCartItem,
  removeAllFromCart,
  removeFromCart,
} from "../store/slices/cart/cart";

const storedCart = JSON.parse(localStorage.getItem("cart") || "[]");

const useCart = () => {
  const dispatch = useDispatch();
  const [sale, setSale] = useState(false);

  const changeStatusSale = (status) => {
    setSale(status);
  };

  const modifyQtyCartPersist = (element) => {
    let storedCartInner = JSON.parse(localStorage.getItem("cart") || "[]");
    const itemIndexInCart = storedCartInner.findIndex(
      (item) => item.id === element.id
    );

    if (itemIndexInCart >= 0) {
      const itemInCart = storedCartInner[itemIndexInCart];
      itemInCart.quantity = element.quantity;
      storedCartInner[itemIndexInCart] = itemInCart;
      localStorage.setItem("cart", JSON.stringify([...storedCartInner]));
      dispatch(modifyCartItem(itemInCart));
    }
  };

  const decrementQtyCartPersist = (element) => {
    let storedCartInner = JSON.parse(localStorage.getItem("cart") || "[]");

    const itemIndexInCart = storedCartInner.findIndex(
      (item) => item.id === element.id
    );

    if (itemIndexInCart >= 0 && element.quantity > 0) {
      const itemInCart = storedCartInner[itemIndexInCart];
      itemInCart.quantity--;
      storedCartInner[itemIndexInCart] = itemInCart;
      localStorage.setItem("cart", JSON.stringify([...storedCartInner]));
      dispatch(modifyCartItem(itemInCart));
    }
  };

  const addToCartPersist = (element) => {
    try {
      let storedCartInner = JSON.parse(localStorage.getItem("cart") || "[]");

      const itemIndexInCart = storedCartInner.findIndex(
        (item) => item.id === element.id
      );
      if (itemIndexInCart >= 0) {
        const itemInCart = storedCartInner[itemIndexInCart];
        itemInCart.quantity++;
        storedCartInner[itemIndexInCart] = itemInCart;
        dispatch(modifyCartItem(itemInCart));
      } else {
        storedCartInner.push({ ...element, quantity: 1 });
        dispatch(addToCart(element));
      }
      localStorage.setItem("cart", JSON.stringify([...storedCartInner]));
    } catch (e) {
      console.log(e);
    }
  };

  const addToCartPersistWithQuantity = (element) => {
    try {
      let storedCartInner = JSON.parse(localStorage.getItem("cart") || "[]");

      const itemIndexInCart = storedCartInner.findIndex(
        (item) => item.id === element.id
      );
      if (itemIndexInCart >= 0) {
        const itemInCart = storedCartInner[itemIndexInCart];
        itemInCart.quantity++;
        storedCartInner[itemIndexInCart] = itemInCart;
        dispatch(modifyCartItem(itemInCart));
      } else {
        storedCartInner.push({ ...element });
        dispatch(addToCart(element));
      }
      localStorage.setItem("cart", JSON.stringify([...storedCartInner]));
    } catch (e) {
      console.log(e);
    }
  };

  const removeAllPersist = (element) => {
    localStorage.setItem("cart", JSON.stringify([]));
    dispatch(removeAllFromCart());
  };

  const removeFromCartPersist = (element) => {
    const arr = JSON.parse(localStorage.getItem("cart") || "[]");
    const itemToRemove = element.id;
    const itemsToKeep = removeFromArr({ arr, itemToRemove });
    localStorage.setItem("cart", JSON.stringify(itemsToKeep));
    dispatch(removeFromCart(itemToRemove));
  };

  const verifyCart = (element) => {
    return storedCart.filter((elem) => elem.id === element.id).length > 0;
  };

  const restoreCart = () => {
    const arr = JSON.parse(localStorage.getItem("cart") || "[]");
    dispatch(initializeCart(arr));
  };

  return {
    addToCartPersist,
    addToCartPersistWithQuantity,
    removeFromCartPersist,
    verifyCart,
    sale,
    changeStatusSale,
    removeAllPersist,
    modifyQtyCartPersist,
    decrementQtyCartPersist,
    restoreCart,
  };
};

export default useCart;
