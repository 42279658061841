import styled from "styled-components";
import { COMMON_STYLES } from "../../constants/genericStyles";
import { HEADER_HEIGHT } from "../../constants/constants";

export const StyledWalletPage = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }
  .tf-connect-wallet.tf-section {
    padding-top: 0;
  }
`;

export const StyledWalletSection = styled.section`
  padding-top: 60px;

  .main-indication {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
  }

  .sub-title {
    padding: 0;
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
  }

  .indications {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
    padding: 0px 90px;
    margin-bottom: 20px;
  }

  .divider {
    width: 100%;
    border: none;
    border-top: 1px solid #9a9a9a;
    background: none;
    margin-bottom: 55px;
  }

  .wallets-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 42px;
    padding: 0 7%;
  }

  .wallets-container .wallet {
    width: 17%;
  }

  .sc-box-icon-inner.style-2 {
    margin: 0 !important;
    align-content: flex-start;
  }

  .wallet .sc-box-icon {
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
  }

  .wallet .image {
    width: 100%;
    margin-bottom: 8px;
  }

  .wallet .image img {
    width: 100%;
    height: auto;
  }

  .wallet .note {
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    text-transform: capitalize;
    color: #2159a2;
    margin-bottom: 8px;
  }

  .wallet .logout-note {
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
  }

  a {
    color: #1b70d0;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
  }

  ${COMMON_STYLES.UNDERLINE_ANIMATION({ color: "#1b70d0" })}
  .sc-button-3 {
    border: 3px solid var(--button-green);
    color: var(--button-green);
    transition: all 0.3s ease;
    margin-top: 5px;
    padding: 5px 30px;
    font-size: 16px;
  }

  .wallet-details {
    p {
      width: 100%;
      text-align: center;
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 768px) {
    padding-top: 40px;
    h5 {
      font-size: 12px;
      text-align: center;
      margin-bottom: 8px;
    }
    .tf-connect-wallet.tf-section {
      padding-bottom: 20px;
    }

    p {
      font-size: 10px;
      line-height: 10px;
    }

    .main-indication {
      font-size: 20px;
      line-height: 20px;
    }

    .wallet-details {
      p {
        width: 100%;
        text-align: center;
        font-size: 10px;
        line-height: 10px;
        margin-bottom: 10px;
      }
    }

    .wallet .logout-note {
      font-size: 10px;
      line-height: 10px;
    }

    .sub-title {
      font-size: 10px;
      line-height: 10px;
      margin-bottom: 20px !important;
      a {
        font-size: 10px;
        line-height: 10px;
      }
    }

    .indications {
      padding: 0 40px;
      font-size: 10px;
      line-height: 10px;
      a {
        font-size: 10px;
        line-height: 10px;
      }
    }

    .divider {
      margin-bottom: 20px;
    }

    .wallets-container {
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 40px;
      margin-bottom: 20px;
    }

    .wallets-container .wallet {
      width: 40%;
    }

    .wallet.sc-box-icon-inner.style-2 {
      margin-bottom: 20px !important;
    }

    .sc-button-3 {
      width: 100%;
      font-size: 10px;
      line-height: 10px;
      padding: 8px;
      border-radius: 6px;
      margin-top: 0px;
      margin-bottom: 4px;
    }

    .wallet .note {
      font-size: 10px;
      line-height: 10px;
    }
  }
`;
