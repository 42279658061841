import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CardModal from "../../../components/layouts/CardModal";
import useCart from "../../../hooks/useCart";
import { useCustomNavigator } from "../../../hooks/useCustomNavigator";

const BuyBtn = ({ card }) => {
  const { addToCartPersist, verifyCart } = useCart();
  const { customNavigate } = useCustomNavigator();
  const { wallets } = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const buyItem = async () => {
    const itemVerification = verifyCart(card);
    await addToCartPersist(card);
    if (!wallets.owner.email) {
      customNavigate({ path: "/cart" });
    } else {
      toast.success("Item added to cart", {
        onClose: () => {
          customNavigate({ path: "/cart" });
        },
      });
    }
  };

  return (
    <>
      {!showModal ? null : (
        <CardModal
          show={showModal}
          token={card}
          onHide={() => setShowModal(false)}
          isBuy={false}
        />
      )}

      <button className="filled-button full-width buy-now" onClick={buyItem}>
        <span>Buy Now</span>
      </button>
    </>
  );
};

export default BuyBtn;
