import { useForm } from "react-hook-form";
import styled from "styled-components";
import { SUCCESFUL_SUBSCRIPTION } from "../../constants/constants";
import { useApi } from "../../hooks/useApi";
const { REACT_APP_API_URL } = process.env;

const StyledNewsletter = styled.div`
  input {
    color: white;
  }
`;

const Newsletter = ({ notify }) => {
  const { register, handleSubmit } = useForm();
  const { executeApiRequest } = useApi();

  const onSubmit = async (data) => {
    try {
      await executeApiRequest(
        `${REACT_APP_API_URL}general/email-for-marketing`,
        {
          method: "POST",
          body: JSON.stringify({
            email: data.email,
          }),
          headers: { "Content-Type": "application/json" },
        }
      );
      notify(SUCCESFUL_SUBSCRIPTION);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <StyledNewsletter>
      <div className="form-subcribe">
        <form
          id="subscribe-form"
          onSubmit={handleSubmit(onSubmit)}
          acceptCharset="utf-8"
          className="form-submit"
        >
          <input
            name="email"
            className="email"
            type="email"
            placeholder="info@yourgmail.com"
            {...register("email")}
            required
          />
          <button id="submit" name="submit" type="submit">
            <i className="icon-fl-send"></i>
          </button>
        </form>
      </div>
    </StyledNewsletter>
  );
};

export default Newsletter;
