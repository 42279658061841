import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Layout from "../../components/layouts/Layout";
import Spinner from "../../components/layouts/Spinner/Spinner";
import {
  ALL_WALLET_TYPES,
  HEADER_HEIGHT,
  SUCCESFUL_PROFILE_EDITING,
} from "../../constants/constants";
import { notify, notifyError } from "../../helpers/toastNotificationService";
import { useApi } from "../../hooks/useApi";
import { useRole } from "../../hooks/useRole";
import { editUser } from "../../store/slices/user/user";
import BasicData from "./BasicData";
import Bio from "./Bio";
import CardProfile from "./CardProfile";
import DefaultWalletDropdown from "./DefaultwalletDropdown/DefaultWalletDropdown";
import EditCover from "./EditCover";
import Password from "./Password";
import ShippingInfo from "./ShippingInfo";
import SocialMedia from "./SocialMedia";
import Space from "./Space";
import SpecialInvitation from "./SpecialInvitation";
const { REACT_APP_API_URL } = process.env;

const StyledEditProfile = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }
  .tf-section {
    padding-top: 0;
  }
  .dropdown-title {
    width: 100%;
    margin-bottom: 8px;
  }
  .line-divider {
    border-bottom: 2px solid #25a29d;
    margin-bottom: 15px;
  }
`;

const EditProfile = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { executeApiRequest, executeApiRequestTwo } = useApi();
  const { isGallery } = useRole();
  const methods = useForm();

  useEffect(() => {
    methods.reset();
  }, []);

  const patchAccessCode = async (code) => {
    const invitationData = await executeApiRequestTwo(
      `${REACT_APP_API_URL}user/addInvite`,
      {
        body: JSON.stringify({ code }),
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
      }
    );
    // if (Object.entries(userData).length > 0) {
    //   if (userData.statusCode === 400) {
    //     notifyError(userData.message);
    //   } else {
    //     notify(SUCCESFUL_CODE_SUBMISSION);
    //   }
    // }
    // console.log(
    //   "🚀 ~ file: EditProfile.jsx ~ line 41 ~ submitProfileInfo ~ userData",
    //   userData
    // );
  };

  const submitProfileInfo = async (data) => {
    // console.log({ user, data });
    setLoading(true);
    const profileData = data;
    for (const key in profileData) {
      profileData[key] = profileData[key] || undefined;
    }

    if (profileData.confirm) delete profileData.confirm;

    const userData = await executeApiRequestTwo(`${REACT_APP_API_URL}user`, {
      body: JSON.stringify(profileData),
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
    });
    console.log(
      "🚀 ~ file: EditProfile.jsx ~ line 41 ~ submitProfileInfo ~ userData",
      userData
    );

    if (Object.entries(userData).length > 0) {
      if (userData.statusCode === 400) {
        notifyError(userData.message);
      } else {
        notify(SUCCESFUL_PROFILE_EDITING);
        dispatch(editUser(userData));
      }
    }

    if (data?.specialInvitation) {
      patchAccessCode(data?.specialInvitation);
    }

    setLoading(false);
  };

  const submitSpaceInfo = async (data) => {
    let spaceData = {};
    if (data.bio) spaceData["bio"] = data.bio;
    if (data.customURL) spaceData["customURL"] = data.customURL;
    console.log(
      "🚀 ~ file: EditProfile.jsx ~ line 52 ~ submitSpaceInfo ~ spaceData",
      spaceData
    );
    if (Object.keys(spaceData).length !== 0) {
      await executeApiRequest(`${REACT_APP_API_URL}space`, {
        body: JSON.stringify(spaceData),
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
      });
    }
  };

  const onSubmit = async (data) => {
    await submitProfileInfo(data);
    await submitSpaceInfo(data);
  };

  return (
    <StyledEditProfile>
      <Layout>
        <div id="spacer"></div>
        <div>
          <div className="tf-create-item tf-section edit-profile">
            {loading && (
              <div className="spinner-overlay">
                <Spinner />
              </div>
            )}
            <div className="themesflat-container py-5">
              <FormProvider {...methods}>
                <form
                  autoComplete="off"
                  onSubmit={methods.handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 order-md-2 order-lg-1 social-bio-container">
                      <CardProfile imgProfile={user.profileFileName} />
                      <Bio />

                      <>
                        <h4 className="text-center dropdown-title">
                          Default Wallet
                        </h4>
                        <DefaultWalletDropdown
                          methods={methods}
                          user={user}
                          dropdownValues={ALL_WALLET_TYPES}
                        />
                        <div className="line-divider"></div>
                      </>

                      <SocialMedia user={user} />
                    </div>
                    <div className="col-xl-9 col-lg-8 col-md-12 col-12 order-md-1 order-lg-2 main-container">
                      <div className="form-upload-profile">
                        <EditCover coverFileName={user.coverFileName} />
                        <hr />
                        <BasicData user={user} />
                        <hr />
                        <div className="row">
                          <Password />
                          <Space user={user} />
                        </div>
                        <hr />
                        <div className="row">
                          <SpecialInvitation />
                        </div>
                        <hr />
                        <ShippingInfo user={user} />
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <button type="submit" className="center save-btn">
                      Save All Changes
                    </button>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </Layout>
    </StyledEditProfile>
  );
};

export default EditProfile;
