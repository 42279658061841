import { AwesomeQRCode } from "@awesomeqr/react";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { HASHPACK_CHROME_EXTENSION_URL } from "../../../constants/constants";
import useHashconnect from "../../../hooks/useHashconnect";
import { StyledModal } from "./styles";

const PairingModal = ({ show, onHide }) => {
  const [isChrome, setIsChrome] = useState(false);
  const [codePairingString, setCodePairingString] = useState("");

  const { getPairingData, connectToExtension, detectExtension } =
    useHashconnect();

  const initPairingData = async () => {
    const pairingString = getPairingData();
    if (pairingString) {
      setCodePairingString(pairingString);
    }
  };

  const pairingWithChrome = async () => {
    const extension = detectExtension();
    if (!extension) {
      window.open(HASHPACK_CHROME_EXTENSION_URL, "_blank");
      return;
    }
    connectToExtension();
  };

  useEffect(() => {
    const isChrome = navigator.userAgentData?.brands?.some(
      (b) => b.brand === "Google Chrome"
    );
    setIsChrome(isChrome);
    initPairingData();
  }, []);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="popup-blocker-modal"
    >
      <Modal.Header
        style={{
          backgroundColor: "#011126",
        }}
        closeButton
      ></Modal.Header>
      <Modal.Body
        style={{
          backgroundColor: "#011126",
        }}
      >
        <StyledModal>
          {codePairingString && (
            <div style={{ marginBottom: "20px" }}>
              <div className="pairing-code">Pair with Code:</div>
              <input
                type="text"
                placeholder={codePairingString}
                value={codePairingString}
                readOnly
              />
            </div>
          )}

          {isChrome && (
            <div style={{ marginBottom: "20px" }}>
              <div className="pairing-code">Pair with installed Extension:</div>
              <div className="w-100 flex justify-content-start">
                <button
                  className="sc-button-3 no-bg"
                  onClick={pairingWithChrome}
                >
                  connect
                </button>
              </div>
            </div>
          )}

          {codePairingString && (
            <>
              <div className="qr-label">Pair with QR:</div>
              <div className="qr-container">
                <AwesomeQRCode
                  options={{
                    text: codePairingString,
                  }}
                  onStateChange={(state) => {
                    switch (state) {
                      case "working":
                        break;
                      case "idle":
                        break;
                      default:
                    }
                  }}
                />
              </div>
            </>
          )}

          {/* <div style={{ marginBottom: "20px" }}>
            <div className="pairing-code">Disconnect:</div>
            <div className="w-100 flex justify-content-start">
              <button className="sc-button-3 no-bg" onClick={disconnect}>
                disconnect
              </button>
            </div>
          </div> */}
        </StyledModal>
      </Modal.Body>
    </Modal>
  );
};

export default PairingModal;
