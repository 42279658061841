import { useState, useEffect } from 'react';
import UploadButton from '../../components/layouts/button/UploadButton';
import { useApi } from '../../hooks/useApi';
import { useDispatch } from 'react-redux';
import { editCoverFile } from '../../store/slices/user/user';
import { useSelector } from 'react-redux';
import { useS3Url } from '../../hooks/useS3Url';
import HeadingText from './HeadingText';
import { REACT_APP_API_URL } from '../../constants/constants';

const EditCover = ({coverFileName}) => {
  const { getS3Url } = useS3Url();

  const [img, setImg] = useState();
  const fileName = 'coverImage';
  const { executeApiRequest, executeApiRequestTwo } = useApi();
  const dispatch = useDispatch();

  useEffect(() => {
    if(!coverFileName ) return;
    if(coverFileName.includes("amazonaws")){
      setImg(coverFileName); return;
    }
    const coverFileNameS3 = getS3Url(coverFileName, 'coverImage')
    if(coverFileNameS3.length!==0){
      setImg(coverFileNameS3);
    }
  }, [coverFileName, getS3Url]);

  const uploadPicture = async (picture) => {
    try{
      const formData = new FormData();
      formData.append('file', picture);
      const res = await executeApiRequestTwo(
        `${REACT_APP_API_URL}user/cover-image`,
        {
          method: 'PUT',
          body: formData,
        }
      );
      if (res.coverFileName) {
        dispatch(editCoverFile(getS3Url(res.coverFileName, 'coverImage')));
      }
    }
    catch(e){
      console.log(e)
    }
 
  };

  const deletePicture = (e) => {
    e.preventDefault();
    executeApiRequest(`${REACT_APP_API_URL}user/delete-cover-image`, {
      method: 'PATCH',
    });
    setImg(null);
    dispatch(editCoverFile(null));
  };

  const onImageChange = async (element) => {
    await uploadPicture(element);
  };

  return (
    <section id="cover" className='row'>
      <div className='col-12'>
        <HeadingText title={"Cover Photo"} variant="small"/>
        <div>
          {img ? (
            <div className='image-container'>
              <img src={img} alt='UserCover' />
            </div>
          ) : (
            <p>No cover image selected!</p>
          )}
        </div>

        <div
          className={`option-profile${img ? '-cover' : ''} clearfix`}
        >
          <UploadButton
            title={`${img ? 'Change' : 'Add'} Cover Photo`}
            action={onImageChange}
            fileName={fileName}
          />
          {img && (
            <div className='upload-profile'>
              <button className='btn-upload cover' onClick={deletePicture}>
                Delete Picture
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default EditCover;
