import React from "react";

const NFTCardVideo = ({ isPreview, itemData, element }) => {
  return (
    <video
      id="desktop-video"
      width="100%"
      height="100%"
      style={{
        position: "absolute",
        objectFit: "contain",
        backgroundColor: element?.backgroundColor
          ? element?.backgroundColor
          : "",
      }}
      autoPlay
      muted
      loop
      poster={isPreview ? itemData.image : itemData.thumbnailImage}
    >
      <source
        src={isPreview ? itemData.image : itemData.thumbnailImage}
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
  );
};

export default NFTCardVideo;
