import { useFormContext } from "react-hook-form";
import {
  PASSWORD_INVALID_MESSAGE,
  PASSWORD_PATTERN,
} from "../../constants/constants";
import HeadingText from "./HeadingText";

const Password = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const passwordField = watch("password");
  const validateConfirm = (value) => {
    if (passwordField && value !== passwordField) {
      return "Passwords do not match";
    }
  };

  return (
    <section id="security" className="col-md-5 col-12 border-right pl-md-4">
      <HeadingText title={"Security"} variant="small" />

      <fieldset>
        <h6>New Password</h6>
        <input
          id="password"
          {...register("password", {
            pattern: {
              value: PASSWORD_PATTERN,
              message: PASSWORD_INVALID_MESSAGE,
            },
          })}
          type="password"
          placeholder="Set Your Password"
          autoComplete="new-password"
        />
        <p className="error-message">{errors.password?.message}</p>
      </fieldset>
      <br />
      <fieldset>
        <h6>Confirm New Password</h6>
        <input
          id="confirm"
          type="password"
          placeholder="Confirm Your Password"
          {...register("confirm", {
            validate: {
              value: (value) => validateConfirm(value),
            },
          })}
          autoComplete="new-password"
        />
        <p className="error-message">{errors.confirm?.message}</p>
      </fieldset>
    </section>
  );
};

export default Password;
