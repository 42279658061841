import styled from "styled-components";
import backgroundImage from "../../assets/images/backgroup-secsion/img_bg_slider_3.png";
import { COMMON_STYLES } from "../../constants/genericStyles";
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from "../../constants/constants";

export const StyledActivityPage = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }

  #top-announcements {
    ${COMMON_STYLES.MAIN_SLIDER_BACKGROUND}
    background-image: url(${backgroundImage});
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    align-items: flex-start;
    padding-left: 3%;
    padding-top: 2%;
    padding-right: 2%;
    padding-bottom: 2%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height:30vh;

    .tf-title {
      span {
        color: white;s
      }
    }
  }
  #recent-activity {
    padding-top: 30px;
  }
  .filters-container {
    padding-left: 16%;
    padding-right: 3%;
  }

  .heading {
    justify-content: flex-start;
    align-items: center;
  }

  .heading .tf-title {
    font-size: 48px;
    line-height: 100%;
    color: #011126;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .heading .vertical {
    margin-right: 10px;
    border-left: 8px solid #ffd061;
    height: 30px;
    display: inline-block;
  }

  .heading .span-container {
    display: flex;
    align-items: center;
    color: #011126;
  }

  .heading.small .tf-title {
    font-size: 32px;
  }

  .edit-profile .heading.small .vertical {
    height: 32px;
  }

  #announcement-cards-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 4%;
    position: relative;
  }

  .swiper{
    position: static;
  }

  .swiper-button-prev,
  .mainslider .swiper-button-next {
    width: 50px;
    height: 50px;
    background: var(--accent-button);
    border-radius: 50%;
  }

  .swiper-button-next:after,
  .mainslider .swiper-button-prev:after {
    font-size: 16px;
    color: #fff;
  }

  .swiper-pagination {
    bottom: 4%;
  }
  .swiper-pagination-bullet {
    background-color: #ffffff;
    opacity: 1;
    width: 13px;
    height: 14px;
  }
  .swiper-pagination-bullet-active {
    background-color: #ffd061;
  }
  .swiper-button {
    cursor: pointer;
    color: #fff;
    position: absolute;
    width: 2%;
    height: auto;
    z-index: 100;
    bottom: 3%;
    &:hover {
      opacity: 0.8;
    }
  }
  .swiper-button img {
    width: 100%;
    height: auto;
  }
  .swiper-button.custom-prev-button {
    left: 43%;
  }
  .swiper-button.custom-next-button {
    right: 43%;
  }

  @media (max-width: 768px) {
    #spacer {
      padding-top: ${MOBILE_HEADER_HEIGHT.toString() + "px"};
    }

    #top-announcements{
      padding-top: 20px;
      min-height: auto;
    }

    #announcement-vertical-container{
      padding: 20px 30px ;
    }

    .heading.small .tf-title {
      font-size: 20px;
      line-height: 20px;
      color: #FFFFFF;
      .vertical {
        margin-right: 6px;
        border-left: 4px solid #ffd061;
        height: 20px;
        display: inline-block;
      }
    }
  }
`;
