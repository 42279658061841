import copy from "copy-to-clipboard";
import React, { useEffect, useState } from "react";
import { getRoomByCollection } from "../../api/room/getRooms";
import getUser, { getMyUser } from "../../api/user/getUser";
import Assets from "../../assets/Assets";
import {
  BASE_URL,
  IS_FOLLOW_ENABLED,
  IS_OTHER_OPTIONS_ENABLED,
  IS_SHARE_ENABLED,
  IS_TESTING_DESCRIPTION_UI,
  IS_VIRTUAL_SPACE_ENABLED,
  PLACEHOLDER_DESCRIPTION,
  REACT_APP_FRONTEND_URL,
} from "../../constants/constants";
import { copyToClipboard, iOSversion } from "../../helpers/itemHelpers";
import { useApi } from "../../hooks/useApi";
import CollectionImage from "./CollectionImage";

const InfoCollection = ({ collectionInfo }) => {
  const [likes] = useState(collectionInfo?.numberOfLikes ?? 0);
  const [itemsCount] = useState(collectionInfo?.itemsCount ?? 0);
  const statsData = [
    { statName: "items", statResult: itemsCount },
    { statName: "followers", statResult: 0, disabled: !IS_FOLLOW_ENABLED },
    { statName: "likes", statResult: likes },
  ];

  const { executeApiRequestTwo } = useApi();
  const [userData, setUserData] = useState({});
  const [spaceUrl, setSpaceUrl] = useState();
  const [visible, setVisible] = useState(false);
  const [copied, setCopied] = useState(false);

  const likeCollection = async () => {
    try {
      // await executeApiRequestTwo(
      //   `${REACT_APP_API_URL}space/like/${collectionInfo.id}`,
      //   {
      //     method: "PUT",
      //   }
      // );
    } catch (e) {
      console.log(e);
    }
  };

  const getSpaceData = async () => {
    const collectionId = collectionInfo?.id;
    const collectionName = collectionInfo?.tokenName;
    if (!collectionId || !collectionName) return;

    const roomData = await getRoomByCollection({ collectionId });
    const roomId = roomData?.id;

    if (roomId) {
      const url = `${BASE_URL}/webxr/index.html?lid=${encodeURI(
        roomId
      )}&sid=${encodeURI(collectionName)}&nid=al`;
      setSpaceUrl(url);
    }
  };

  const getUserData = async (id) => {
    if (id) {
      try {
        const data = await getUser(id);
        data && setUserData(data);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const getEncodedShareableURL = async () => {
    const itemSeparator = "collection";
    const encodedURL = encodeURI(
      `${REACT_APP_FRONTEND_URL}${itemSeparator}/${collectionInfo?.id}`
    );
    const myUser = await getMyUser();
    const shareableURL = encodeURI(
      encodedURL +
        (myUser?.affiliateCode ? `?affl=${myUser?.affiliateCode}` : "")
    );
    return shareableURL;
  };

  const onCopyLink = async () => {
    const shareableURL = await getEncodedShareableURL();
    setCopied(true);
    const ver = iOSversion();
    if (ver && ver?.[0] >= 10) {
      setTimeout(() => {
        copyToClipboard(shareableURL);
      });
    } else {
      copy(shareableURL);
    }
    setTimeout(() => {
      setCopied(false);
      setVisible(false);
    }, "2000");
  };

  const onTwitterShare = async () => {
    const shareableURL = await getEncodedShareableURL();
    const encodedMessage = encodeURI(
      `Check out what I've been curating at Acme Labs!\n`
    );
    const twitterURL = `https://twitter.com/intent/tweet?text=${encodedMessage}&url=${shareableURL}`;
    setTimeout(() => {
      window.open(twitterURL, "_blank");
    });
  };

  const onFacebookShare = async () => {
    const shareableURL = await getEncodedShareableURL();
    const facebookURL = `https://facebook.com/sharer/sharer.php?u=${shareableURL}`;
    setTimeout(() => {
      window.open(facebookURL, "_blank");
    });
  };

  useEffect(() => {
    (async () => {
      getUserData(collectionInfo?.artist?.userId);
      getSpaceData();
    })();
  }, [collectionInfo]);

  return (
    <>
      <div className="row m-0">
        <div className="actions-container">
          {collectionInfo?.id && (
            <i
              onClick={likeCollection}
              className={
                (collectionInfo.liked ? "fa-solid" : "fa-regular") + " fa-heart"
              }
              style={{ fontStyle: "normal", cursor: "pointer" }}
            ></i>
          )}

          {IS_SHARE_ENABLED && (
            <span
              className="fa-share-alt"
              onClick={() => setVisible(!visible)}
            ></span>
          )}

          {visible && (
            <div className="share_popup mt-20">
              <div className="share_option" onClick={onTwitterShare}>
                <img src={Assets.twitterIcon} alt={"twitter icon"} />
                <p>Twitter</p>
              </div>

              <div className="share_option" onClick={onFacebookShare}>
                <img src={Assets.facebookIcon} alt={"facebook icon"} />
                <p>Facebook</p>
              </div>

              <div className="share_option" onClick={onCopyLink}>
                <img src={Assets.copyIcon} alt={"copy icon"} />
                <p className="copy-link">
                  {copied ? "Link Copied" : "Copy Link"}
                </p>
              </div>
            </div>
          )}

          {IS_OTHER_OPTIONS_ENABLED && (
            <span className="fa-elipsis-stroke"></span>
          )}
        </div>

        <div className="left-side-container">
          <CollectionImage dbImage={collectionInfo?.collectionImage} />
        </div>

        <div className="right-side-container">
          <div className="information-container">
            <div className="page-title-heading">
              <div className="vertical-line"></div>
              <h1 className="heading">{collectionInfo.tokenName}</h1>
            </div>
            {collectionInfo?.curatorName && (
              <div className="curator-container">
                Curated by:{" "}
                <span className="curator-name">
                  {collectionInfo?.curatorName}
                </span>
              </div>
            )}
            <hr className="faint-line" />
            <p className="collection-description">
              {collectionInfo.description}
              {!collectionInfo.description &&
                IS_TESTING_DESCRIPTION_UI &&
                PLACEHOLDER_DESCRIPTION}
            </p>
            <div className="row m-0">
              {IS_VIRTUAL_SPACE_ENABLED && spaceUrl && (
                <a href={spaceUrl} rel="noreferrer" target="_blank">
                  <div>
                    <button className="filled-button">
                      <span>launch virtual space</span>
                    </button>
                  </div>
                </a>
              )}
            </div>
          </div>
          {IS_FOLLOW_ENABLED && (
            <div className="follow-container">
              <div>
                <button className="outlined-button button__bottom">
                  <span>follow</span>
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-2">
          {/* <button className='sc-button-3 no-bg'>follow</button> */}
        </div>
      </div>

      <div className="row m-0 stats-container">
        {statsData &&
          statsData.map(
            (stat, i) =>
              !stat.disabled && (
                <div className="stat-container" key={"stat" + i}>
                  <p className="stat-title">{stat.statResult}</p>
                  <h3 className="stat-number">{stat.statName}</h3>
                </div>
              )
          )}
      </div>
    </>
  );
};

export default InfoCollection;
