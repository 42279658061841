import styled from "styled-components";
import { HEADER_HEIGHT } from "../../constants/constants";
import { COMMON_STYLES } from "../../constants/genericStyles";

export const BaseItemPage = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }
  .tf-section.tf-item-details {
    padding-top: 0;
  }
  .themesflat-container {
    padding-left: 4%;
    padding-right: 4%;
  }
  .sc-item-details {
    margin-bottom: 20px;
  }
  .tf-item-details .content-right {
    padding-left: 0;
  }
  .btn-container {
    width: 80%;
  }
  .filled-button {
    ${COMMON_STYLES.FILLED_BUTTON({ fillColor: "#25A29D", borderSize: "10px" })}
    &.buy-now {
      ${COMMON_STYLES.FILLED_BUTTON({
        fillColor: "#ff7b7c",
        borderSize: "10px",
      })}
    }
    &.full-width {
      width: 100%;
      font-weight: 700;
      font-size: 36px;
      line-height: 36px;
    }
  }
  .outlined-button {
    ${COMMON_STYLES.OUTLINED_BUTTON({
      textColor: "#ff7b7c",
      borderSize: "10px",
    })}
    margin-bottom: 20px;
  }
  .actions-container {
    padding-right: 20px;
    padding-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    /* margin-bottom: 30px; */
  }

  .fa-heart,
  .fa-share-alt,
  .fa-elipsis-stroke {
    font-family: "Font Awesome 5 Pro";
    height: auto;
    font-size: 24px;
    margin-right: 26px;
    cursor: pointer;
  }
  .fa-elipsis-stroke {
    margin-right: 0px;
  }

  .fa-elipsis-stroke::before {
    content: "\f39b";
  }

  .item-card {
    border: 1px solid #d7d7d7;
    box-shadow: 6px 6px 17px rgba(0, 0, 0, 0.25);
    border-radius: 18px;
    margin-bottom: 20px;
  }

  .sc-item-details {
    padding: 20px;
    position: relative;
  }

  .header-container {
    display: flex;
    position: relative;
  }

  .page-title-heading {
    display: flex;
    align-items: center;
    align-content: center;
    .vertical-line {
      height: 30px;
      border-left: 8px solid #ffd061;
    }

    .heading {
      font-weight: 700;
      font-size: 48px;
      line-height: 48px;
      color: #011126;
    }
  }

  .nft-artist {
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #1b70d0;
  }

  .nft-description,
  .nft-left {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #011126;
  }

  .nft-product-name {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #9a9a9a;
  }

  .price-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .price-label {
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    text-align: center;
    text-transform: capitalize;
    color: #2159a2;
    margin-bottom: 16px;
  }

  .price {
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    color: #011126;
    margin-bottom: 16px;
  }

  .table-row {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    align-items: flex-start;
    h6 {
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.019em;
      color: #000000;
    }
    p,
    ul {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.019em;
      color: rgba(148, 148, 148, 0.8);
      text-align: left;
      width: 40%;
    }
    p.blue {
      color: #1b70d0;
    }
  }

  .properties-container {
    .properties-text {
      padding: 10px;

      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #000000;
    }
  }
  .creator-container {
    .social-media {
      align-items: flex-start;
    }
    .creator-description {
      padding: 10px;

      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #000000;
    }
    ul {
      color: #1b70d0;
      li {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        margin-bottom: 4px;
        img {
          width: 15px;
          height: auto;
          margin-right: 7px;
        }
        a {
          color: #1b70d0;
        }
      }
    }
  }

  .related-items {
    padding-top: 40px;
    padding-bottom: 40px;
    background: #214377;
    .header-container {
      width: 100%;
      h1 {
        color: white;
      }
    }
  }

  .gray-background {
    width: 100px;
    height: 100px;
    background: #d9d9d9;
  }

  .share_popup {
    position: absolute;
    right: 0;
    padding: 20px 10px;
    top: 60px;
    overflow: hidden;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    visibility: visible;
    -webkit-transform: translateY(-1rem);
    transform: translateY(-1rem);

    background-color: #ffffff;
    border: 1px solid #d7d7d7;
    box-shadow: 6px 6px 17px rgba(0, 0, 0, 0.25);
    border-radius: 18px;
    padding: 17px 17px;
    padding-bottom: 7px;
    z-index: 100;
    .share_option {
      display: flex;
      margin-bottom: 10px;
      cursor: pointer;

      img {
        width: 23px;
        height: 23px;
        margin-right: 6px;
      }
      p,
      a {
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        color: #011126;
      }
    }

    .links {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      a {
        color: var(--header-buttons-font);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }
    }
    .links a i {
      margin-right: 5px;
      font-size: 20px;
    }

    .links a:hover {
      color: var(--primary-color3);
    }
  }

  .share_popup.visible {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .collection-info-container {
    display: flex;
    justify-content: flex-start;
    font-size: 20px;
    line-height: 20px;
    color: #000000;

    margin-top: 20px;
    .collection-label {
      margin-right: 4px;
    }
    .collection-value {
      a {
        color: #1b70d0;
      }
    }
  }

  @media (max-width: 768px) {
    #spacer {
      padding-top: 80px;
    }
    .tf-section.tf-item-details {
      padding: 0;
    }
    .item-card {
      border: none;
      box-shadow: none;
      border-radius: 0px;
      margin-bottom: 0;
    }

    .sc-item-details {
      padding: 4px;
      position: relative;
      margin-bottom: 0;
    }
    .fa-heart,
    .fa-share-alt,
    .fa-elipsis-stroke {
      font-size: 12px;
      margin-right: 12px;
    }
    .avatar-image {
      border-radius: 9px;
      width: 43px;
      height: 43px;
      margin-right: 10px;
    }
    .nft-artist,
    .nft-product-name {
      font-size: 10px;
      line-height: 10px;
    }
    .nft-description {
      font-size: 10px;
      line-height: 10px;
    }
    .nft-left {
      font-size: 10px;
      line-height: 10px;
    }
    .price-label {
      font-size: 14px;
      line-height: 14px;
    }
    .collection-info-container {
      font-size: 14px;
      line-height: 14px;
    }
    .price {
      font-size: 20px;
      line-height: 20px;
    }

    .filled-button.full-width {
      width: 100%;
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
    }
    .sc-button-7 {
      padding: 9px 60px;
      border-radius: 10px;
      border: 5px solid #247773;
      span {
        color: #25a29d;
      }
    }
    .filled-button,
    .sc-button-7 a span {
      font-size: 18px;
      line-height: 18px;
    }
    .page-title-heading {
      .vertical-line {
        height: 13px;
        border-left: 3px solid #ffd061;
        margin-right: 4px;
      }

      .heading {
        font-size: 24px;
        line-height: 24px;
      }
    }

    .react-tabs__tab-list {
      display: flex;
      justify-content: space-between;
    }
    .themesflat-tabs .react-tabs__tab {
      font-size: 12px;
      line-height: 12px;
      margin-bottom: 10px;
      margin-right: 0px;
    }
    .tf-item-details .content-right .themesflat-tabs .react-tabs__tab-panel {
      height: auto;
    }
    .creator-container .creator-description,
    .properties-container .properties-text {
      font-size: 7px;
      line-height: 150%;
    }

    .table-row {
      h6,
      p {
        font-size: 7px;
        line-height: 150%;
      }
    }
  }
`;
