import { Link } from "react-router-dom";
import styled from "styled-components";
import LinkWithQueryParams from "../../LinkWithQueryParams";
const StyledCreateItem = styled.div`
  .instructions-container {
    .heading a {
      color: #214377;
      font-weight: 700;
    }
  }
  @media (max-width: 768px) {
    .sc-box-icon {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      margin-bottom: 7%;
    }
    .sc-box-icon .image {
      width: 20%;
      height: auto;
      margin-bottom: 0;
    }
    .sc-box-icon .image .icon-create {
      width: 100%;
    }
    .sc-box-icon .heading {
      margin-bottom: 6px;
    }
    .instructions-container {
      width: 76%;
      .heading,
      .content {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
`;

const CreateLink = ({ item, content, intendedClass }) => {
  return item?.externalSite ? (
    <a
      target="_blank"
      rel="noreferrer"
      href={item?.to}
      className={intendedClass}
    >
      {content}
    </a>
  ) : (
    <LinkWithQueryParams className={intendedClass} path={item.to}>
      {content}
    </LinkWithQueryParams>
  );
};

const CreateItem = (props) => (
  <StyledCreateItem className="col-lg-3 col-md-6 col-12 p-md-5">
    <div className="sc-box-icon">
      <div className="image">
        <div className={`icon-create`}>
          <img src={props.item.icon} alt="" />
        </div>
      </div>
      <div className="instructions-container">
        <h6 className="heading">
          <CreateLink content={props.item.title} item={props.item} />
        </h6>
        <p className="content">
          {props.item.description}
          <CreateLink
            intendedClass={"call-to-action"}
            content={props.item.linkText}
            item={props.item}
          />
        </p>
      </div>
    </div>
  </StyledCreateItem>
);
export default CreateItem;
