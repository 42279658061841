import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAnnouncementById } from "../../api/announcements/getAnnouncements";
import Layout from "../../components/layouts/Layout";
import { StyledAnnouncementDetailPage } from "./styles";

const AnnouncementDetail = () => {
  const iframe = () => {
    return {
      __html: `'<iframe src="${announcement?.cmsLink}" width="100%" height="500" style="overflow-y: scroll;" scrolling="yes"></iframe>'`,
    };
  };

  const { announcementId } = useParams();
  const [announcement, setAnnouncement] = useState();
  const getAnnouncementFromDbById = async ({ announcementId }) => {
    const announcement = await getAnnouncementById({ announcementId });
    setAnnouncement(announcement);
  };

  useEffect(() => {
    announcementId && getAnnouncementFromDbById({ announcementId });
  }, [announcementId]);

  return (
    <Layout>
      <StyledAnnouncementDetailPage>
        <div id="spacer"></div>
        <div className="announcement-container">
          {announcement?.cmsLink && (
            <div
              className="announcement-content"
              dangerouslySetInnerHTML={iframe()}
            />
          )}
        </div>
      </StyledAnnouncementDetailPage>
    </Layout>
  );
};

export default AnnouncementDetail;
