import React, { useState, useEffect, Fragment } from "react";
import { Accordion } from "react-bootstrap-accordion";
import getCategories from "../../api/category/getCategories";
import Spinner from "../../components/layouts/Spinner/Spinner";

const Filters = ({
  action,
  categories = true,
  blockchains = true,
  statusFiltersEnabled = true,
  activitiesFiltersEnabled = false,
  // privateEndpoint = false,
}) => {
  const [filters, setFilters] = useState([]);

  const [statusFilter, setStatusFilter] = useState([
    {
      id: "FOR_SALE",
      name: "buy now",
      checked: false,
    },
    {
      id: "ON_HOLD",
      name: "on hold",
      checked: false,
    },
    {
      id: "FOR_AUCTION",
      name: "has offers",
      checked: false,
    },
    // {
    //   id: 'HOLDED',
    //   name: 'on hold',
    //   checked: false,
    // },
  ]);

  const [chains] = useState([
    {
      id: 0,
      name: "Hedera",
      checked: true,
    },
    {
      id: 1,
      name: "Ethereum",
      checked: false,
    },
  ]);

  const [activitiesFilters] = useState([
    {
      id: 0,
      name: "Minted",
      checked: true,
    },
    {
      id: 1,
      name: "Bids",
      checked: false,
    },
    {
      id: 2,
      name: "Transferred",
      checked: false,
    },
    {
      id: 3,
      name: "Purchased",
      checked: false,
    },
    {
      id: 4,
      name: "Sold",
      checked: false,
    },

    {
      id: 5,
      name: "Liked",
      checked: false,
    },
  ]);

  const [loading, setLoading] = useState(false);

  const getAllCategories = async () => {
    setLoading(true);
    try {
      const response = await getCategories();
      console.log(response);
      response && setFilters(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const manageChecks = (id) => {
    setStatusFilter(
      statusFilter.map((status) => {
        return status.id !== id
          ? { ...status, checked: false }
          : status.checked
          ? { ...status, checked: false }
          : { ...status, checked: true };
      })
    );
  };

  useEffect(() => {
    if (categories) {
      (async () => {
        await getAllCategories();
      })();
    }
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="widget widget-filter style-1 ">
            {statusFiltersEnabled && (
              <>
                <Accordion title="status" show={true}>
                  <form className="form-inner mt-3">
                    {statusFilter.map((item) => (
                      <div key={item.id}>
                        <label>
                          {item.name}
                          <input
                            type="checkbox"
                            checked={item.checked}
                            onChange={() => {
                              manageChecks(item.id);
                              action({ name: "status", value: item.id });
                            }}
                          />
                          <span className="btn-checkbox"></span>
                        </label>
                        <br />
                      </div>
                    ))}
                  </form>
                </Accordion>
                <hr />
              </>
            )}

            {categories && (
              <>
                <Accordion title={"categories"} show={true}>
                  <form className="form-inner mt-3">
                    {filters.map((item, index) => (
                      <div key={index}>
                        <label>
                          {item.name}
                          <input
                            type="checkbox"
                            onChange={() =>
                              action({ name: "category", value: item.id })
                            }
                          />
                          <span className="btn-checkbox"></span>
                        </label>
                        <br />
                      </div>
                    ))}
                  </form>
                </Accordion>
                <hr />
              </>
            )}

            {blockchains && (
              <>
                <Accordion title="chains" show={true}>
                  <form className="form-inner mt-3">
                    {chains.map((item) => (
                      <div key={item.id}>
                        <label>
                          {item.name}
                          <input
                            type="checkbox"
                            checked={item.checked}
                            disabled={true}
                            onChange={() => {
                              manageChecks(item.id);
                              action({ name: "chains", value: item.id });
                            }}
                          />
                          <span className="btn-checkbox"></span>
                        </label>
                        <br />
                      </div>
                    ))}
                  </form>
                </Accordion>
                <hr />
              </>
            )}

            {activitiesFiltersEnabled && (
              <>
                <Accordion title="filters" show={true}>
                  <form className="form-inner mt-3">
                    {activitiesFilters.map((item) => (
                      <div key={item.id}>
                        <label>
                          {item.name}
                          <input
                            type="checkbox"
                            onChange={() => {
                              action({ name: "filter", value: item.name });
                            }}
                          />
                          <span className="btn-checkbox"></span>
                        </label>
                        <br />
                      </div>
                    ))}
                  </form>
                </Accordion>
                <hr />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Filters;
