import React from "react";
import styled from "styled-components";
import backgroundImage from "../../../assets/images/backgroup-secsion/img_bg_slider_2.png";
import { COMMON_STYLES } from "../../../constants/genericStyles";
import LinkWithQueryParams from "../../LinkWithQueryParams";

const StyledSliderLefttImage = styled.div`
  a {
    width: 100%;
  }

  .sc-button-2.no-bg,
  .sc-button-2.orange {
    padding: 20px 8%;
  }

  ${COMMON_STYLES.MAIN_SLIDER_BACKGROUND}
  background-image: url(${backgroundImage});
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 768px) {
    .slider-image-content {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      width: 30%;
    }
    .slider-text-content {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .slider-content .image-container {
      overflow: hidden !important;
      border-radius: 10px !important;
    }
    .slider-content .slider-text-content {
      width: 50% !important;
    }
    .slider-content .image-container:before {
      padding-top: 140% !important;
    }
  }
`;

const SliderLeftImage = (props) => {
  const ActionBtn = () => (
    <div className="btn-auction center mb-4">
      <button
        id="load-more"
        className={`sc-button-2 no-bg button__bottom-top ${props.item.button.class}`}
      >
        <span>{props.item.button.name}</span>
      </button>
    </div>
  );

  return (
    <StyledSliderLefttImage className="flat-title-page">
      <div className="slider-content">
        <div
          className="slider-image-content"
          style={{
            width: props.imageProportions ? props.imageProportions : "40%",
          }}
        >
          <div className="image-lines-container">
            <div className="image-container">
              <img src={props.item.img} alt="axies" />
            </div>
            <div className="left-line"></div>
            <div className="bottom-line"></div>
            <div className="right-line"></div>
          </div>
        </div>

        <div
          className="slider-text-content"
          style={{
            width: props.textProportions
              ? props.textProportions
              : "55% !important",
          }}
        >
          <h2 className="heading mb-4">{props.item.title_1}</h2>
          <h1 className="sub-heading mb-style mb-4">
            <span className="tf-text s1">{props.item.title_2}</span>
          </h1>

          {props.item.button &&
            props.item.button.name &&
            props.item.button.hash && (
              <a href={props.item.button.hash}>
                <ActionBtn />
              </a>
            )}

          {props.item.button &&
            props.item.button.name &&
            props.item.button.path &&
            !props.item.button?.externalSite && (
              <LinkWithQueryParams path={props.item.button.path}>
                <ActionBtn />
              </LinkWithQueryParams>
            )}

          {props.item.button &&
            props.item.button.name &&
            props.item.button.path &&
            props.item.button?.externalSite && (
              <a className="btn-auction" href={props.item.button.path}>
                <ActionBtn />
              </a>
            )}
        </div>
      </div>
    </StyledSliderLefttImage>
  );
};

export default SliderLeftImage;
