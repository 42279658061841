import React, { useEffect, useState } from "react";
import getItem from "../../../api/Item/getItem";
import getOneSpace from "../../../api/spaces/getSpaceByUserId";
import getUser from "../../../api/user/getUser";
import LinkWithQueryParams from "../../../components/LinkWithQueryParams";
import Spinner from "../../../components/layouts/Spinner/Spinner";
import { priceFormatter } from "../../../helpers/formatters";
import {
  getProductName,
  isAddonItem,
  isResaleItem,
} from "../../../helpers/itemHelpers";
import useIPFS from "../../../hooks/useIPFS";
import OrderItemVisual from "./OrderItemVisual";

const OrderItem = ({ item }) => {
  const [itemData, setItemData] = useState({});
  const [productName, setProductName] = useState(null);
  const [ownerData, setOwnerData] = useState({});
  const [spaceData, setSpaceData] = useState({});
  const { getData } = useIPFS();

  const fetchProductName = async ({ item }) => {
    const productName = await getProductName({ item });
    setProductName(productName);
  };

  const getItemData = async () => {
    let orderItemData = await getItem(item.itemId);
    const elementData = await getData(
      orderItemData.finalCid ? orderItemData?.finalCid : orderItemData.cid
    );
    if (isAddonItem({ item })) {
      /* Preserve addon information  such as price and quantity*/
      orderItemData = { ...orderItemData, ...elementData, ...item };
      setItemData(orderItemData);
    } else {
      orderItemData = { ...orderItemData, ...elementData };
      setItemData(orderItemData);
    }
  };

  useEffect(() => {
    if (item) {
      getItemData();
    }
  }, [item]);

  const getSpaceData = async ({ item }) => {
    if (item.originalOwnerId) {
      await getData(item?.finalCid ? item?.finalCid : item.cid);
      const userData = await getUser(item.originalOwnerId);
      const spaceOwnerData = await getOneSpace(userData.id);
      setOwnerData(userData);
      setSpaceData(spaceOwnerData);
    }
  };

  useEffect(() => {
    if (itemData?.productId && itemData?.id) {
      fetchProductName({ item: itemData });
    }
    if (itemData?.id) {
      getSpaceData({ item: itemData });
    }
  }, [itemData]);

  return itemData ? (
    <div className="order-item-content-container">
      <div className="left-side">
        <div className="media">
          <LinkWithQueryParams path={`/my-items/#${itemData?.id}`}>
            <OrderItemVisual itemData={itemData} element={itemData} />
          </LinkWithQueryParams>
        </div>
        <div className={`infor`}>
          <h4>
            {itemData?.quantity ? itemData?.quantity : 1} x {itemData.name}
          </h4>
          <div className="author">
            <LinkWithQueryParams path={`/spaces/${spaceData.customURL}`}>
              <h6 style={{ color: "#6899FA" }}>{ownerData?.displayName}</h6>
            </LinkWithQueryParams>
          </div>
          {productName && <div className="product-name">{productName}</div>}
        </div>
      </div>
      <div className="right-side">
        <div className="price">
          {priceFormatter({
            currencyType: isResaleItem({ item: itemData })
              ? itemData?.saleCurrencyType
              : itemData?.currencyType,
            price: item?.salePrice,
          })}
        </div>
      </div>
    </div>
  ) : (
    <div style={{ width: "100%", marginBottom: "20px" }}>
      <Spinner />
    </div>
  );
};

export default OrderItem;
