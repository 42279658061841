export const COMMON_STYLES = {
  OVERLAY: `
    background: var(--header-background);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,
  HEADER_STYLES: `
    .page-title-heading {
      display: flex;
      align-items: center;
      align-content: center;
      margin-bottom: 30px;
      .vertical-line {
        height: 30px;
        border-left: 6px solid #ffd061;
      }

      .heading {
        font-weight: 700;
        font-size: 32px;
        line-height: 32px;
        color: #011126;
      }
    }
  `,
  MAIN_SLIDER_BACKGROUND: `
    background-size: 150%;
    background-position: top left;
    padding-top: 15%;
    padding-left: 10%;
    padding-right: 8%;
    background-color: var(--header-background);
  `,
  SWIPER_NAVIGATION: `
    .swiper-button {
      width: 4%;
      bottom: 16px;
    }
    .swiper-pagination {
      bottom: 20px;
      padding: 0 !important;
    }
    .live-auctions .swiper-pagination-bullets{
      margin:0;
    }
    .swiper-pagination-bullets {
      display: block !important;
    }
    .swiper-pagination-bullet {
      width: 7px;
      height: 7px;
      border: none;
    }
    .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-pagination-horizontal.swiper-pagination-bullets
      .swiper-pagination-bullet {
      margin: 0 !important;
      padding: 0 !important;
      margin-left: 10px !important;
    }

    .swiper-pagination-horizontal.swiper-pagination-bullets
      .swiper-pagination-bullet:first-of-type {
      margin-left: 0% !important;
    }
    .swiper-button.custom-prev-button {
      left: 35%;
    }

    .swiper-button.custom-next-button {
      right: 35%;
    }
  `,
  GENERIC_IMAGE_CONTAINER: ({ paddingTop }) => `
    width: 100%;
    position: relative;
    overflow: hidden;
    img{
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0;
      webkit-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
    }
    &:before {
      content: "";
      display: block;
      width: 100%;
      padding-top: ${paddingTop};
    }
    &:hover{
      img{
        transform: scale(1.1);
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
        -webkit-filter: grayscale(0%);
        -ms-filter: grayscale(0%);
        -moz-filter: grayscale(0%);
      }
    }
  `,

  OUTLINED_BUTTON: ({ textColor, borderSize }) => `
    padding: 5px 60px;
    font-size: 16px;
    color: ${textColor};
    border: 3px solid ${textColor};
    background-color: transparent;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: ${borderSize};
  `,

  FILLED_BUTTON: ({ fillColor, borderSize }) => `
    padding: 9px 60px;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    border: none;
    background-color:  ${fillColor};
    border-radius: ${borderSize};
    color: white;
    &:hover{
      opacity: 0.8;
    }
  `,

  BUTTON: `
        padding: 25px 18%;
        border: 5px solid #FF7B7C;
        color: #F1A09F;
        border-color: #F1A09F;
        position: relative;
        cursor: pointer;
        z-index: 1;
        transition:         0.08s ease-in;
        -o-transition:      0.08s ease-in;
        -ms-transition:     0.08s ease-in;
        -moz-transition:    0.08s ease-in;
        -webkit-transition: 0.08s ease-in;
    `,
  BOTTOM_TOP_ANIMATION: ({ finalButtonFill, initialButtonFill }) => `
        border: 5px solid ${finalButtonFill};
        background: linear-gradient(to top, ${finalButtonFill} 50%, ${initialButtonFill} 50%);
        background-size: 100% 200%;
        background-position: top;
        transition: all .35s ease-out;
        &:hover {
            border: 5px solid ${finalButtonFill};
            background-position: bottom;
            span{
                color: #fff;
            }
        }
    `,
  ZOOM_ANIMATION: ` transform: scale(1.1);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;`,
  LEVITATE_ANIMATION: `
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
    /* -webkit-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    -moz-filter: grayscale(0%); */
    transition: all 0.3s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  `,
  SLIDE_ANIMATION: ({ transitionTime }) => `
    .slide-in {
      /* @keyframes duration | easing-function | delay | name */

      /* vendor/browser related */
      -webkit-animation: ${transitionTime}ms ease-in 0s slideIn;

      /* common */
      animation: ${transitionTime}ms ease-in 0s slideIn;
    }


    .slide-out {
      display: flex;
      -webkit-animation: slideOut ${transitionTime}ms forwards;
      animation: slideOut ${transitionTime}ms forwards;
    }

    /* vendor/browser related */
    @-webkit-keyframes slideIn {
      0% {
        opacity: 0;
        -webkit-transform: translateX(100%);
      }
      100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
      }
    }

    /* common */
    @keyframes slideIn {
      0% {
        opacity: 0;
        transform: translateX(100%);
      }
      100% {
        opacity: 1;
        transform: translateX(0%);
      }
    }

    @-webkit-keyframes slideOut{
      0% {
        -webkit-transform: translateX(0%);
        opacity: 1;
      }
      100% {
        -webkit-transform: translateX(100%);
        opacity: 0;
      }
    }

    @keyframes slideOut {
      0% {
        transform: translateX(0%);
        opacity: 1;
      }
      100% {
        transform: translateX(100%);
        opacity: 0;
      }
    }

      `,
  FADE_IN: `
    @keyframes fadein {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }`,
  /* Apply className to link element */
  UNDERLINE_ANIMATION: ({ color }) => `
    .underline-animation{
      position   : relative;
    }
    .underline-animation::after {
      content           : '';
      width             : 0;
      height            : 1px;
      position          : absolute;
      background        : ${color};
      left              : 50%;
      bottom            : -2px;
      transform         : translateX(-50%);
      visibility        : hidden;
      opacity           : 0;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition   : all 0.3s ease-in-out;
      -ms-transition    : all 0.3s ease-in-out;
      -o-transition     : all 0.3s ease-in-out;
      transition        : all 0.3s ease-in-out;
  }

  .underline-animation:hover::after {
      width             : 100%;
      visibility        : visible;
      opacity           : 1;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition   : all 0.3s ease-in-out;
      -ms-transition    : all 0.3s ease-in-out;
      -o-transition     : all 0.3s ease-in-out;
      transition        : all 0.3s ease-in-out;
  }
  `,
};
