import auth0 from "auth0-js";

const webAuth = new auth0.WebAuth({
  domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
  clientID: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
  responseType: `${process.env.REACT_APP_AUTH0_RESPONSE_TYPE}`,
  redirectUri: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}`,
  responseMode: `${process.env.REACT_APP_AUTH0_RESPONSE_MODE}`,
  scope: "openid profile email offline_access",
});

export const auth0GoogleSignup = () => {
  webAuth.authorize({
    connection: "google-oauth2",
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URL,
    scope:
      "openid profile email read:current_user update:current_user_metadata",
  });
};

export const auth0FacebookSignup = () => {
  webAuth.authorize({
    connection: "facebook",
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URL,
    scope:
      "openid profile email read:current_user update:current_user_metadata",
  });
};

export const auth0Signup = ({ email, password, firstName, lastName }) => {
  return new Promise((resolve, reject) => {
    webAuth.signup(
      {
        connection: `${process.env.REACT_APP_AUTH0_DB_CONNECTION}`,
        email,
        password,
        user_metadata: {
          role: "CUSTOMER",
          firstName: firstName?.length ? firstName : " ",
          lastName: lastName?.length ? lastName : " ",
        },
      },
      async (err, result) => {
        if (err) {
          return reject(err);
        }
        const loginResult = await auth0Login({ email, password });

        return resolve(loginResult);
      }
    );
  });
};

export const auth0ResetPassword = ({ email }) => {
  return new Promise((resolve, reject) => {
    webAuth.client.dbConnection.changePassword(
      { connection: `${process.env.REACT_APP_AUTH0_DB_CONNECTION}`, email },
      async (err, result) => {
        if (err) {
          return reject(err);
        }
        return resolve(result);
      }
    );
  });
};

export const auth0Login = async ({ email, password }) => {
  let user;
  return new Promise((resolve, reject) => {
    webAuth.client.login(
      {
        realm: `${process.env.REACT_APP_AUTH0_DB_CONNECTION}`,
        username: email,
        password: password,
      },
      async (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        if (result?.accessToken && (result?.idToken || result?.id_token)) {
          handleAuthentication(result);
        }
        user = await getUser(result?.accessToken);
        resolve({ result, user });
      }
    );
  });
};

export const auth0Logout = () => {
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("expires_at");
};

const getUserWithRefreshToken = async () => {
  return new Promise((resolve, reject) => {
    const refreshToken = localStorage.getItem("refresh_token");

    webAuth.client.oauthToken(
      {
        grantType: "refresh_token",
        refresh_token: refreshToken,
      },
      async (err, result) => {
        if (err) {
          return reject(err);
        }
        resolve(result);
      }
    );
  });
};

export const getInitialAuthenticatedUser = async () => {
  let user, refreshTokenResponse;
  if (localStorage.getItem("refresh_token")) {
    const isUserAuthenticated = isAuthenticated();
    try {
      refreshTokenResponse = await getUserWithRefreshToken();
      if (isUserAuthenticated && refreshTokenResponse) {
        handleAuthentication(refreshTokenResponse);
        user = await getUser(refreshTokenResponse.accessToken);
      }
    } catch (e) {
      console.log("there was an error");
      console.log(e);
      console.log(e.code);
      if (e?.code === "invalid_grant") {
        /*Refresh token has expired */
        removeSessionStorage();
      }
    }
  }
  return { user, refreshTokenResponse };
};

const getUser = (accessToken) => {
  return new Promise((resolve, reject) => {
    webAuth.client.userInfo(accessToken, (err, result) => {
      if (err) {
        console.error("Something went wrong: ", err.message);
        reject(err.message);
      }
      resolve(result);
    });
  });
};

export const removeSessionStorage = () => {
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("expires_at");
  localStorage.removeItem("idToken");
};

export const isAuthenticated = () => {
  const date = localStorage.getItem("expires_at");
  const refreshToken = localStorage.getItem("refresh_token");
  if (date && refreshToken) {
    const expiresAt = JSON.parse(date);
    if (!refreshToken || new Date().getTime() > expiresAt) {
      removeSessionStorage();
      return false;
      // return new Date().getTime() < expiresAt;
    }
    return true;
  }
  return false;
};

export const setSession = (result) => {
  const expiresAt = result.expiresIn
    ? JSON.stringify(result.expiresIn * 1000 + new Date().getTime())
    : JSON.stringify(result.expires_in * 1000 + new Date().getTime());
  setSessionStorage(result, expiresAt);
};

const setSessionStorage = (result, expiresAt) => {
  localStorage.setItem(
    "refresh_token",
    result.refreshToken ? result.refreshToken : result.refresh_token
  );

  localStorage.setItem(
    "idToken",
    result.idToken ? result.idToken : result.id_token
  );

  localStorage.setItem("expires_at", expiresAt);
};

export const getIdTokenClaims = () => {
  return { __raw: localStorage.getItem("idToken") };
};

export const handleAuthentication = (result) => {
  if (result.idToken || result.id_token) {
    setSession(result);
  }
  //  else {
  //   History.push("/");
  //   window.location.reload();
  // }
};
