import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-tabs/style/react-tabs.css";
import styled from "styled-components";
import { getBaseItem } from "../../api/Item/baseItems";
import getItem from "../../api/Item/getItem";
import { createReservation } from "../../api/order/reservation";
import getProducts from "../../api/prodigi/getProducts";
import { getProductById, getProductSku } from "../../api/products/getProducts";
import VenlyConnectIndications from "../../components/Venly/VenlyConnectIndications";
import VenlyOverlay from "../../components/Venly/VenlyOverlay";
import VenlySelectWalletIndications from "../../components/Venly/VenlySelectWalletIndications";
import Layout from "../../components/layouts/Layout";
import {
  ADDON_TYPE,
  BASE_ITEM_TYPE,
  HASHPACK_WALLET_TYPE,
  HBAR_CURRENCY_TYPE,
  HEADER_HEIGHT,
  LIMITED_PRODUCT_QUANTITY_MESSAGE,
  RESALE_ITEM_TYPE,
  RESERVATION_ERROR,
  USD_CURRENCY_TYPE,
  VENLY_WALLET_TYPE,
} from "../../constants/constants";
import { retrieveAddonProducts } from "../../helpers/addonHelpers";
import { filterFromArr, totalize } from "../../helpers/commonOperations";
import { isResaleItem } from "../../helpers/itemHelpers";
import { notifyError } from "../../helpers/toastNotificationService";
import useCart from "../../hooks/useCart";
import { useCustomNavigator } from "../../hooks/useCustomNavigator";
import useVenly from "../../hooks/useVenly";
import { logoutWallet } from "../../store/slices/user/user";
import { generateHmac } from "../../utils/sha256Encription";
import CartList from "./CartList";
import ModalAfterAction from "./ModalAfterAction";
import TotalCalculations from "./TotalCalculations";
import ConnectWallet from "./Wallet/ConnectWallet";
import HashpackWalletConnected from "./Wallet/HashpackWalletConnected";
import WalletConnected from "./Wallet/WalletConnected";
import { StyledCart } from "./styles";

const StyledCartPage = styled.div`
  #spacer {
    padding-top: ${(HEADER_HEIGHT + 35).toString() + "px"};
  }
`;

const initialPrice = 0;

const Cart = () => {
  const { cart } = useSelector((state) => state.cart);
  const { customNavigate } = useCustomNavigator();
  const [products, setProducts] = useState([]);
  const [resaleProducts, setResaleProducts] = useState([]);
  const [resaleTotals, setResaleTotals] = useState({});

  const [hbarProducts, setHbarProducts] = useState([]);
  const [hbarTotals, setHbarTotals] = useState({});

  const [usdProducts, setUsdProducts] = useState([]);
  const [usdTotals, setUsdTotals] = useState({});

  const [sale, setSale] = useState(false);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { callToVenly, manageWallets, venlyLogout } = useVenly();
  const { removeFromCartPersist, removeAllPersist, restoreCart } = useCart();

  const overlayRef = useRef(null);
  const connectIndicationsRef = useRef(null);
  const selectIndicationsRef = useRef(null);
  const walletActionRef = useRef({
    status: null,
  });

  const filterProductsData = async ({ cart }) => {
    const resaleProducts = cart.filter((item) => isResaleItem({ item }));
    const nonResaleProducts = cart.filter((item) => !isResaleItem({ item }));
    const resaleTotal = totalize({ products: resaleProducts, initialPrice });
    const resaleTotals = {
      total: resaleTotal,
    };

    const hbarProducts = filterFromArr({
      arr: nonResaleProducts,
      prop: "currencyType",
      conditionValue: HBAR_CURRENCY_TYPE,
    });

    const usdProducts = filterFromArr({
      arr: nonResaleProducts,
      prop: "currencyType",
      conditionValue: USD_CURRENCY_TYPE,
    });

    const hbarTotal = totalize({ products: hbarProducts, initialPrice });

    const hbarTotals = {
      total: hbarTotal,
    };

    const usdTotal = totalize({ products: usdProducts, initialPrice });

    const usdTotals = {
      total: usdTotal,
    };

    setResaleTotals(resaleTotals);
    setHbarTotals(hbarTotals);
    setUsdTotals(usdTotals);
    setResaleProducts(resaleProducts);
    setHbarProducts(hbarProducts);
    setUsdProducts(usdProducts);
  };

  const getProductData = async () => {
    const cartProductsData = [];
    const { addonProducts, nonAddonProducts } = retrieveAddonProducts({
      items: cart,
    });
    /* Addon products population for rendering and quantity management */
    await Promise.all(
      addonProducts.map(async (addonItem) => {
        const requestBody = {
          addonId: addonItem.addonId,
          configDetails: addonItem.addonConfigDetails,
        };
        /* Done to recollect the addon price */
        const sku = await getProductSku({
          requestBody,
        });

        if (sku?.success) {
          /* Done to render product type and image */
          const itemData = await getItem(addonItem.itemId);
          let cartItemData = itemData;
          cartItemData.productData = await getProductById({
            productId: cartItemData.productId,
          });

          /* This ensures cart quantity remains intact */
          cartItemData = {
            ...cartItemData,
            price: sku.price,
            ...addonItem, // this has the information for reservation
            id: addonItem.addonId, // this id is used internally to increment/decrement cart quantity
            quantity: addonItem.quantity,
          };
          cartProductsData.push(cartItemData);
        }
      })
    );

    await Promise.all(
      nonAddonProducts.map(async (item) => {
        try {
          const [baseItemData, itemData] = await Promise.all([
            getBaseItem(item.id),
            getItem(item.id),
          ]);
          let cartItemData = baseItemData?.id ? baseItemData : itemData;
          cartItemData.productData = await getProductById({
            productId: cartItemData.productId,
          });
          /* This ensures cart quantity remains intact */
          cartItemData = { ...cartItemData, ...item };
          cartProductsData.push(cartItemData);
        } catch (e) {}
      })
    );

    filterProductsData({ cart: cartProductsData });
  };

  useEffect(() => {
    /* Removes background if user is connected */
    if (
      user?.wallets?.owner?.email &&
      walletActionRef.current.status === "AUTHENTICATING_USER"
    ) {
      overlayRef.current.classList.remove("active");
      connectIndicationsRef.current.classList.remove("active");
    }

    /* Removes background if wallet is connected */
    if (
      user?.wallets?.walletDetails?.id &&
      walletActionRef.current.status === "CONNECTING_WALLET"
    ) {
      overlayRef.current.classList.remove("active");
      selectIndicationsRef.current.classList.remove("active");
    }
  }, [user]);

  useEffect(() => {
    if (cart?.length >= 0) {
      getProductData();
    }
  }, [cart]);

  useEffect(() => {
    restoreCart();
  }, []);

  const selectWallet = async () => {
    overlayRef.current.classList.add("active");
    selectIndicationsRef.current.classList.add("active");
    walletActionRef.current.status = "CONNECTING_WALLET";
    await manageWallets();
  };

  const connectWallet = async (event) => {
    try {
      overlayRef.current.classList.add("active");
      connectIndicationsRef.current.classList.add("active");
      walletActionRef.current.status = "AUTHENTICATING_USER";
      await callToVenly();
    } catch (e) {
      console.log(e);
    }
  };

  const handleLogout = async () => {
    await venlyLogout();
    dispatch(logoutWallet());
  };

  const handleDelete = (nft) => {
    removeFromCartPersist(nft);
  };

  const handleMore = (nft) => {
    customNavigate({ path: `/collection/${nft.collectionId}` });
  };

  const checkoutNavigation = async ({ currencyType, products }) => {
    if (products.length !== 1 || products[0].quantity > 1) {
      notifyError(LIMITED_PRODUCT_QUANTITY_MESSAGE);
      return;
    }
    if (user?.wallets?.walletDetails?.id) {
      switch (currencyType) {
        case HBAR_CURRENCY_TYPE:
          customNavigate({ path: `/checkout/item/${products[0].id}` });
          break;
        case USD_CURRENCY_TYPE:
          customNavigate({ path: `/checkout/item/${products[0].id}` });
          break;
        default:
          break;
      }
    } else {
      notifyError("You must connect your wallet to buy, sell & trade items.");
    }
  };

  const validateExistentProducts = ({ products }) => {
    if (!products?.length > 0) {
      notifyError("You must add a product in order to checkout.");
      return false;
    }
    return true;
  };

  const proceedToCheckout = ({ currencyType, isResale = false }) => {
    let existentProducts;
    switch (currencyType) {
      case HBAR_CURRENCY_TYPE:
        existentProducts = validateExistentProducts({
          products: isResale ? resaleProducts : hbarProducts,
        });
        if (!existentProducts) return;
        checkoutNavigation({
          currencyType: HBAR_CURRENCY_TYPE,
          products: isResale ? resaleProducts : hbarProducts,
        });
        break;
      case USD_CURRENCY_TYPE:
        existentProducts = validateExistentProducts({
          products: usdProducts,
        });
        if (!existentProducts) return;
        checkoutNavigation({
          currencyType: USD_CURRENCY_TYPE,
          products: usdProducts,
        });

        break;
      default:
        break;
    }
  };

  const validateDigitalItemStorageSelection = ({ currencyType }) => {
    const walletId = user?.wallets?.walletDetails?.id;
    const hashpackWalletId = user?.hashpackWallets?.accountIds?.[0];

    /* Only Allow to proceed for hbar priced if there is any wallet connected */
    if (currencyType === HBAR_CURRENCY_TYPE && !hashpackWalletId && !walletId) {
      notifyError("You must connect your wallet to buy, sell & trade items.");
      return false;
    }

    /* Handle possibility of digital item storage selected but not connected */
    if (
      (user?.defaultWalletType === HASHPACK_WALLET_TYPE && !hashpackWalletId) ||
      (user?.defaultWalletType === VENLY_WALLET_TYPE && !walletId)
    ) {
      notifyError("You must connect your wallet to buy, sell & trade items.");
      return false;
    }
    return true;
  };

  const logErrorForSentry = ({ reserveItemList, reservation }) => {
    console.log(reservation);
    const itemsDebugInfo = reserveItemList.map(
      (i) => `item:${i?.itemId} reqType:${i?.reqType} qty:${i?.quantity}`
    );
    console.log(itemsDebugInfo);
    console.log(`user id: ${user?.id}`);
    console.log(reservation?.message);
    console.error("Reservation creation failed");
  };

  const newProceedToCheckout = async ({ currencyType, isResale = false }) => {
    let existentProducts, checkoutProducts;
    const prodigiProducts = await getProducts();
    switch (currencyType) {
      case HBAR_CURRENCY_TYPE:
        checkoutProducts = isResale ? resaleProducts : hbarProducts;
        existentProducts = validateExistentProducts({
          products: checkoutProducts,
        });
        break;
      case USD_CURRENCY_TYPE:
        checkoutProducts = usdProducts;
        existentProducts = validateExistentProducts({
          products: checkoutProducts,
        });
        break;
      default:
        break;
    }

    /* Handle possibility of digital item storage selected but not connected */
    const validSelection = validateDigitalItemStorageSelection({
      currencyType,
    });
    if (!validSelection) {
      return;
    }

    const { addonProducts, nonAddonProducts } = retrieveAddonProducts({
      items: checkoutProducts,
    });

    /* Non addon cart products formatting for reservation */
    let reserveItemList = nonAddonProducts.map((product) => ({
      reqType: isResale ? RESALE_ITEM_TYPE : BASE_ITEM_TYPE,
      itemId: product?.id,
      quantity: product?.quantity ? product?.quantity : 1,
      prodigiSKUId: prodigiProducts[0].skus[0].id,
      prodigiSizing: "fillPrintArea",
      prodigiAttributes: {},
    }));

    /* Addon cart products formatting for reservation */
    const addonProductsReservation = addonProducts.map((addonProduct) => {
      return {
        reqType: ADDON_TYPE,
        addonConfigDetails: addonProduct.addonConfigDetails,
        addonId: addonProduct.addonId,
        addonSKUId: addonProduct.addonSKUId,
        quantity: addonProduct.quantity,
        itemId: addonProduct.itemId,
      };
    });

    reserveItemList = [...reserveItemList, ...addonProductsReservation];
    const { hString, epochTimestamp } = generateHmac({
      userId: user.id,
      id: isResale ? reserveItemList[0]?.itemId : reserveItemList[0]?.itemId,
    });

    const requestBody = {
      reserveItemList,
      hString,
      epochTimestamp,
    };

    const reservation = await createReservation({ requestBody });
    if (reservation?.id) {
      customNavigate({ path: `/checkout/reservation/${reservation?.id}` });
    } else {
      logErrorForSentry({ reserveItemList, reservation });
      if (reservation?.message) {
        if (Array.isArray(reservation?.message)) {
          if (typeof reservation?.message?.[0] === "object") {
            notifyError(reservation?.message?.[0]?.error);
          } else {
            notifyError(reservation?.message?.[0]);
          }
        } else if (
          typeof reservation?.message === "string" ||
          reservation?.message instanceof String
        ) {
          notifyError(reservation?.message);
        } else {
          notifyError(RESERVATION_ERROR);
        }
      } else {
        notifyError(RESERVATION_ERROR);
      }
    }
  };

  const [actions] = useState([
    { title: "Delete", action: handleDelete },
    { title: "More Items Of Collection", action: handleMore },
  ]);

  const total = products.reduce(
    (prev, current) => prev + current.quantity * current.price,
    initialPrice
  );

  return (
    <StyledCartPage>
      <VenlyOverlay overlayRef={overlayRef} />
      <VenlyConnectIndications
        connectIndicationsRef={connectIndicationsRef}
        connectWallet={connectWallet}
      />
      <VenlySelectWalletIndications
        selectIndicationsRef={selectIndicationsRef}
        selectWallet={selectWallet}
      />
      <Layout>
        <div id="spacer"></div>
        {sale && (
          <ModalAfterAction
            show={sale}
            onHide={() => setSale(false)}
            modalType="BUY"
          />
        )}
        <StyledCart>
          <div className="page-title-heading mg-bt-12">
            <div className="header-container">
              <div style={{ display: "flex" }}>
                <div className="vertical-line"></div>
                <h1 className="heading text-center">Shopping Cart</h1>
              </div>
              <button
                className="text-button"
                onClick={() => {
                  removeAllPersist();
                }}
              >
                <span>Remove All</span>
              </button>
            </div>
          </div>

          <div className="themesflat-container">
            <section className="tf-activity s1 tf-section">
              <div className="container-fluid p-0">
                <div className="row m-0 mt-5">
                  <div className="col-12">
                    {!cart?.length > 0 ? (
                      <div className="no-items-container">
                        <div className="d-flex flex-column">
                          <h3>No Items Here!</h3>
                          <button
                            id="load-more"
                            className="sc-button-3 no-bg"
                            onClick={() => customNavigate({ path: "/" })}
                          >
                            Explore Marketplace
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        {resaleProducts?.length > 0 && (
                          <>
                            <div id="usd-section">
                              <CartList
                                items={resaleProducts}
                                actions={actions}
                                changeSaleStatus={() => setSale(true)}
                              />

                              <div className="bottom-section">
                                <div className="left-spacer"></div>
                                <TotalCalculations
                                  subtotal={resaleTotals?.total}
                                  currencyType={HBAR_CURRENCY_TYPE}
                                  proceedToCheckout={() =>
                                    newProceedToCheckout({
                                      currencyType: HBAR_CURRENCY_TYPE,
                                      isResale: true,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            <div className="section-separator"></div>
                          </>
                        )}

                        {usdProducts?.length > 0 && (
                          <>
                            <div id="usd-section">
                              <CartList
                                items={usdProducts}
                                actions={actions}
                                changeSaleStatus={() => setSale(true)}
                              />

                              <div className="bottom-section">
                                <div className="left-spacer"></div>
                                <TotalCalculations
                                  subtotal={usdTotals?.total}
                                  currencyType={USD_CURRENCY_TYPE}
                                  proceedToCheckout={() =>
                                    newProceedToCheckout({
                                      currencyType: USD_CURRENCY_TYPE,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            <div className="section-separator"></div>
                          </>
                        )}

                        {hbarProducts?.length > 0 && (
                          <>
                            <div id="hbar-section">
                              <CartList
                                items={hbarProducts}
                                actions={actions}
                                changeSaleStatus={() => setSale(true)}
                              />

                              <div className="bottom-section">
                                <div className="left-spacer"></div>
                                <TotalCalculations
                                  subtotal={hbarTotals?.total}
                                  currencyType={HBAR_CURRENCY_TYPE}
                                  proceedToCheckout={() =>
                                    newProceedToCheckout({
                                      currencyType: HBAR_CURRENCY_TYPE,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            <div className="section-separator"></div>
                          </>
                        )}

                        <div className="bottom-section">
                          {user?.hashpackWallets?.accountIds && (
                            <>
                              <HashpackWalletConnected
                                hashAccountId={
                                  user?.hashpackWallets?.accountIds?.[0]
                                }
                              />
                            </>
                          )}

                          {user?.wallets.owner.email && (
                            <WalletConnected
                              wallets={user?.wallets}
                              selectWallet={selectWallet}
                            />
                          )}

                          {!user?.hashpackWallets?.accountIds &&
                            !user?.wallets.walletDetails.id && (
                              <ConnectWallet
                                connectWallet={() => {
                                  customNavigate({
                                    path: `/wallet-connect`,
                                    state: { intendedRoute: "/cart" },
                                  });
                                }}
                              />
                            )}

                          <div className="back-to-home-container">
                            <div className="action-buttons">
                              <div>
                                <button
                                  onClick={() => customNavigate("/")}
                                  className="outlined-button button__bottom"
                                >
                                  <span>Back to Home</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </StyledCart>
      </Layout>
    </StyledCartPage>
  );
};

export default Cart;
