import React, { useState, useCallback } from 'react';
import ArtistCard from './ArtistCard/ArtistCard';
import CollectionHeader from './CollectionHeader';

const Collection04 = ({ data, stop, color, title, navigateTo, header }) => {
  const [visible, setVisible] = useState(stop);

  const showMoreItems = useCallback(() => {
    setVisible((prev) => prev + stop);
  }, [visible]);

  return (
    <section className='tf-section our-creater dark-style2'>
      <div className='themesflat-container'>
        <div className='row'>
          {header && (
            <CollectionHeader
              title={title}
              navigateTo={navigateTo}
              show={false}
              color={color}
            />
          )}
          {data.slice(0, visible).map((item) => (
            <div key={item.id} className='col-lg-4 col-md-6 col-12'>
              <ArtistCard card={item} />
            </div>
          ))}
        </div>
        {visible < data.length && (
          <div className='btn-auction center'>
            <button
              id='load-more'
              className='sc-button-2 no-bg'
              onClick={showMoreItems}
            >
              <span>load more</span>
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Collection04;
