import styled from "styled-components";

export const StyledOrderItem = styled.div`
  margin-bottom: 50px;

  .top-section {
    background-color: #214377;
    &.green {
      background-color: #247773;
    }
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;

    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;

    display: flex;
    justify-content: space-between;
    padding: 20px;
    .order-number {
      .order-number-value {
        font-weight: 700;
      }
    }
    .order-date {
      .order-date-value {
        font-weight: 700;
      }
    }
  }

  .order-content {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    padding-top: 40px;
    background-color: #ffffff;

    border: 1px solid #cdcdcd;
    border-bottom-left-radius: 21px;
    border-bottom-right-radius: 21px;
    .infor {
      h4 {
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        color: #011126;
        margin-bottom: 10px;
      }
      .author {
        margin-bottom: 15px;
      }
      .author a {
        h6 {
          font-size: 20px;
          line-height: 20px;
        }
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        text-decoration-line: underline;
        color: #1b70d0;
        text-decoration-color: #1b70d0;
      }
      .product-name {
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        color: #9a9a9a;
      }
    }

    .order-details {
      width: 100%;
      padding-bottom: 40px;
      display: flex;
      flex-wrap: wrap;
    }

    .tracking-info {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      text-align: right;
      color: #9a9a9a;
      width: 100%;
      margin-bottom: 4px;

      a {
        color: #9a9a9a;
        overflow-wrap: break-word;
      }
      .tracking-label {
        margin-right: 4px;
      }
      .tracking-value {
        font-weight: 600;
        text-decoration: underline;
      }
    }

    .order-status {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-bottom: 4px;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      text-align: right;
      color: #9a9a9a;
      width: 100%;
      .order-status-label {
        margin-right: 4px;
        color: #9a9a9a;
      }
      .order-status-value {
        color: #000000;
        font-weight: 600;
        text-decoration: underline;
      }
    }

    .right-side {
      .price {
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        text-align: right;
        color: #000000;
        margin-bottom: 10px;
      }
      .transaction-fees,
      .royalty-fees {
        display: flex;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        text-align: right;
        color: #9a9a9a;
        .transaction-fee-label,
        .transaction-fee-price,
        .royalty-fee-label,
        .royalty-fee-price {
          margin-right: 4px;
        }
      }
    }

    .order-item-content-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .media {
        width: 112px;
        height: 112px;
        border-radius: 10px;
        overflow: hidden;
        margin-right: 29px;
        position: relative;
        flex-shrink: 0;
        .image-container .image img {
          height: 100%;
        }
      }
      .left-side {
        flex: 1;
        display: flex;
      }
      .right-side {
        width: 40%;
      }
      .right-side {
        justify-content: flex-end;
        text-align: right;
      }
      .transaction-fees,
      .royalty-fees {
        display: flex;
        justify-content: flex-end;

        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 16px;

    .top-section {
      font-size: 10px;
      line-height: 10px;
      padding: 10px 7px;
    }

    .order-content {
      padding: 7px;

      .order-item-content-container .media {
        width: 60px;
        height: 60px;
        border-radius: 11px;
        margin-right: 10px;
      }
      .infor h4 {
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 5px;
      }
      .infor .author {
        margin-bottom: 5px;
      }
      .infor .author a h6 {
        font-size: 10px;
        line-height: 10px;
      }
      .infor .product-name {
        font-size: 10px;
        line-height: 10px;
      }

      .right-side .price {
        font-size: 10px;
        line-height: 10px;
      }
      .order-status,
      .tracking-info {
        font-size: 10px;
        line-height: 10px;
        padding-bottom: 0;
      }
      .tracking-info {
        align-items: center;
        .tracking-value {
          max-width: 50%;
        }
        .tracking-label {
          flex: 1;
          margin-right: 10px;
        }
      }

      .right-side {
        .transaction-fees,
        .royalty-fees {
          font-size: 10px;
          line-height: 10px;
        }
      }
    }
  }
`;
