const UploadButton = ({ title, action, fileName }) => {
  const handleChange = (element) => {
    const [img] = element.target.files;
    action(img);
  };

  return (
    <div id='upload-profile'>
      <button className='btn-upload'>
        {title}

        <input
          id='tf-upload-img'
          type='file'
          name={fileName}
          onChange={handleChange}
        />
      </button>
    </div>
  );
};

export default UploadButton;
