import React from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Cover from '../../components/cover/Cover';
import { useForm } from "react-hook-form";
import { useApi } from '../../hooks/useApi';
import  { useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux";

const { REACT_APP_API_URL } = process.env;

const CreateArtist = () => {
  const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const { executeApiRequestTwo } = useApi();

    const {
        register,
        handleSubmit,
    } = useForm();
    
    const onSubmit = async (data) =>{
        const collectionData = 
        await executeApiRequestTwo(`${REACT_APP_API_URL}artist`, {
            method: 'POST',
            body: JSON.stringify({
              displayName: data.displayName,
              userId : user.id
            }),
            headers: {
                "content-type": "application/json",
              },
        })
        navigate(`/my-artists`)
    }

    return (
        <div className='create-item'>
            <Header />
            <Cover
                title="Create Artist"
                subtitle="Profile | My Artists | Create"
            />
            <div className="tf-create-item tf-section">
                <div className="themesflat-container">
                    <div className="row justify-content-md-center justify-content-xl-center justify-content-lg-center">
                         <div className="col-xl-offset-2 col-xl-9 col-lg-6 col-md-12 col-12">
                             <div className="form-create-item">
                                <div className="flat-tabs tab-create-item">
                                    <form action="#"  onSubmit={handleSubmit(onSubmit)}>
                                        <h4 className="title-create-item">Artist Display Name</h4>
                                        <input 
                                        {...register('displayName')}
                                        name="displayName" 
                                        type="text" 
                                        max={100}
                                        required="false"
                                        placeholder="Enter the Name of your Artist" />

                                        <div className="row-form style-3">
                                            <div className="inner-row-form">
                                                <button className="submit">Create Artist</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                             </div>
                         </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default CreateArtist;
