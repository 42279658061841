import React from "react";
import {
  HBAR_CURRENCY_TYPE,
  USD_CURRENCY_TYPE,
} from "../../../../constants/constants";
import { priceFormatter } from "../../../../helpers/formatters";

const OrderTotals = ({
  isHBARPriced,
  subtotal,
  totalTax,
  shippingCost,
  total,
  requiresShipping,
  taxCalculated,
}) => {
  return (
    <div id="order-totals">
      <div>
        <section className="d-flex justify-content-end p-2">
          <ul>
            <li className="d-flex m-3 justify-content-between">
              <span className="sc-button-5">Subtotal: </span>
              <h6>
                &nbsp;
                {priceFormatter({
                  currencyType: isHBARPriced
                    ? HBAR_CURRENCY_TYPE
                    : USD_CURRENCY_TYPE,
                  price: subtotal,
                })}
              </h6>
            </li>

            <li className="d-flex m-3 justify-content-between align-items-center">
              <span className="sc-button-5">Tax: </span>
              {taxCalculated && (
                <h6>
                  &nbsp;
                  {priceFormatter({
                    currencyType: isHBARPriced
                      ? HBAR_CURRENCY_TYPE
                      : USD_CURRENCY_TYPE,
                    price: totalTax,
                  })}
                </h6>
              )}
              {!taxCalculated && <div className="skeleton skeleton-text"></div>}
            </li>

            {requiresShipping && (
              <li className="d-flex m-3 justify-content-between">
                <span className="sc-button-5">Shipping: </span>
                <h6>
                  &nbsp;
                  {priceFormatter({
                    currencyType: isHBARPriced
                      ? HBAR_CURRENCY_TYPE
                      : USD_CURRENCY_TYPE,
                    price: shippingCost,
                  })}
                </h6>
              </li>
            )}
          </ul>
        </section>

        <section className="d-flex justify-content-end border-top p-2">
          <span className="sc-button-5">Total: </span>
          <h6>
            &nbsp;
            {priceFormatter({
              currencyType: isHBARPriced
                ? HBAR_CURRENCY_TYPE
                : USD_CURRENCY_TYPE,
              price: total,
            })}
          </h6>
        </section>
      </div>
    </div>
  );
};

export default OrderTotals;
