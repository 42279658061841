import React, { useState } from "react";
import { useForm } from "react-hook-form";
import facebookIcon from "../../assets/images/svg/facebook_icon.svg";
import googleIcon from "../../assets/images/svg/google_icon.svg";
import LinkWithQueryParams from "../../components/LinkWithQueryParams";
import Layout from "../../components/layouts/Layout";
import Spinner from "../../components/layouts/Spinner/Spinner";
import {
  IS_SOCIAL_ENABLED,
  SIGNUP_ERRORS_PRESENT,
} from "../../constants/constants";
import {
  auth0FacebookSignup,
  auth0GoogleSignup,
} from "../../helpers/auth0Service";
import SignupForm from "./SignupForm";
import { SignupPage } from "./styles";

const SignUp = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  return (
    <SignupPage>
      <Layout>
        <div id="spacer"></div>
        <div>
          <section className="tf-signup tf-section">
            <div className="row m-0">
              {loading && (
                <div className="spinner-overlay">
                  <Spinner />
                </div>
              )}

              <div className="col-md-6 col-12 left-background">
                <div className="overlay"></div>
                <div className="row justify-content-center">
                  <div className="col-md-9">
                    <div className="page-title-heading mg-bt-12">
                      <div className="vertical-line"></div>
                      <h1 className="heading text-center">welcome</h1>
                    </div>
                    <div className="header-container style2">
                      <h4>
                        Creating an account with ACME LABS™ is the first step in
                        <b>creating your metaverse.</b>
                      </h4>
                    </div>
                    <div className="header-container mb-4">
                      <h4 className="action-link">Already have an account?</h4>
                    </div>
                    <div className="journey-btn-link">
                      <LinkWithQueryParams path={"/login"} className="m-3">
                        <span>Sign in here</span>
                      </LinkWithQueryParams>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-12 right-container">
                {Object.entries(errors).length > 0 && (
                  <div className="general-error-container">
                    <p className="error-message">{SIGNUP_ERRORS_PRESENT}</p>
                  </div>
                )}

                <div className="right-container-content ">
                  {IS_SOCIAL_ENABLED && (
                    <>
                      <div className="row mb-4 justify-content-center">
                        <h4 className="opt-text">Create an account with</h4>
                      </div>

                      <div className="row m-0">
                        <div className="row justify-content-center m-0 mb-4 w-100">
                          <button
                            className="social-button"
                            onClick={() => auth0FacebookSignup()}
                          >
                            <img
                              className="facebookIcon mr-4"
                              src={facebookIcon}
                              alt="/"
                            />
                            Facebook
                          </button>
                        </div>
                        <div className="row justify-content-center m-0 mb-4 w-100">
                          <button
                            className="social-button"
                            onClick={() => auth0GoogleSignup()}
                          >
                            <img
                              className="googleIcon mr-4"
                              src={googleIcon}
                              alt="/"
                            />
                            Google
                          </button>
                        </div>
                      </div>

                      <div className="row mb-4 justify-content-center">
                        <h4 className="opt-text">Or with your email</h4>
                      </div>
                    </>
                  )}

                  <SignupForm
                    register={register}
                    handleSubmit={handleSubmit}
                    watch={watch}
                    errors={errors}
                    setLoading={setLoading}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </SignupPage>
  );
};

export default SignUp;
