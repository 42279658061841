import styled from "styled-components";

export const StyledCheckout = styled.div`
  .reservation-timer-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 10px;
    .reservation-note {
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
    }
    .reservation-timer {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: #ff7b7c;
    }
  }

  #order-summary {
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;

    #order-top-section,
    #order-header,
    #order-products,
    #order-totals {
      width: 100%;
    }

    #order-top-section {
      flex: 1;
    }
  }
  .progress-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    right: 0;
    bottom: 0;
    background: rgba(205, 205, 205, 0.8);
    z-index: 7777;
    position: fixed;

    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    .progess-status {
      display: block;
      width: 100%;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 8px;
      text-align: center;
      color: #011126;
    }
    .spinner {
      width: 50px;
      height: 50px;
    }
  }

  .error-message,
  .card-error {
    color: #e42c2c;
    font-weight: 400;
    font-size: 15px;
  }
  .sc-button:hover {
    border: 3px solid var(--primary-color3);
  }
  #stripe-form h4,
  #stripe-form #card-element {
    margin-bottom: 20px;
  }
  #stripe-form #submit {
    margin-bottom: 10px;
  }
  #shipping-header,
  #shipping-selection,
  #shipping-information,
  #stripe-form-container {
    margin-bottom: 20px;
  }
  .product-name {
    display: block;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #9a9a9a;
  }
  .artist-name {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #1b70d0;
    margin-bottom: 12px;
  }
  .wallet-section {
    margin-top: 20px;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #cdcdcd;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    .wallet-to-connect,
    .wallet-connected {
      text-align: center;
    }
  }

  .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
  }

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }

  .skeleton-text {
    width: 70%;
    height: 15px;
    border-radius: 0.25rem;
  }
`;
