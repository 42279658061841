import React from "react";
import styled from "styled-components";

const StyledIndications = styled.div`
  .indications {
    display: none;
    width: 22%;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;

    position: fixed;
    z-index: 2147483649;
    right: 7%;
    top: 12%;
  }

  .indications p {
    color: white;
    margin-bottom: 12px;
  }

  .indications .title {
    font-weight: 700;
  }

  .indications.active {
    display: block;
  }

  .popup-btn {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    .indications {
      width: 90%;
      right: 5%;
      top: 12%;
    }

    .indications p,
    .indications .popup-btn {
      font-size: 10px;
      line-height: 10px;
    }
  }
`;

const VenlySelectWalletIndications = ({
  selectIndicationsRef,
  selectWallet,
}) => {
  return (
    <StyledIndications>
      <div
        id="select-wallet-indications"
        className="indications"
        ref={selectIndicationsRef}
      >
        <p className="title">Please Choose Your Wallet</p>
        <p>
          In order to buy, sell, and trade items, you must select a wallet to
          use.
        </p>
        <p>
          Please select the wallet you would like to use. You may come back and
          change this later.
        </p>
        <div className="popup-btn" onClick={selectWallet}>
          Click to select your wallet
        </div>
      </div>
    </StyledIndications>
  );
};

export default VenlySelectWalletIndications;
