import { useEffect, useState } from 'react';
import getItem from '../../api/Item/getItem';
import Spinner from '../../components/layouts/Spinner/Spinner';
import useIPFS from '../../hooks/useIPFS';
import getProducts from '../../api/prodigi/getProducts';

const OrderSummary = ({ order }) => {
  const [item, setItem] = useState({});
  const [prodigiProduct, setProdigiProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { getData, element } = useIPFS();

  const getIndividualItem = async (id) => {
    try {
      const prodigiProduct = await getProducts();
      setProdigiProduct(prodigiProduct[0]);

      const item = await getItem(id);
      setItem(item);

      await getData(item.cid);
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const PRODIGI_ITEM_PRICE = 0;

  useEffect(() => {
    (async () => getIndividualItem(order.itemId))();
  }, [order.itemId]);

  return (
    <>
      {loading ? (
        <div className='d-flex justify-content-center align-items-center h-100 w-100'>
          <Spinner />
        </div>
      ) : error ? (
        <div className='d-flex justify-content-center align-items-center h-100 w-100'>
          <h5>An error has ocurred!</h5>
        </div>
      ) : (
        <div className='p-5 shadow rounded d-flex flex-column justify-content-between h-100'>
          <section>
            <div className='d-flex justify-content-between'>
              <div>
                <h2>Order Summary: </h2> <br />
                <p>
                  Order Number:{' '}
                  <span style={{ color: 'blue' }}>{order.id}</span>
                </p>
              </div>
            </div>
            <div className='p-3 border-bottom'>
              <div className='d-flex justify-content-between align-items-center'>
                <div>
                  <h5 className='p-2'>1 x {element.name}</h5>
                  <span className='sc-button-5'>{element.name}</span>
                </div>
                <div>
                  <h5>${Number(item.price).toFixed(2)}</h5>
                </div>
              </div>
            </div>
            <div className='p-3 border-bottom'>
              <div className='d-flex justify-content-between align-items-center'>
                <div>
                  <h5 className='p-2'>1 x {prodigiProduct?.name}</h5>
                  <span className='sc-button-5'>{element.name}</span>
                </div>
                <div>
                  <h5>$ {PRODIGI_ITEM_PRICE.toFixed(2)}</h5>
                </div>
              </div>
            </div>
          </section>

          <div>
            <section className='d-flex justify-content-end p-2'>
              <ul>
                <li className='d-flex m-3 justify-content-between'>
                  <span className='sc-button-5'>Subtotal: </span>
                  <h6>&nbsp;${(order.shipping + item.price).toFixed(2)}</h6>
                </li>
                <li className='d-flex m-3 justify-content-between'>
                  <span className='sc-button-5'>Tax: </span>
                  <h6>&nbsp;${order.tax.toFixed(2)}</h6>
                </li>
                <li className='d-flex m-3 justify-content-between'>
                  <span className='sc-button-5'>Shipping: </span>
                  <h6>&nbsp;${order.shipping.toFixed(2)}</h6>
                </li>
              </ul>
            </section>

            <section className='d-flex justify-content-end border-top p-2'>
              <span className='sc-button-5'>Total: </span>
              <h6>&nbsp;${(order.shipping + item.price).toFixed(2)}</h6>
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderSummary;
