import styled from "styled-components";
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from "../../constants/constants";
import { COMMON_STYLES } from "../../constants/genericStyles";

export const StyledContactUsPage = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }
  section#contact-us {
    padding: 24%;
    padding-top: 2%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .header-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .page-title-heading {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 10px;
    .vertical-line {
      height: 38px;
      border-left: 6px solid #ffd061;
    }

    .heading {
      font-weight: 700;
      font-size: 48px;
      line-height: 48px;
      color: #011126;
    }
  }

  .salutation {
    width: 100%;
    margin-bottom: 20px;

    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    text-decoration-line: underline;
    text-transform: capitalize;

    color: #011126;
  }

  .indications {
    width: 100%;

    margin-bottom: 50px;

    font-family: "Josefin Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 48px;
    letter-spacing: 0.1em;

    color: #000000;
  }
  /* who are we section */
  .who-are-we-container {
    display: flex;
    justify-content: space-between;
    padding: 2%;
    .left-section {
      width: 40%;
    }
    .page-title-heading {
      margin-bottom: 20px;
    }
    .right-section {
      display: flex;
      align-items: center;
      width: 50%;
    }
    .image-container {
      width: 100%;
    }
    .image-container img {
      width: 100%;
      height: auto;
      border-radius: 17px;
    }
    .who-are-we {
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.1em;
      color: #000000;
      div {
        margin-bottom: 20px;
      }
    }
  }

  /* our mission section */
  .our-mission-container {
    padding: 2%;
    display: flex;
    justify-content: space-between;
    background-color: #2159a2;
    padding-top: 40px;
    padding-bottom: 40px;
    .right-section {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
    }
    .left-section {
      width: 50%;
    }
    .image-container {
      width: 100%;
    }
    .image-container img {
      width: 100%;
      height: auto;
      border-radius: 31px;
    }
    .page-title-heading {
      margin-bottom: 20px;
      width: 100%;
      .heading {
        color: #ffffff;
      }
    }
    .our-mission {
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.1em;
      color: #ffffff;
    }
  }

  /* form related */
  .form-inner {
    width: 100%;
  }
  form fieldset {
    margin-bottom: 24px;
  }
  .form-inner form input,
  .form-inner form textarea {
    margin-bottom: 0;
  }
  .field-label,
  .form-field-label {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-transform: capitalize;
    color: #000000;
    /* margin-bottom: 8px; */
    height: 40px;
  }

  .error-message,
  .card-error {
    color: #e42c2c;
    font-weight: 400;
    font-size: 15px;
  }

  .filled-button {
    ${COMMON_STYLES.FILLED_BUTTON({
      fillColor: "#ff7b7c",
      borderSize: "10px",
    })}
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    width: 100%;
  }

  @media (max-width: 768px) {
    #spacer {
      padding-top: ${MOBILE_HEADER_HEIGHT.toString() + "px"};
    }
    section#contact-us {
      padding: 5%;
    }
    .header-container {
      justify-content: flex-start;
    }
    .page-title-heading {
      .vertical-line {
        height: 24px;
        border-left: 4px solid #ffd061;
      }
      .heading {
        font-size: 24px;
        line-height: 24px;
      }
    }
    .salutation {
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
    }

    .indications {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      margin-bottom: 20px;
      text-align: center;
    }

    .form-inner form input {
      font-size: 10px;
      line-height: 10px;
    }

    fieldset .dropdown {
      div {
        padding: 0;
        div {
          div {
            padding-left: 8px;
          }
        }
      }
      #react-select-3-placeholder {
        font-size: 12px;
      }
    }

    form fieldset {
      margin-bottom: 16px;
    }

    .inner-row-form {
      display: flex;
      justify-content: center;
    }

    .filled-button {
      font-size: 14px;
      line-height: 14px;
      width: 160px;
    }
  }
`;
