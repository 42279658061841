import React, { Fragment, useRef, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getLinks } from "../../../api/links/getLinks";
import { useAuth0 } from "../../../auth/Auth";
import { NAVBAR_TRANSITION_TIME } from "../../../constants/constants";
import { displayNameFormatter } from "../../../helpers/formatters";
import useApi2 from "../../../hooks/useApi2";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";
import LinkWithQueryParams from "../../LinkWithQueryParams";
import SignInBtn from "../SignInBtn";
import WalletBtn from "../WalletBtn";
import { stackWalker } from "../utils/stackWalker";
import UserInfo from "./UserInfo";
import { StyledNavbar } from "./styles";

const isTrueHelper = (c) => c && typeof c === "boolean";
const isFalseHelper = (c) => !c && typeof c === "boolean";

const NavbarLink = ({ submenu }) => {
  return submenu?.external ? (
    <a target="_blank" rel="noreferrer" href={submenu?.link}>
      {submenu.name}
    </a>
  ) : (
    <LinkWithQueryParams path={submenu.link}>
      {submenu.name}
    </LinkWithQueryParams>
  );
};

const Navbar = () => {
  const isMobile = useCheckMobileScreen();
  const { isAuthenticated } = useAuth0();
  const { pathname } = useLocation();
  const [activeIndex, setActiveIndex] = useState(null);
  const [closingMenu, setClosingMenu] = useState();
  const [navLinks, setNavLinks] = useState([]);
  const user = useSelector((state) => state.user);
  const { data } = useApi2("space/my", { method: "GET" });
  const displayName = displayNameFormatter({ user });

  const menuLeft = useRef(null);
  const menuRight = useRef(null);
  const btnToggle = useRef(null);

  const activateOverlay = () => {
    const homeOverlay = document.querySelector("#home-overlay");
    const header = document.querySelector(".header_1");
    if (homeOverlay) homeOverlay.classList.toggle("active");
    if (header) header.classList.toggle("active");
    // btnToggle.current.classList.toggle("active");
    menuRight.current.classList.toggle("active");
  };

  React.useEffect(() => {
    if (isTrueHelper(closingMenu)) {
      menuRight.current.classList.toggle("slide-in");
      menuRight.current.classList.toggle("slide-out");
      const timeout = setTimeout(() => {
        menuRight.current.classList.toggle("slide-out");
      }, NAVBAR_TRANSITION_TIME);
      activateOverlay();
      return () => {
        clearTimeout(timeout);
      };
    }

    if (isFalseHelper(closingMenu)) {
      activateOverlay();
      menuRight.current.classList.toggle("slide-in");
    }
  }, [closingMenu]);

  const fetchLinks = async () => {
    const links = await getLinks();
    let navLinks = links?.length > 0 ? links : [];
    // filter for menu items that are not enabled by users not authenticated
    if (!isAuthenticated) {
      navLinks = stackWalker(navLinks);
    }
    setNavLinks(navLinks);
  };

  React.useEffect(() => {
    fetchLinks();
  }, []);

  const menuToggle = () => {
    setClosingMenu(false);
  };

  const closeMenu = () => {
    setClosingMenu(true);
  };

  const handleOnClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <Fragment>
      <div className="mobile-button" ref={btnToggle} onClick={menuToggle}>
        <span></span>
      </div>
      <StyledNavbar>
        <div className="mobile-nav" ref={menuRight}>
          <div id="close" onClick={closeMenu}>
            <span>x</span>
          </div>
          {!isAuthenticated && (
            <div id="sign-in">
              <SignInBtn />
            </div>
          )}

          {isAuthenticated && (
            <UserInfo user={user} displayName={displayName} />
          )}

          <ul>
            <Accordion allowZeroExpanded>
              {navLinks?.length > 0 &&
                navLinks?.map((data, index) => (
                  <AccordionItem
                    key={"accordion" + index}
                    className={
                      index % 2 ? "accordion-item" : "accordion-item light-blue"
                    }
                  >
                    {data?.linkSubLevel?.length > 0 ? (
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span>{data.name}</span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                    ) : (
                      <>
                        <span>{data.name}</span>
                      </>
                    )}
                    <AccordionItemPanel>
                      {data?.linkSubLevel &&
                        data?.linkSubLevel?.length > 0 &&
                        data?.linkSubLevel?.map((submenu) => (
                          <div className="menu-option" key={submenu.id}>
                            {submenu.disabled ? (
                              <p>{submenu.name}</p>
                            ) : (
                              <NavbarLink submenu={submenu} />
                            )}
                          </div>
                        ))}
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
            </Accordion>
          </ul>
          <WalletBtn />
        </div>
      </StyledNavbar>

      <nav id="main-nav" className="main-nav" ref={menuLeft}>
        <ul id="menu-primary-menu" className="menu">
          {navLinks?.length > 0 &&
            navLinks?.map((data, index) => (
              <li
                key={index}
                onClick={() => handleOnClick(index)}
                className={`menu-item ${
                  data.linkSubLevel && data.linkSubLevel?.length > 0
                    ? "menu-item-has-children"
                    : ""
                } ${activeIndex === index ? "active" : ""}`}
              >
                <LinkWithQueryParams path={data.links}>
                  {data.name}
                </LinkWithQueryParams>

                {data.linkSubLevel && data.linkSubLevel?.length > 0 && (
                  <ul className="sub-menu">
                    {data?.linkSubLevel
                      ?.sort((a, b) => a.orderNumber - b.orderNumber)
                      ?.map((submenu) => (
                        <li
                          key={submenu.id}
                          className={`
                        ${submenu.disabled ? "disabled" : ""}
                        ${
                          pathname === submenu.link
                            ? "menu-item current-item"
                            : "menu-item"
                        }`}
                        >
                          <NavbarLink submenu={submenu} />
                        </li>
                      ))}
                  </ul>
                )}
              </li>
            ))}
        </ul>
        <WalletBtn />

        <SignInBtn />
      </nav>
    </Fragment>
  );
};

export default Navbar;
