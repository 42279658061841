import React from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Cover from '../../components/cover/Cover';
import { useForm } from "react-hook-form";
import { useApi } from '../../hooks/useApi';
import { useApi2 } from '../../hooks/useApi2';
import { useParams } from "react-router-dom";

const { REACT_APP_API_URL } = process.env;

const EditItem = () => {
  const { itemId } = useParams();
    const {data, loading} = useApi2(`item/one/${itemId}`);
    
    
    const { executeApiRequest } = useApi();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    
    const onSubmit = (form) =>{
      let formData = {
        categoryIds : [form.category],
        price: form.price,
      }
      executeApiRequest(`${REACT_APP_API_URL}item/one/${itemId}`, {
          method: 'PATCH',
          body: JSON.stringify(formData),
          headers: {
              "content-type": "application/json",
            },
      })
    }
    if(loading) return <h3>Loading...</h3>;
    console.log("🚀 ~ file: EditItem.jsx ~ line 15 ~ EditItem ~ data", data)
    return (
        <div className='create-item'>
            <Header />
            <Cover
                title={`Editing:`}
                subtitle="Profile | My Items | Edit"
            />
            <div className="tf-create-item tf-section">
                <div className="themesflat-container">
                    <div className="row justify-content-md-center justify-content-xl-center justify-content-lg-center">
                         <div className="col-xl-offset-2 col-xl-9 col-lg-6 col-md-12 col-12">
                             <div className="form-create-item">
                                <div className="flat-tabs tab-create-item">
                                    <form action="#"  onSubmit={handleSubmit(onSubmit)}>
                                        <h4 className="title-create-item">Price</h4>
                                        <input 
                                        {...register('price')}
                                        name="price" 
                                        type="text" 
                                        max={100}
                                        required={true}
                                        value={data?.price}
                                        placeholder="Enter the Name of your Collection" />
                                          <h4 className="title-create-item">Category</h4>
                                          <div className="select-box">
                                            <div id="item-create" className="dropdown">
                                              <select id="category"
                                              className="select-box btn-selector nolink"
                                              {...register("category")}>
                                                <option value="Art">Art</option>
                                                <option value="Music">Music</option>
                                                <option value="Domain Names">Domain Names</option>
                                                <option value="Virtual World">Virtual World</option>
                                                <option value="Trading Cards">Trading Cards</option>
                                                <option value="Sports">Sports</option>
                                                <option value="Utility">Utility</option>
                                              </select>
                                            </div>
                                          </div>

                                        <div className="row-form style-3">
                                            <div className="inner-row-form">
                                                <button className="submit">Edit Item</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                             </div>
                         </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default EditItem;
