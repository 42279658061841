import { useFormContext } from 'react-hook-form';
import HeadingText from './HeadingText';

const ShippingInfo = ({ user }) => {
  const { register } = useFormContext();
  return (
    <section id="shipping-info">
      <HeadingText title={"Shipping Information"} variant="small"/>
      <br />
      <fieldset>
        <h6>Street Address</h6>
        <input
          {...register('streetAddress')}
          type='text'
          placeholder='1234 Pennsylvania Avenue NW'
          defaultValue={user.streetAddress}
        />
      </fieldset>
      <br />
      <fieldset>
        <h6>Shipping Address (cont'd)</h6>
        <input
          {...register('streetAddressContd')}
          type='text'
          placeholder='Suite 1400 P.O. Box 345'
          defaultValue={user.streetAddressContd}
        />
      </fieldset>
      <br />
      <div className='row'>
        <div className='col-md-4 col-12'>
          <fieldset>
            <h6>City</h6>
            <input
              {...register('city')}
              type='text'
              placeholder='Atlantic City'
              defaultValue={user.city}
            />
          </fieldset>
        </div>
        <div className='col-md-4 col-12'>
          <fieldset>
            <h6>State</h6>
            <input
              {...register('state')}
              type='text'
              placeholder='GA'
              defaultValue={user.state}
            />
          </fieldset>
        </div>
        <div className='col-md-4 col-12'>
          <fieldset>
            <h6>Zip Code</h6>
            <input
              {...register('zipCode')}
              type='text'
              placeholder='30308'
              defaultValue={user.zipCode}
            />
          </fieldset>
        </div>
      </div>
    </section>
  );
};

export default ShippingInfo;
