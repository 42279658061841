import { useEffect, useState } from "react";

function isScriptLoaded(id) {
  return document.getElementById(id);
}

function loadScript(src, id) {
  if (isScriptLoaded(id)) {
    const scriptDOM = isScriptLoaded(id);
    scriptDOM.remove();
  }
  var s = document.createElement("script");
  let tag = document.getElementsByTagName("script")[0];
  s.src = src;
  s.id = id;
  tag.parentNode.insertBefore(s, tag);
}

const useIbenda = () => {
  const id = "iubenda-script";
  const src = "https://cdn.iubenda.com/iubenda.js";
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    loadScript(src, id);
    setLoading(false);
  }, []);

  return { isLoading };
};

export default useIbenda;
