import React from "react";
import styled from "styled-components";
import backgroundImage from "../../../assets/images/backgroup-secsion/img_bg_slider_2.png";
import { COMMON_STYLES } from "../../../constants/genericStyles";
import LinkWithQueryParams from "../../LinkWithQueryParams";

const StyledSliderCentered = styled.div`
  ${COMMON_STYLES.MAIN_SLIDER_BACKGROUND}
  background-image: url(${backgroundImage});
  display: flex;
  flex-direction: row;
  align-items: center;
  .main-content {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1440px;
  }
  .slider-centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
  }

  .slider-centered-content .slider-text-content {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .main-content .slider-centered-content .btn-auction.center {
    width: initial;
  }
  .main-content .slider-centered-content .btn-auction.center .sc-button-2 {
    padding: 25px 90px;
  }

  .images-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .images-container .image-container {
    background: #011126;
    width: 20%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 22px;
  }
  .images-container .image {
    width: 100%;
  }
  .images-container .image img {
    width: 100%;
    height: auto;
    margin-bottom: 18px;
  }
  .images-container .image-title {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
  }
  .heading {
    margin-bottom: 1.5rem;
  }

  @media (max-width: 768px) {
    .slider-image-content {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      width: 30%;
    }
    .slider-text-content {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .slider-content .image-container {
      overflow: hidden !important;
      border-radius: 10px !important;
    }
    .slider-content .slider-text-content {
      width: 50% !important;
    }
    .slider-content .image-container:before {
      padding-top: 140% !important;
    }

    .slider-centered-content .slider-text-content {
      margin-bottom: 0px;
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 8px;
    }

    .slider-centered-content .slider-text-content .heading {
      font-size: 20px;
      line-height: 20px;
      width: 100%;
      text-align: center;
      margin-bottom: 0px;
    }

    .images-container {
      margin-bottom: 8px;
    }

    .main-content .slider-centered-content .btn-auction.center .sc-button-2 {
      font-size: 12px;
      line-height: 12px;
      padding: 10px 20px;
      margin: 0;
    }
  }
`;

const SliderCenteredContent = (props) => {
  const ActionBtn = () => (
    <div className="btn-auction center mb-4">
      <button
        id="load-more"
        className={`sc-button-2 no-bg button__bottom-top ${props.item.button.class}`}
      >
        <span>{props.item.button.name}</span>
      </button>
    </div>
  );

  return (
    <StyledSliderCentered className="flat-title-page">
      <div className="main-content">
        <div className="slider-centered-content">
          <div className="slider-text-content">
            <h2 className="heading">{props.item.title_1}</h2>

            {props.item.title_2 && (
              <h1 className="sub-heading mb-style mb-4">
                <span className="tf-text s1">{props.item.title_2}</span>
              </h1>
            )}
          </div>
          <div className="images-container">
            {props.item.centeredImages.map((image, i) => (
              <div className="image-container" key={i}>
                <div className="image">
                  <img src={image.img} alt="" />
                </div>
                <div className="image-title">{image.title}</div>
              </div>
            ))}
          </div>

          {props.item.button &&
            props.item.button.name &&
            (props.item.button.hash ? (
              <a href={props.item.button.hash}>
                <ActionBtn />
              </a>
            ) : (
              <LinkWithQueryParams path={props.item.button.path}>
                <ActionBtn />
              </LinkWithQueryParams>
            ))}
        </div>
      </div>
    </StyledSliderCentered>
  );
};

export default SliderCenteredContent;
