import { useFormContext } from "react-hook-form";
import { useState, useEffect } from "react";
import { useApi } from "../../hooks/useApi"

const { REACT_APP_API_URL } = process.env;

const Bio = () => {

    const { executeApiRequestTwo } = useApi();
    const [ bio, setBio] = useState('');
    const { register } = useFormContext();
    const onBioChange = (e) => {
        setBio(e.target.value);
    }
    useEffect(async () => {
        const data = await executeApiRequestTwo(`${REACT_APP_API_URL}space/my`)
        if( data.bio ) setBio(data.bio)
    }, [])

    return (
        <>
            <br /><br /><br />
            <h4 className="text-center mb-4">Biography</h4> 
            
                    <fieldset>
                        <textarea
                        {...register('bio')}
                        tabIndex="4"
                        rows="5"
                        value={bio}
                        onChange={onBioChange}
                        placeholder='Tell the world about your bio'
                        >
                            { bio.length === 0 ? (<></>) :( {bio} ) }
                        </textarea>
                    </fieldset>


            <br /><br /><br />
        </>
    );
};

export default Bio;