import useProdigi from '../../../../hooks/useProdigi';
import Spinner from '../../Spinner/Spinner';
import AttributesSection from './AttributesSection';
import SkuTable from './SkuTable';

const QuoteTab = ({ token }) => {
  const { sku, loading, attributes, error, quote, calculateSku, quoteProduct } =
    useProdigi();

  const divStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const styleDiv = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    borderBottom: '1px solid #ccc',
  };

  return (
    <div>
      <SkuTable skus={token.skus} action={calculateSku} />

      {attributes && (
        <AttributesSection
          sku={sku}
          attributes={attributes}
          action={quoteProduct}
          requireSizing={false}
        />
      )}

      {loading ? (
        <div style={divStyle}>
          <Spinner />
        </div>
      ) : error ? (
        <div style={divStyle}>
          <p>error trying to calculate the quote</p>
        </div>
      ) : (
        quote && (
          <>
            {Object.entries(quote).map(([key, value]) => (
              <div style={styleDiv} key={key}>
                <h6>{key}</h6>
                <p>{value}</p>
              </div>
            ))}
          </>
        )
      )}
    </div>
  );
};

export default QuoteTab;
