import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "../../auth/Auth";
import LinkWithQueryParams from "../../components/LinkWithQueryParams";
import {
  AUTH0_LOGIN_ERROR_STATUS_CODE,
  AUTH0_MANY_ATTEMPTS_CODE,
  EMAIL_INVALID_MESSAGE,
  EMAIL_PATTERN,
  PASSWORD_INVALID_LOGIN_MESSAGE,
  REQUIRED_MESSAGE,
} from "../../constants/constants";
import { useApi } from "../../hooks/useApi";
import { useCustomNavigator } from "../../hooks/useCustomNavigator";
import { signin } from "../../store/slices/user/user";

const { REACT_APP_API_URL } = process.env;

const LoginForm = ({
  register,
  handleSubmit,
  errors,
  setLoading,
  setLoginError,
  setTooManyError,
}) => {
  const { login } = useAuth0();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { executeApiRequestTwo } = useApi();
  const { customNavigate } = useCustomNavigator();

  const executeLogin = async ({ user }) => {
    const url = `${REACT_APP_API_URL}user/my`;
    const options = { method: "GET" };
    const userData = await executeApiRequestTwo(url, options);
    dispatch(signin({ ...userData, ...user }));
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setLoginError(false);
    const { email, password } = data;
    try {
      const { user } = await login({
        email,
        password,
      });
      await executeLogin({ user });
      if (state?.path) {
        customNavigate({ path: state.path, state });
      } else {
        customNavigate({ path: "/", state });
      }
    } catch (e) {
      setLoading(false);
      setLoginError(true);
      console.log(e);
      if (e?.statusCode === AUTH0_LOGIN_ERROR_STATUS_CODE) {
      }
      if (e?.code === AUTH0_MANY_ATTEMPTS_CODE) {
        setTooManyError(true);
        setLoginError(false);
      }
    }
  };

  return (
    <div className="form-inner">
      <form
        id="contactform"
        noValidate="novalidate"
        className="form-submit"
        onSubmit={handleSubmit(onSubmit)}
      >
        <fieldset className="mb-4">
          <h6 className="form-field-label">Email Address</h6>
          <input
            id="email"
            name="email"
            tabIndex="1"
            aria-required="true"
            required
            type="email"
            placeholder="Email Address"
            className="form-field-input"
            {...register("email", {
              required: REQUIRED_MESSAGE,
              pattern: {
                value: EMAIL_PATTERN,
                message: EMAIL_INVALID_MESSAGE,
              },
            })}
          />
          <p className="error-message">{errors.email?.message}</p>
        </fieldset>

        <fieldset className="mb-2">
          <h6 className="form-field-label">Password</h6>
          <input
            id="currentPassword"
            type="password"
            tabIndex="2"
            placeholder="************"
            className="form-field-input"
            {...register("password", {
              required: REQUIRED_MESSAGE,
              minLength: {
                value: 4,
                message: PASSWORD_INVALID_LOGIN_MESSAGE,
              },
              maxLength: {
                value: 20,
                message: PASSWORD_INVALID_LOGIN_MESSAGE,
              },
            })}
          />
          <p className="error-message">{errors.password?.message}</p>
        </fieldset>

        <div className="row-form style-1" style={{ marginBottom: "1vw" }}>
          <LinkWithQueryParams path="/forgot-password" className="forgot-pass">
            Forgot Password ?
          </LinkWithQueryParams>
        </div>
        <div className="row-form style-2">
          <label>
            Remember Me
            <input type="checkbox" />
            <span className="btn-checkbox"></span>
          </label>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button className="submit">Login</button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
