import CartCard from '../../components/layouts/cards/CartCard';

const CartList = ({ items, actions, changeSaleStatus }) => {
  return (
    <div>
      {items.map((item, index) => (
        <CartCard
          item={item}
          actions={actions}
          key={index}
          changeSaleStatus={changeSaleStatus}
        />
      ))}
    </div>
  );
};

export default CartList;
