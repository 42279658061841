import React , {useState} from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import ListMyCollections from './ListMyCollections';
import Cover from '../../components/cover/Cover';
import Actions from '../MyItems/Actions';

const MyCollections = () => {

    return (
        <div>
            <Header />
            <Cover
                title="Your NFTs"
                subtitle="Profile | Collections | Mint"
            />

            <section className="tf-activity s1 tf-section">
                <div className="themesflat-container">
                    <div className="row ">
                        <div className="col-lg-8 col-md-8 col-12">
                            <ListMyCollections/>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">

                            <Actions />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default MyCollections;
