import React from "react";
import styled from "styled-components";
import { REQUIRED_MESSAGE } from "../../constants/constants";
import ImageWithLoader from "../../components/ImageWithLoader";

const StyledActivityImagePreview = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .subheader {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
    width: 100%;
  }
  #item-image-preview {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .nft-card {
      width: 60%;
    }
    #crate-preview {
      width: 100%;
      display: flex;
      justify-content: center;
      .main-image-container {
        width: 60%;
        position: relative;
        padding-top: 100%;
        .image-container .image:before {
          padding-top: 100%;
        }
        .image-container .image {
          height: auto;
        }
        .image-container .image img {
          top: 0;
          object-fit: contain;
        }
      }
    }
  }

  .no-image-preview {
    margin-bottom: 20px;
    .gray-background {
      width: 285px;
      height: 264px;
      background-color: #d9d9d9;
      box-shadow: 4px 0px 12px rgba(0, 0, 0, 0.25);
      border-radius: 27px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
    }

    .btn-upload {
      margin-top: 0;
      margin-bottom: 8px;
    }
    .uploadFile {
      height: 50px;
      width: 50%;
      background-color: #25a29d;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
    }
    .uploadFile input[type="file"]:before {
      color: white;
      background-color: transparent;
    }
    .uploadFile .form-control:focus {
      background-color: transparent;
    }
    .uploadFile input[type="file"] {
      left: 50%;
      right: auto;
      transform: translate(-50%, -50%);
    }
  }

  /* preview image related */
  .preview-image-container {
    width: 250px;
    height: 250px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
  }
  .image-container .image img {
    top: 0;
    height: 100%;
    object-fit: contain;
  }
  .image-container .image:before {
    padding-top: 100%;
  }
`;

const ActivityImagePreview = ({ methods, activityImage, setActivityImage }) => {
  const {
    register,
    formState: { errors },
  } = methods;

  const handleChange = (element) => {
    const [img] = element.target.files;
    setActivityImage(URL.createObjectURL(img));
  };

  return (
    <StyledActivityImagePreview>
      <div id="item-image-preview">
        {activityImage ? (
          <div className="preview-image-container">
            <ImageWithLoader img={activityImage} alt={"image"} />
          </div>
        ) : (
          <div className="no-image-preview">
            <div className="gray-background">
              <label className="uploadFile">
                <input
                  type="file"
                  name="file"
                  className="inputfile form-control"
                  {...register("file", {
                    onChange: handleChange,
                    required: REQUIRED_MESSAGE,
                  })}
                  accept="image/jpg,image/webp,image/jpeg,image/png,image/gif,video/mp4"
                />
              </label>
              <div className="image-indications">
                .png or .jpg files maximum: 10mb
              </div>
            </div>
          </div>
        )}
        <p className="error-message">{errors.file?.message}</p>
      </div>
    </StyledActivityImagePreview>
  );
};

export default ActivityImagePreview;
