import React from "react";
import PropTypes from "prop-types";
import { Navigation, Scrollbar, A11y, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import leftArrow from "../../assets/images/icon/leftArrow.png";
import rightArrow from "../../assets/images/icon/rightArrow.png";
import SliderLeftImage from "../layouts/mainSlides/SliderLeftImage";
import SliderRightImage from "../layouts/mainSlides/SliderRightImage";
import SliderCenteredContent from "../layouts/mainSlides/SliderCenteredContent";
import GenericSliderItem from "../layouts/mainSlides/GenericSliderItem";
import { MainSlider } from "./styles";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

const slideSelector = ({ item, index }) => {
  let sliderItem;
  switch (index) {
    case 0:
      sliderItem = (
        <SliderLeftImage
          item={item}
          textProportions={"40%"}
          imageProportions={"40%"}
        />
      );
      break;
    case 1:
      sliderItem = (
        <SliderRightImage
          item={item}
          imageProportions={"40%"}
          textProportions={"40%"}
        />
      );
      break;
    case 2:
      sliderItem = (
        <SliderRightImage
          item={item}
          imageProportions={"50%"}
          textProportions={"40%"}
        />
      );
      break;
    case 3:
      sliderItem = (
        <SliderLeftImage
          item={item}
          textProportions={"40%"}
          imageProportions={"40%"}
        />
      );
      break;
    default:
      sliderItem = <GenericSliderItem item={item} />;
  }
  return sliderItem;
};

const Slider = (props) => {
  const data = props.data;
  const [swiper, setSwiper] = React.useState();
  const prevRef = React.useRef();
  const nextRef = React.useRef();
  React.useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  return (
    <MainSlider>
      <img
        className="swiper-button custom-prev-button"
        ref={prevRef}
        src={leftArrow}
        alt="swiper-left-arrow"
      />
      <Swiper
        modules={[Navigation, Scrollbar, A11y, Pagination]}
        spaceBetween={0}
        slidesPerView={1}
        navigation={{
          prevEl: prevRef?.current,
          nextEl: nextRef?.current,
        }}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={setSwiper}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index} className={item.class}>
            {slideSelector({ item, index })}
          </SwiperSlide>
        ))}
      </Swiper>
      <img
        className="swiper-button custom-next-button"
        ref={nextRef}
        src={rightArrow}
        alt="swiper-right-arrow"
      />
    </MainSlider>
  );
};

Slider.propTypes = {
  data: PropTypes.array.isRequired,
  control: PropTypes.bool,
  auto: PropTypes.bool,
  timeOut: PropTypes.number,
};

export default Slider;
