import React, { useState, useEffect } from "react";
import Spinner from "../../components/layouts/Spinner/Spinner";
import Collection03 from "../../components/collection/Collection03";
// import Filters from '../Explore/Filters';
import { getBaseItemsByUser } from "../../api/Item/baseItems";
import LoadMore from "./LoadMore";

const ItemsByArtist = ({ userId, items }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  // const [filters, setFilters] = useState({
  //   categoriesIds: [],
  //   status: '',
  // });

  const getAllItems = async (userId) => {
    !loading && setLoading(true);

    if (items.length > 0) {
      setData(items);
      setLoading(false);
      return;
    }

    try {
      const response = await getBaseItemsByUser(userId);
      response.length && setData(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // const changeCategories = (obj, filters) => {
  //   filters.categoriesIds.includes(obj.value)
  //     ? setFilters({
  //         ...filters,
  //         categoriesIds: filters.categoriesIds.filter(
  //           (elem) => elem !== obj.value
  //         ),
  //       })
  //     : setFilters({
  //         ...filters,
  //         categoriesIds: [...filters.categoriesIds, obj.value],
  //       });
  // };

  // const changeStatus = (obj, filters) => {
  //   filters.status.includes(obj.value)
  //     ? setFilters({
  //         ...filters,
  //         status: '',
  //       })
  //     : setFilters({
  //         ...filters,
  //         status: obj.value,
  //       });
  // };

  // const filterRedirector = {
  //   status: changeStatus,
  //   category: changeCategories,
  // };

  // const onChangeFilters = (obj) => {
  //   filterRedirector[obj.name](obj, filters);
  // };

  useEffect(() => {
    (async () => await getAllItems(userId))();
  }, [userId]);

  return (
    <div className="themesflat-container mt-0 mt-sm-5">
      <div className="row mb-5">
        {/* <div className='col-xl-3 col-lg-3 col-md-12'>
          <Filters action={onChangeFilters} />
        </div> */}
        {loading ? (
          <div className="d-flex justify-content-center align-items-center w-100 vh-100">
            <Spinner />
          </div>
        ) : (
          <div className="col-12">
            <Collection03
              data={data}
              stop={6}
              measures={{ md: 4, sm: 6, lg: 4 }}
              isBaseItem={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemsByArtist;
