import React from "react";
import { itemRequiresProdigi } from "../../../../helpers/orderHelpers";
import CheckoutItem from "./CheckoutItem";
import OrderTotals from "./OrderTotals";

const CheckoutItemList = ({
  order,
  prodigiProduct,
  isHBARPriced,
  requiresShipping,
  taxCalculated,
}) => {
  return (
    <div id="order-summary" className="p-5 shadow rounded">
      <div id="order-top-section">
        <div id="order-header">
          <div className="d-flex justify-content-between">
            <div>
              <h2>Order Summary: </h2> <br />
              <p>
                <span style={{ color: "blue" }}> </span>
              </p>
            </div>
          </div>
        </div>

        <div id="order-products">
          {order?.products?.length > 0 &&
            order?.products.map((product, id) => (
              <CheckoutItem
                key={"product" + id}
                item={product}
                prodigiProduct={
                  itemRequiresProdigi({ productData: product?.productData })
                    ? prodigiProduct
                    : null
                }
              />
            ))}
        </div>
      </div>

      <OrderTotals
        isHBARPriced={isHBARPriced}
        subtotal={order?.subtotal}
        totalTax={order?.quote?.totalTax}
        shippingCost={order?.quote?.totalCost}
        total={isHBARPriced ? order?.amount : order?.amount / 100}
        requiresShipping={requiresShipping}
        taxCalculated={taxCalculated}
      />
    </div>
  );
};

export default CheckoutItemList;
