import styled from "styled-components";

export const StyledDropdown = styled.div`
  fieldset {
    margin-bottom: 20px;
    .dropdown {
      flex: 1;
    }
  }

  .dropdown-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
      width: 30px;
      height: auto;
      margin-right: 8px;
    }
    .dropdown-label-text {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      text-align: center;

      color: #011126;
    }
  }

  @media (max-width: 768px) {
    fieldset {
      .dropdown-label {
        .dropdown-label-text {
          font-size: 18px;
          line-height: 18px;
        }
      }
    }
  }
`;
