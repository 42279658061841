import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Assets from "../../../assets/Assets";
import { useCustomNavigator } from "../../../hooks/useCustomNavigator";
import useHashconnect from "../../../hooks/useHashconnect";

const HashpackWallet = ({
  setShowPairingModal,
  setShowAdditionalFeaturesModal,
}) => {
  const { customNavigate } = useCustomNavigator();
  const { state } = useLocation();
  const user = useSelector((state) => state.user);

  const [hashWallet, setHashWallet] = useState();
  const [hashAccountId, setHashAccountId] = useState();

  const { getConnectedWallet, disconnect } = useHashconnect();

  const connectToWallet = async () => {
    setShowPairingModal(!0);
  };
  const additionalFeatures = async () => {
    setShowAdditionalFeaturesModal(!0);
  };

  const walletData = {
    icon: Assets.hashpackIcon,
    altIcon: "hashpackIcon",
    brand: Assets.hashpackBrand,
    altBrand: "hashpackBrand",
  };

  useEffect(() => {
    /* Redirect the user to intended route if wallet is connected*/
    if (hashWallet && state?.intendedRoute) {
      customNavigate({ path: state?.intendedRoute });
    }
  }, [hashWallet]);

  const fetchHashData = async () => {
    setHashAccountId(user?.hashpackWallets?.accountIds?.[0]);
    const hashConnectWallet = await getConnectedWallet();
    if (hashConnectWallet) {
      setHashWallet(hashConnectWallet);
    }
    setHashWallet(hashConnectWallet);
  };

  useEffect(() => {
    if (user?.hashpackWallets?.accountIds?.[0]) {
      fetchHashData();
    } else {
      setHashAccountId();
    }
  }, [user]);

  return (
    <div className="wallet">
      <div className="image">
        <img src={walletData.icon} alt={walletData.altIcon} />
      </div>
      <div className="image">
        <img src={walletData.brand} alt={walletData.altBrand} />
      </div>

      {!hashAccountId && !user?.wallets?.owner?.email && (
        <div className="w-100 flex justify-content-center">
          <button className="sc-button-3 no-bg" onClick={connectToWallet}>
            connect
          </button>
        </div>
      )}

      {hashAccountId && (
        <>
          <div className="wallet-details">
            {hashAccountId && (
              <p>
                Wallet connected: <br /> {hashAccountId} <br />
              </p>
            )}
            {hashWallet && (
              <p>
                Balance: {hashWallet?.walletDetails?.balance?.hbars?.toString()}{" "}
              </p>
            )}
          </div>

          <div className="w-100 flex justify-content-center">
            <button
              className="sc-button-3 no-bg"
              onClick={() => {
                disconnect();
                // localStorage.removeItem("hashconnectData");
                fetchHashData();
              }}
            >
              Logout
            </button>
          </div>
        </>
      )}

      {user?.wallets?.owner?.email && (
        <p className="logout-note">
          To connect, first logout from your venly wallet
        </p>
      )}

      {/* <div className="w-100 flex justify-content-center">
        <button className="sc-button-3 no-bg" onClick={additionalFeatures}>
          additional
        </button>
      </div> */}
    </div>
  );
};

export default HashpackWallet;
