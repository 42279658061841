import { useFormContext } from 'react-hook-form';
import HeadingText from './HeadingText';

const BasicData = ({ user }) => {
  const { register } = useFormContext();
  return (
    <section id="basic-data">
      <HeadingText title={"Basic Info"} variant="small"/>
      <br />
      <fieldset>
        <h6>Email Address</h6>
        <input
          {...register('email')}
          type='email'
          placeholder='Email'
          required={true}
          defaultValue={user.email}
          disabled={true}
        />
      </fieldset>
      <br />
      <div className='row'>
        <div className='col-md-6 col-12'>
          <fieldset>
            <h6>First Name</h6>
            <input
              {...register('firstName')}
              type='text'
              placeholder='First Name'
              required={true}
              defaultValue={user.firstName}
            />
          </fieldset>
        </div>
        <div className='col-md-6 col-12'>
          <fieldset>
            <h6>Last Name</h6>
            <input
              {...register('lastName')}
              type='text'
              placeholder='Last Name'
              required={true}
              defaultValue={user.lastName}
            />
          </fieldset>
        </div>
      </div>

      <br />

      <fieldset>
        <h6>Display Name</h6>
        <input
          {...register('displayName')}
          type='text'
          placeholder='Display Name'
          required={true}
          defaultValue={user.displayName}
        />
      </fieldset>
    </section>
  );
};

export default BasicData;
