import React, { useState, useEffect, Fragment } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Collection03 from "../../components/collection/Collection03";
import getItems from "../../api/Item/getItems";
import Filters from "./Filters";
import Spinner from "../../components/layouts/Spinner/Spinner";

const Explore04 = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    categoriesIds: [],
    status: "",
    // orderByPrice: '',
    // orderByStatus: '',
    // orderByLikes: '',
  });
  // const [orderBy, setOrderBy] = useState('');

  // const sortList = [
  //   { id: 0, name: 'Likes', key: 'orderByLikes' },
  //   { id: 1, name: 'Price', key: 'orderByPrice' },
  //   { id: 2, name: 'Status', key: 'orderByStatus' },
  // ];

  const getFilteredItems = async (params) => {
    !loading && setLoading(true);
    try {
      const response = await getItems(params);
      response && setItems(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const changeCategories = (obj, filters) => {
    filters.categoriesIds.includes(obj.value)
      ? setFilters({
          ...filters,
          categoriesIds: filters.categoriesIds.filter(
            (elem) => elem !== obj.value
          ),
        })
      : setFilters({
          ...filters,
          categoriesIds: [...filters.categoriesIds, obj.value],
        });
  };

  const changeStatus = (obj, filters) => {
    filters.status.includes(obj.value)
      ? setFilters({
          ...filters,
          status: "",
        })
      : setFilters({
          ...filters,
          status: obj.value,
        });
  };

  // const changeOrder = (obj, filters) => {
  //   console.log('obj', obj);
  //   setFilters({
  //     ...filters,
  //     orderBy: obj.value,
  //   });
  // };

  const filterRedirector = {
    status: changeStatus,
    category: changeCategories,
    // sortBy: changeOrder,
  };

  const onChangeFilters = (obj) => {
    filterRedirector[obj.name](obj, filters);
  };

  // const onChangeSelect = (text) => {};

  useEffect(() => {
    getFilteredItems(filters);
  }, [filters /* orderBy */]);

  return (
    <Fragment>
      <Header />
      <section className="tf-explore tf-section">
        <div className="themesflat-container mt-5">
          {/* <div className="row d-flex justify-content-end px-5">
            <SelectOption action={onChangeFilters} sortByList={sortList} />
          </div> */}
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-12">
              <Filters action={onChangeFilters} />
            </div>
            {loading ? (
              <div className="col-xl-9 col-lg-9 col-md-12 flex justify-content-center align-items-center">
                <Spinner />
              </div>
            ) : !items.length ? (
              <h3>No items to Show!</h3>
            ) : (
              <>
                <div className="col-lg-9 col-md-12 flex justify-content-center align-items-center">
                  {items?.length > 0 && (
                    <Collection03
                      data={items}
                      stop={9}
                      measures={{ md: 4, sm: 6 }}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Explore04;
