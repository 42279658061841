import React, { useState } from 'react';
import ProdigiCard from '../../layouts/cards/Prodigi/ProdigiCard';

const ProdigiCollection = ({ data, stop, measures }) => {
  const [visible, setVisible] = useState(stop);

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + stop);
  };

  return (
    <section>
      <div className='row'>
        {data.slice(0, visible).map((item) => (
          <div
            key={item.id}
            className={`col-md-${measures.md} col-sm-${measures.sm} col-12 flex justify-content-center p-4`}
          >
            <ProdigiCard card={item} />
          </div>
        ))}
      </div>
      {visible < data.length && (
        <div className='btn-auction center'>
          <button
            to='#'
            id='load-more'
            className='sc-button-2 no-bg'
            style={{ color: '#F1A09F', borderColor: '#F1A09F' }}
            onClick={showMoreItems}
          >
            <span>Load More</span>
          </button>
        </div>
      )}
    </section>
  );
};

export default ProdigiCollection;
