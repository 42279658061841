import React, { Fragment } from "react";
import { useAuth0 } from "../../auth/Auth";
import LinkWithQueryParams from "../LinkWithQueryParams";

const SignInBtn = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <Fragment>
      {!isAuthenticated && (
        <div>
          <div className="sc-button header-slider fl-button pri-1">
            <LinkWithQueryParams path="/sign-up" className="m-3">
              <span>Sign up</span>
            </LinkWithQueryParams>
            <span>/</span>
            <LinkWithQueryParams path="/login" className="m-3">
              <span>Sign in</span>
            </LinkWithQueryParams>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default SignInBtn;
