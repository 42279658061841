import React from 'react';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import ProdigiItems from './ProdigiItems';

const PrintItems = () => {
  return (
    <div>
      <Header />
      <ProdigiItems />
      <Footer />
    </div>
  );
};

export default PrintItems;
