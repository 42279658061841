import React from "react";
import ImageWithLoader from "../../../components/ImageWithLoader";

const ItemCardVisual = ({ element, itemData }) => {
  return (
    <>
      {element?.type?.includes("image") &&
        itemData?.thumbnailImage?.length > 0 && (
          <ImageWithLoader img={itemData?.thumbnailImage} alt={element.name} />
        )}

      {element?.type?.includes("video") &&
        itemData?.thumbnailImage?.length > 0 && (
          <video
            id="desktop-video"
            width="100%"
            height="100%"
            style={{
              position: "absolute",
              objectFit: "contain",
              backgroundColor: element?.backgroundColor
                ? element?.backgroundColor
                : "",
            }}
            autoPlay
            muted
            loop
            poster={itemData?.thumbnailImage}
          >
            <source src={itemData?.thumbnailImage} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
    </>
  );
};

export default ItemCardVisual;
