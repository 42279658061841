import { useFormContext } from "react-hook-form";
import Assets from "../../assets/Assets";

const SocialMedia = ({ user }) => {
  const { register } = useFormContext();
  return (
    <section id="social-info">
      <h4 className="text-center">Social Media</h4>
      <br />
      <fieldset>
        <div className="social-icon-container">
          <img
            className="social-icon"
            src={Assets["twitterIcon"]}
            alt={"social-icons-twitter"}
          />
          <h6>Twitter URL</h6>
        </div>
        <input
          {...register("twitterUrl")}
          type="text"
          placeholder="https://twitter.com/username"
          defaultValue={user.twitterUrl}
        />
      </fieldset>
      <br />
      <fieldset>
        <div className="social-icon-container">
          <img
            className="social-icon"
            src={Assets["facebookIcon"]}
            alt={"social-icons-facebook"}
          />
          <h6>Facebook URL</h6>
        </div>
        <input
          {...register("facebookUrl")}
          type="text"
          placeholder="https://facebook.com/username"
          defaultValue={user.facebookUrl}
        />
      </fieldset>
      <br />
      <fieldset>
        <div className="social-icon-container">
          <img
            className="social-icon"
            src={Assets["discordIcon"]}
            alt={"social-icons-discord"}
          />
          <h6>Discord URL</h6>
        </div>
        <input
          {...register("discordUrl")}
          type="text"
          placeholder="https://discord.com/users/user-id"
          defaultValue={user.discordUrl}
        />
      </fieldset>
      <br />
      <fieldset>
        <div className="social-icon-container">
          <img
            className="social-icon"
            src={Assets["instagramIcon"]}
            alt={"social-icons-instagram"}
          />
          <h6>Instagram URL</h6>
        </div>
        <input
          {...register("instagramUrl")}
          type="text"
          placeholder="https://instagram.com/username"
          defaultValue={user.instagramUrl}
        />
      </fieldset>
      <br />
      <fieldset>
        <div className="social-icon-container">
          <img
            className="social-icon"
            src={Assets["tiktokIcon"]}
            alt={"social-icons-tiktok"}
          />
          <h6>Tiktok URL</h6>
        </div>
        <input
          {...register("tiktokUrl")}
          type="text"
          placeholder="https://tiktok.com/username"
          defaultValue={user.tiktokUrl}
        />
      </fieldset>
    </section>
  );
};

export default SocialMedia;
