import { useEffect, useState } from "react";
import getOneSpace from "../../api/spaces/getSpaceByUserId";
import { useS3Url } from "../../hooks/useS3Url";
import LinkWithQueryParams from "../LinkWithQueryParams";

const SellerCard = ({ card, name = true }) => {
  const [space, setSpace] = useState({});

  const getSpaceByUserId = async (userId) => {
    if (userId) {
      const response = await getOneSpace(userId);
      response && setSpace(response);
    }
  };

  const { getS3Url } = useS3Url();
  const image = getS3Url(card.profileFileName, "profilePicture");

  useEffect(() => {
    (async () => {
      await getSpaceByUserId(card.id);
    })();
  }, [card.id]);

  return (
    <div className="seller-card">
      <div className="seller">
        <LinkWithQueryParams path={`/spaces/${space.customURL}`}>
          <div className="profile-image-container">
            <div className="profile-image">
              <img src={image} alt={`Seller-${card.displayName}`} />{" "}
            </div>
          </div>
        </LinkWithQueryParams>
        <div className="badge"></div>
      </div>
      {name && (
        <div className="author-infor">
          <h5>{card.displayName}</h5>
        </div>
      )}
    </div>
  );
};

export default SellerCard;
