import React, { Fragment, useEffect, useState } from "react";
import useIPFS from "../../../hooks/useIPFS";
import LinkWithQueryParams from "../../LinkWithQueryParams";
import Spinner from "../../layouts/Spinner/Spinner";
import ArtistButton from "../ArtistButton";
import ArtistInfo from "../ArtistInfo";
import { StyledArtistCard } from "./styles";

const ArtistCard = ({ card, artistPage = false, isCollection = false }) => {
  const { getImageTwo } = useIPFS();

  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState();
  const baseRoute = isCollection ? "collection" : null;
  const collectionRoute = `/${baseRoute}/${card.id}`;
  useEffect(() => {
    const getAndSaveImage = async (cids) => {
      let thumbs = [];
      const imagesPromises = cids.map(async (cid) => {
        try {
          const thumb = await getImageTwo(cid);
          thumbs.push(thumb);
        } catch (e) {}
      });
      await Promise.all(imagesPromises);
      return thumbs;
    };

    const getAllCids = async (cids) => {
      if (!cids || !Array.isArray(cids)) return;
      if (cids.length === 0) return;
      const thumbs = await getAndSaveImage(cids);
      setImages(thumbs);
      setLoading(false);
    };

    getAllCids(card.cids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledArtistCard>
      {loading ? (
        <div className="spinner-container">
          <Spinner />
        </div>
      ) : (
        <div className="is_dark sc-card-collection style-2">
          <ArtistInfo
            item={card}
            artistPage={artistPage}
            collectionRoute={collectionRoute}
            isCollection={isCollection}
          />

          <LinkWithQueryParams path={baseRoute ? `${collectionRoute}` : "#"}>
            <div className="collection-card">
              {images.length > 2 ? (
                <Fragment>
                  <div className="item-collection">
                    <div className="left-img">
                      <div className="image-container">
                        <img src={images[0]} alt="axies" />
                      </div>
                    </div>
                  </div>

                  <div className="item-collection rows-container">
                    <div className="top-img">
                      <div className="image-container">
                        <img src={images[1]} alt="axies" />
                      </div>
                    </div>
                    <div className="bottom-img">
                      <div className="image-container">
                        <img src={images[2]} alt="axies" />
                      </div>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <div className="item-collection style3">
                  <div className="unique-img">
                    <div className="image-container">
                      <img src={images[0]} alt="axies" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </LinkWithQueryParams>

          {!artistPage && <ArtistButton item={card} />}
        </div>
      )}
    </StyledArtistCard>
  );
};

export default ArtistCard;
