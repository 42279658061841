import {
  FIRST_NAME_CLAIM,
  LAST_NAME_CLAIM,
  USD_CURRENCY_TYPE,
} from "../constants/constants";

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
export const priceFormatter = ({ currencyType, price }) => {
  let formattedPrice;
  if (!currencyType || !isNumeric(price)) return "";
  const priceWithCurrencyType = `${parseFloat(price).toFixed(
    2
  )} ${currencyType}`;
  switch (currencyType) {
    case USD_CURRENCY_TYPE:
      formattedPrice = `$${priceWithCurrencyType}`;
      break;
    default:
      formattedPrice = priceWithCurrencyType;
  }
  return formattedPrice;
};

export const displayNameFormatter = ({ user }) => {
  if (user?.displayName) return user.displayName;

  const claimsName =
    `${user[FIRST_NAME_CLAIM] ?? ""} ${user[LAST_NAME_CLAIM] ?? ""}`.length > 4
      ? `${user[FIRST_NAME_CLAIM]} ${user[LAST_NAME_CLAIM]}`
      : user.nickname;
  const displayName = user.firstName ? user.firstName : claimsName;

  return displayName;
};

export const statusFormatter = ({ status }) => {
  switch (status) {
    case "SOLD":
      return "Purchased";
    case "HOLDED":
      return "Purchased";
    case "FOR_SALE":
      return "For Sale";
    case "SELLING_IN_PROGRESS":
      return "Selling In Progress";
    default:
      return status;
  }
};

export const orderStatusFormatter = ({ status }) => {
  switch (status) {
    case "COMPLETED":
      return "Completed";
    case "COMPLETED-WITH-ERRORS":
      return "Processing";
    case "IN-PRODUCTION":
      return"In Production";
    case "SHIPPED":
      return "Shipped";
    default:
      return "Processing"; // return status;
  }
};

export const prodigiStatusFormatter = ({ status }) => {
  switch (status) {
    case "Complete":
      return "Completed";
    case "InProgress":
      return "In Progress";
    case "Cancelled":
      return "Cancelled";
    default:
      return status;
  }
};

export const statusClassSelector = ({ status }) => {
  switch (status) {
    case "SOLD":
      return "green";
    case "HOLDED":
      return "green";
    case "FOR_SALE":
      return "red";
    case "SELLING_IN_PROGRESS":
      return "red";
    default:
      return status;
  }
};

export const prodigiStatusClassSelector = ({ status }) => {
  switch (status) {
    case "Complete":
      return "green";
    case "InProgress":
      return "red";
    case "Cancelled":
      return "red";
    default:
      return status;
  }
};

export const sellItemButtonValidator = ({ item, productData, myVaultIds }) => {
  if (myVaultIds?.length > 0 && myVaultIds.includes(item?.id)) {
    return false;
  }

  return (
    (item?.status === "SOLD" || item?.status === "HOLDED") &&
    item?.finalCid?.length > 0 &&
    productData?.eligibleForResale === "Y"
  );
};

export const removeFromSaleButtonValidator = ({ item }) => {
  return item?.saleStartDate && item?.status === "FOR_SALE";
};

export const additionalPurchaseOptionsButtonValidator = ({ productData }) => {
  return productData?.addons?.length > 0;
};

export const approveButtonValidator = ({ item }) => {
  return item?.status === "SELLING_IN_PROGRESS";
};
