import React from "react";
import { Modal } from "react-bootstrap";
import useHashconnect from "../../../hooks/useHashconnect";
import { StyledModal } from "./styles";

const AdditionalFeaturesModal = ({ show, onHide }) => {
  const { associateToken, payWithHashconnect, transferNFTWithHashconnect } =
    useHashconnect();

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="popup-blocker-modal"
    >
      <Modal.Header
        style={{
          backgroundColor: "#011126",
        }}
        closeButton
      ></Modal.Header>
      <Modal.Body
        style={{
          backgroundColor: "#011126",
        }}
      >
        <StyledModal>
          <div style={{ marginBottom: "20px" }}>
            <div className="pairing-code">Pay with wallet:</div>
            <div className="w-100 flex justify-content-start">
              <button
                className="sc-button-3 no-bg"
                onClick={() =>
                  payWithHashconnect({ toAccount: "0.0.3075571", amount: 10 })
                }
              >
                pay
              </button>
            </div>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <div className="pairing-code">Transfer NFT:</div>
            <div className="w-100 flex justify-content-start">
              <button
                className="sc-button-3 no-bg"
                onClick={() =>
                  transferNFTWithHashconnect({
                    toAccount: "0.0.3075571",
                    tokenId: "0.0.14658561",
                    serialNumber: "1",
                  })
                }
              >
                transfer
              </button>
            </div>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <div className="pairing-code">Associate Collection:</div>
            <div className="w-100 flex justify-content-start">
              <button
                className="sc-button-3 no-bg"
                onClick={() => associateToken({ tokenId: "0.0.3119091" })}
              >
                associate
              </button>
            </div>
          </div>
        </StyledModal>
      </Modal.Body>
    </Modal>
  );
};

export default AdditionalFeaturesModal;
