import React from "react";
import styled from "styled-components";
import LinkWithQueryParams from "../LinkWithQueryParams";
const StyledCollectionHeader = styled.div`
  .vertical {
    margin-right: 10px;
    border-left: 8px solid #ffd061;
    height: 36px;
    display: inline-block;
  }
  @media (max-width: 768px) {
    .tf-title.style2 {
      margin-bottom: 0;
      font-size: 20px;
      line-height: 20px;
    }
    .vertical {
      height: 20px;
    }
  }
`;
const CollectionHeader = ({ title, navigateTo, show, centered, color }) => {
  return (
    <StyledCollectionHeader className="col-md-12 p-0">
      <div
        className="heading-live-auctions"
        style={{
          justifyContent: centered ? "center" : "space-between",
        }}
      >
        <h2
          className="tf-title style2 text-left"
          style={{
            color: color ? color : "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="vertical"></div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>{title}</span>
          </div>
        </h2>
        {show && (
          <LinkWithQueryParams path={navigateTo} className="exp style2">
            Explore More
          </LinkWithQueryParams>
        )}
      </div>
    </StyledCollectionHeader>
  );
};

export default CollectionHeader;
