import React, { Fragment, useEffect, useState } from "react";
import item2 from "../../../assets/images/item-background/item-2.png";
import useIPFS from "../../../hooks/useIPFS";
import LinkWithQueryParams from "../../LinkWithQueryParams";
import Spinner from "../Spinner/Spinner";

const TodayPickCard = ({ card }) => {
  const { element, getData } = useIPFS();

  const [loading, setLoading] = useState(true);

  const getCid = async (cid) => {
    try {
      if (cid.includes("ipfs")) {
        await getData(cid);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCid(card.cid);
  }, []);

  return (
    <Fragment>
      {loading ? (
        <div className="card-spinner-container">
          <Spinner />
        </div>
      ) : (
        <div className="container-cards p-1">
          <div className="card_item">
            <LinkWithQueryParams path={`/item-details-01/${card.id}`}>
              <div className="inner-card">
                <img src={element.image ? element.image : item2} alt="Axies" />
                <div className="content2">
                  <div className="fs-16">{element.name}</div>
                </div>
              </div>
            </LinkWithQueryParams>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default TodayPickCard;
