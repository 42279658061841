import React, { useEffect, useState } from "react";
import { getAnnouncements } from "../../api/announcements/getAnnouncements";
import Layout from "../../components/layouts/Layout";
import { activitiesData } from "../../constants/fakeData";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import HeadingText from "../editProfile/HeadingText";
import AnnouncementsContainer from "./AnnouncementsContainer";
import AnnouncementsSlider from "./AnnouncementsSlider";
import { StyledActivityPage } from "./styles";

const Activity = () => {
  const [activities, setActivities] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const isMobile = useCheckMobileScreen();

  const getActivitiesFromDb = async () => {
    try {
      // const activities = await getActivities();
      setActivities(activitiesData);
    } catch (error) {
      console.log(error);
    }
  };

  const getAnnouncementsFromDb = async () => {
    try {
      const announcements = await getAnnouncements();
      setAnnouncements(announcements);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getActivitiesFromDb();
    getAnnouncementsFromDb();
  }, []);

  return (
    <Layout>
      <StyledActivityPage>
        <div id="spacer"></div>
        <section id="top-announcements">
          <HeadingText title={"Top Announcements"} variant="small" />
          {!isMobile && (
            <div id="announcement-cards-container">
              <AnnouncementsSlider data={announcements} />
            </div>
          )}

          <div id="height"></div>
        </section>
        {isMobile && (
          <div id="announcement-vertical-container">
            <AnnouncementsContainer data={announcements} />
          </div>
        )}
      </StyledActivityPage>
    </Layout>
  );
};

export default Activity;
