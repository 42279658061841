import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import getCollection from "../../../api/collections/getCollection";
import { getProductById } from "../../../api/products/getProducts";
import getOneSpace from "../../../api/spaces/getSpaceByUserId";
import getUser from "../../../api/user/getUser";
import LinkWithQueryParams from "../../../components/LinkWithQueryParams";
import {
  OWNED_TYPE,
  WALLET_NOT_CONNECTED_ERROR,
} from "../../../constants/constants";
import {
  additionalPurchaseOptionsButtonValidator,
  removeFromSaleButtonValidator,
  sellItemButtonValidator,
} from "../../../helpers/formatters";
import {
  ErrorIcon,
  notifyError,
} from "../../../helpers/toastNotificationService";
import { useApi } from "../../../hooks/useApi";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";
import { useCustomNavigator } from "../../../hooks/useCustomNavigator";
import useIPFS from "../../../hooks/useIPFS";
import useVenly from "../../../hooks/useVenly";
import { generateHmac } from "../../../utils/sha256Encription";
import COAModal from "../COAModal";
import ProdigiCard from "../Cards/ProdigiCard";
import RemoveModal from "../RemoveFromSaleModal/RemoveModal";
import SellModal from "../SellModal/SellModal";
import TransferFromVaultModal from "../TransferFromVault/TransferFromVaultModal";
import ItemCardLeftSection from "./ItemCardLeftSection";
import ItemCardRightSection from "./ItemCardRightSection";
import ItemCardVisual from "./ItemCardVisual";
import { StyledItemCard } from "./styles";
const { REACT_APP_API_URL } = process.env;

const ItemCard = ({
  item,
  type,
  isGallery,
  transferToEscrow,
  myVaultItems,
}) => {
  const { customNavigate } = useCustomNavigator();
  const isMobile = useCheckMobileScreen();
  const user = useSelector((state) => state.user);
  const { executeTransaction, executeTokenAssociation } = useVenly();
  const navigate = useNavigate();
  const { executeApiRequest, executeApiRequestTwo } = useApi();
  const [ownerData, setOwnerData] = useState({});
  const [spaceData, setSpaceData] = useState({});
  const [collectionData, setCollectionData] = useState({});
  const [productData, setProductData] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [sellShowModal, setShowSellModal] = useState(false);
  const [removeShowModal, setShowRemoveModal] = useState(false);
  const [transferShowModal, setShowTransferModal] = useState(false);

  const [coaImg, setCoaImg] = useState();
  const [needsExpansion, setNeedsExpansion] = useState(false);

  const [loading, setLoading] = useState(false);
  const { getData, element, convertIPFSUrl } = useIPFS();

  const notifyWalletError = () =>
    toast.error(WALLET_NOT_CONNECTED_ERROR, {
      icon: ErrorIcon,
      onClose: () =>
        customNavigate({
          path: `/wallet-connect`,
          state: { intendedRoute: "/my-items" },
        }),
    });

  const transferFromEscrow = async ({ itemId }) => {
    const { hString, epochTimestamp } = generateHmac({
      userId: user.id,
      id: itemId,
    });
    const requestBody = { transferAccount: "SELLER", hString, epochTimestamp };
    const url = `${REACT_APP_API_URL}transfer/item-from-escrow/${itemId}`;
    const options = {
      headers: { "Content-Type": "application/json" },
      method: "PATCH",
      body: JSON.stringify(requestBody),
    };

    const response = await executeApiRequestTwo(url, options);
    return response;
  };

  const wantsToHold = async ({ itemId }) => {
    const { hString, epochTimestamp } = generateHmac({
      userId: user.id,
      id: itemId,
    });
    const requestBody = {
      hString,
      epochTimestamp,
    };
    const url = `${REACT_APP_API_URL}item/hold/${itemId}`;
    const options = {
      headers: { "Content-Type": "application/json" },
      method: "PATCH",
      body: JSON.stringify(requestBody),
    };

    await executeApiRequest(url, options);
  };

  const getProductName = async () => {
    const productId = item?.productId;
    const productData = await getProductById({ productId });
    setProductData(productData);
  };

  const getSpaceData = async () => {
    if (item.originalOwnerId) {
      await getData(item?.finalCid ? item?.finalCid : item.cid);
      const userData = await getUser(item.originalOwnerId);
      const spaceOwnerData = await getOneSpace(userData.id);
      const collectionData = await getCollection(item.collectionId);
      setCollectionData(collectionData);
      setOwnerData(userData);
      setSpaceData(spaceOwnerData);
    }
  };

  const checkForExpansion = async () => {
    if (!item || !productData) {
      return;
    }
    if (
      Object.entries(item).length > 0 &&
      Object.entries(productData).length > 0
    ) {
      setNeedsExpansion(
        sellItemButtonValidator({ item, productData }) ||
          removeFromSaleButtonValidator({ item }) ||
          additionalPurchaseOptionsButtonValidator({ productData })
      );
    }

    if (item?.COAImage) {
      const coaImgExists = item?.COAImage?.length > 0;
      setNeedsExpansion(coaImgExists);
      const convertedImage = await convertIPFSUrl(item?.COAImage);
      setCoaImg(convertedImage);
    }
  };

  const removeItemFromSale = async ({ item }) => {
    try {
      setLoading(true);
      const transferResponse = await transferFromEscrow({ itemId: item?.id });
      if (transferResponse?.statusCode === 500) {
        notifyError("Something went wrong, please try again later");
      } else {
        const response = await wantsToHold({ itemId: item?.id });
        setLoading(false);
        setShowRemoveModal(false);
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const showRemoveModal = async () => {
    setShowRemoveModal(true);
  };

  useEffect(() => {
    checkForExpansion();
  }, [item, productData]);

  useEffect(() => {
    (async () => {
      try {
        getSpaceData();

        if (item?.productId && item?.id) {
          getProductName();
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [item]);

  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  const handleExpand = () => {
    if (needsExpansion) {
      setExpanded(!expanded);
    }
  };

  const handleCloseSellModal = () => {
    setShowSellModal(false);
  };

  const handleCloseRemoveModal = () => {
    setShowRemoveModal(false);
  };

  const sellItem = () => {
    if (user?.wallets?.walletDetails.id || user?.hashpackWallets?.accountIds) {
      setShowSellModal(true);
    } else {
      notifyWalletError();
    }
  };

  const wantsToTransferFromVault = async ({ itemId, walletId }) => {
    const { hString, epochTimestamp } = generateHmac({
      userId: user.id,
      id: itemId,
    });
    const requestBody = {
      walletId,
      hString,
      epochTimestamp,
    };

    const url = `${REACT_APP_API_URL}transfer/item-from-vault/${itemId}`;
    const options = {
      headers: { "Content-Type": "application/json" },
      method: "PATCH",
      body: JSON.stringify(requestBody),
    };

    return await executeApiRequestTwo(url, options);
  };

  const transferItemFromVault = async ({ itemId, walletId }) => {
    try {
      setLoading(true);
      const transferResponse = await wantsToTransferFromVault({
        itemId,
        walletId,
      });
      setLoading(false);
      setShowTransferModal(false);
      if (transferResponse?.statusCode === 500) {
        notifyError("Something went wrong, please try again later");
      } else if (transferResponse?.statusCode === 400) {
        notifyError(transferResponse?.message[0]);
      } else {
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      setShowTransferModal(false);
    }
  };

  const handleCloseTransferModal = () => {
    setShowTransferModal(false);
  };

  const showTransferModal = async () => {
    setShowTransferModal(true);
  };

  const showAdditionalOptions = async () => {
    customNavigate({ path: `/additional-purchase-options/${item.id}` });
  };

  if (type === "printedItems") {
    return <ProdigiCard item={item} />;
  }
  return (
    <StyledItemCard>
      <div className="sc-card-activity" key={item.id} id={item.id}>
        <div className={`content ${expanded ? "expanded" : ""}`}>
          <div className="media">
            <LinkWithQueryParams path={`/item-details-01/${item?.id}`}>
              <ItemCardVisual itemData={item} element={element} />
            </LinkWithQueryParams>
          </div>

          <div className={`infor ${type}`}>
            <ItemCardLeftSection
              itemDisplayName={element?.name}
              spaceData={spaceData}
              creatorDisplayName={ownerData.displayName}
              productName={productData?.name}
              productData={productData}
              expanded={expanded}
              item={item}
              loading={loading}
              showRemoveModal={showRemoveModal}
              sellItem={sellItem}
              showTransferModal={showTransferModal}
              isMobile={isMobile}
              myVaultItems={myVaultItems}
              showAdditionalOptions={showAdditionalOptions}
            />

            {type === OWNED_TYPE && (
              <ItemCardRightSection
                item={item}
                handleExpand={handleExpand}
                needsExpansion={needsExpansion}
                expanded={expanded}
                isMobile={isMobile}
              />
            )}

            {/* {
              <>
                <p className="status">{element.description}</p>
                <div className="status">
                  {item.status} <span className="author">{item.author}</span>
                </div>
              </>
            } */}

            {/* Prodigi Button =============> */}
            {/*<button
            onClick={() => navigate(`/print/${item.id}`)}
            id='load-more'
            className='sc-button-3 no-bg'
          >
            print item
          </button>*/}
            {/* ================================ */}
          </div>
        </div>

        {expanded && item?.COAImage && (
          <div className="hidden" onClick={handleOpen}>
            <div className="coa">View Certificate of Authenticity</div>
          </div>
        )}

        <COAModal
          show={showModal}
          onHide={handleClose}
          img={coaImg}
          alt={"coa"}
        />

        <SellModal
          itemId={item?.id}
          show={sellShowModal}
          onHide={handleCloseSellModal}
          walletId={user?.wallets?.walletDetails?.id}
          item={item}
          collectionData={collectionData}
          user={user}
          transferToEscrow={transferToEscrow}
        />

        <RemoveModal
          show={removeShowModal}
          onHide={handleCloseRemoveModal}
          removeItemFromSale={removeItemFromSale}
          item={item}
        />

        <TransferFromVaultModal
          show={transferShowModal}
          onHide={handleCloseTransferModal}
          transferItemFromVault={transferItemFromVault}
          item={item}
          loading={loading}
        />
      </div>
    </StyledItemCard>
  );
};

export default ItemCard;
