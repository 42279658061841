import React from "react";
import LinkWithQueryParams from "../../../components/LinkWithQueryParams";

const ConnectWallet = ({ connectWallet }) => {
  return (
    <div className="wallet-to-connect">
      <p className="title">Optional - Connect Your Wallet</p>
      <p className="indications">
        If you wish to use a third-party wallet, you must connect to your wallet to buy, sell & trade items.
      </p>
      <div>
        <button className="filled-button" onClick={connectWallet}>
          <span>Connect a wallet</span>
        </button>
      </div>
      <p className="indications">
        Need additional help connecting your wallet?{" "}
        <LinkWithQueryParams
          className="underline-animation"
          target="_blank"
          rel="noopener noreferrer"
          path="/faq"
        >
          Click here
        </LinkWithQueryParams>
      </p>

      <p className="indications">
        By connecting your wallet you agree to our{" "}
        <LinkWithQueryParams
          path="/terms"
          target="_blank"
          rel="noopener noreferrer"
          className="underline-animation"
        >
          Terms of Service
        </LinkWithQueryParams>{" "}
        and our{" "}
        <LinkWithQueryParams
          path="/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          className="underline-animation"
        >
          Privacy Policy.
        </LinkWithQueryParams>{" "}
      </p>
    </div>
  );
};

export default ConnectWallet;
