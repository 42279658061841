import React, { useEffect, useState } from "react";
import { useS3Url } from "../../hooks/useS3Url";

const CollectionVideo = ({ dbImage, previewImage }) => {
  const { getS3Url } = useS3Url();
  const [img, setImg] = useState(dbImage);
  useEffect(() => {
    if (previewImage?.image) {
      setImg(previewImage?.image);
      return;
    }
    if (!dbImage) return;
    if (dbImage.includes("amazonaws")) {
      setImg(dbImage);
      return;
    }
    const profileFileNameS3 = getS3Url(dbImage, "coverImage");
    if (profileFileNameS3.length !== 0) {
      setImg(profileFileNameS3);
    }
  }, [dbImage, getS3Url]);

  return (
    <div className="collection-image-container">
      <div
        className="collection-video"
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        {img && img?.length !== 0 ? (
          <video
            id="desktop-video"
            width="100%"
            height="100%"
            style={{
              position: "absolute",
              objectFit: "contain",
              backgroundColor: img?.backgroundColor ? img?.backgroundColor : "",
            }}
            autoPlay
            muted
            loop
            poster={img}
          >
            <source src={img} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <div className="gray-background"></div>
        )}
      </div>
    </div>
  );
};

export default CollectionVideo;
