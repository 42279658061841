import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import styled from "styled-components";
import {
  getMyItems,
  getMyPendingApprovalItems,
  getMyPrintedItems,
  getMyResaleItems,
  getMyVaultItems,
} from "../../api/Item/getItems";
import VenlyOverlay from "../../components/Venly/VenlyOverlay";
import VenlyPayWithVenlyIndications from "../../components/Venly/VenlyPayWithVenlyIndications";
import CollectionHeader from "../../components/collection/CollectionHeader";
import Layout from "../../components/layouts/Layout";
import Spinner from "../../components/layouts/Spinner/Spinner";
import {
  CUSTOM_PAGE_SIZE,
  FOR_SALE_ID,
  HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
  OWNED_ID,
  OWNED_TYPE,
  OWNED_TYPE_FORMATTED,
  PHYSICAL_PRINTS_TYPE,
  VAULT_ID,
} from "../../constants/constants";
import { statusFormatter } from "../../helpers/formatters";
import { joinResaleAndRegularItems } from "../../helpers/itemHelpers";
import { useRole } from "../../hooks/useRole";
import useVenly from "../../hooks/useVenly";
import Filters from "../Explore/Filters";
import Actions from "./Actions";
import ListMyItems from "./ListMyItems";

const StyledMyItems = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }

  .tf-section.tf-activity {
    padding-top: 0;
  }

  .filter-label {
    text-transform: capitalize;
  }

  @media (max-width: 768px) {
    #spacer {
      padding-top: ${MOBILE_HEADER_HEIGHT.toString() + "px"};
    }

    button.accordion-button {
      font-size: 14px;
      line-height: 14px;
    }

    .themesflat-tabs .react-tabs__tab {
      font-size: 12px;
      line-height: 16px;
    }

    .widget-filter.style-1 form label {
      font-size: 12px;
      line-height: 12px;
      padding-left: 20px;
      margin-bottom: 10px;
    }

    .widget-filter .btn-checkbox {
      width: 12px;
      height: 12px;
      border-radius: 2px;
    }

    .sc-button-2 {
      font-size: 12px;
      line-height: 12px;
      padding: 10px;
      border-radius: 3px;
      border: 1px solid #ff7b7c;
      color: #ff7b7c;
    }

    hr {
      border: 0;
      border-top: 1px solid #cdcdcd;
    }
  }
`;

let myVaultItems = [];
const MyItems = () => {
  const user = useSelector((state) => state.user);
  const { isGallery } = useRole();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [prodigiItems, setProdigiItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [pendingApprovalItems, setPendingApprovalItems] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [itemsLimit, setItemsLimit] = useState();

  useEffect(() => {
    const hash = window.location.hash.substr(1);

    if (items && hash) {
      setTimeout(() => {
        const element = document.getElementById(hash);

        if (element) {
          window.scrollTo({
            behavior: element ? "smooth" : "auto",
            top: element ? element.offsetTop : 0,
          });
        }
      }, 3000);
    }
  }, [items]);

  const { executeTokenTransfer } = useVenly();

  const overlayRef = useRef(null);
  const payIndicationsRef = useRef(null);
  const executeDataRef = useRef({
    status: null,
  });

  const getResale = async () => {
    const resaleItems = await getMyResaleItems();
    return resaleItems;
  };

  const fetchItems = async () => {
    const myItems = await getMyItems();
    myVaultItems = await getMyVaultItems();
    const resaleItems = await getResale();

    let resaleAndRegularItems = joinResaleAndRegularItems({
      regularItems: myItems,
      resaleItems,
    });

    setItems(resaleAndRegularItems);
    setFilteredItems(resaleAndRegularItems);
  };

  const getPrintedItems = async () => {
    const printedItems = await getMyPrintedItems();
    setProdigiItems(printedItems);
  };

  const getPendingApprovalItems = async () => {
    const pendingItems = await getMyPendingApprovalItems();
    setPendingApprovalItems(pendingItems);
  };

  const fetchAllInitialData = async () => {
    try {
      await fetchItems();
      await getPrintedItems();
      await getPendingApprovalItems();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const hash = window.location.hash.substr(1);
    if (hash) {
      setItemsLimit(CUSTOM_PAGE_SIZE);
    }
    fetchAllInitialData();
  }, []);

  const onChangeFilters = (obj) => {
    if (selectedTab !== 0) return;
    if (obj.checked) {
      setFilteredItems(items);
      return;
    }
    const selectedValue = obj.value;
    let filteredItems = [];
    switch (selectedValue) {
      case FOR_SALE_ID:
        filteredItems = items.filter((item) => {
          return statusFormatter({ status: item.status }) === "For Sale";
        });
        break;
      case OWNED_ID:
        filteredItems = items.filter((item) => {
          return (
            statusFormatter({ status: item.status }) === OWNED_TYPE_FORMATTED
          );
        });
        break;
      case VAULT_ID:
        let myVaultIds = myVaultItems.map((item) => item.id);
        filteredItems = items.filter((item) => myVaultIds.includes(item.id));
        break;
      default:
        break;
    }
    setFilteredItems(filteredItems);
  };

  const transferToEscrow = async ({ walletId, tokenId, to, tokenAddress }) => {
    let executeData;
    /*  Done to preserve data for second attempt
        from background screen
    */
    if (executeDataRef.current.data) {
      executeData = executeDataRef.current.data;
    } else {
      executeData = { walletId, tokenId, to, tokenAddress };
      executeDataRef.current.data = executeData;
    }

    displayPayVenlyIndications();

    const response = await executeTokenTransfer(executeData);
    if (response?.error) {
      console.log(response.message);
    } else {
      hidePayWithVenlyIndications();
    }
    return response;
  };

  const displayPayVenlyIndications = () => {
    /* Displays pay with venly background screen */
    overlayRef.current.classList.add("active");
    payIndicationsRef.current.classList.add("active");
  };

  const hidePayWithVenlyIndications = () => {
    /* Removes pay with venly background screen */
    overlayRef.current.classList.remove("active");
    payIndicationsRef.current.classList.remove("active");
  };

  return (
    <StyledMyItems>
      <Layout>
        <VenlyOverlay overlayRef={overlayRef} />
        <VenlyPayWithVenlyIndications
          payIndicationsRef={payIndicationsRef}
          payWithVenly={transferToEscrow}
        />
        <div id="spacer"></div>
        <div>
          <section className="tf-activity s1 tf-section">
            <div className="themesflat-container mt-5">
              <div className="row m-0">
                <CollectionHeader title="My Items" color="black" />
                <div className="col-xl-3 col-lg-3 col-md-12">
                  <Filters
                    categories={false}
                    blockchains={false}
                    vaultStorage={true}
                    action={onChangeFilters}
                  />
                  {isGallery() && <Actions />}
                </div>

                <div className="col-xl-9 col-lg-9 col-md-12">
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "400px",
                        alignItems: "center",
                      }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <div className="form-create-item">
                      <div className="flat-tabs themesflat-tabs">
                        <Tabs
                          onSelect={(index) => {
                            setSelectedTab(index);
                            switch (index) {
                              case 0:
                                setFilteredItems(items);
                                break;
                              case 1:
                                setFilteredItems(prodigiItems);
                                break;
                              case 2:
                                setFilteredItems(pendingApprovalItems);
                                break;
                              default:
                            }
                          }}
                        >
                          <TabList>
                            <Tab>My Items</Tab>
                            <Tab>My Physical Prints</Tab>
                          </TabList>
                          <TabPanel>
                            <ListMyItems
                              itemType={OWNED_TYPE}
                              items={filteredItems}
                              isGallery={isGallery}
                              itemsLimit={itemsLimit}
                              transferToEscrow={transferToEscrow}
                              myVaultItems={myVaultItems}
                            />
                          </TabPanel>
                          <TabPanel>
                            <ListMyItems
                              itemType={PHYSICAL_PRINTS_TYPE}
                              items={filteredItems}
                              isGallery={isGallery}
                              itemsLimit={itemsLimit}
                              transferToEscrow={transferToEscrow}
                              myVaultItems={myVaultItems}
                            />
                          </TabPanel>
                        </Tabs>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </StyledMyItems>
  );
};

export default MyItems;
