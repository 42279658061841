import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Cover from "../components/cover/Cover";

const Contact02 = () => {
  return (
    <div>
      <Header />
      <Cover title="Hiya!" subtitle="We want to hear from you" />

      <section className="tf-contact tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <div className="flat-form">
                <h2 className="tf-title-heading ct style-2 mg-bt-12">
                  Filling out this form helps us get back to you quickly.
                </h2>
                <h5 className="sub-title ct style-1 pad-0-15">
                  Keeping it short makes us like you even more.
                </h5>
                <div className="form-inner">
                  <form
                    id="contactform"
                    noValidate="novalidate"
                    className="form-submit"
                  >
                    <input
                      id="name"
                      name="name"
                      tabIndex="1"
                      aria-required="true"
                      required
                      type="text"
                      placeholder="First and Last Name"
                    />
                    <input
                      id="email"
                      name="email"
                      tabIndex="2"
                      aria-required="true"
                      required
                      type="email"
                      placeholder="Email Address"
                    />
                    <div className="row-form style-2">
                      <select id="subject">
                        <option value="1">Select Category</option>
                        <option value="1">Buying</option>
                        <option value="2">Selling</option>
                        <option value="3">Registration</option>
                        <option value="3">Connecting Wallet</option>
                        <option value="3">Other</option>
                      </select>
                      <i className="icon-fl-down"></i>
                    </div>
                    <textarea
                      id="message"
                      name="message"
                      tabIndex="3"
                      aria-required="true"
                      required
                      placeholder="Please keep your message short!"
                    ></textarea>
                    <button className="submit">Send</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contact02;
