import React, { useContext, useState } from "react";
import {
  auth0Login,
  auth0Signup,
  getInitialAuthenticatedUser,
  removeSessionStorage,
} from "../helpers/auth0Service";
export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);

const Auth0Provider = (props) => {
  const { initialAuthState, initialUser } = props;
  const [isAuthenticated, setAuthenticated] = useState(initialAuthState);
  const [user, setUser] = useState(initialUser);
  const login = async ({ email, password }) => {
    const { result, user } = await auth0Login({
      email,
      password,
    });
    setAuthenticated(true);
    setUser(user);
    return {user};
  };

  const signup = async ({ email, password, firstName, lastName }) => {
    const { result, user } = await auth0Signup({
      email,
      password,
      firstName,
      lastName,
    });
    // setAuthenticated(true);
    // setUser(user);
    return { result, user };
  };

  const logout = () => {
    removeSessionStorage();
    setAuthenticated(false);
    setUser(null);
  };

  return (
    <Auth0Context.Provider
      value={{
        login,
        signup,
        logout,
        isAuthenticated,
        user,
      }}
    >
      {props.children}
    </Auth0Context.Provider>
  );
};

export default Auth0Provider;
