import React, { useState } from 'react';
import ProdigiModal from './ProdigiModal';

const ProdigiBtn = ({ element }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {!showModal ? null : (
        <ProdigiModal
          show={showModal}
          token={element}
          onHide={() => setShowModal(false)}
        />
      )}
      <button style={{ width: '100%' }} onClick={() => setShowModal(true)}>
        <span style={{ fontSize: '25px', fontWeight: 'bold' }}>Buy Now</span>
      </button>
    </>
  );
};

export default ProdigiBtn;
