import styled from "styled-components";

export const StyledProdigiCard = styled.div`
  .sc-card-activity {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .content {
    width: 100%;
  }

  .status-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      display: inline-block;
    }
    &.red {
      span {
        color: #ff7b7c;
      }
    }
    &.green {
      span {
        color: #25a29d;
      }
    }
  }
  .artwork-size {
    width: 100%;
    font-weight: 700;
    font-size: 15px;
    line-height: 15px;
    text-transform: capitalize;
    color: #9a9a9a;
    text-align: center;
  }

  .product-name {
    font-weight: 400;
    font-size: 20px;
    color: #9a9a9a;
    margin-bottom: 4px;
  }

  .author {
    h6 {
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      text-decoration-line: underline;
      text-decoration-color: #1b70d0;
      color: #1b70d0;
      margin-bottom: 20px;
    }
  }

  .infor {
    display: flex;
    flex: 1;
    justify-content: space-between;
    line-height: 20px;
    .left-section {
      width: 40%;
    }
    .right-section {
      width: 20%;
      line-height: 20px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex-wrap: wrap;
    }
    h4 {
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
      color: #011126;
      margin-bottom: 10px;
    }
  }
  .chevron-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    i {
      font-size: 30px;
      color: #011126;
      font-weight: 600;
    }
  }
  .hidden {
    margin-top: 10px;
    width: 100%;
  }
  .coa {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-decoration-line: underline;
    text-decoration-color: #1b70d0;
    color: #1b70d0;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .sc-card-activity {
      padding: 7px;
    }

    .infor h4 {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 4px;
    }

    .infor .left-section {
      flex: 1;
    }

    .infor .right-section {
      justify-content: center;
      align-items: center;
    }

    .sc-card-activity .content.expanded .infor .right-section {
      justify-content: center;
      align-items: center;
      flex: 0 1 auto;
    }

    .sc-card-activity .content .infor .author a,
    .product-name {
      font-size: 10px;
      line-height: 10px;
    }

    .sc-card-activity .content .infor .author h6 {
      margin-bottom: 4px;
      font-size: 10px;
      line-height: 10px;
    }

    .sc-card-activity .content .media {
      width: 82px;
      height: 68px;
    }

    .status-label {
      display: inline-block;
      padding: 1px 7px;
      margin-bottom: 8px;
      width: auto;
      span {
        font-size: 10px;
        line-height: 10px;
      }
    }

    .filled-button,
    .outlined-button {
      font-size: 12px;
      line-height: 12px;
      padding: 10px;
      border-radius: 3px;
      width: 100%;
    }

    .outlined-button {
      border: 1px solid #ff7b7c;
    }

    .chevron-container {
      i {
        font-size: 20px;
        font-weight: 300;
      }
    }

    .hidden {
      width: 100%;
      text-align: center;
      margin-top: 17px;
      margin-bottom: 7px;
      .coa {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
`;
