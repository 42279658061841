import React from "react";
import AnnouncementCard from "./AnnouncementCard/AnnouncementCard";

const AnnouncementsContainer = ({ data }) => {
  return (
    <>
      {data?.length > 0 &&
        data.map((item, index) => (
          <AnnouncementCard
            key={index}
            title={item?.title}
            description={item?.description}
            img={item?.activityImage}
            link={item?.cmsLink}
            id={item?.id}
          />
        ))}
    </>
  );
};

export default AnnouncementsContainer;
