import { toast } from "react-toastify";
import Assets from "../assets/Assets";

export const ExpirationIcon = () => {
  return (
    <img
      style={{ width: "34px", height: "auto" }}
      src={Assets.expirationIcon}
      alt="errorIcon"
    />
  );
};

export const ErrorIcon = () => {
  return (
    <img
      style={{ width: "34px", height: "auto" }}
      src={Assets.errorIcon}
      alt="errorIcon"
    />
  );
};

export const SuccesfulIcon = () => {
  return (
    <img
      style={{ width: "34px", height: "auto" }}
      src={Assets.succesfulIcon}
      alt="succesfulIcon"
    />
  );
};

export const notify = (text, onClose) =>
  toast.success(text, {
    icon: SuccesfulIcon,
    onClose,
  });

export const notifyError = (text, onClose) =>
  toast.error(text, {
    icon: ErrorIcon,
    onClose,
  });

export const notifyExpiration = (text, onClose) =>
  toast.error(text, {
    icon: ExpirationIcon,
    onClose,
  });
