import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import logofooter from "../../assets/images/logo/logo2.png";
import logodark from "../../assets/images/logo/logo_dark.png";
import { notify } from "../../helpers/toastNotificationService";
import LinkWithQueryParams from "../LinkWithQueryParams";
import Newsletter from "./Newsletter";
import { legalList, routesList, socialList } from "./lists";

const StyledFooter = styled.footer`
  .widget.widget-logo p {
    font-size: 18px;
    line-height: 28px;
  }
  .social-icons-desktop-version {
    margin-top: 32px;
  }
  .social-icons-mobile-version {
    display: none;
  }

  .widget .sub-widget-logo {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    padding: 20px 0px !important;
    .widget.widget-logo p {
      font-size: 10px;
      line-height: 10px;
    }

    .widget-menu ul li {
      margin-bottom: 12px;
    }

    .widget-menu ul li a,
    .widget .title-widget {
      font-size: 10px;
      line-height: 10px;
      margin-bottom: 0px;
    }
    .widget .sub-widget-logo {
      font-size: 8px;
      line-height: 8px;
      margin-bottom: 4px;
    }
    .sub-widget-logo {
      font-size: 8px;
      line-height: 8px;
      margin-bottom: 10px;
    }
    .sub-widget-logo b {
      font-weight: 700;
    }
    .widget-subcribe .form-subcribe form {
      margin-bottom: 4%;
    }
    .widget-social ul {
      width: 100% !important;
      justify-content: space-between;
    }
    .widget-social img {
      width: 28px;
      height: auto;
    }
    .widget-social.style-1 ul li {
      margin-right: 12px;
    }
    .widget-social.style-1 ul li a {
      width: auto;
      height: auto;
    }
    .social-icons-mobile-version {
      display: flex;
      align-content: flex-start;
      margin-top: 16px;
    }
    .social-icons-desktop-version {
      display: none;
    }
    .widget-subcribe .form-subcribe input {
      padding: 9px;
      font-size: 8px;
      line-height: 8px;
    }
    .widget-subcribe .form-subcribe input::placeholder {
      font-size: 8px;
      line-height: 8px;
    }
    .widget-subcribe .form-subcribe button {
      padding: 2px 10px;
    }
    .widget-subcribe .form-subcribe button .icon-fl-send {
      font-size: 14px;
    }
  }
`;

const FooterLink = ({ submenu }) => {
  return submenu?.externalSite ? (
    <a target="_blank" rel="noreferrer" href={submenu?.link}>
      <b>{submenu.title}</b>
    </a>
  ) : (
    <LinkWithQueryParams path={submenu.link}>
      <b>{submenu.title}</b>
    </LinkWithQueryParams>
  );
};

const FooterList = ({ obj }) => (
  <div className="col-lg-2 col-md-4 col-sm-5 col-3">
    <div className="widget widget-menu style-1">
      <h5 className="title-widget">{obj.title}</h5>
      <ul>
        {obj.routes.map((item, index) => (
          <li key={index} className={`${item.disabled ? "disabled" : ""}`}>
            <FooterLink submenu={item} />
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const SocialIconsContainer = ({ className }) => {
  return (
    <div className={`col-6 col-md-6 ${className}`}>
      <div className="widget widget-subcribe">
        <div className="widget-social style-1">
          <h5 className="title-widget">Join the community</h5>
          <ul>
            {socialList.map((item, index) => (
              <li key={index}>
                <a href={item.link}>
                  <img src={item.image} alt={"social-icons/" + index} />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
      />
      <StyledFooter id="footer" className="is_dark footer-dark-style ">
        <div className="themesflat-container">
          <div className="row mb-md-5">
            <div className="col-lg-3 col-md-12 col-6">
              <div className="widget widget-logo">
                <div className="logo-footer" id="logo-footer">
                  <LinkWithQueryParams path={"/"}>
                    <img
                      className="logo-dark"
                      id="logo_footer"
                      src={logodark}
                      alt="nft-gaming"
                    />
                    <img
                      className="logo-light"
                      id="logo_footer"
                      src={logofooter}
                      alt="nft-gaming"
                    />
                  </LinkWithQueryParams>
                </div>
                <p className="sub-widget-logo">
                  Buy, sell, and experience digital art and collectibles in
                  virtual places and spaces. Join a community built around you.
                </p>
              </div>
            </div>
            <SocialIconsContainer className="social-icons-mobile-version" />

            {/* Here is the iteration of links   */}
            {routesList.map((list, index) => (
              <FooterList key={index} obj={list} />
            ))}
          </div>

          <div className="row">
            <SocialIconsContainer className="social-icons-desktop-version" />

            <div className="col-12 col-md-6">
              <div id="subscribe" className="widget widget-subcribe">
                <h5 className="title-widget">Stay Connected</h5>
                <p className="sub-widget-logo">
                  Subscribe to stay informed about upcoming drops and community
                  news. No spam. Unsubscribe whenever you want.
                </p>
                <Newsletter notify={notify} />
              </div>
            </div>
          </div>

          <div className="row mt-3 mt-md-5">
            <div className="col-12">
              <p className="sub-widget-logo">
                {new Date().getFullYear()} ACME LABS™. All rights reserved.{" "}
              </p>
              <div className="widget-social">
                <ul>
                  {legalList.map((item, index) => (
                    <li key={index}>
                      <p className="sub-widget-logo mr-5">
                        <FooterLink submenu={item} />
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </StyledFooter>
    </div>
  );
};

export default Footer;
