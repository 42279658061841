const { REACT_APP_API_URL } = process.env;

export const getAnnouncements = async () => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}general/activity`);
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

export const getAnnouncementById = async ({ announcementId }) => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}general/activity`);
    const announcements = await response.json();
    const announcement = announcements.find(
      (announcement) => announcement.id === announcementId
    );

    return announcement;
  } catch (error) {
    console.log(error);
  }
};
