import { getHeaders } from "./utils";

const { REACT_APP_API_URL } = process.env;

export const getCOATemplates = async () => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}coa/templates`, {
      method: "GET",
      headers,
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};
