import React, { useEffect, useState } from "react";
import LinkWithQueryParams from "../../../../components/LinkWithQueryParams";
import { priceFormatter } from "../../../../helpers/formatters";
import { getProductName, isResaleItem } from "../../../../helpers/itemHelpers";
import useApi2 from "../../../../hooks/useApi2";
import useIPFS from "../../../../hooks/useIPFS";

const CheckoutItem = ({ item, prodigiProduct }) => {
  const { getData, element } = useIPFS();

  const [productName, setProductName] = useState(null);

  const { data: userData } = useApi2(`user/one/${item?.originalOwnerId}`, {
    method: "GET",
  });
  const { data, loading } = useApi2(`space/user/${item?.originalOwnerId}`, {
    method: "GET",
  });
  const fetchProductName = async () => {
    const productName = await getProductName({ item });
    setProductName(productName);
  };

  const getProductData = async () => {
    await getData(item.finalCid ? item?.finalCid : item.cid);
  };

  useEffect(() => {
    fetchProductName();
    getProductData();
  }, [item]);

  return (
    <>
      <div className="p-3 border-bottom">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h5 className="p-2">
              {item?.quantity} x {element.name}
            </h5>

            <LinkWithQueryParams path={`/spaces/${data?.customURL}`}>
              <div className="artist-name">{userData?.displayName}</div>
            </LinkWithQueryParams>

            {productName && <span className="product-name">{productName}</span>}
          </div>
          <div>
            <h5>
              {priceFormatter({
                currencyType: isResaleItem({ item })
                  ? item?.saleCurrencyType
                  : item?.currencyType,
                price: isResaleItem({ item })
                  ? item?.salePrice * item?.quantity
                  : item?.price * item?.quantity,
              })}
            </h5>
          </div>
        </div>
      </div>

      {prodigiProduct?.name && (
        <div className="p-3 border-bottom">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5 className="p-2">
                {item?.quantity} x {prodigiProduct?.name}
              </h5>
              <span className="sc-button-5">{element.name}</span>
            </div>
            <div>
              <h5>
                {priceFormatter({
                  currencyType: isResaleItem({ item })
                    ? item?.saleCurrencyType
                    : item?.currencyType,
                  price: 0.0,
                })}
              </h5>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckoutItem;
