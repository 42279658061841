import React from "react";
import styled from "styled-components";
import LinkWithQueryParams from "../../components/LinkWithQueryParams";

const StyledCollection = styled.div`
  .btn-auction-7.center {
    width: 100%;
    margin-bottom: 28px;
  }
  .sc-button-7 {
    margin-top: 0;
    padding: 10px 23px;
    font-size: 12px;
    line-height: 12px;
    border: 3px solid #ff7b7c;
    border-radius: 13px;

    border: 5px solid #ff7b7c;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 25px;
    background-color: white;
    padding: 20px 88px;
  }
  .sc-button-7 span {
    color: #ff7b7c;

    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    color: #ff7b7c;
  }
`;

const LoadMore = ({ onClick }) => {
  return (
    <StyledCollection>
      <div className="btn-auction-7 center">
        <button
          to="#"
          id="load-more"
          className="sc-button-7 no-bg button__bottom-top"
          onClick={onClick}
        >
          <LinkWithQueryParams path="#">
            <span>load more</span>
          </LinkWithQueryParams>
        </button>
      </div>
    </StyledCollection>
  );
};

export default LoadMore;
