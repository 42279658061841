import React from "react";
import styled from "styled-components";

const StyledOverlay = styled.div`
  .overlay {
  }
  .overlay.active {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 99999;
    position: fixed;

    display: flex;
    justify-content: flex-end;
  }
`;

const VenlyOverlay = ({ overlayRef }) => {
  return (
    <StyledOverlay>
      <div className="overlay" ref={overlayRef}></div>
    </StyledOverlay>
  );
};

export default VenlyOverlay;
