const { REACT_APP_API_URL } = process.env;

const getOneSpace = async (userId) => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}space/user/${userId}`);
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export default getOneSpace;
