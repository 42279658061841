import React from "react";
import { useLocation } from "react-router-dom";
import LinkWithQueryParams from "../../components/LinkWithQueryParams";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { FORGOT_PASSWORD_EMAIL_SENT } from "../../constants/constants";
import { auth0ResetPassword } from "../../helpers/auth0Service";
import { useCustomNavigator } from "../../hooks/useCustomNavigator";

const ForgotPasswordEmailSent = () => {
  const { state } = useLocation();
  const { customNavigate } = useCustomNavigator();
  const resendEmail = async () => {
    try {
      const email = state?.userEmail;
      const result = await auth0ResetPassword({ email });
      if (result && result.includes(FORGOT_PASSWORD_EMAIL_SENT)) {
        customNavigate({
          path: "/forgot-password-sent",
          state: { userEmail: email },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div>
      <Header />

      <section className="tf-forgot-password-email-sent tf-section">
        <div className="centered-content">
          <div className="row justify-content-center m-0 w-100">
            <div className="page-title-heading mg-bt-12">
              <h1 className="heading text-center">
                An email with a link to reset your password has been sent to
                <b>{state?.userEmail ?? "your email address"}</b>
              </h1>
            </div>
          </div>

          <div className="row justify-content-center m-0">
            <div className="instructions-container">
              follow the instructions in the email to reset your password.
            </div>
          </div>

          <div className="instructions-container style2 mb-4">
            <h4>Didn't recieve an email confirmation?</h4>
          </div>

          <div
            onClick={resendEmail}
            className="resend-btn-link row justify-content-center m-0"
          >
            <LinkWithQueryParams path="#"> Resend email</LinkWithQueryParams>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ForgotPasswordEmailSent;
