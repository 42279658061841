import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Cover from '../components/cover/Cover';

const NoResult = () => {
  return (
    <div>
      <Header />
      <Cover
        title="Zoinks!  We couldn't find anything"
        subtitle='Try a different search or check out these other ideas'
      />
      <section className='tf-no-result tf-section'>
        <div className='themesflat-container'>
          <div className='row'>
            <div className='col-12 m-5'>
              <h2 className='tf-title-heading ct style-2 fs-30 mg-bt-20'>
                Sorry, We Couldn't Find Any Results For This Search.
              </h2>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default NoResult;
