import React from "react";
import { itemDetailsBaseRoute } from "../../../../helpers/itemHelpers";
import LinkWithQueryParams from "../../../LinkWithQueryParams";
import NFTCardImage from "./NFTCardImage";
import NFTCardVideo from "./NFTCardVideo";

const NFTCardVisual = ({ element, previewType, isPreview, itemData }) => {
  return (
    <div
      className="image-container"
      style={{
        backgroundColor: element?.backgroundColor
          ? element?.backgroundColor
          : "",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        display: "flex",
      }}
    >
      {(element?.type?.includes("video") || previewType?.includes("video")) && (
        <NFTCardVideo
          element={element}
          isPreview={isPreview}
          itemData={itemData}
        />
      )}

      {(element?.type?.includes("image") ||
        element?.type?.includes("model") ||
        (!isPreview && !element.type) ||
        previewType?.includes("image")) && (
        <LinkWithQueryParams
          path={`/${itemDetailsBaseRoute({ item: itemData })}/${itemData.id}`}
        >
          <NFTCardImage isPreview={isPreview} itemData={itemData} />
        </LinkWithQueryParams>
      )}
    </div>
  );
};

export default NFTCardVisual;
