import styled from "styled-components";

export const ResetPasswordPage = styled.div`
  @media (max-width: 768px) {
    .tf-reset-password.tf-section {
      background-color: white;
    }

    .tf-reset-password.tf-section .centered-content {
      width: 100%;
      background-color: white;
      padding: 10% 13%;
    }

    .tf-reset-password.tf-section .page-title-heading {
      width: 100%;
    }
    .tf-reset-password.tf-section .page-title-heading .heading {
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
      text-align: center !important;
      width: 100%;
    }

    .tf-reset-password.tf-section .instructions-container {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      color: #000000;
    }

    .tf-reset-password.tf-section .submit {
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 10px;
      text-align: center;
      color: #ffffff;
      padding: 10px 40px;
      margin-top: 10px;
    }

    .tf-reset-password.tf-section .form-field-input {
      margin-bottom: 4px;
    }

    h6 {
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
      margin-bottom: 4px;
    }
    .tf-reset-password.tf-section .form-field-input {
      border-radius: 4px;
    }
    .tf-reset-password.tf-section .form-field-input,
    .tf-reset-password.tf-section .form-field-input::placeholder {
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
    }
    .general-error-container{
        margin-bottom: 10px;
    }
    .tf-reset-password.tf-section .error-message {
      font-size: 10px;
      line-height: 10px;
    }
  }
`;
