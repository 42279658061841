import React, { useEffect, useState } from "react";
import getOneSpace from "../../api/spaces/getSpaceByUserId";
import { useS3Url } from "../../hooks/useS3Url";
import LinkWithQueryParams from "../LinkWithQueryParams";

const AuthorInfo = ({ item, isCollection, artistPage, collectionRoute }) => {
  const [space, setSpace] = useState({});
  const { getS3Url } = useS3Url();
  const [img, setImg] = useState(item.profileFileName);

  useEffect(() => {
    const image = isCollection ? item?.collectionImage : item?.profileFileName;
    const s3Location = isCollection ? "coverImage" : "profilePicture";
    if (!image) {
      /* updates image in case it was deleted from edit profile */
      setImg(null);
      return;
    }
    if (image.includes("amazonaws")) {
      setImg(image);
      return;
    }
    const profileFileNameS3 = getS3Url(image, s3Location);
    if (profileFileNameS3.length !== 0) {
      setImg(profileFileNameS3);
    }
  }, [item, getS3Url]);

  const getSpace = async (userId) => {
    const response = await getOneSpace(userId);
    if (response.status === 400) return;
    response && setSpace(response);
  };

  useEffect(() => {
    if (artistPage || !item?.id) return;
    getSpace(item.id);
  }, []);

  return (
    <div className="card-bottom">
      <div className="author flex justify-content-between">
        <div className="flex">
          <div className="sc-author-box style-2">
            <div className="author-avatar">
              {img && img.length !== 0 ? (
                <img src={img} alt="" className="avatar" />
              ) : (
                <div className="avatar avatar-placeholder"></div>
              )}
            </div>
          </div>
          <div className="content">
            <LinkWithQueryParams
              path={artistPage ? collectionRoute : `/spaces/${space.customURL}`}
            >
              <h4 style={{ color: "white" }}>{item.tokenName}</h4>
            </LinkWithQueryParams>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorInfo;
