import { useParams } from "react-router-dom";
import styled from "styled-components";
import Layout from "../../components/layouts/Layout";
import { HEADER_HEIGHT } from "../../constants/constants";
import CheckoutReservation from "./ProdigiShipping/CheckoutReservation";

const RESERVATION_TYPE = "reservation";

const StyledCheckout = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }
  .tf-section {
    padding-top: 0;
  }
`;

const StripePageWrapper = () => {
  const { itemId, operationType } = useParams();
  return (
    <StyledCheckout>
      <Layout>
        <div id="spacer"></div>
        <div>
          <br />
          <br />
          <br />

          {operationType === RESERVATION_TYPE && (
            <CheckoutReservation reservationId={itemId} />
          )}
        </div>
      </Layout>
    </StyledCheckout>
  );
};

export default StripePageWrapper;
