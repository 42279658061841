import { getHeaders } from "../Item/utils";
const { REACT_APP_API_URL } = process.env;

const getCollection = async (id) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}collection/one/${id}`, {
      method: "GET",
      headers,
    });
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

export default getCollection;
