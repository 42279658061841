import { useEffect } from 'react';
import getItem from '../../api/Item/getItem';

const Blank = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const itemId = urlSearchParams.get('itemId');
  const action = urlSearchParams.get('action');

  const saveInLocalStorage = (obj) => {
    const cart = localStorage.getItem('cart') || '[]';
    const objCart = JSON.parse(cart);
    const objInCart = objCart.findIndex((item) => item.id === obj.id);

    switch (action) {
      case 'DELETE': {
        if (objInCart > -1) {
          objCart.splice(objInCart, 1);
          localStorage.setItem('cart', JSON.stringify(objCart));
          return;
        }
        break;
      }

      default: {
        if (objInCart === -1) {
          objCart.push(obj);
          localStorage.setItem('cart', JSON.stringify(objCart));
        }
      }
    }
  };

  const getDataOfItem = async (id) => {
    const response = await getItem(id);

    response && saveInLocalStorage(response);
  };

  useEffect(() => {
    getDataOfItem(itemId);
  }, [itemId]);

  return <></>;
};

export default Blank;
