import React, { useState } from "react";
import { useAuth0 } from "../../auth/Auth";
import LinkWithQueryParams from "../../components/LinkWithQueryParams";
import {
  EMAIL_INVALID_MESSAGE,
  EMAIL_PATTERN,
  EXISTENT_EMAIL_MESSAGE,
  EXISTENT_EMAIL_NOTIFICATION,
  EXISTENT_USER_ERROR_CODE,
  FORGOT_PASSWORD,
  PASSWORD_INVALID_MESSAGE,
  PASSWORD_PATTERN,
  REQUIRED_ACCEPT_MESSAGE,
  REQUIRED_MESSAGE,
} from "../../constants/constants";
import { notifyError } from "../../helpers/toastNotificationService";
import { useApi } from "../../hooks/useApi";
import { useCustomNavigator } from "../../hooks/useCustomNavigator";

const { REACT_APP_API_URL } = process.env;
const SignupForm = ({ register, handleSubmit, errors, watch, setLoading }) => {
  const [existentUser, setExistentUser] = useState(false);
  const { customNavigate } = useCustomNavigator();
  const { executeApiRequestTwo } = useApi();
  const { signup, logout } = useAuth0();

  const storeUserInDb = async ({
    receivedUser,
    firstName,
    lastName,
    token,
  }) => {
    let userData;
    try {
      const url = `${REACT_APP_API_URL}user`;
      const displayName =
        firstName + lastName ? firstName + " " + lastName : receivedUser?.name;
      const options = {
        method: "POST",
        body: JSON.stringify({
          displayName,
          username: receivedUser?.nickname,
          shippingAddress: "FakeDirection123",
        }),
        headers: {
          "Content-Type": "application/json",
        },
        idToken: token,
      };

      const user = await executeApiRequestTwo(url, options);
      if (user.statusCode === 500) {
        logout();
        customNavigate({ path: "/" });
        return;
      }
      userData = { ...user };
      const profileData = {
        firstName,
        lastName,
        displayName,
      };

      await executeApiRequestTwo(url, {
        body: JSON.stringify(profileData),
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
      });
      customNavigate({
        path: `/verification-email-sent`,
        state: { userEmail: receivedUser?.email, userId: receivedUser?.sub },
      });
    } catch (error) {
      console.log(error);
    } finally {
      return { userData };
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setExistentUser(false);
    const { email, password, firstName, lastName } = data;
    try {
      const { result, user } = await signup({
        email,
        password,
        firstName,
        lastName,
      });
      if (user?.email) {
        await storeUserInDb({
          receivedUser: user,
          firstName,
          lastName,
          token: result?.idToken,
        });
      }
    } catch (e) {
      setLoading(false);
      if (e.code && e.code.includes(EXISTENT_USER_ERROR_CODE)) {
        notifyError(EXISTENT_EMAIL_NOTIFICATION);
        setExistentUser(true);
      }
      return e;
    }
  };

  const validateConfirm = (value) => {
    const passwordField = watch("password");
    if (value !== passwordField) {
      return "Passwords do not match";
    }
  };

  return (
    <div className="form-inner">
      <form
        id="contactform"
        noValidate="novalidate"
        className="form-submit"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row m-0 justify-content-between mb-4">
          <fieldset className="fieldset-half">
            <div id="first-name">
              <h6 className="form-field-label">First Name</h6>
              <input
                id="firstName"
                name="firstName"
                tabIndex="1"
                aria-required="true"
                required
                type="text"
                placeholder="First Name"
                className="form-field-input"
                {...register("firstName")}
              />
              <p className="error-message">{errors.firstName?.message}</p>
            </div>
          </fieldset>

          <fieldset className="fieldset-half">
            <h6 className="form-field-label">Last Name</h6>
            <input
              id="lastName"
              name="lastName"
              tabIndex="2"
              aria-required="true"
              required
              type="text"
              placeholder="Last Name"
              className="form-field-input"
              {...register("lastName")}
            />
            <p className="error-message">{errors.lastName?.message}</p>
          </fieldset>
        </div>

        <fieldset className="mb-4">
          <h6 className="form-field-label">Email Address</h6>
          <input
            id="email"
            name="email"
            tabIndex="3"
            aria-required="true"
            required
            type="email"
            placeholder="Email Address"
            className="form-field-input"
            {...register("email", {
              required: REQUIRED_MESSAGE,
              pattern: {
                value: EMAIL_PATTERN,
                message: EMAIL_INVALID_MESSAGE,
              },
            })}
          />
          <p className="error-message">{errors.email?.message}</p>
          {existentUser && (
            <p className="error-message">
              {EXISTENT_EMAIL_MESSAGE}
              <LinkWithQueryParams
                path="/forgot-password"
                className="forgot-pass"
              >
                {FORGOT_PASSWORD}
              </LinkWithQueryParams>
            </p>
          )}
        </fieldset>

        <fieldset className="mb-4">
          <h6 className="form-field-label">Create Password</h6>
          <input
            id="currentPassword"
            type="password"
            tabIndex="4"
            placeholder="Create Password"
            className="form-field-input"
            {...register("password", {
              required: REQUIRED_MESSAGE,
              pattern: {
                value: PASSWORD_PATTERN,
                message: PASSWORD_INVALID_MESSAGE,
              },
            })}
          />
          <p className="error-message">{errors.password?.message}</p>
        </fieldset>

        <fieldset className="mb-4">
          <h6 className="form-field-label">Confirm Password</h6>
          <input
            id="confirmPassword"
            type="password"
            tabIndex="5"
            placeholder="Confirm Password"
            className="form-field-input"
            {...register("confirm", {
              required: REQUIRED_MESSAGE,
              validate: {
                value: (value) => validateConfirm(value),
              },
            })}
          />
          <p className="error-message">{errors.confirm?.message}</p>
        </fieldset>

        <div className="row-form style-2 margin-2">
          <label className="form-checkbox-label">
            By clicking Create Account, I hereby acknowledge that I agree to
            ACME LABS™ Terms and Conditions and Privacy Policy.
            <input
              tabIndex="6"
              type="checkbox"
              {...register("termsConditions", {
                required: REQUIRED_ACCEPT_MESSAGE,
              })}
            />
            <span className="btn-checkbox"></span>
          </label>
          <p className="error-message">{errors.termsConditions?.message}</p>
        </div>

        <div className="terms-container">
          <LinkWithQueryParams
            path="/terms"
            target="_blank"
            rel="noopener noreferrer"
            className="forgot-pass"
          >
            Terms and Conditions
          </LinkWithQueryParams>

          <LinkWithQueryParams
            path="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            className="forgot-pass"
          >
            Privacy Policy
          </LinkWithQueryParams>
        </div>

        <div className="row-form style-2 m-0 mb-4">
          <label className="form-checkbox-label">
            I agree to recieving electronic communication via the email address
            provided.
            <input type="checkbox" {...register("emailConfirmation")} />
            <span className="btn-checkbox"></span>
          </label>
          <p className="error-message">{errors.emailConfirmation?.message}</p>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button className="submit-btn">Create Account</button>
        </div>
      </form>
    </div>
  );
};

export default SignupForm;
