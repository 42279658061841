import ArtistCard from './ArtistCard/ArtistCard';
import CollectionHeader from './CollectionHeader';
import BuyCard from '../layouts/buyCard/BuyCard';
import TrendingCard from '../layouts/TrendingCard/TrendingCard';
import SellerCard from '../TopSellers/SellerCard';

const Collection02 = ({
  data,
  title,
  navigateTo,
  stop,
  collectionType,
  measures,
  color,
}) => {
  const collectionTypeHandler = {
    Buy: BuyCard,
    Artist: ArtistCard,
    Trending: TrendingCard,
    Seller: SellerCard,
  };

  return (
    <section className='tf-section live-auctions'>
      <div className='themesflat-container'>
        <CollectionHeader
          title={title}
          navigateTo={navigateTo}
          show={data.length > stop}
          color={color}
        />
        <div className='row'>
          {data.slice(0, stop).map((item) => (
            <div
              key={item.id}
              className={`col-lg-${measures.lg} col-sm-${measures.sm} col-md-${measures.md} col-10`}
            >
              {collectionTypeHandler[collectionType]({
                card: item,
              })}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Collection02;
