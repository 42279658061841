import styled from "styled-components";
import { COMMON_STYLES } from "../../../constants/genericStyles";

export const StyledTrendingCard = styled.div`
  margin-right: 5.33%;
  height: auto;
  margin-bottom: 7%;
  position: relative;
  padding: 0;
  width: 21%;
  .inner-card .price.mobile,
  .like-container{
    display: none;
  }
  hr.mobile{
    display: none;
  }
  &:nth-child(4n) {
    margin-right: 0;
  }

  .inner-card {
    width: 100%;
    border-radius: 28px;
    overflow: visible;
  }

  .inner-card .image-container {
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    ${COMMON_STYLES.GENERIC_IMAGE_CONTAINER({ paddingTop: "100.5%" })}
  }

  .inner-card .content2 {
    padding-top: 0;
  }

  .inner-card .content2 {
    z-index: 11;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    bottom: 0;
    left: 0;
    padding: 13px;
    padding-top: 0;
    height: 30%;
    width: 100%;
    background: #fff;
    border-bottom-left-radius: 28px;
    border-bottom-right-radius: 28px;
    padding-left: 10%;
    padding-bottom: 7%;
    bottom: -15%;
  }

  .inner-card .price {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 11;

    padding-right: 8%;
    padding-top: 8%;
    font-size: 1.4vw;
    color: #fff;
  }

  .inner-card .content2 .name-art,
  .inner-card .content2 .artist {
    font-size: 1.4vw;
    color: #011126;
  }

  .inner-card .content2 .name-art {
    margin-bottom: 3px;
    font-weight: bolder;
    letter-spacing: 0.1rem;
    // overflow: hidden;
    // max-width: 70%;
    // color: #000;
  }
  .inner-card .content2 .artist {
    // overflow: hidden;
    // max-width: 70%;
    // color: #000;
  }
  @media (max-width: 768px) {
    .inner-card .price.desktop{
      display: none;
    }
    .inner-card .price.mobile,
    hr.mobile{
      display: block;
    }
    .inner-card .content2{
      display: flex; position: relative;
    }
    .inner-card .price {
      position: relative;
    
     
      width: auto;  
      padding: 0;

      color: #fff;
      font-weight: 400;
      font-size: 8px;
      line-height: 8px;
      text-align: center;
      display: block;
    }

    .inner-card .price span
    {
      background-color:#011126;
      border-radius: 6px;
      padding: 2px  13px;

    }
    
    hr {
      border: solid 1px #ff7b7c;
      margin-bottom: 20px;
      width: 100%;
      margin-top:3px;
      margin-bottom:6px;

    }

    width: 31%;
    position: relative;
    border-radius: 30px;
    padding: 0;
    margin-right: 3.5%;
    height: auto;
    margin-bottom: 7%;

    .inner-card {
      border-radius: 10px;
    }

    .inner-card .content2 {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 5px;
    }

    .inner-card .content2 .name-art {
      font-size: 10px;
      max-width: initial;
      width: 100%;
    }

    .inner-card .content2 .artist {
      font-size: 8px;
      color: #247773;
    }

    .inner-card .image-container {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .inner-card .image-container::before{
      padding-top: 80%;
    }
    &:nth-child(4n) {
      margin-right: 3.5%;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }


    .like-container {
      display: block;
      position: absolute;
      right: 5%;
      top: 5%;
      padding: 4px;
      background: #ffd061;
      border-radius: 5px;
    }
  
    .like-container span {
      font-weight: 400;
      font-size: 7px;
      line-height: 7px;
      color: #000000;
    }

    .heart::before {
      color: #000000;
      font-weight: 700;
    }
  
    .heart::before {
      /* .wishlist-button.heart::before { */
      content: "\f004";
      font-family: "Font Awesome 5 Pro";
      font-weight: 300;
      margin-right: 7px;
    }
  }
`;
