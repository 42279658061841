import { useEffect } from "react";
import { Link } from "react-router-dom";
import imga1 from "../../../assets/images/avatar/avt-1.jpg";
import useIPFS from "../../../hooks/useIPFS";
import LinkWithQueryParams from "../../LinkWithQueryParams";
import Like from "../cards/Like";
import BuyBtn from "./BuyBtn";

const BuyCard = (props) => {
  const { card } = props;
  const { element, getImage } = useIPFS();

  // To do:
  // request for search data of owner passing his id, for the moment the id is provided
  // request for search data of categories based on his id, for the moment the id is provided

  useEffect(() => {
    getImage(card.cid);
  }, []);

  return (
    <div
      className={`sc-card-product style2 ${card.feature ? "comingsoon" : ""} `}
    >
      <div className="card-media">
        <LinkWithQueryParams path={`/item-details-01/${card.id}`}>
          <img src={element.image ? element.image : imga1} alt="axies" />
        </LinkWithQueryParams>
        <Like
          liked={card.liked}
          likes={card.numberOfLikes}
          endpoint="item"
          elementId={card.id}
        />
        <div className="coming-soon">{card.feature}</div>
      </div>
      <div className="card-title">
        <h5 className="style2">{element.description}</h5>
        {/* <div className="tags">{element.tags[0]}</div> */}
      </div>
      <div className="meta-info">
        <div className="author">
          <div className="avatar">
            <img src={imga1} alt="axies" />
          </div>
          <div className="info">
            <span>Owned By</span>
            <h6>
              {" "}
              <LinkWithQueryParams path={`/authors-02/${card.originalOwnerId}`}>
                {card.originalOwnerId.slice(0, 10).concat("...")}
              </LinkWithQueryParams>{" "}
            </h6>
          </div>
        </div>
        <div className="price">
          <span>Current price</span>
          <h5> {card.price}</h5>
        </div>
      </div>
      <div className="card-bottom">
        <BuyBtn card={card} />
        <Link
          to={`/item-details-01/${card.id}`}
          className="view-history reload"
        >
          View History
        </Link>
      </div>
    </div>
  );
};

export default BuyCard;
