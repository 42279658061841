import React, { useState } from "react";
import styled from "styled-components";
import { COMMON_STYLES } from "../constants/genericStyles";
export const StyledImageWithLoader = styled.div`
  .image-container {
    width: 100%;
    .skeleton-loader {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      display: block;
      border-radius: 18px;

      background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.5) 50%,
          rgba(255, 255, 255, 0) 80%
        ),
        lightgray;
      background-repeat: repeat-y;
      background-size: 50px 500px;
      background-position: 0 0;
      animation: shine 1s infinite;
    }
    .image {
      ${COMMON_STYLES.GENERIC_IMAGE_CONTAINER({ paddingTop: "110%" })}

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      border-radius: 18px;
      image {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }
    .gray-background {
      width: 150px;
      height: 150px;
      background: #c7c7c7;
      border-radius: 18px;
    }
  }
  @keyframes shine {
    to {
      background-position: 100% 0, /* move highlight to right */ 0 0;
    }
  }
`;

const ImageWithLoader = ({ img, alt }) => {
  const [loading, setLoading] = useState(true);

  return (
    <StyledImageWithLoader className="main-image-container">
      <div className="image-container">
        {img && img.length !== 0 ? (
          <>
            <div className="spinner-container">
              {loading && <span className="skeleton-loader"></span>}
              <div className="image">
                <img
                  style={!loading ? {} : { display: "none" }}
                  src={img}
                  alt={alt}
                  onLoad={() => setLoading(false)}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="gray-background"></div>
        )}
      </div>
    </StyledImageWithLoader>
  );
};

export default ImageWithLoader;
