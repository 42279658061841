import Assets from "../../../assets/Assets";
import React from "react";

const WalletConnected = ({ wallets, selectWallet }) => {
  return (
    <div className="wallet-connected">
      <p className="title">
        {wallets.walletDetails.id ? "Wallet connected:" : "No Wallet connected"}
      </p>
      <div className="image">
        <img src={Assets.venlyIcon} alt="venlyIcon" />
      </div>
      <div className="image">
        <img src={Assets.venlyBrand} alt="venlyBrand" />
      </div>
      {wallets.walletDetails.id && (
        <p className="wallet-name">{wallets.walletDetails.description}</p>
      )}

      <button className="text-button no-bg" onClick={selectWallet}>
        {!wallets.walletDetails.id ? "select" : "change"} wallet
      </button>
    </div>
  );
};

export default WalletConnected;
