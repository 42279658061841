import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import {
  CONTACT_CATEGORY_SELECTION_DROPDOWN,
  CONTACT_ERROR_MESSAGE,
  CONTACT_SUCCESFUL_MESSAGE,
  REACT_APP_API_URL,
  REQUIRED_MESSAGE,
} from "../../constants/constants";
import { notify } from "../../helpers/toastNotificationService";
import { useApi } from "../../hooks/useApi";
import { customSelectStyles } from "../CreateCollection/styles";

const ContactUsForm = ({ handleSubmit, register, errors, control }) => {
  const { executeApiRequest } = useApi();

  const onSubmit = async (data) => {
    try {
      await executeApiRequest(`${REACT_APP_API_URL}general/help`, {
        method: "POST",
        body: JSON.stringify({
          name: data?.name,
          email: data?.email,
          category: data?.category,
          description: data?.description,
        }),
        headers: { "Content-Type": "application/json" },
      });
      notify(CONTACT_SUCCESFUL_MESSAGE);
    } catch (e) {
      notify(CONTACT_ERROR_MESSAGE);
    }
  };

  return (
    <div className="form-inner">
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <input
            {...register("name", {
              required: REQUIRED_MESSAGE,
            })}
            name="name"
            type="text"
            tabIndex="1"
            max={100}
            placeholder="First and Last Name"
          />
          <p className="error-message">{errors.name?.message}</p>
        </fieldset>

        <fieldset>
          <input
            {...register("email", {
              required: REQUIRED_MESSAGE,
            })}
            name="email"
            type="email"
            tabIndex="2"
            max={100}
            placeholder="Email Address"
          />
          <p className="error-message">{errors.email?.message}</p>
        </fieldset>

        <fieldset>
          <div style={{ display: "flex" }}>
            <div className="dropdown" style={{ flex: 1 }}>
              <Controller
                control={control}
                name="category"
                rules={{
                  required: "Selecting a category type is required",
                }}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Select
                    tabIndex="3"
                    styles={customSelectStyles}
                    inputRef={ref}
                    options={CONTACT_CATEGORY_SELECTION_DROPDOWN}
                    value={CONTACT_CATEGORY_SELECTION_DROPDOWN.find(
                      (c) => c.value === value
                    )}
                    onChange={(val) => {
                      onChange(val.value);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <p className="error-message">{errors.category?.message}</p>
        </fieldset>

        <fieldset>
          <textarea
            {...register("description", {
              required: REQUIRED_MESSAGE,
            })}
            name="description"
            tabIndex="4"
            placeholder="Please keep your message short!"
          ></textarea>
          <p className="error-message">{errors.description?.message}</p>
        </fieldset>

        <div className="row-form style-3 justify-content-center">
          <div className="inner-row-form">
            <button className="submit filled-button">Send</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactUsForm;
