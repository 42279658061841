import React, { useEffect, useState } from "react";
import Collection04 from "../components/collection/Collection04";
// import getGalleries from "../api/gallery/getGalleries";
import styled from "styled-components";
import userTop from "../api/user/userTop";
import TopSellers from "../components/TopSellers/TopSellers";
import Layout from "../components/layouts/Layout";
import Spinner from "../components/layouts/Spinner/Spinner";
import { HEADER_HEIGHT } from "../constants/constants";
import CreatorFinder from "./Creators/CreatorFinder";

const StyledAuthors = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }
  .tf-section.live-auctions {
    padding-top: 0;
  }
  .heading-live-auctions span,
  .tf-title {
    color: black;
  }
`;

const Authors01 = () => {
  const [galleries, setGalleries] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAllGalleries = async () => {
    const response = await userTop();
    response && setGalleries(response);
    setLoading(false);
  };

  useEffect(() => {
    getAllGalleries();
  }, []);

  return (
    <Layout>
      <StyledAuthors>
        <div id="spacer"></div>
        <div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "400px",
              }}
            >
              <Spinner />
            </div>
          ) : (
            <>
              <TopSellers />

              <section className="flat-cart-item">
                <div className="overlay"></div>
                <Collection04
                  data={galleries}
                  title="Recent Activity"
                  navigateTo="#"
                  stop={3}
                  collectionType="Artist"
                  color="white"
                  measures={{ md: 6, lg: 4 }}
                  header={true}
                />
              </section>

              <section className="flat-cart-item">
                <div
                  className="overlay"
                  style={{ backgroundColor: "#285599" }}
                ></div>
                <section className="tf-section live-auctions">
                  <div className="themesflat-container flex justify-content-center">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "900px",
                      }}
                    >
                      <CreatorFinder data={galleries} />
                    </div>
                  </div>
                </section>
              </section>
            </>
          )}
        </div>
      </StyledAuthors>
    </Layout>
  );
};

export default Authors01;
