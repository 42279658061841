import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import userTopSellers from "../../api/user/topSellers";
import { COMMON_STYLES } from "../../constants/genericStyles";
import ArtistCard from "../collection/ArtistCard/ArtistCard";
import Collection from "../collection/Collection";
import CollectionHeader from "../collection/CollectionHeader";

const StyledTopArtistsSection = styled.section`
  .overlay {
    ${COMMON_STYLES.OVERLAY}
  }
  @media (max-width: 768px) {
    padding: 0px !important;
    .exp {
      font-size: 12px !important;
    }
    .tf-section.live-auctions {
      padding: 20px 0px !important;
    }
  }
`;

const TopArtists = (props) => {
  const [artists, setArtists] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTopArtists = async () => {
    let artists = await userTopSellers();
    const treatedArtists = artists.map((a) => ({
      ...a,
      tokenName: a.displayName,
    }));
    treatedArtists.length && setArtists(treatedArtists);
    setLoading(false);
  };

  useEffect(() => {
    getTopArtists();
  }, []);

  return (
    <Fragment>
      {loading ? (
        <h3> Loading...</h3>
      ) : (
        <StyledTopArtistsSection className="flat-cart-item top-artists">
          <div className="overlay"></div>
          {artists.length < 3 ? (
            <section className="tf-section live-auctions">
              <div className="themesflat-container">
                <div className="row m-0">
                  <CollectionHeader
                    title="Recent Activity"
                    navigateTo="/explore-04"
                    show={artists.length > 6}
                  />
                </div>
                <div className="row m-0">
                  {artists.map((item, i) => (
                    <div
                      className=" col-6 col-md-4 p-0"
                      key={"artist-card" + i}
                    >
                      <div
                        className="slides-container"
                        style={{ marginRight: "6%" }}
                      >
                        <ArtistCard card={item} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          ) : (
            <Collection
              data={artists}
              title="Recent Activity"
              navigateTo="/explore-04"
              stop={6}
              collectionType="Artist"
              measures={{ minimum: 2, sm: 2, md: 2, lg: 3 }}
              navigation={false}
              pagination={false}
            />
          )}
        </StyledTopArtistsSection>
      )}
    </Fragment>
  );
};
export default TopArtists;
