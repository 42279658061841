export const useS3Url = () => {
  const pathTypes = {
    coverImage: 'develop-cover-images',
    profilePicture: 'develop-profile-pictures'
  }

  /**
   *
   * @param fileName: fileName with extension to assamble the url
   * @param pathType: you must include audience, scope and fetch options params
   * @returns String
   */
  const getS3Url = (fileName, pathType) => {
    return `https://${pathTypes[pathType]}.s3.amazonaws.com/${fileName}`
  }

  return {
    getS3Url,
  };
};