import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { REQUIRED_MESSAGE } from "../../../constants/constants";
import { STATES_SELECTION_DROPDOWN } from "../../../helpers/orderHelpers";
import { customSelectStyles } from "../../CreateCollection/styles";

const ShippingInfo = ({ user }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div id="shipping-information">
      <h4>Shipping Information</h4>
      <br />
      <fieldset>
        <h6>Email</h6>
        <input
          {...register("email")}
          type="text"
          placeholder="acme@labs.xyz"
          defaultValue={user.email}
        />
      </fieldset>
      <br />
      <div className="row">
        <div className="col-md-6 col-12">
          <fieldset>
            <h6>First Name</h6>
            <input
              {...register("firstName")}
              type="text"
              placeholder="Acme"
              defaultValue={user.firstName}
            />
          </fieldset>
        </div>
        <div className="col-md-6 col-12">
          <fieldset>
            <h6>Last Name</h6>
            <input
              {...register("lastName")}
              type="text"
              placeholder="Labs"
              defaultValue={user.lastName}
            />
          </fieldset>
        </div>
      </div>
      <br />
      <fieldset>
        <h6>Street Address</h6>
        <input
          {...register("streetAddress", {
            required: REQUIRED_MESSAGE,
          })}
          type="text"
          placeholder="1234 Pennsylvania Avenue NW"
          defaultValue={user.streetAddress}
        />
        <p className="error-message">{errors.streetAddress?.message}</p>
      </fieldset>
      <br />
      <fieldset>
        <h6>Shipping Address (cont'd)</h6>
        <input
          {...register("streetAddressContd")}
          type="text"
          placeholder="Suite 1400 P.O. Box 345"
          defaultValue={user.streetAddressContd}
        />
      </fieldset>
      <br />
      <div className="row">
        <div className="col-md-4 col-12">
          <fieldset>
            <h6>City</h6>
            <input
              {...register("city", {
                required: REQUIRED_MESSAGE,
              })}
              type="text"
              placeholder="Atlanta"
              defaultValue={user.city}
            />
            <p className="error-message">{errors.city?.message}</p>
          </fieldset>
        </div>
        <div className="col-md-4 col-12">
          <fieldset>
            <h6>State</h6>
            <Controller
              control={control}
              name="state"
              rules={{
                required: REQUIRED_MESSAGE,
              }}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Select
                  styles={customSelectStyles}
                  inputRef={ref}
                  options={STATES_SELECTION_DROPDOWN}
                  value={STATES_SELECTION_DROPDOWN.find(
                    (c) => c.value === value
                  )}
                  onChange={(val) => {
                    onChange(val.value);
                  }}
                />
              )}
            />
            <p className="error-message">{errors.state?.message}</p>
          </fieldset>
        </div>
        <div className="col-md-4 col-12">
          <fieldset>
            <h6>Zip Code</h6>
            <input
              {...register("zipCode", {
                required: REQUIRED_MESSAGE,
              })}
              type="text"
              placeholder="30308"
              defaultValue={user.zipCode}
            />
            <p className="error-message">{errors.zipCode?.message}</p>
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default ShippingInfo;
