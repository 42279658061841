import React from "react";
import styled from "styled-components";
import ImageWithLoader from "../../components/ImageWithLoader";
import LinkWithQueryParams from "../../components/LinkWithQueryParams";
import { COMMON_STYLES } from "../../constants/genericStyles";
const StyledPurchaseOption = styled.div`
   {
    background: #ffffff;
    border: 1px solid #cdcdcd;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 21px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    width: 28%;
    margin-left: 5%;
    padding: 20px 30px;
    margin-bottom: 40px;
  }
  div.name {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-align: center;

    width: 100%;
    margin-bottom: 10px;

    text-transform: capitalize;
  }

  .media {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
    /* margin-right: 29px; */
    position: relative;
    flex-shrink: 0;

    margin-bottom: 20px;
    .image-container .image img {
      object-fit: contain;
      height: 100%;
      border-radius: 10px;
    }
  }

  button.filled-button {
    ${COMMON_STYLES.FILLED_BUTTON({
      fillColor: "#ff7b7c",
      borderSize: "10px",
    })}
    width: 100%;
    padding: 8px 50px;
    font-size: 16px;
    line-height: 16px;

    /* margin-bottom: 16px; */
  }
`;

const PurchaseOption = ({ option, item, element, onClick }) => {
  return (
    <StyledPurchaseOption>
      <div className="media">
        <LinkWithQueryParams path={`/item-details-01/${item?.id}`}>
          <ImageWithLoader img={element.image} alt={element.name} />
        </LinkWithQueryParams>
      </div>
      <div className="name">{option.description}</div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button className="filled-button" onClick={onClick}>
          Select
        </button>
      </div>
    </StyledPurchaseOption>
  );
};

export default PurchaseOption;
