import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Assets from "../../assets/Assets";
import AdditionalFeaturesModal from "../../components/Hashconnect/Pairing/AdditionalFeaturesModal";
import PairingModal from "../../components/Hashconnect/Pairing/PairingModal";
import LinkWithQueryParams from "../../components/LinkWithQueryParams";
import PopupModal from "../../components/PopupModal";
import VenlyConnectIndications from "../../components/Venly/VenlyConnectIndications";
import VenlyOverlay from "../../components/Venly/VenlyOverlay";
import VenlySelectWalletIndications from "../../components/Venly/VenlySelectWalletIndications";
import Layout from "../../components/layouts/Layout";
import { VENLY_WIDGET_BLOCKED } from "../../constants/constants";
import { useCustomNavigator } from "../../hooks/useCustomNavigator";
import useVenly from "../../hooks/useVenly";
import { logoutWallet } from "../../store/slices/user/user";
import AcmeVaultWallet from "./AcmeVaultWallet/AcmeVaultWallet";
import HashpackWallet from "./HashpackWallet/HashpackWallet";
import VenlyWallet from "./VenlyWallet/VenlyWallet";
import { StyledWalletPage, StyledWalletSection } from "./styles";

const WalletConnect = () => {
  const { state } = useLocation();
  const { callToVenly, manageWallets, venlyLogout, errorMessage } = useVenly();
  const { customNavigate } = useCustomNavigator();
  const user = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);
  const [showPairingModal, setShowPairingModal] = useState(false);
  const [showAdditionalFeaturesModal, setShowAdditionalFeaturesModal] =
    useState(false);

  const dispatch = useDispatch();
  const overlayRef = useRef(null);
  const connectIndicationsRef = useRef(null);
  const selectIndicationsRef = useRef(null);
  const walletActionRef = useRef({
    status: null,
  });

  const handleClose = () => {
    setShowModal(false);
  };

  const handlePairingModalClose = () => {
    setShowPairingModal(!!0);
  };

  const handleAdditionalFeaturesModalClose = () => {
    setShowAdditionalFeaturesModal(!!0);
  };

  const handleLogout = async () => {
    await venlyLogout();
    dispatch(logoutWallet());
  };

  const selectWallet = async () => {
    overlayRef.current.classList.add("active");
    selectIndicationsRef.current.classList.add("active");
    walletActionRef.current.status = "CONNECTING_WALLET";
    await manageWallets();
  };

  const connectWallet = async (event) => {
    try {
      overlayRef.current.classList.add("active");
      connectIndicationsRef.current.classList.add("active");
      walletActionRef.current.status = "AUTHENTICATING_USER";
      await callToVenly();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    try {
      if (
        errorMessage &&
        errorMessage.toString().includes(VENLY_WIDGET_BLOCKED)
      ) {
        // setShowModal(true);
      }
    } catch (e) {}
  }, [errorMessage]);

  useEffect(() => {
    /* Removes background if user is connected */
    if (
      user?.wallets?.owner?.email &&
      walletActionRef.current.status === "AUTHENTICATING_USER"
    ) {
      overlayRef.current.classList.remove("active");
      connectIndicationsRef.current.classList.remove("active");
    }

    /* Removes background if wallet is connected */
    if (
      user?.wallets?.walletDetails?.id &&
      walletActionRef.current.status === "CONNECTING_WALLET"
    ) {
      overlayRef.current.classList.remove("active");
      selectIndicationsRef.current.classList.remove("active");
    }

    /* Redirect the user to intended route if wallet is connected*/
    if (user?.wallets.walletDetails.id && state?.intendedRoute) {
      customNavigate({ path: state?.intendedRoute });
    }

    /* hashpack wallet is connected close dialog*/
    if (user?.hashpackWallets?.accountIds) {
      handlePairingModalClose();
    }
  }, [user]);

  const comingSoonWallets = [
    {
      icon: Assets.metamaskIcon,
      altIcon: "metamaskIcon",
      brand: Assets.metamaskBrand,
      altBrand: "metamaskBrand",
      note: "coming soon",
    },
  ];

  return (
    <StyledWalletPage>
      <VenlyOverlay overlayRef={overlayRef} />
      <VenlyConnectIndications
        connectIndicationsRef={connectIndicationsRef}
        connectWallet={connectWallet}
      />
      <VenlySelectWalletIndications
        selectIndicationsRef={selectIndicationsRef}
        selectWallet={selectWallet}
      />

      <Layout>
        <div id="spacer"></div>

        <div>
          <StyledWalletSection>
            <div className="tf-connect-wallet tf-section">
              <div className="themesflat-container">
                <div className="row m-0">
                  <div className="col-md-12">
                    <h3 className="main-indication">Select your wallet.</h3>

                    <div className="wallets-container">
                      <AcmeVaultWallet />

                      <VenlyWallet
                        selectWallet={selectWallet}
                        handleLogout={handleLogout}
                        connectWallet={connectWallet}
                      />

                      {comingSoonWallets.map((w, i) => (
                        <div className="wallet" key={`w-${i}`}>
                          <div className="note">{w.note}</div>
                          <div className="image">
                            <img src={w.icon} alt={w.altIcon} />
                          </div>
                          <div className="image">
                            <img src={w.brand} alt={w.altBrand} />
                          </div>
                        </div>
                      ))}

                      <HashpackWallet
                        setShowPairingModal={setShowPairingModal}
                        setShowAdditionalFeaturesModal={
                          setShowAdditionalFeaturesModal
                        }
                      />
                    </div>

                    <h3 className="sub-title ct style-1">
                      Need additional help connecting your wallet?{" "}
                      <LinkWithQueryParams
                        className="underline-animation"
                        target="_blank"
                        rel="noopener noreferrer"
                        path="/faq"
                      >
                        Click here
                      </LinkWithQueryParams>
                      .
                    </h3>
                  </div>

                  <hr className="divider" />

                  <div className="col-12 p-0">
                    <h5 className="indications style-1">
                      Please register first if you want to receive a free ACME
                      LABS™ Place and get IRL items shipped to you.
                    </h5>
                    <h5 className="indications style-1">
                      By connecting your wallet you agree to our{" "}
                      <LinkWithQueryParams
                        path="/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline-animation"
                      >
                        Terms of Service
                      </LinkWithQueryParams>{" "}
                      and our{" "}
                      <LinkWithQueryParams
                        path="/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline-animation"
                      >
                        Privacy Policy
                      </LinkWithQueryParams>{" "}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </StyledWalletSection>

          <PopupModal show={showModal} onHide={handleClose} />

          <PairingModal
            show={showPairingModal}
            onHide={handlePairingModalClose}
          />
          <AdditionalFeaturesModal
            show={showAdditionalFeaturesModal}
            onHide={handleAdditionalFeaturesModalClose}
          />
        </div>
      </Layout>
    </StyledWalletPage>
  );
};

export default WalletConnect;
