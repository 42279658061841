import React from "react";
import { Link, useLocation } from "react-router-dom";

const LinkWithQueryParams = ({
  children,
  path,
  className,
  id,
  target,
  rel,
  onClick,
}) => {
  const location = useLocation();
  /* Handles possibility of not giving a route */
  let intendedRoute = path;
  if (!intendedRoute) {
    intendedRoute = "/";
  }

  return (
    <Link
      className={className}
      id={id}
      target={target}
      rel={rel}
      to={{
        pathname: !intendedRoute.includes("#") ? intendedRoute : null,
        hash: intendedRoute.includes("#") ? intendedRoute : null,
        search: location.search,
      }}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

export default LinkWithQueryParams;
