import styled from "styled-components";
import { COMMON_STYLES } from "../../../constants/genericStyles";

export const StyledItemCard = styled.div`
  .sc-card-activity {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 30px;
  }
  .sc-card-activity .content .media {
    width: 112px;
    height: 112px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 29px;
    position: relative;
    flex-shrink: 0;
  }
  .sc-card-activity .content.expanded {
    align-items: flex-start;
    .infor {
      .left-section {
        width: 70%;
        .product-name {
          margin-bottom: 18px;
        }
      }
      .right-section {
        flex: 1;
      }
    }
    .media {
      width: 36%;
      padding-top: 30%;
      position: relative;
      margin-right: 9%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .image-container .image img {
      object-fit: contain;
    }
  }

  .product-name {
    font-weight: 400;
    font-size: 20px;
    color: #9a9a9a;
    margin-bottom: 4px;
  }
  .content {
    width: 100%;
  }
  .status-label {
    width: 100%;
    max-width: 110px;
    max-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.red {
      background: #ff7b7c;
    }
    &.green {
      background: #25a29d;
    }
    border-radius: 12.5px;
    padding: 13px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    font-size: 20px;

    span {
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      color: #ffffff;
      display: inline-block;
    }
  }
  .infor {
    display: flex;
    flex: 1;
    justify-content: space-between;
    line-height: 20px;
    .left-section {
      width: 40%;
    }
    .right-section {
      width: 20%;
      line-height: 20px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex-wrap: wrap;
    }
    h4 {
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
      color: #011126;
      margin-bottom: 10px;
    }
  }
  .author {
    h6 {
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      text-decoration-line: underline;
      text-decoration-color: #1b70d0;
      color: #1b70d0;
      margin-bottom: 20px;
    }
  }
  .chevron-container {
    width: 100%;
    max-width: 110px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    i {
      font-size: 30px;
      color: #011126;
      font-weight: 600;
    }
  }
  .hidden {
    margin-top: 10px;
    width: 100%;
  }
  .coa {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-decoration-line: underline;
    text-decoration-color: #1b70d0;
    color: #1b70d0;
    cursor: pointer;
  }

  .outlined-button {
    ${COMMON_STYLES.OUTLINED_BUTTON({
      textColor: "#ff7b7c",
      borderSize: "10px",
    })}
    font-size: 24px;
    padding: 16px 0px;
    width: 100%;
  }

  .filled-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .filled-button {
    ${COMMON_STYLES.FILLED_BUTTON({
      fillColor: "#ff7b7c",
      borderSize: "10px",
    })}
    font-size: 16px;
    padding: 8px 0px;
    width: 80%;
  }
  .selling-label {
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    text-transform: capitalize;
    color: #25a29d;

    width: 100%;
    text-align: center;
    margin-bottom: 4px;
  }

  .price-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
  }
  .price {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-transform: capitalize;
    color: #000000;

    text-align: center;
  }

  @media (max-width: 768px) {
    .sc-card-activity {
      padding: 7px;
    }

    .infor h4 {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 4px;
    }

    .infor .left-section {
      flex: 1;
    }

    .infor .right-section {
      justify-content: center;
      align-items: center;
    }

    .sc-card-activity .content.expanded .infor .right-section {
      justify-content: center;
      align-items: center;
      flex: 0 1 auto;
    }

    .sc-card-activity .content .infor .author a,
    .product-name {
      font-size: 10px;
      line-height: 10px;
    }

    .sc-card-activity .content .infor .author h6 {
      margin-bottom: 4px;
      font-size: 10px;
      line-height: 10px;
    }

    .sc-card-activity .content .media {
      width: 82px;
      height: 68px;
      .image img {
        object-fit: contain;
      }
    }

    .status-label {
      display: inline-block;
      padding: 1px 7px;
      margin-bottom: 8px;
      width: auto;
      span {
        font-size: 10px;
        line-height: 10px;
      }
    }

    .filled-button,
    .outlined-button {
      font-size: 12px;
      line-height: 12px;
      padding: 10px;
      border-radius: 3px;
      width: 100%;
    }

    .outlined-button {
      border: 1px solid #ff7b7c;
    }

    .chevron-container {
      i {
        font-size: 20px;
        font-weight: 300;
      }
    }

    .hidden {
      width: 100%;
      text-align: center;
      margin-top: 17px;
      margin-bottom: 7px;
      .coa {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
`;
