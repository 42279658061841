import React, { useState } from "react";
import { useFormContext } from "react-hook-form";

const AttributesSection = ({ sku, action }) => {
  const { register } = useFormContext();

  const [order, setOrder] = useState({
    shippingMethod: "",
    prodigiProductSKUId: sku.id,
    sizing: "",
    attributes: {},
  });

  const handleChange = (e) => {
    action(e.target.value);
  };

  return (
    <>
      <div id="shipping-selection" className="row-form style-3">
        <div className="inner-row-form-2">
          <div className="select-box">
            <div id="item-create" className="dropdown">
              <h6>Shipping Method: Standard</h6>
              <h6>Sizing: Fit Print Area</h6>
              {/* <h6>shipping method </h6> */}
              <select
                id="shippingMethod"
                {...register("shippingMethod")}
                name="shippingMethod"
                className="select-box btn-selector nolink"
                onChange={handleChange}
                required={true}
                hidden={true}
                defaultValue={"Standard"}
              >
                <option value="Budget">Budget</option>
                <option value="Overnight">Overnight</option>
                <option value="Express">Express</option>
                <option value="Standard">Standard</option>
              </select>
            </div>
          </div>
        </div>
        <div className="inner-row-form-2">
          <div className="select-box">
            <div id="item-create" className="dropdown">
              {/* <h6>sizing</h6> */}
              <select
                id="sizing"
                name="sizing"
                {...register("sizing")}
                className="select-box btn-selector nolink"
                required={true}
                hidden={true}
                defaultValue={"fitPrintArea"}
              >
                <option value="fillPrintArea">Fill Print Area</option>
                <option value="fitPrintArea">Fit Print Area</option>
                <option value="stretchToPrintArea">
                  Stretch To Print Area
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttributesSection;
