import React, { useState } from "react";
import Layout from "../../components/layouts/Layout";
import { StyledCreateActivityPage } from "./styles";
import { useForm } from "react-hook-form";
import CreateActivityForm from "./CreateActivityForm";
import ActivityImagePreview from "./ActivityImagePreview";
import { useApi } from "../../hooks/useApi";
import Spinner from "../../components/layouts/Spinner/Spinner";
import { REACT_APP_API_URL } from "../../constants/constants";

const CreateActivity = () => {
  const { executeApiRequestTwo } = useApi();
  const methods = useForm();
  const [activityImage, setActivityImage] = useState(null);
  const [isloading, setLoading] = useState(false);

  const createActivityRequest = async ({ postFormData }) => {
    try {
      const activityRes = await executeApiRequestTwo(
        `${REACT_APP_API_URL}general/activity`,
        {
          method: "POST",
          body: postFormData,
        }
      );
      // if (colRes?.statusCode === 400) {
      //   notifyError("There was an error creating the collection");
      //   return;
      // }

      // navigate(`/collection/${colRes.id}`);
    } catch (error) {
      console.log(error);
      // setError(true);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", data.file[0]);
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("startDate", data?.startDate.toISOString());
    formData.append("endDate", data?.endDate.toISOString());
    formData.append("cmsLink", data.title);
    createActivityRequest({ postFormData: formData });
  };

  return (
    <Layout>
      <StyledCreateActivityPage>
        <div id="spacer"></div>
        {isloading && (
          <div className="progress-overlay">
            <div className="progess-status">Creating Activity</div>
            <Spinner />
          </div>
        )}
        <section id="create-activity-page">
          <div className="header-container">
            <div className="page-title-heading">
              <div className="vertical-line"></div>
              <h1 className="heading">Create Activity</h1>
            </div>

            <div className="explanation">
              explaination of what an activity is and what to use it for. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit,exercitation
              ullamco
            </div>

            <form
              className="row m-0 justify-content-md-center justify-content-xl-center justify-content-lg-center"
              action="#"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <div className="col-3">
                <ActivityImagePreview
                  methods={methods}
                  setActivityImage={setActivityImage}
                  activityImage={activityImage}
                />
              </div>

              <div id="right-section" className="col-9">
                <CreateActivityForm methods={methods} />
              </div>
            </form>
          </div>
        </section>
      </StyledCreateActivityPage>
    </Layout>
  );
};

export default CreateActivity;
