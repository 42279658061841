import React from "react";
import styled from "styled-components";
import backgroundImage from "../../../assets/images/backgroup-secsion/img_bg_slider_2.png";
import { COMMON_STYLES } from "../../../constants/genericStyles";
import LinkWithQueryParams from "../../LinkWithQueryParams";

const StyledSliderRightImage = styled.div`
  a {
    width: 100%;
  }
  .sc-button-2,
  .sc-button-2.orange {
    padding: 20px 8%;
  }
  .cards-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .cards-container .card-item {
    width: 48%;
    color: white;
    background: #2159a2;
    margin-bottom: 4%;
    border-radius: 15px;
    display: flex;
  }

  .card-content {
    padding: 20px;
    padding-right: 40px;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }

  .card-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    width: 100%;
  }

  .card-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    width: 100%;
  }

  .card-step-id {
    color: #ff7b7c;
    margin-right: 8px;
  }

  .vertical-line {
    content: "|";
    margin-right: 8px;
    font-weight: 400;
  }

  .heading.centered {
    width: 100%;
    text-align: center;
  }

  ${COMMON_STYLES.MAIN_SLIDER_BACKGROUND}
  background-image: url(${backgroundImage});
  .slider-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    .image-lines-container .image-container:before {
      padding-top: 75%;
    }
    .image-container img {
      width: 100%;
      position: absolute;
      object-fit: cover;
    }
  }

  @media (max-width: 768px) {
    .cards-container .card-item {
      width: 100%;
    }
    .card-content {
      padding: 8px;
    }
    .card-title {
      font-size: 12px;
      line-height: 12px;
    }
    .card-description {
      font-size: 8px;
      line-height: 8px;
    }
  }
`;

const SliderRightImage = (props) => {
  const ActionBtn = () => (
    <div className="btn-auction center mb-4">
      <button
        id="load-more"
        className={`sc-button-2 no-bg button__bottom-top ${props.item.button.class}`}
      >
        <span>{props.item.button.name}</span>
      </button>
    </div>
  );
  return (
    <StyledSliderRightImage className="flat-title-page">
      <div className="slider-content">
        <div
          className="slider-text-content"
          style={{
            width: props.textProportions
              ? props.textProportions
              : "55% !important",
          }}
        >
          <h2
            className={`heading mb-4 ${props.item.title_1_class}`}
            style={{ whiteSpace: "pre-wrap" }}
          >
            {props.item.title_1}
          </h2>
          <h1 className="sub-heading mb-style mb-4">
            <span className="tf-text s1">{props.item.title_2}</span>
          </h1>

          <div className="cards-container">
            {props.item.cardsContent &&
              props.item.cardsContent.map((card, i) => (
                <div className="card-item" key={i}>
                  <div className="card-content">
                    <div className="card-title">
                      <span className="card-step-id">{"0" + (i + 1)}</span>
                      <span className="vertical-line">|</span>
                      {card.title}
                    </div>
                    <div className="card-description">{card.description}</div>
                  </div>
                </div>
              ))}
          </div>

          {props.item.button &&
            props.item.button.name &&
            (props.item.button.hash ? (
              <a className="action-button" href={props.item.button.hash}>
                <ActionBtn />
              </a>
            ) : props.item.button?.externalSite ? (
              <a href={props.item.button.path}>
                <ActionBtn />
              </a>
            ) : (
              <LinkWithQueryParams
                className="action-button"
                path={props.item.button.path}
              >
                <ActionBtn />
              </LinkWithQueryParams>
            ))}
        </div>

        <div
          className="slider-image-content"
          style={{
            width: props.imageProportions ? props.imageProportions : "40%",
          }}
        >
          <div className="image-lines-container">
            {props.item.videoUrl && (
              <div className="iframe-container">
                <iframe
                  title="slide-video"
                  src={props.item.videoUrl}
                  width="640"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                ></iframe>
              </div>
            )}
            {!props.item.videoUrl && (
              <div className="image-container">
                {props.item.img && <img src={props.item.img} alt="axies" />}
                {props.item.video && (
                  <video
                    id="desktop-video"
                    width="100%"
                    height="100%"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    autoPlay
                    muted
                    loop
                    poster="/videos/dentyx_placeholder.png"
                  >
                    <source src={props.item.video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            )}
            <div className="left-line"></div>
            <div className="bottom-line"></div>
            <div className="right-line"></div>
          </div>
        </div>
      </div>
    </StyledSliderRightImage>
  );
};

export default SliderRightImage;
