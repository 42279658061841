import styled from "styled-components";
import imgCover from "../../assets/images/backgroup-secsion/cover_placeholder.png";
import { COMMON_STYLES } from "../../constants/genericStyles";
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from "../../constants/constants";

export const AuthorsSection = styled.section`
  position: relative;
  padding-top: ${HEADER_HEIGHT.toString() + "px"};

  .filters-container {
    padding-left: 16%;
    padding-right: 3%;
  }

  .top-container {
    border-bottom: 1px solid #cdcdcd;
    padding-left: 4%;
    padding-right: 3%;
    margin-bottom: 2%;
  }

  .actions-container {
    position: absolute;
    right: 3%;
    top: 0;
    padding-top: ${(HEADER_HEIGHT + 27).toString() + "px"};
  }

  .top-section {
    padding-bottom: 5%;
  }

  .profile-image-container {
    position: absolute;
    top: 130px;
    left: 4%;

    height: 200px;
    width: 200px;
  }

  .profile-image {
    ${COMMON_STYLES.GENERIC_IMAGE_CONTAINER({ paddingTop: "100%" })}
  }

  .profile-image img {
    width: auto;
    max-width: initial;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
  }

  .fa-heart,
  .fa-share-alt,
  .fa-elipsis-stroke {
    font-family: "Font Awesome 5 Pro";
    height: auto;
    font-size: 24px;
    margin-right: 26px;
  }
  .fa-elipsis-stroke {
    margin-right: 0px;
  }

  .fa-elipsis-stroke::before {
    content: "\f39b";
  }

  .cover-image {
    background-image: ${(props) =>
      `url(${props.cover ? props.cover : imgCover})`};
    background-size: cover;
    position: relative;
    height: 214px;
  }

  .filled-button {
    ${COMMON_STYLES.FILLED_BUTTON({ fillColor: "#ff7b7c", borderSize: "10px" })}
  }

  .outlined-button {
    ${COMMON_STYLES.OUTLINED_BUTTON({
      textColor: "#ff7b7c",
      borderSize: "10px",
    })}
  }

  .button__bottom {
    ${COMMON_STYLES.BOTTOM_TOP_ANIMATION({
      finalButtonFill: "#ff7b7c",
      initialButtonFill: "transparent",
    })}
  }

  .widget-filter .btn-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    background-color: transparent;
    border-radius: 8px;

    width: 14px;
    height: 14px;
    border: 1px solid #011126;
    border-radius: 2px;
    top: 50%;
    -webkit-transform: translateY(-50%);
  }

  .widget-filter.style-1 form label {
    line-height: 26px;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
    padding-left: 40px;

    display: flex;
    align-content: center;
    align-items: center;

    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #011126;
  }

  .widget-filter .btn-checkbox:after {
    content: \e921;
    font-family: "nfts";
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: #fff;
  }

  .creator-info {
    margin-bottom: 42px;
    .creator-description {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
      width: 60%;
      margin-bottom: 9px;
    }

    .name-container {
      margin: 0;
      margin-bottom: 14px;
      display: flex;
      justify-content: space-between;
    }

    .artist-name {
      font-weight: 700;
      font-size: 36px;
      line-height: 36px;
      color: #000000;
    }
  }

  .stats-container {
    width: 70%;
    .stat-row {
      display: flex;
      width: 100%;
    }
    .stat-container {
      width: 25%;
    }
    .stat-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
      color: #011126;
    }
    .stat-number {
      font-size: 24px;
      line-height: 24px;
      color: #565656;
    }
  }

  .themesflat-tabs .react-tabs__tab-list {
    display: flex;
    justify-content: space-between;
    padding-right: 30%;
    background: white;
    border-bottom: none;
  }

  .themesflat-tabs .react-tabs__tab {
    font-size: 32px;
    line-height: 32px;
    color: #011126;
    margin-right: 0;
  }
  .themesflat-tabs .react-tabs__tab.react-tabs__tab--selected::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #ff7b7c;
    transform: none;
  }

  /* items section */

  @media (max-width: 768px) {
    padding: 20px 0px;
    padding-top: 100px;
    #spacer {
      padding-top: ${MOBILE_HEADER_HEIGHT.toString() + "px"};
    }

    .creator-info {
      margin-bottom: 20px;
      .creator-description {
        font-size: 12px;
        line-height: 14px;
        width: 100%;
        margin-bottom: 9px;
      }
      .artist-name {
        font-size: 18px;
        line-height: 18px;
      }
    }

    .cover-image {
      height: 130px;
    }

    .actions-container {
      padding-top: 140px;
      .fa-heart,
      .fa-share-alt,
      .fa-elipsis-stroke {
        font-size: 12px;
        margin-right: 12px;
      }
    }

    .profile-image-container {
      height: 100px;
      width: 100px;
      top: 150px;
    }

    .top-section {
      padding-bottom: 6%;
    }

    .stats-container {
      width: 100%;
      justify-content: center;
      .stat-row {
        width: 90%;
      }
      .stat-container {
        width: 25%;
      }
      .stat-title {
        font-size: 16px;
        line-height: 16px;
      }
      .stat-number {
        font-size: 12px;
        line-height: 12px;
      }
    }

    .themesflat-tabs .react-tabs__tab {
      font-size: 12px;
      line-height: 12px;
    }

    .themesflat-tabs .react-tabs__tab.react-tabs__tab--selected::after {
      bottom: 0px;
      width: 60%;
      left: 30%;
      height: 2px;
    }

    .react-tabs__tab {
      padding: 2.2% 10px 2.5%;
    }

    .tf-section.our-creater {
      padding: 10px 0px;
    }

    .nft-card {
      /* margin-bottom: 10px; */
    }

    .btn-auction-7.center {
      margin-bottom: 0px;
    }

    .sc-button-7 {
      padding: 12px 33px;
      border-radius: 10px;
      border: 2.5px solid #ff7b7c;

      a span {
        font-size: 14px;
        line-height: 14px;
      }
    }

    .sc-card-collection.style-2 .card-bottom .author .content h4 {
      font-size: 8px;
      line-height: 8px;
    }
    .sc-card-collection
      .card-bottom
      .sc-author-box.style-2
      .author-avatar
      .avatar {
      width: 20px;
    }
  }
`;
