import img1 from '../images/box-item/card-item8.jpg'
import imga1 from '../images/avatar/avt-11.jpg'
import img2 from '../images/box-item/image-box-10.jpg'
import imga2 from '../images/avatar/avt-12.jpg'
import img3 from '../images/box-item/card-item-11.jpg'
import imga3 from '../images/avatar/avt-13.jpg'
import img4 from '../images/box-item/image-box-21.jpg'
import imga4 from '../images/avatar/avt-14.jpg'
import imgCollection1 from '../images/avatar/avt-18.jpg'
import imgCollection2 from '../images/avatar/avt-16.jpg'
import imgCollection3 from '../images/avatar/avt-17.jpg'


const liveAuctionData = [
    {
        img: img1,
        title: "Lorem Ipsum",
        tags: "tag",
        imgAuthor: imga1,
        nameAuthor: "Lorem Ipsum",
        price: "4.89 HBAR",
        priceChange: "$240,40",
        wishlist: "100",
        imgCollection: imgCollection1,
        nameCollection: "Lorem Ipsum"
    },
    {
        img: img2,
        title: "Lorem Ipsum",
        tags: "tag",
        imgAuthor: imga2,
        nameAuthor: "Lorem Ipsum",
        price: "4.89 HBAR",
        priceChange: "$240,40",
        wishlist: "220",
        imgCollection: imgCollection2,
        nameCollection: "Lorem Ipsum"
    },
    {
        img: img3,
        title: "Lorem Ipsum",
        tags: "tag",
        imgAuthor: imga3,
        nameAuthor: "Lorem Ipsum",
        price: "4.89 HBAR",
        priceChange: "$240,40",
        wishlist: "90",
        imgCollection: imgCollection3,
        nameCollection: "Lorem Ipsum"
    },
    {
        img: img4,
        title: "Lorem Ipsum",
        tags: "tag",
        imgAuthor: imga4,
        nameAuthor: "Lorem Ipsum",
        price: "4.89 HBAR",
        priceChange: "$240,40",
        wishlist: "145",
        imgCollection: imgCollection1,
        nameCollection: "Lorem Ipsum"
    },
    {
        img: img1,
        title: "Lorem Ipsum",
        tags: "tag",
        imgAuthor: imga1,
        nameAuthor: "Lorem Ipsum",
        price: "4.89 HBAR",
        priceChange: "$240,40",
        wishlist: "100",
        imgCollection: imgCollection2,
        nameCollection: "Lorem Ipsum"
    },
    {
        img: img2,
        title: "Lorem Ipsum",
        tags: "tag",
        imgAuthor: imga2,
        nameAuthor: "Lorem Ipsum",
        price: "4.89 HBAR",
        priceChange: "$240,40",
        wishlist: "220",
        imgCollection: imgCollection3,
        nameCollection: "Lorem Ipsum"
    },
    {
        img: img3,
        title: "Lorem Ipsum",
        tags: "tag",
        imgAuthor: imga3,
        nameAuthor: "Lorem Ipsum",
        price: "4.89 HBAR",
        priceChange: "$240,40",
        wishlist: "90",
        imgCollection: imgCollection1,
        nameCollection: "Lorem Ipsum"
    },
    {
        img: img1,
        title: "Lorem Ipsum",
        tags: "tag",
        imgAuthor: imga1,
        nameAuthor: "Lorem Ipsum",
        price: "4.89 HBAR",
        priceChange: "$240,40",
        wishlist: "100",
        imgCollection: imgCollection1,
        nameCollection: "Lorem Ipsum"
    },
    {
        img: img2,
        title: "Lorem Ipsum",
        tags: "tag",
        imgAuthor: imga2,
        nameAuthor: "Lorem Ipsum",
        price: "4.89 HBAR",
        priceChange: "$240,40",
        wishlist: "220",
        imgCollection: imgCollection2,
        nameCollection: "Lorem Ipsum"
    },
    {
        img: img3,
        title: "Lorem Ipsum",
        tags: "tag",
        imgAuthor: imga3,
        nameAuthor: "Lorem Ipsum",
        price: "4.89 HBAR",
        priceChange: "$240,40",
        wishlist: "90",
        imgCollection: imgCollection3,
        nameCollection: "Lorem Ipsum"
    },
    {
        img: img4,
        title: "Lorem Ipsum",
        tags: "tag",
        imgAuthor: imga4,
        nameAuthor: "Lorem Ipsum",
        price: "4.89 HBAR",
        priceChange: "$240,40",
        wishlist: "145",
        imgCollection: imgCollection1,
        nameCollection: "Lorem Ipsum"
    },
    {
        img: img1,
        title: "Lorem Ipsum",
        tags: "tag",
        imgAuthor: imga1,
        nameAuthor: "Lorem Ipsum",
        price: "4.89 HBAR",
        priceChange: "$240,40",
        wishlist: "100",
        imgCollection: imgCollection2,
        nameCollection: "Lorem Ipsum"
    },
    {
        img: img2,
        title: "Lorem Ipsum",
        tags: "tag",
        imgAuthor: imga2,
        nameAuthor: "Lorem Ipsum",
        price: "4.89 HBAR",
        priceChange: "$240,40",
        wishlist: "220",
        imgCollection: imgCollection3,
        nameCollection: "Lorem Ipsum"
    },
    {
        img: img3,
        title: "Lorem Ipsum",
        tags: "tag",
        imgAuthor: imga3,
        nameAuthor: "Lorem Ipsum",
        price: "4.89 HBAR",
        priceChange: "$240,40",
        wishlist: "90",
        imgCollection: imgCollection1,
        nameCollection: "Lorem Ipsum"
    }
]

export default liveAuctionData;