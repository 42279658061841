import React, { useState, useEffect } from 'react';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ConfirmationDescription from './ConfirmationDescription';
import OrderSummary from './OrderSummary';
import { useApi } from '../../hooks/useApi';
import Spinner from '../../components/layouts/Spinner/Spinner';

const { REACT_APP_API_URL } = process.env;

const OrderConfirmation = () => {
  const { executeApiRequest, executeApiRequestTwo } = useApi();
  const { id } = useParams();
  const { displayName } = useSelector((state) => state.user);
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);

  const email = JSON.parse(sessionStorage.getItem('email'));

  const sendEmail = async (email, orderId) => {
    const url = `${REACT_APP_API_URL}prodigi/order/checkout-email/${orderId}`;
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email }),
    };
    await executeApiRequest(`${url}`, options);
  };

  useEffect(() => {
    (async () => {
      try {
        const prodigiOrder = await executeApiRequestTwo(
          `${REACT_APP_API_URL}prodigi/order/my/${id}`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          }
        );
        setOrder(prodigiOrder);
        await sendEmail(email, prodigiOrder.id);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  return (
    <>
      <Header />
      <section className='tf-explore tf-section'>
        <div className='themesflat-container mt-5 py-5'>
          <div className='row'>
            {loading ? (
              <div className='d-flex justify-content-center  align-items-center vh-100 w-100'>
                <Spinner />
              </div>
            ) : (
              <>
                <div className='col-md-6 p-3'>
                  <ConfirmationDescription
                    userName={displayName}
                    order={order}
                    action={sendEmail}
                  />
                </div>
                <div className='col-md-6'>
                  <OrderSummary order={order} />
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default OrderConfirmation;
