import React from "react";
import styled from "styled-components";
const StyledConnectIndications = styled.div`
  .indications {
    display: none;
    width: 22%;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;

    position: fixed;
    z-index: 2147483649;
    right: 7%;
    top: 12%;
  }

  .indications p {
    color: white;
    margin-bottom: 12px;
  }

  .indications .title {
    font-weight: 700;
  }

  .indications.active {
    display: block;
  }

  .popup-btn {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    .indications {
      width: 90%;
      right: 5%;
      top: 12%;
    }

    .indications p ,  .indications .popup-btn {
      font-size: 10px;
      line-height: 10px;
    }
  }
`;
const VenlyConnectIndications = ({ connectIndicationsRef, connectWallet }) => {
  return (
    <StyledConnectIndications>
      <div
        id="connect-indications"
        className="indications"
        ref={connectIndicationsRef}
      >
        <p className="title">Please Connect Your Wallet</p>
        <p>
          Please sign in to an existing account, or click “Create Account” if
          you are new to this site.
        </p>
        <p>
          In order to buy, sell, and trade items, you must sign in to a wallet.
        </p>
        <p>
          “The Venly wallet opens a pop-up window. If you do not see the pop-up:
        </p>
        <p>1. Allow pop-ups in your browser.</p>
        <p>
          2. Check to see if the pop-up is covered by a different window or on
          another screen.
        </p>
        <div className="popup-btn" onClick={connectWallet}>
          Click to connect your wallet
        </div>
      </div>
    </StyledConnectIndications>
  );
};

export default VenlyConnectIndications;
