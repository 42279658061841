import { getHeaders } from "../Item/utils";

const { REACT_APP_API_URL } = process.env;

export const getRoomByCollection = async ({ collectionId }) => {
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}room/collection/${collectionId}`
    );
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

export const getRoomTemplates = async () => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}room/template`, {
      method: "GET",
      headers,
    });
    return response.json();
  } catch (error) {
    console.log(error);
  }
};
