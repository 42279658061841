const { REACT_APP_API_URL } = process.env;

export const getLinks = async () => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}links`, {
      method: "GET",
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};
