import moment from "moment";
import React from "react";
import { orderStatusFormatter } from "../../../helpers/formatters";
import OrderItem from "../OrderItem/OrderItem";
import SoldOrderItem from "../OrderItem/SoldOrderItem";
import { StyledOrderItem } from "./styles";

const Order = ({ order, orderType }) => {
  return (
    <StyledOrderItem>
      <div
        className={`top-section ${
          orderType && orderType === "SOLD" ? "green" : ""
        }`}
      >
        <div className="order-number">
          <div className="order-number-label">Order Number:</div>
          <div className="order-number-value">
            {orderType && orderType === "SOLD" ? order?.orderNumber : order.id}
          </div>
        </div>
        <div className="order-date">
          <div className="order-date-label">Date Ordered:</div>
          {order.saleDate && (
            <div className="order-date-value">
              {/* {moment(order.saleDate).format("MMMM Do YYYY, h:mm:ss a")} */}
              {moment(order.saleDate).format("MMMM Do YYYY")}
            </div>
          )}
        </div>
      </div>
      <div className="order-content">
        {orderType === "SOLD" ? (
          <SoldOrderItem item={order} key={"id " + order?.itemId} />
        ) : (
          order?.orderDetails.map((item, i) => (
            <OrderItem item={item} key={"id " + i} />
          ))
        )}

        {orderType !== "SOLD" && (
          <div className="order-details">
            <div className="order-status">
              <div className="order-status-label">Order Status:</div>
              <div className="order-status-value">
                {orderStatusFormatter({ status: order?.status })}
              </div>
            </div>

            {order?.prodigiOrderList?.prodigiOrderListShipments?.length > 0 &&
              order?.prodigiOrderList?.prodigiOrderListShipments?.map(
                (shippingDetails) => {
                  return (
                    shippingDetails?.trackingURL?.length > 0 && (
                      <div className="tracking-info">
                        <div className="tracking-label">Tracking:</div>
                        <a
                          className="tracking-value"
                          href={shippingDetails?.trackingURL}
                        >
                          {shippingDetails?.trackingURL}
                        </a>
                      </div>
                    )
                  );
                }
              )}
          </div>
        )}
      </div>
    </StyledOrderItem>
  );
};

export default Order;
