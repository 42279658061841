import styled from "styled-components";
import { COMMON_STYLES } from "../../constants/genericStyles";
import { HEADER_HEIGHT } from "../../constants/constants";

export const StyledAdministrationPage = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }

  section#administration-page {
    padding: 2%;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .page-title-heading {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 30px;
    .vertical-line {
      height: 30px;
      border-left: 6px solid #ffd061;
    }

    .heading {
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
      color: #011126;
    }
  }

  .administrative-card {
    display: flex;
    align-content: center;
    flex-wrap: wrap;

    background: #ffffff;
    border: 1px solid #cdcdcd;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 18px;
    padding: 40px;
    min-height: 200px;

    .administrative-label {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      color: #000000;
      margin-bottom: 20px;
      width: 100%;
    }

    .filled-button {
      ${COMMON_STYLES.FILLED_BUTTON({
        fillColor: "#ff7b7c",
        borderSize: "10px",
      })}
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      width: 100%;
    }
  }
`;
