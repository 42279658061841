import React from "react";

const IbendaComponent = ({ id, title }) => {
  return (
    <a
      href={`https://www.iubenda.com/${id}`}
      className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed"
      title={title}
    >
      Privacy Policy
    </a>
  );
};

export default IbendaComponent;
