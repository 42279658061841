import { getHeaders } from "../Item/utils";
const { REACT_APP_API_URL } = process.env;

const getCollectionsMy = async () => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}collection/my`, {
      method: "GET",
      headers,
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export default getCollectionsMy;
