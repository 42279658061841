import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import HeadingText from "../../editProfile/HeadingText";
import { StyledRemoveModal } from "./styles";

const RemoveModal = ({ show, onHide, item, removeItemFromSale }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>
        <StyledRemoveModal>
          <HeadingText title={"Remove from Sale"} variant="small" />
          <p className="main-indications">
            ACME LABS will be removing the item from sale and returning the item
            to your original wallet.
          </p>
          <p className="return-fee">Return Fee: 0.5 HBAR</p>
          <p className="return-fee-note">
            (If you device to de-list the item before it is purchased)
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              className="filled-button"
              onClick={() => removeItemFromSale({ item })}
            >
              Remove From Sale
            </button>
          </div>
        </StyledRemoveModal>
      </Modal.Body>
    </Modal>
  );
};

export default RemoveModal;
