import { useState, useEffect } from "react";
import UploadButton from "../../components/layouts/button/UploadButton";
import avt from "../../assets/images/avatar/avatar-profile.png";
import { useApi } from "../../hooks/useApi";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { editPicture } from "../../store/slices/user/user";
import { useS3Url } from "../../hooks/useS3Url";
import HeadingText from "./HeadingText";
import LinkWithQueryParams from "../../components/LinkWithQueryParams";

const { REACT_APP_API_URL } = process.env;

const CardProfile = (props) => {
  const { getS3Url } = useS3Url();
  const dispatch = useDispatch();
  const { imgProfile } = props;
  const fileName = "profilePicture";
  const { executeApiRequestTwo, executeApiRequest } = useApi();

  const [img, setImg] = useState(imgProfile);

  const onImageChange = (element) => {
    setImg(URL.createObjectURL(element));
    uploadPicture(element);
  };

  useEffect(() => {
    if (!imgProfile) return;
    if (imgProfile.includes("amazonaws")) {
      setImg(imgProfile);
      return;
    }
    const profileFileNameS3 = getS3Url(imgProfile, "profilePicture");
    if (profileFileNameS3.length !== 0) {
      setImg(profileFileNameS3);
    }
  }, [imgProfile, getS3Url]);

  const uploadPicture = async (picture) => {
    const formData = new FormData();
    formData.append("file", picture);
    const res = await executeApiRequestTwo(
      `${REACT_APP_API_URL}user/profile-picture`,
      {
        method: "PUT",
        body: formData,
      }
    );
    if (res.profileFileName) {
      const profileFileNameS3 = getS3Url(res.profileFileName, "profilePicture");
      dispatch(editPicture(profileFileNameS3));
    }
  };

  const deletePicture = (e) => {
    e.preventDefault();
    executeApiRequest(`${REACT_APP_API_URL}user/delete-profile-picture`, {
      method: "PATCH",
    });
    setImg(null);
    dispatch(editPicture(null));
  };

  return (
    <div className="text-center">
      <HeadingText title={"My Profile"} />
      <div className="card-media">
        <div className="image-container">
          <img
            id="profileimg"
            src={img && img.length !== 0 ? img : avt}
            alt=""
          />
        </div>
      </div>

      <UploadButton
        title="Change Profile Image"
        action={onImageChange}
        fileName={fileName}
      />
      <LinkWithQueryParams
        path="#"
        className="btn-upload style2"
        onClick={deletePicture}
      >
        Delete Picture
      </LinkWithQueryParams>
    </div>
  );
};

export default CardProfile;
