import { CUSTOM_PAGE_SIZE } from "../../constants/constants";
import { getHeaders } from "./utils";
const { REACT_APP_API_URL } = process.env;
const getStrParams = (params) => {
  let result = "?";
  return (
    result +
    Object.entries(params)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value
            .map((item) => `${key}=${encodeURIComponent(item)}`)
            .join("&");
        } else {
          return `${key}=${encodeURIComponent(value)}`;
        }
      })
      .join("&")
  );
};

const filterVoidParams = (params) => {
  let newParams = {};
  for (let i in params) {
    params[i].length && (newParams[i] = params[i]);
  }
  return newParams;
};

export const getTodayPicks = async () => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}item/today-picks`, {
      method: "GET",
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const getTopTrending = async (params) => {
  const newParams = filterVoidParams(params);
  let url = `${REACT_APP_API_URL}item/base/top/sellers`;
  const fullpath = !Object.keys(newParams).length
    ? url
    : url + getStrParams(newParams);
  try {
    const response = await fetch(`${fullpath}`, {
      method: "GET",
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

const getItems = async (params) => {
  const newParams = filterVoidParams(params);
  let url = `${REACT_APP_API_URL}item`;
  const fullpath = !Object.keys(newParams).length
    ? url
    : url + getStrParams(newParams);
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${fullpath}`, {
      method: "GET",
      headers,
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const getAllResaleItems = async () => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}item/resale`, {
      method: "GET",
      headers,
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const getMyResaleItems = async () => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}item/resale/my`, {
      method: "GET",
      headers,
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const getMyItems = async () => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(
      `${REACT_APP_API_URL}item/my?` +
        new URLSearchParams({
          customPageSize: CUSTOM_PAGE_SIZE,
        }),
      {
        method: "GET",
        headers,
      }
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const getMyVaultItems = async () => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(
      `${REACT_APP_API_URL}item/my?` +
        new URLSearchParams({
          customPageSize: CUSTOM_PAGE_SIZE,
          inVaultOnly: "Y",
        }),
      {
        method: "GET",
        headers,
      }
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const getMyPrintedItems = async () => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}prodigi/order/my`, {
      method: "GET",
      headers,
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const getMyPendingApprovalItems = async () => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}item/pending-approval`, {
      method: "GET",
      headers,
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};
export default getItems;
