import React, { useState } from "react";
import Countdown from "react-countdown";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import liveAuctionData from "../assets/fake-data/data-live-auction";
import img3 from "../assets/images/avatar/avt-1.jpg";
import img2 from "../assets/images/avatar/avt-11.jpg";
import img7 from "../assets/images/avatar/avt-2.jpg";
import img1 from "../assets/images/avatar/avt-3.jpg";
import img4 from "../assets/images/avatar/avt-5.jpg";
import img5 from "../assets/images/avatar/avt-7.jpg";
import img6 from "../assets/images/avatar/avt-8.jpg";
import imgdetail1 from "../assets/images/box-item/images-item-details2.jpg";
import LinkWithQueryParams from "../components/LinkWithQueryParams";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import LiveAuction from "../components/layouts/LiveAuction";

const ItemDetails02 = () => {
  const [dataHistory] = useState([
    {
      img: img1,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 HBAR",
      priceChange: "$12.246",
    },
    {
      img: img2,
      name: "Mason Woodward",
      time: "at 06/10/2021, 3:20 AM",
      price: "4.89 HBAR",
      priceChange: "$12.246",
    },
    {
      img: img3,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 HBAR",
      priceChange: "$12.246",
    },
    {
      img: img4,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 HBAR",
      priceChange: "$12.246",
    },
    {
      img: img5,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 HBAR",
      priceChange: "$12.246",
    },
    {
      img: img6,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 HBAR",
      priceChange: "$12.246",
    },
  ]);
  return (
    <div className="item-details">
      <Header />
      <div className="tf-section tf-item-details style-2">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="content-left">
                <div className="media">
                  <img src={imgdetail1} alt="Axies" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="content-right">
                <div className="sc-item-details">
                  <div className="meta-item">
                    <div className="left">
                      <h2>“The Pretty Fantasy Flower illustration ”</h2>
                    </div>
                    <div className="right">
                      <span className="viewed eye mg-r-8">225</span>
                      <span to="/login" className="liked heart wishlist-button">
                        <span className="number-like">100</span>
                      </span>
                    </div>
                  </div>
                  <div className="client-infor sc-card-product">
                    <div className="meta-info">
                      <div className="author">
                        <div className="avatar">
                          <img src={img6} alt="Axies" />
                        </div>
                        <div className="info">
                          <span>Owned By</span>
                          <h6>
                            {" "}
                            <LinkWithQueryParams path="/author-02">
                              Ralph Garraway
                            </LinkWithQueryParams>{" "}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="meta-info">
                      <div className="author">
                        <div className="avatar">
                          <img src={img7} alt="Axies" />
                        </div>
                        <div className="info">
                          <span>Create By</span>
                          <h6>
                            {" "}
                            <LinkWithQueryParams path="/author-02">
                              Freddie Carpenter
                            </LinkWithQueryParams>{" "}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p>
                    Habitant sollicitudin faucibus cursus lectus pulvinar dolor
                    non ultrices eget. Facilisi lobortisal morbi fringilla urna
                    amet sed ipsum vitae ipsum malesuada. Habitant sollicitudin
                    faucibus cursus lectus pulvinar dolor non ultrices eget.
                    Facilisi lobortisal morbi fringilla urna amet sed ipsum
                  </p>
                  <div className="meta-item-details">
                    <div className="item-style-2 item-details">
                      <ul className="list-details">
                        <li>
                          <span>Artist : </span>
                          <h6>Ralph Garraway</h6>{" "}
                        </li>
                        <li>
                          <span>Size : </span>
                          <h6>3000 x 3000</h6>{" "}
                        </li>
                        <li>
                          <span>Create : </span>
                          <h6>04 April , 2021</h6>{" "}
                        </li>
                        <li>
                          <span>Collection : </span>
                          <h6>Cyberpunk City Art</h6>{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="item-style-2">
                      <div className="item meta-price">
                        <span className="heading">Current Bid</span>
                        <div className="price">
                          <div className="price-box">
                            <h5> 4.89 HBAR</h5>
                            <span>= $12.246</span>
                          </div>
                        </div>
                      </div>
                      <div className="item count-down">
                        <Countdown date={Date.now() + 500000000}>
                          <span>You are good to go!</span>
                        </Countdown>
                      </div>
                    </div>
                  </div>
                  <LinkWithQueryParams
                    path="/wallet-connect"
                    className="sc-button loadmore style bag fl-button pri-3"
                  >
                    <span>Place a bid</span>
                  </LinkWithQueryParams>
                  <div className="flat-tabs themesflat-tabs">
                    <Tabs>
                      <TabList>
                        <Tab>Bid History</Tab>
                        <Tab>Info</Tab>
                        <Tab>Provenance</Tab>
                      </TabList>

                      <TabPanel>
                        <ul className="bid-history-list">
                          <li>
                            <div className="content">
                              <div className="client">
                                <div className="sc-author-box style-2">
                                  <div className="author-avatar">
                                    <LinkWithQueryParams path="#">
                                      <img
                                        src={img1}
                                        alt="Axies"
                                        className="avatar"
                                      />
                                    </LinkWithQueryParams>
                                    <div className="badge"></div>
                                  </div>
                                  <div className="author-infor">
                                    <div className="name">
                                      <h6>
                                        {" "}
                                        <LinkWithQueryParams path="/author-02">
                                          Mason Woodward{" "}
                                        </LinkWithQueryParams>
                                      </h6>{" "}
                                      <span> place a bid</span>
                                    </div>
                                    <span className="time">8 hours ago</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </TabPanel>
                      <TabPanel>
                        <div className="provenance">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged. It was popularised in the 1960s with the
                            release of Letraset sheets containing Lorem Ipsum
                            passages, and more recently with desktop publishing
                            software like Aldus PageMaker including versions of
                            Lorem Ipsum.
                          </p>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LiveAuction data={liveAuctionData} />
      <Footer />
    </div>
  );
};

export default ItemDetails02;
