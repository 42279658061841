import styled from "styled-components";

export const ForgotPasswordPage = styled.div`
  @media (max-width: 768px) {
    .tf-forgot-password.tf-section {
      background-color: white;
      min-height: 50vh;
    }

    .tf-forgot-password.tf-section .centered-content {
      width: 100%;
      background-color: white;
      padding: 10% 13%;

      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    .tf-forgot-password.tf-section .page-title-heading .heading {
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
    }

    .tf-forgot-password.tf-section .instructions-container {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      color: #000000;
    }

    .tf-forgot-password.tf-section .submit {
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 10px;
      text-align: center;
      color: #ffffff;
      padding: 10px 40px;
    }

    .form-inner{
      width: 100%;
    }
    #contactform{
      width: 100%;
    }

    .tf-forgot-password.tf-section .form-field-input {
      margin-bottom: 4px;
      width: 100%;
    }

    .tf-forgot-password.tf-section .form-field-input,
    .tf-forgot-password.tf-section .form-field-input::placeholder {
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
    }

    .tf-forgot-password.tf-section .error-message {
      font-size: 10px;
      line-height: 10px;
    }
  }
`;
