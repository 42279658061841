import { getHeaders } from "../Item/utils";
const { REACT_APP_API_URL } = process.env;

// Finds base items by collection id.
export const getBaseItemsByCollection = async (id) => {
  try {
    const { headers } = await getHeaders();

    const response = await fetch(
      `${REACT_APP_API_URL}item/base/collection/${id}`,
      {
        method: "GET",
        headers,
      }
    );

    return response.json();
  } catch (err) {
    console.error(err);
  }
};
