import React, { useEffect, useState } from "react";
import Collection05 from "../../components/collection/Collection05";
import Spinner from "../../components/layouts/Spinner/Spinner";

const CollectionByArtist = ({ userId, collections }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getAllCollections = async (userId) => {
    if (collections.length > 0) {
      setData(collections);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => await getAllCollections(userId))();
  }, [userId]);

  return (
    <div style={{ width: "100%" }}>
      <div className="row mb-5">
        {loading ? (
          <div className="col-md-12 flex justify-content-center">
            <Spinner />
          </div>
        ) : (
          <section className="flat-cart-item" style={{ width: "100%" }}>
            <Collection05
              data={collections}
              stop={6}
              measures={{ md: 4, sm: 12, lg: 4 }}
              header={false}
            />
          </section>
        )}
      </div>
    </div>
  );
};

export default CollectionByArtist;
