import React, { useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { notifyError } from "../../helpers/toastNotificationService";

const StyledTraitsList = styled.div`
  #rarity-traits-list {
    padding-top: 20px;
    border-top: 1px solid #cdcdcd;

    height: 250px;
    overflow-y: scroll;

    padding-bottom: 20px;
    border-bottom: 1px solid #cdcdcd;

    margin-bottom: 20px;
  }
  .form-subheader {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 20px;
  }
  .header-row {
    margin-bottom: 8px;
  }
  .values-row {
    margin-bottom: 4px;
    align-content: center;
    align-items: center;
  }
  .inputs-row {
    margin-bottom: 24px;
  }
  .header-row,
  .inputs-row,
  .values-row {
    display: flex;
    justify-content: flex-start;
  }
  .input-cell,
  .value-cell {
    flex: 1;
    padding-right: 2%;
  }
  .value-cell {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    text-transform: capitalize;
    color: #000000;
  }
  .header-cell {
    flex: 1;

    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-transform: capitalize;
    color: #000000;
  }
  .no-fill {
    background-color: transparent;
    border: none;
    padding: 0;

    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #000000;
  }
  .no-underline {
    text-decoration-line: none;
    font-size: 12px;
    margin-right: 12px;
    color: #e42c2c;
    font-weight: bold;
  }
`;

const TraitsList = ({ rarities, setRarities }) => {
  const removeRarity = (e, id) => {
    e.preventDefault();
    const actualRarities = JSON.parse(JSON.stringify(rarities));
    const index = actualRarities.findIndex((treat) => treat.id === id); //use id instead of index
    if (index > -1) {
      actualRarities.splice(index, 1);
      setRarities(actualRarities);
    }
  };

  const [value, setValue] = useState();
  const [type, setType] = useState();
  const [occurrence, setOccurrence] = useState();

  return (
    <StyledTraitsList>
      <div id="rarity-traits-list">
        <div className="form-subheader">Rarity/Traits List</div>
        <div className="header-row">
          <div className="header-cell">Type</div>
          <div className="header-cell">Value</div>
          <div className="header-cell">Occurrence</div>
        </div>
        {rarities?.length > 0 &&
          rarities.map((rarity) => (
            <div className="values-row" key={"rarity" + rarity.id}>
              <button
                className="no-fill no-underline"
                onClick={(e) => removeRarity(e, rarity.id)}
              >
                x
              </button>
              <div className="value-cell">{rarity.type}</div>
              <div className="value-cell">{rarity.value}</div>
              <div className="value-cell">{rarity.occurrence}</div>
            </div>
          ))}
        <div className="inputs-row">
          <div className="input-cell">
            <input
              name="type"
              type="text"
              max={100}
              placeholder="Background"
              onChange={(event) => setType(event.target.value)}
            />
          </div>
          <div className="input-cell">
            <input
              name="value"
              type="text"
              max={100}
              placeholder="Red"
              onChange={(event) => setValue(event.target.value)}
            />
          </div>
          <div className="input-cell">
            <input
              name="occurrence"
              type="text"
              max={100}
              placeholder="50"
              onChange={(event) => setOccurrence(event.target.value)}
            />
          </div>
        </div>
        <button
          className="no-fill"
          onClick={(e) => {
            e.preventDefault();
            if (
              typeof Number(occurrence) === "number" &&
              !Number.isNaN(Number(occurrence))
            ) {
              const newRarity = {
                id: uuidv4(),
                type,
                value,
                occurrence: Number(occurrence),
              };
              setRarities([...rarities, newRarity]);
            } else {
              notifyError("Occurrence must be a number");
            }
          }}
        >
          + Rarity/traits
        </button>
      </div>
    </StyledTraitsList>
  );
};

export default TraitsList;
