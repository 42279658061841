export const todaysPicksData = [
  {
    name: "name of work",
    description: "name of artist",
    // https://www.pexels.com/photo/dude-with-sign-11798029/
    image: "https://live.staticflickr.com/65535/52392375775_bff5cb8c92.jpg",
    id: "6310cfeda4bb0b6ddd4f2da7",
    cid: "ipfs://bafyreienhgo2iektbsac55spibucurtiihljwxxuo2mqhx67gjri4g2h4e/metadata.json",
  },
  {
    name: "name of work",
    description: "name of artist",
    // https://www.pexels.com/photo/infinity-symbol-9999715/
    image: "https://live.staticflickr.com/65535/52391948531_974667347d.jpg",
    id: "62ffcb18674f70f55af3c95a",
    cid: "ipfs://bafyreihee36defislkf23bxsov56kruya3go3egdndbgcb6kvremcttjai/metadata.json",
  },
];

export const placesData = [
  {
    customURL: "juan1",
    name: "name of work",
    description: "name of artist",
    image: "https://live.staticflickr.com/65535/52392573385_89402de0d9_k.jpg",
    id: "6310cfeda4bb0b6ddd4f2dae",
    cid: "ipfs://bafyreienhgo2iektbsac55spibucurtiihljwxxuo2mqhx67gjri4g2h4e/metadata.json",
  },
  {
    customURL: "jjcrump",
    name: "name of work",
    description: "name of artist",
    image: "https://live.staticflickr.com/65535/52392442234_4439352b66.jpg",
    id: "62ffcb18674f70f55af3c951",
    cid: "ipfs://bafyreihee36defislkf23bxsov56kruya3go3egdndbgcb6kvremcttjai/metadata.json",
  },
  {
    customURL: "leoda",
    name: "name of work",
    description: "name of artist",
    image: "https://live.staticflickr.com/65535/52392132001_180f932fe5.jpg",
    id: "62ffcb18674f70f55af3c952",
    cid: "ipfs://bafyreihee36defislkf23bxsov56kruya3go3egdndbgcb6kvremcttjai/metadata.json",
  },
  {
    customURL: "cellcius",
    name: "name of work",
    description: "name of artist",
    image: "https://live.staticflickr.com/65535/52392135556_ede020a189.jpg",
    id: "62ffcb18674f70f55af3c953",
    cid: "ipfs://bafyreihee36defislkf23bxsov56kruya3go3egdndbgcb6kvremcttjai/metadata.json",
  },
  {
    customURL: "juan1",
    name: "name of work",
    description: "name of artist",
    image: "https://live.staticflickr.com/65535/52392573385_89402de0d9_k.jpg",
    id: "6310cfeda4bb0b6ddd4f2da4",
    cid: "ipfs://bafyreienhgo2iektbsac55spibucurtiihljwxxuo2mqhx67gjri4g2h4e/metadata.json",
  },
  {
    customURL: "jjcrump",
    name: "name of work",
    description: "name of artist",
    image: "https://live.staticflickr.com/65535/52392442234_4439352b66.jpg",
    id: "62ffcb18674f70f55af3c955",
    cid: "ipfs://bafyreihee36defislkf23bxsov56kruya3go3egdndbgcb6kvremcttjai/metadata.json",
  },
  {
    customURL: "leoda",
    name: "name of work",
    description: "name of artist",
    image: "https://live.staticflickr.com/65535/52392132001_180f932fe5.jpg",
    id: "62ffcb18674f70f55af3c956",
    cid: "ipfs://bafyreihee36defislkf23bxsov56kruya3go3egdndbgcb6kvremcttjai/metadata.json",
  },
  {
    customURL: "cellcius",
    name: "name of work",
    description: "name of artist",
    image: "https://live.staticflickr.com/65535/52392135556_ede020a189.jpg",
    id: "62ffcb18674f70f55af3c957",
    cid: "ipfs://bafyreihee36defislkf23bxsov56kruya3go3egdndbgcb6kvremcttjai/metadata.json",
  },
  {
    customURL: "juan1",
    name: "name of work",
    description: "name of artist",
    image: "https://live.staticflickr.com/65535/52392573385_89402de0d9_k.jpg",
    id: "6310cfeda4bb0b6ddd4f2da8",
    cid: "ipfs://bafyreienhgo2iektbsac55spibucurtiihljwxxuo2mqhx67gjri4g2h4e/metadata.json",
  },
  {
    customURL: "jjcrump",
    name: "name of work",
    description: "name of artist",
    image: "https://live.staticflickr.com/65535/52392442234_4439352b66.jpg",
    id: "62ffcb18674f70f55af3c959",
    cid: "ipfs://bafyreihee36defislkf23bxsov56kruya3go3egdndbgcb6kvremcttjai/metadata.json",
  },
  {
    customURL: "leoda",
    name: "name of work",
    description: "name of artist",
    image: "https://live.staticflickr.com/65535/52392132001_180f932fe5.jpg",
    id: "62ffcb18674f70f55af3c9510",
    cid: "ipfs://bafyreihee36defislkf23bxsov56kruya3go3egdndbgcb6kvremcttjai/metadata.json",
  },
  {
    customURL: "cellcius",
    name: "name of work",
    description: "name of artist",
    image: "https://live.staticflickr.com/65535/52392135556_ede020a189.jpg",
    id: "62ffcb18674f70f55af3c9511",
    cid: "ipfs://bafyreihee36defislkf23bxsov56kruya3go3egdndbgcb6kvremcttjai/metadata.json",
  },
];
export const knownCids = [
  "ipfs://bafyreiggjkpgldw2dbas47ovxpfht5ywr2wx6ninkgelab2qbmm7dgip6u/metadata.json",
  "ipfs://bafyreidxsc47hbq3wr3b6j3qev5oddnfs4vr74sknwcsezktsrvu6lzes4/metadata.json",
  "ipfs://bafyreif6hoeb7j5vui34f5nk7deuyysk5saidivxvewtzeqpyg24ul5cie/metadata.json",
  "ipfs://bafyreidgj3zyki2brs62mbhacdhccosxrubbc66vdsn5hsg6ua5xlgbbta/metadata.json",
];
export const knownProfileFileName = "87cba95a-dd2e-4fe6-abb2-68233e4c968d.png";

export const popularData = [
  {
    artist: {
      id: "62f56bc715bdff2ddb0df978",
      displayName: "JJ Crump",
      userId: "62f56bc715bdff2ddb0df977",
    },
    cids: [
      "ipfs://bafyreiggjkpgldw2dbas47ovxpfht5ywr2wx6ninkgelab2qbmm7dgip6u/metadata.json",
    ],
    createdAt: "2022-08-19T22:14:30.769Z",
    description: "111",
    id: "63000b46626bc86b465c4ec12",
    isAssociated: false,
    liked: false,
    numberOfLikes: 0,
    tokenId: "0.0.47941597",
    tokenName: "123",
    tokenSymbol: "111",
  },
  {
    artist: {
      id: "62f56bc715bdff2ddb0df9713",
      displayName: "JJ Crump",
      userId: "62f56bc715bdff2ddb0df977",
    },
    cids: [
      "ipfs://bafyreiggjkpgldw2dbas47ovxpfht5ywr2wx6ninkgelab2qbmm7dgip6u/metadata.json",
    ],
    createdAt: "2022-08-19T22:14:30.769Z",
    description: "111",
    id: "63000b46626bc86b465c4ece",
    isAssociated: false,
    liked: false,
    numberOfLikes: 0,
    tokenId: "0.0.47941597",
    tokenName: "Test Collection",
    tokenSymbol: "111",
  },

  {
    artist: {
      id: "62f56bc715bdff2ddb0df97",
      displayName: "JJ Crump",
      userId: "62f56bc715bdff2ddb0df977",
    },
    cids: [
      "ipfs://bafyreiggjkpgldw2dbas47ovxpfht5ywr2wx6ninkgelab2qbmm7dgip6u/metadata.json",
    ],
    createdAt: "2022-08-19T22:14:30.769Z",
    description: "111",
    id: "63000b46626bc86b465c4ece14",
    isAssociated: true,
    liked: false,
    numberOfLikes: 0,
    tokenId: "0.0.47941597",
    tokenName:
      "This is a brand new Quintin Collection for the pleasure of the collector",
    tokenSymbol: "111",
  },
  {
    artist: {
      id: "62f56bc715bdff2ddb0df978",
      displayName: "JJ Crump",
      userId: "62f56bc715bdff2ddb0df977",
    },
    cids: [
      "ipfs://bafyreiggjkpgldw2dbas47ovxpfht5ywr2wx6ninkgelab2qbmm7dgip6u/metadata.json",
    ],
    createdAt: "2022-08-19T22:14:30.769Z",
    description: "111",
    id: "63000b46626bc86b465c4ece15",
    isAssociated: false,
    liked: false,
    numberOfLikes: 0,
    tokenId: "0.0.47941597",
    tokenName: "123",
    tokenSymbol: "111",
  },
  {
    artist: {
      id: "62f56bc715bdff2ddb0df978",
      displayName: "JJ Crump",
      userId: "62f56bc715bdff2ddb0df977",
    },
    cids: [
      "ipfs://bafyreiggjkpgldw2dbas47ovxpfht5ywr2wx6ninkgelab2qbmm7dgip6u/metadata.json",
    ],
    createdAt: "2022-08-19T22:14:30.769Z",
    description: "111",
    id: "63000b46626bc86b465c4ece16",
    isAssociated: false,
    liked: false,
    numberOfLikes: 0,
    tokenId: "0.0.47941597",
    tokenName: "Test Collection",
    tokenSymbol: "111",
  },

  {
    artist: {
      id: "62f56bc715bdff2ddb0df978",
      displayName: "JJ Crump",
      userId: "62f56bc715bdff2ddb0df977",
    },
    cids: [
      "ipfs://bafyreiggjkpgldw2dbas47ovxpfht5ywr2wx6ninkgelab2qbmm7dgip6u/metadata.json",
    ],
    createdAt: "2022-08-19T22:14:30.769Z",
    description: "111",
    id: "63000b46626bc86b465c4ece17",
    isAssociated: true,
    liked: false,
    numberOfLikes: 0,
    tokenId: "0.0.47941597",
    tokenName:
      "This is a brand new Quintin Collection for the pleasure of the collector",
    tokenSymbol: "111",
  },
  {
    artist: {
      id: "62f56bc715bdff2ddb0df978",
      displayName: "JJ Crump",
      userId: "62f56bc715bdff2ddb0df977",
    },
    cids: [
      "ipfs://bafyreiggjkpgldw2dbas47ovxpfht5ywr2wx6ninkgelab2qbmm7dgip6u/metadata.json",
    ],
    createdAt: "2022-08-19T22:14:30.769Z",
    description: "111",
    id: "63000b46626bc86b465c4ece18",
    isAssociated: false,
    liked: false,
    numberOfLikes: 0,
    tokenId: "0.0.47941597",
    tokenName: "123",
    tokenSymbol: "111",
  },
  {
    artist: {
      id: "62f56bc715bdff2ddb0df978",
      displayName: "JJ Crump",
      userId: "62f56bc715bdff2ddb0df977",
    },
    cids: [
      "ipfs://bafyreiggjkpgldw2dbas47ovxpfht5ywr2wx6ninkgelab2qbmm7dgip6u/metadata.json",
    ],
    createdAt: "2022-08-19T22:14:30.769Z",
    description: "111",
    id: "63000b46626bc86b465c4ece19",
    isAssociated: false,
    liked: false,
    numberOfLikes: 0,
    tokenId: "0.0.47941597",
    tokenName: "Test Collection",
    tokenSymbol: "111",
  },

  {
    artist: {
      id: "62f56bc715bdff2ddb0df978",
      displayName: "JJ Crump",
      userId: "62f56bc715bdff2ddb0df977",
    },
    cids: [
      "ipfs://bafyreiggjkpgldw2dbas47ovxpfht5ywr2wx6ninkgelab2qbmm7dgip6u/metadata.json",
    ],
    createdAt: "2022-08-19T22:14:30.769Z",
    description: "111",
    id: "63000b46626bc86b465c4ece20",
    isAssociated: true,
    liked: false,
    numberOfLikes: 0,
    tokenId: "0.0.47941597",
    tokenName:
      "This is a brand new Quintin Collection for the pleasure of the collector",
    tokenSymbol: "111",
  },
];

// on toptrending
// import { topTrendingData } from "../../constants/fakeData";
// setValue(topTrendingData);

// on trending card
// const element = card;
// const user = card;
// const { getData } = useIPFS();

export const topTrendingData = [
  {
    baseItemId: "63353b916b9fd0b2ed8142ea",
    belongsToId: "6310cfeda4bb0b6ddd4f2da7",
    cid: "ipfs://bafyreienhgo2iektbsac55spibucurtiihljwxxuo2mqhx67gjri4g2h4e/metadata.json",
    claimed: true,
    collectionId: "62f41c6e15bdff2ddb0df961",
    id: "62f41c7815bdff2ddb0df9631",
    liked: false,
    numberOfLikes: 0,
    originalOwnerId: "62e62f8735887fe37ca25523",
    price: 123,
    saved: false,
    serial: 1,
    status: "FOR_SALE",
    walletIdForMoney: "0.0.47545145",
    image: "https://live.staticflickr.com/65535/52392573385_89402de0d9_k.jpg",
    name: "name of work",
    displayName: "name of artist",
  },
  {
    baseItemId: "63353b916b9fd0b2ed8142ea",
    belongsToId: "6310cfeda4bb0b6ddd4f2da7",
    cid: "ipfs://bafyreihee36defislkf23bxsov56kruya3go3egdndbgcb6kvremcttjai/metadata.json",
    claimed: true,
    collectionId: "62f41c6e15bdff2ddb0df961",
    id: "62f41c7815bdff2ddb0df9632",
    liked: false,
    numberOfLikes: 0,
    originalOwnerId: "62e62f8735887fe37ca25523",
    price: 123,
    saved: false,
    serial: 1,
    status: "FOR_SALE",
    walletIdForMoney: "0.0.47545145",
    image: "https://live.staticflickr.com/65535/52392442234_4439352b66.jpg",
    name: "name of work2",
    displayName: "name of artist2",
  },
  {
    baseItemId: "63353b916b9fd0b2ed8142ea",
    belongsToId: "6310cfeda4bb0b6ddd4f2da7",
    cid: "ipfs://bafyreidh2cq73gdxgk6usvhrv3r7iszyjbwuhsdzae354r74y3m24w4eti/metadata.json",
    claimed: true,
    collectionId: "62f41c6e15bdff2ddb0df961",
    id: "62f41c7815bdff2ddb0df9633",
    liked: false,
    numberOfLikes: 0,
    originalOwnerId: "62e62f8735887fe37ca25523",
    price: 123,
    saved: false,
    serial: 1,
    status: "FOR_SALE",
    walletIdForMoney: "0.0.47545145",
    image: "https://live.staticflickr.com/65535/52392132001_180f932fe5.jpg",
  },
  {
    baseItemId: "63353b916b9fd0b2ed8142ea",
    belongsToId: "6310cfeda4bb0b6ddd4f2da7",
    cid: "ipfs://bafyreif3ste4yvppbdjps63v7ejokyijwh5elflzwa7ws4cwotkls62olu/metadata.json",
    claimed: true,
    collectionId: "62f41c6e15bdff2ddb0df961",
    id: "62f41c7815bdff2ddb0df9634",
    liked: false,
    numberOfLikes: 0,
    originalOwnerId: "62e62f8735887fe37ca25523",
    price: 123,
    saved: false,
    serial: 1,
    status: "FOR_SALE",
    walletIdForMoney: "0.0.47545145",
    image: "https://live.staticflickr.com/65535/52392135556_ede020a189.jpg",
  },
  {
    baseItemId: "63353b916b9fd0b2ed8142ea",
    belongsToId: "6310cfeda4bb0b6ddd4f2da7",
    cid: "ipfs://bafyreif3ste4yvppbdjps63v7ejokyijwh5elflzwa7ws4cwotkls62olu/metadata.json",
    claimed: true,
    collectionId: "62f41c6e15bdff2ddb0df961",
    id: "62f41c7815bdff2ddb0df9635",
    liked: false,
    numberOfLikes: 0,
    originalOwnerId: "62e62f8735887fe37ca25523",
    price: 123,
    saved: false,
    serial: 1,
    status: "FOR_SALE",
    walletIdForMoney: "0.0.47545145",
    image: "https://live.staticflickr.com/65535/52391948531_974667347d.jpg",
    name: "name of work5",
    displayName: "name of artist5",
  },
  {
    baseItemId: "63353b916b9fd0b2ed8142ea",
    belongsToId: "6310cfeda4bb0b6ddd4f2da7",
    cid: "ipfs://bafyreienhgo2iektbsac55spibucurtiihljwxxuo2mqhx67gjri4g2h4e/metadata.json",
    claimed: true,
    collectionId: "62f41c6e15bdff2ddb0df961",
    id: "62f41c7815bdff2ddb0df96310",
    liked: false,
    numberOfLikes: 0,
    originalOwnerId: "62e62f8735887fe37ca25523",
    price: 123,
    saved: false,
    serial: 1,
    status: "FOR_SALE",
    walletIdForMoney: "0.0.47545145",
    image: "https://live.staticflickr.com/65535/52392573385_89402de0d9_k.jpg",
    name: "name of work",
    displayName: "name of artist",
  },
  {
    baseItemId: "63353b916b9fd0b2ed8142ea",
    belongsToId: "6310cfeda4bb0b6ddd4f2da7",
    cid: "ipfs://bafyreihee36defislkf23bxsov56kruya3go3egdndbgcb6kvremcttjai/metadata.json",
    claimed: true,
    collectionId: "62f41c6e15bdff2ddb0df961",
    id: "62f41c7815bdff2ddb0df963211",
    liked: false,
    numberOfLikes: 0,
    originalOwnerId: "62e62f8735887fe37ca25523",
    price: 123,
    saved: false,
    serial: 1,
    status: "FOR_SALE",
    walletIdForMoney: "0.0.47545145",
    image: "https://live.staticflickr.com/65535/52392442234_4439352b66.jpg",
    name: "name of work2",
    displayName: "name of artist2",
  },
  {
    baseItemId: "63353b916b9fd0b2ed8142ea",
    belongsToId: "6310cfeda4bb0b6ddd4f2da7",
    cid: "ipfs://bafyreidh2cq73gdxgk6usvhrv3r7iszyjbwuhsdzae354r74y3m24w4eti/metadata.json",
    claimed: true,
    collectionId: "62f41c6e15bdff2ddb0df961",
    id: "62f41c7815bdff2ddb0df963312",
    liked: false,
    numberOfLikes: 0,
    originalOwnerId: "62e62f8735887fe37ca25523",
    price: 123,
    saved: false,
    serial: 1,
    status: "FOR_SALE",
    walletIdForMoney: "0.0.47545145",
    image: "https://live.staticflickr.com/65535/52392132001_180f932fe5.jpg",
  },
  {
    baseItemId: "63353b916b9fd0b2ed8142ea",
    belongsToId: "6310cfeda4bb0b6ddd4f2da7",
    cid: "ipfs://bafyreif3ste4yvppbdjps63v7ejokyijwh5elflzwa7ws4cwotkls62olu/metadata.json",
    claimed: true,
    collectionId: "62f41c6e15bdff2ddb0df961",
    id: "62f41c7815bdff2ddb0df963413",
    liked: false,
    numberOfLikes: 0,
    originalOwnerId: "62e62f8735887fe37ca25523",
    price: 123,
    saved: false,
    serial: 1,
    status: "FOR_SALE",
    walletIdForMoney: "0.0.47545145",
    image: "https://live.staticflickr.com/65535/52392135556_ede020a189.jpg",
  },
  {
    baseItemId: "63353b916b9fd0b2ed8142ea",
    belongsToId: "6310cfeda4bb0b6ddd4f2da7",
    cid: "ipfs://bafyreif3ste4yvppbdjps63v7ejokyijwh5elflzwa7ws4cwotkls62olu/metadata.json",
    claimed: true,
    collectionId: "62f41c6e15bdff2ddb0df961",
    id: "62f41c7815bdff2ddb0df963514",
    liked: false,
    numberOfLikes: 0,
    originalOwnerId: "62e62f8735887fe37ca25523",
    price: 123,
    saved: false,
    serial: 1,
    status: "FOR_SALE",
    walletIdForMoney: "0.0.47545145",
    image: "https://live.staticflickr.com/65535/52391948531_974667347d.jpg",
    name: "name of work5",
    displayName: "name of artist5",
  },
];

export const activitiesData = [
  {
    id: "123",
    nameOfWork: "Arrival",
    artistName: "artist 1",
    price: 255,
    likes: 25,
    status: "sold",
    image: "https://live.staticflickr.com/65535/52392375775_bff5cb8c92.jpg",
    time: "0s ago",
    from: "Esao Andrews",
    to: "Oscar Rosete",
    quantity: 1,
    productId: "636907d3f03e9dd445f1b44a",
  },
  {
    id: "1234",
    nameOfWork: "Wino",
    artistName: "artist 2",
    price: 355,
    likes: 35,
    status: "sale",
    image: "https://live.staticflickr.com/65535/52392375775_bff5cb8c92.jpg",
    time: "0s ago",
    from: "Esao Andrews",
    to: "Alonso Beas",
    quantity: 2,
    productId: "63690798f03e9dd445f1b449",
  },
  {
    id: "12345",
    nameOfWork: "Dangling Hearts",
    artistName: "artist 3",
    price: 455,
    likes: 45,
    status: "banned",
    image: "https://live.staticflickr.com/65535/52392375775_bff5cb8c92.jpg",
    time: "0s ago",
    from: "Esao Andrews",
    to: "Oscar Rosete",
    quantity: 3,
    productId: "636907d3f03e9dd445f1b44a",
  },
];
