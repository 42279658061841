import React from "react";
import { useForm } from "react-hook-form";
import Layout from "../../components/layouts/Layout";
import ContactUsForm from "./ContactUsForm";
import { StyledContactUsPage } from "./styles";
const ContactUs = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  return (
    <Layout>
      <StyledContactUsPage>
        <div id="spacer"></div>

        <section id="contact-us">
          <div className="header-container">
            <div className="page-title-heading">
              <div className="vertical-line"></div>
              <h1 className="heading">Contact Us</h1>
            </div>
          </div>

          <div className="row m-0">
            <div className="salutation">Hiya! We want to hear from you</div>
          </div>
          <div className="row m-0">
            <div className="indications">
              Filling out this form helps us get back to you quickly. Keeping it
              short makes us like you even more.
            </div>
          </div>
          <ContactUsForm
            handleSubmit={handleSubmit}
            register={register}
            errors={errors}
            control={control}
          />
        </section>
      </StyledContactUsPage>
    </Layout>
  );
};

export default ContactUs;
