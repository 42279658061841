const { REACT_APP_API_URL } = process.env;

const getUserTopSeller = async () => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}user/top/sellers`);
    return response.json();
  } catch (error) {
    console.log(error);
  }
};
export default getUserTopSeller;
