import React from 'react';
import { useNavigate } from 'react-router-dom';

const InfoAfterShip = () => {
  const navigate = useNavigate();
  return (
    <div className='modal-body space-y-20 pd-40'>
      <h3> Order Created Successfully!</h3>

      <div className='hr'></div>

      <p>You will receive an email with the status of your order</p>

      <br />

      <button className='btn btn-primary' onClick={() => navigate('/my-items')}>
        <span>Go to items</span>
      </button>
    </div>
  );
};

export default InfoAfterShip;
