import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import {
  CONVERTED_PRICE_ENABLED,
  HBAR_CURRENCY_TYPE,
  REACT_APP_API_URL,
  REACT_APP_HEDERA_ESCROW_ACCOUNT_ID,
  REQUIRED_MESSAGE,
} from "../../../constants/constants";
import { notifyError } from "../../../helpers/toastNotificationService";
import { useApi } from "../../../hooks/useApi";
import useHashconnect from "../../../hooks/useHashconnect";
import { generateHmac } from "../../../utils/sha256Encription";

const SellModalForm = ({
  itemId,
  handleSubmit,
  setLoading,
  register,
  control,
  errors,
  walletId,
  item,
  collectionData,
  user,
  transferToEscrow,
}) => {
  const { executeApiRequestTwo } = useApi();
  const { transferNFTWithHashconnect } = useHashconnect();

  const wantsToSell = async ({
    itemId,
    currencyType,
    startDate,
    endDate,
    price,
  }) => {
    try {
      const walletId = user?.wallets?.walletDetails?.id;
      const hashpackWalletId = user?.hashpackWallets?.accountIds?.[0];

      let response = { statusCode: 200 };
      const { hString, epochTimestamp } = generateHmac({
        userId: user.id,
        id: itemId,
      });
      const requestBody = {
        currencyType,
        startDate,
        endDate,
        price,
        hString,
        epochTimestamp,
      };
      const url = `${REACT_APP_API_URL}item/wants-to-sell/${itemId}`;
      const options = {
        headers: { "Content-Type": "application/json" },
        method: "PATCH",
        body: JSON.stringify(requestBody),
      };

      let transferResponse = {};
      if (hashpackWalletId) {
        const hashpackResponse = await transferNFTWithHashconnect({
          toAccount: REACT_APP_HEDERA_ESCROW_ACCOUNT_ID,
          tokenId: collectionData?.tokenId,
          serialNumber: item?.serial,
          user,
        });
        if (!hashpackResponse.success) {
          transferResponse.error = true;
        }
      }

      if (walletId && !hashpackWalletId) {
        transferResponse = await transferToEscrow({
          walletId,
          tokenId: item?.serial,
          to: REACT_APP_HEDERA_ESCROW_ACCOUNT_ID,
          tokenAddress: collectionData?.tokenId,
        });
      }

      if (transferResponse?.error) {
        response = {
          error: true,
        };
      } else {
        response = await executeApiRequestTwo(url, options);
      }
      return response;
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const requestBody = {
      itemId,
      currencyType: HBAR_CURRENCY_TYPE,
      startDate: data?.startDate.toISOString(),
      endDate: data?.endDate.toISOString(),
      price: data?.hbarPrice,
    };
    try {
      const response = await wantsToSell(requestBody);
      if (response?.statusCode === 400 && response?.message[0]) {
        notifyError(response.message[0]);
      } else if (response?.error) {
        /* transfer to escrow aborted or popup blocked */
      } else {
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const todayDate = new Date();
  const sixMonthsDate = moment().add(6, "M").toDate();
  return (
    <div className="form-inner">
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="mb-2">
          <h6 className="form-field-label">Sale Start Date </h6>
          <Controller
            control={control}
            name="startDate"
            rules={{
              required: REQUIRED_MESSAGE,
            }}
            defaultValue={todayDate}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                placeholderText="Select date"
                selected={value}
                onChange={(date) => onChange(date)}
              />
            )}
          />
          <p className="error-message">{errors.startDate?.message}</p>
        </fieldset>

        <fieldset className="mb-2">
          <h6 className="form-field-label">Sale End Date </h6>

          <Controller
            control={control}
            name="endDate"
            rules={{
              required: REQUIRED_MESSAGE,
            }}
            defaultValue={sixMonthsDate}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                placeholderText="Select date"
                selected={value}
                onChange={(date) => onChange(date)}
              />
            )}
          />
        </fieldset>

        <div className="row m-0 justify-content-between mb-4">
          <fieldset className="w-100">
            <h6 className="form-field-label">Price</h6>
            <div className="price-input-container">
              <div className="currency-label">HBAR</div>
              <input
                id="hbarPrice"
                name="hbarPrice"
                tabIndex="1"
                aria-required="true"
                required
                type="text"
                placeholder="00"
                className="form-field-input"
                {...register("hbarPrice", {
                  required: REQUIRED_MESSAGE,
                })}
              />
            </div>
            <p className="error-message">{errors.hbarPrice?.message}</p>
          </fieldset>

          {CONVERTED_PRICE_ENABLED && (
            <fieldset className="fieldset-half">
              <h6 className="form-field-label">Converted Price</h6>
              <input
                id="convertedPrice"
                name="convertedPrice"
                tabIndex="1"
                disabled
                type="text"
                placeholder="00"
                className="form-field-input"
              />
            </fieldset>
          )}
        </div>
        <div className="escrow-note">
          ACME LABS™ will be transferring the item to an escrow account while on
          sale.
        </div>
        <div className="fees-container">
          <p className="fee-notes">
            The following fees are applicable for this item:
          </p>
          <div className="fee-note-row">
            <div className="fee-label">Listing fee:</div>
            <div className="fee-price">0 HBAR</div>
          </div>
          <div className="fee-note-row">
            <div className="fee-label">Sale Transaction Fee:</div>
            <div className="fee-price">2.5%</div>
          </div>
          <div className="fee-note-row">
            <div className="fee-label">Royalty Fee:</div>
            {"royaltyFee" in collectionData && (
              <div className="fee-price">{collectionData.royaltyFee}%</div>
            )}
          </div>
          <div className="fee-note-row">
            <div className="fee-label">Return Fee:</div>
            <div className="fee-price">0.5 HBAR</div>
          </div>
          <p className="fee-notes">
            (If you decide to remove the listing before the item is purchased)
          </p>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button className="filled-button">Confirm "For Sale" Status</button>
        </div>

        <div className="status-modification-note">
          You can change or cancel the “For Sale” status at any time.
        </div>
      </form>
    </div>
  );
};

export default SellModalForm;
