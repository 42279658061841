import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getBaseItem } from "../../api/Item/baseItems";
import getItem from "../../api/Item/getItem";
import {
  getProductAddons,
  getProductById,
} from "../../api/products/getProducts";
import Layout from "../../components/layouts/Layout";
import Spinner from "../../components/layouts/Spinner/Spinner";
import useIPFS from "../../hooks/useIPFS";
import Filters from "../Explore/Filters";
import ProductOptionsModal from "./ProductOptionsModal/ProductOptionsModal";
import PurchaseOption from "./PurchaseOption";
import { StyledAdditionalPurchaseOptionsPage } from "./styles";

const AdditionalPurchaseOptions = () => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const { getData, element, convertIPFSUrl } = useIPFS();
  const [item, setitem] = useState();
  const [showProductOptions, setShowProductOptions] = useState();
  const [selectedAddon, setSelectedAddon] = useState();
  const { itemId } = useParams();

  const getAssociatedAddons = async ({ itemData }) => {
    const productId = itemData?.productId;
    const productData = await getProductById({ productId });
    const addons = await getProductAddons();

    const filteredAddons = addons.filter((addon) => {
      return productData?.addons.includes(addon.id);
    });

    setOptions(filteredAddons);
  };

  const getItemById = async ({ itemId }) => {
    const [baseItemData, itemData] = await Promise.all([
      getBaseItem(itemId),
      getItem(itemId),
    ]);

    let itemPurchaseData = baseItemData?.id ? baseItemData : itemData;
    await getData(
      itemPurchaseData?.finalCid
        ? itemPurchaseData?.finalCid
        : itemPurchaseData.cid
    );

    await getAssociatedAddons({ itemData: itemPurchaseData });

    setitem(itemPurchaseData);
  };

  const handleCloseModal = () => {
    setShowProductOptions(false);
  };

  const onChangeFilters = (obj) => {
    const selectedValue = obj.value;
    let filteredItems = [];
    switch (selectedValue) {
    }
  };

  useEffect(() => {
    itemId && getItemById({ itemId });
    setLoading(false);
  }, [itemId]);

  return (
    <Layout>
      <StyledAdditionalPurchaseOptionsPage>
        <div id="spacer"></div>

        <section id="additional-purchase-options">
          <div className="header-container">
            <div className="page-title-heading">
              <div className="vertical-line"></div>
              <h1 className="heading">Additional Purchase Options</h1>
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-12">
            <Filters
              categories={false}
              blockchains={false}
              vaultStorage={true}
              action={onChangeFilters}
            />
          </div>

          <div className="col-xl-9 col-lg-9 col-md-12">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "400px",
                  alignItems: "center",
                }}
              >
                <Spinner />
              </div>
            ) : (
              <div className="purchase-options">
                {options.map((option) => {
                  return (
                    <PurchaseOption
                      option={option}
                      item={item}
                      element={element}
                      key={"purchase-" + option?.id}
                      onClick={() => {
                        setShowProductOptions(true);
                        setSelectedAddon(option?.id);
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </section>
        <ProductOptionsModal
          show={showProductOptions}
          handleCloseModal={handleCloseModal}
          selectedAddon={selectedAddon}
          itemId={itemId}
        />
      </StyledAdditionalPurchaseOptionsPage>
    </Layout>
  );
};

export default AdditionalPurchaseOptions;
