import styled from "styled-components";
import { COMMON_STYLES } from "../../constants/genericStyles";
const titleFontSize = "44px";
const mobileTitleFontSize = "20px";

export const StyledCart = styled.div`
  padding: 135px 0;
  padding-top: 0;
  .themesflat-container {
    padding: 0px;
  }

  .tf-section {
    padding: 0;
  }
  .no-items-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
  }

  .sc-card-activity.style-2 .content .media {
    align-items: flex-start;
  }
  .sc-card-activity.style-2 .content {
    width: auto;
    flex: 1;
  }

  .page-title-heading {
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
    padding: 0px 60px;
    padding-bottom: 35px;

    border-bottom: 1px solid #cdcdcd !important;
  }

  .header-container {
    width: 1440px;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .page-title-heading .vertical-line {
    margin-right: 10px;
    border-left: 13px solid #ffd061;
    height: ${titleFontSize};
    display: inline-block;
  }

  .page-title-heading .heading {
    /* color: var(--header-background); */

    font-weight: 700;
    font-size: ${titleFontSize};
    line-height: ${titleFontSize};
    color: #011126;
  }

  .price-2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
    text-align: right;
    margin-bottom: 20px;
  }

  .total-label,
  .shipping-label,
  .tax-label,
  .subtotal-label {
    color: #2159a2;
  }

  .total-container {
    width: 100%;
  }

  .faint-line {
    border: none;
    border-top: 1px solid #cdcdcd;
  }

  .bottom-section {
    display: flex;
    justify-content: space-between;
    .left-spacer {
      width: 50%;
    }
  }

  .right-section {
    width: 30%;
  }
  .right-section .d-flex {
    width: 100%;
  }
  .action-buttons {
    margin-top: 20px;
    div {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 29px;
    }
    .filled-button {
      ${COMMON_STYLES.FILLED_BUTTON({
        fillColor: "#ff7b7c",
        borderSize: "10px",
      })}
      width: 100%;
      padding: 15px;
      font-size: 24px;
      line-height: 24px;
    }

    .outlined-button {
      ${COMMON_STYLES.OUTLINED_BUTTON({
        textColor: "#ff7b7c",
        borderSize: "10px",
      })}
      width: 100%;
      padding: 15px;
      font-size: 24px;
      line-height: 24px;
    }

    .button__bottom {
      ${COMMON_STYLES.BOTTOM_TOP_ANIMATION({
        finalButtonFill: "#ff7b7c",
        initialButtonFill: "transparent",
      })}
    }
  }

  .wallet-connected {
    width: 37%;
    background: #ffffff;
    border: 1px solid #cdcdcd;
    box-shadow: 4px 4px 6px 2px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 30px 60px;
  }
  .wallet-to-connect {
    width: 50%;
    background: #ffffff;
    border: 1px solid #cdcdcd;
    box-shadow: 4px 4px 6px 2px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding: 30px 60px;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

    a {
      color: #1b70d0;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
    }

    ${COMMON_STYLES.UNDERLINE_ANIMATION({ color: "#1b70d0" })}
  }

  .wallet-connected .title,
  .wallet-to-connect .title {
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000000;
    margin-bottom: 16px;
  }

  .wallet-to-connect .indications {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    margin-bottom: 16px;
  }
  .wallet-to-connect {
    div {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      width: 100%;
    }
    .filled-button {
      ${COMMON_STYLES.FILLED_BUTTON({
        fillColor: "#ff7b7c",
        borderSize: "10px",
      })}
      width: 80%;
      padding: 15px;
      font-size: 24px;
      line-height: 24px;
    }
  }

  .wallet-connected .image {
    width: 60%;
    margin-bottom: 8px;
  }

  .wallet-connected .image img {
    width: 100%;
    height: auto;
  }

  .wallet-connected .wallet-name {
    width: 100%;
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    color: #000000;
  }

  .text-button {
    background-color: transparent;
    border: none;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline;
    color: #1b70d0;
  }
  .section-separator {
    width: 100%;
    height: 14px;
    background: #25a29d;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-bottom: 28px;
  }

  @media (max-width: 768px) {
    padding-bottom: 40px;

    .section-separator {
      height: 8px;
    }

    .infor {
      width: 140px;
    }

    .page-title-heading {
      padding-right: 15px;
      padding-left: 15px;
      padding-bottom: 17px;
      .heading {
        font-size: ${mobileTitleFontSize};
        line-height: ${mobileTitleFontSize};
      }
      .vertical-line {
        margin-right: 8px;
        border-left: 4px solid #ffd061;
        height: ${mobileTitleFontSize};
        display: inline-block;
      }
    }

    /* remove all buttons */
    .text-button {
      font-size: 10px;
      line-height: 10px;
      padding: 0;
      color: #ff0030;
    }

    .right-section {
      width: 40%;
    }

    .price-2 {
      font-size: 10px;
      line-height: 10px;
      margin-bottom: 14px;
    }

    .total-container .price-2:last-child {
      margin-bottom: 0px;
    }

    .action-buttons {
      margin-top: 8px;
    }

    .action-buttons .filled-button,
    .action-buttons .outlined-button {
      font-size: 10px;
      line-height: 10px;
      padding: 8px;
      border-radius: 6px;
    }

    .action-buttons .outlined-button {
      border: 1px solid #ff7b7c;
    }

    .bottom-section {
      display: flex;
      justify-content: space-between;
      .wallet-connected,
      .wallet-to-connect {
        width: 55%;
      }
      .back-to-home-container {
        width: 40%;
      }
    }

    .wallet-connected,
    .wallet-to-connect {
      padding: 10px 20px;
    }

    .wallet-connected .wallet-name {
      font-size: 10px;
      line-height: 10px;
    }

    .wallet-connected .title,
    .wallet-to-connect .title {
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 4px;
    }

    .wallet-to-connect a,
    .wallet-to-connect .indications {
      font-size: 8px;
      line-height: 8px;
      margin-bottom: 10px;
    }

    .wallet-to-connect .filled-button {
      font-size: 12px;
      line-height: 12px;
      width: 100%;
      border-radius: 6px;
      padding: 9px;
    }
  }
`;
