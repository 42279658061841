import styled from "styled-components";
import { HEADER_HEIGHT } from "../../constants/constants";

export const StyledAnnouncementDetailPage = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }
  .announcement-container {
    padding: 40px;
  }
  .announcement-content {
    width: 100%;
  }
`;
