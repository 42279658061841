import React, { useState, useEffect } from 'react';
import getUserTopSeller from '../../api/user/topSellers';
import Collection02 from '../collection/Collection02';
import Spinner from '../layouts/Spinner/Spinner';

const TopSellers = () => {
  const [sellers, setSellers] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSellers = async () => {
    const response = await getUserTopSeller();
    response && setSellers(response);
    setLoading(false);
  };

  useEffect(() => {
    getSellers();
  }, []);

  return (
    <section className='flat-cart-item pt-5'>
      {loading ? (
        <div
          style={{
            height: '400px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <Collection02
          data={sellers}
          title='Trending Users'
          stop={10}
          collectionType='Seller'
          color={'black'}
          measures={{
            lg: 3,
            md: 4,
            sm: 6,
          }}
        />
      )}
    </section>
  );
};

export default TopSellers;
