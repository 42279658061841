import React from "react";

const ItemImage = ({ element, imgdetail1 }) => {
  return (
    <img
      src={element.image ? element.image : imgdetail1}
      alt="Axies"
      style={{
        objectFit: "contain",
        width: "100%",
        borderRadius: "30px",
      }}
    />
  );
};

export default ItemImage;
