import { useEffect, useState } from "react";
import getItem from "../../../api/Item/getItem";
import { getProductById } from "../../../api/products/getProducts";
import getOneSpace from "../../../api/spaces/getSpaceByUserId";
import getUser from "../../../api/user/getUser";
import ImageWithLoader from "../../../components/ImageWithLoader";
import LinkWithQueryParams from "../../../components/LinkWithQueryParams";
import {
  prodigiStatusClassSelector,
  prodigiStatusFormatter,
} from "../../../helpers/formatters";
import useIPFS from "../../../hooks/useIPFS";
import COAModal from "../COAModal";
import ItemCardLeftSection from "../ItemCard/ItemCardLeftSection";
import { StyledProdigiCard } from "./styles";

const ProdigiCard = ({ item }) => {
  const { getData, element, convertIPFSUrl } = useIPFS();
  const [expanded, setExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [coaImg, setCoaImg] = useState();
  const [itemData, setItemData] = useState();
  const [needsExpansion, setNeedsExpansion] = useState(false);
  const [productName, setProductName] = useState(null);
  const [ownerData, setOwnerData] = useState({});
  const [spaceData, setSpaceData] = useState({});
  const [loading, setLoading] = useState(false);

  const getSpaceData = async ({ item }) => {
    if (item.originalOwnerId) {
      await getData(item?.finalCid ? item?.finalCid : item.cid);
      const userData = await getUser(item.originalOwnerId);
      const spaceOwnerData = await getOneSpace(userData.id);
      setOwnerData(userData);
      setSpaceData(spaceOwnerData);
    }
  };

  const getProductName = async ({ item }) => {
    const productId = item?.productId;
    const productData = await getProductById({ productId });
    setProductName(productData?.name);
  };

  const getItemData = async (id) => {
    const itemId = item?.prodigiOrderListItems?.[0]?.itemId;
    if (!itemId) return;
    const response = await getItem(itemId);
    setItemData(response);
    setNeedsExpansion(response?.COAImage?.length > 0);
    if (response?.COAImage) {
      const convertedImage = await convertIPFSUrl(response?.COAImage);
      setCoaImg(convertedImage);
    }
    if (response?.productId && response?.id) {
      getProductName({ item: response });
    }
    if (response?.id) {
      getSpaceData({ item: response });
    }
    response && (await getData(response.cid));
  };

  useEffect(() => {
    if (item) {
      getItemData(item.itemId);
    }
  }, [item]);

  const handleExpand = () => {
    if (needsExpansion) {
      setExpanded(!expanded);
    }
  };

  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <StyledProdigiCard>
      <div className="sc-card-activity style1">
        <div className="content" onClick={handleExpand}>
          <div className="media">
            <LinkWithQueryParams path={`/item-details-01/${item.itemId}`}>
              <ImageWithLoader img={element.image} alt={element.name} />
            </LinkWithQueryParams>
          </div>
          <div className="infor">
            <ItemCardLeftSection
              itemDisplayName={element.name}
              spaceData={spaceData}
              creatorDisplayName={ownerData.displayName}
              productName={productName}
              expanded={expanded}
              removeFromSale={null}
              sellItem={null}
              item={item}
              loading={loading}
            />

            <div className="right-section">
              {item?.status && (
                <div
                  className={`status-label ${prodigiStatusClassSelector({
                    status: item?.status,
                  })}`}
                >
                  <span>
                    {prodigiStatusFormatter({ status: item?.status })}
                  </span>
                </div>
              )}
              {item?.productSKU?.size && (
                <p className="artwork-size">Artwork {item.productSKU.size}</p>
              )}

              {needsExpansion && (
                <div className="chevron-container">
                  <i
                    className={`fal ${
                      expanded ? "fa-chevron-up" : "fa-chevron-down"
                    }`}
                  ></i>
                </div>
              )}
            </div>
          </div>
        </div>
        {expanded && itemData?.COAImage && (
          <div className="hidden" onClick={handleOpen}>
            <div className="coa">View Certificate of Authenticity</div>
          </div>
        )}
        <COAModal
          show={showModal}
          onHide={handleClose}
          img={coaImg}
          alt={"coa"}
        />
      </div>
    </StyledProdigiCard>
  );
};

export default ProdigiCard;
