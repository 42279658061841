import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getBaseItem } from "../../api/Item/baseItems";
import getItem from "../../api/Item/getItem";
import { getBaseItemsByCollection } from "../../api/collections/baseCollection";
import getCollection from "../../api/collections/getCollection";
import { getProductById } from "../../api/products/getProducts";
import { getRoomByCollection } from "../../api/room/getRooms";
import getOneSpace from "../../api/spaces/getSpaceByUserId";
import getUser from "../../api/user/getUser";
import Layout from "../../components/layouts/Layout";
import Spinner from "../../components/layouts/Spinner/Spinner";
import {
  BASE_URL,
  IS_VIEW_AT_HOME_ENABLED,
  IS_VIRTUAL_SPACE_ENABLED,
} from "../../constants/constants";
import { priceFormatter } from "../../helpers/formatters";
import {
  isBuyableBaseItem,
  isBuyableItem,
  isResaleItem,
} from "../../helpers/itemHelpers";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import { useCustomNavigator } from "../../hooks/useCustomNavigator";
import useIPFS from "../../hooks/useIPFS";
import ItemsByCollection from "../Collection/ItemsByCollection";
import BuyBtn from "./InfoNFT/BuyBtn";
import InfoNFT from "./InfoNFT/InfoNFT";
import ItemVisual from "./ItemVisual";
import TabsNFT from "./TabsNFT/TabsNFT";
import { BaseItemPage } from "./styles";

const BaseItemDetails = () => {
  const isMobile = useCheckMobileScreen();

  const { baseItemId } = useParams();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { customNavigate } = useCustomNavigator();
  const [serial, setSerial] = useState(null);
  const [creatorInfo, setCreatorInfo] = useState();
  const [ownerInfo, setOwnerInfo] = useState(null);
  const [spaceInfo, setSpaceInfo] = useState(null);
  const [productData, setProductData] = useState(null);

  const [spaceUrl, setSpaceUrl] = useState();
  const [viewAtHomeUrl, setViewAtHomeUrl] = useState();
  const [videoHeight, setVideoHeight] = useState("50%");
  const itemDetailsRef = useRef(null);

  const [collectionInfo, setCollectionInfo] = useState();
  const { element, getData } = useIPFS();
  const { wallets } = useSelector((state) => state.user);
  const [items, setItems] = useState([]);

  const getSpace = async (userId) => {
    const spaceResponse = await getOneSpace(userId);
    spaceResponse && setSpaceInfo(spaceResponse);
  };

  const getUserData = async (creatorId, ownerId) => {
    const response = await getUser(creatorId);
    response && setCreatorInfo(response);
    await getSpace(creatorId);
    if (ownerId) {
      const responseTwo = await getUser(ownerId);
      responseTwo && setOwnerInfo(responseTwo);
    }
    setLoading(false);
  };

  const getItemsOfCollection = async (id) => {
    try {
      let baseItems = await getBaseItemsByCollection(id);
      baseItems = baseItems.filter((item) => item.id !== baseItemId);
      baseItems && setItems(baseItems);
    } catch (error) {
      console.log(error);
    }
  };

  const getCollectionData = async (collectionId) => {
    const response = await getCollection(collectionId);
    const collectionName = response?.tokenName;

    const roomData = await getRoomByCollection({ collectionId });
    const roomId = roomData?.id;
    if (roomId) {
      const url = `${BASE_URL}/webxr/index.html?lid=${encodeURI(
        roomId
      )}&sid=${encodeURI(collectionName)}&nid=al`;
      setSpaceUrl(url);
    }

    getItemsOfCollection(collectionId);
    response && setCollectionInfo(response);
  };

  const checkForInexistent = ({ response }) => {
    if (response?.statusCode === 400 || response?.statusCode === 404) {
      customNavigate({ path: "/" });
      return;
    }
  };

  const populateItemData = async ({ response }) => {
    if (itemDetailsRef.current) {
      setVideoHeight(itemDetailsRef.current.offsetHeight);
    }
    await getData(response?.finalCid ? response?.finalCid : response.cid);
    await getCollectionData(response.collectionId);
    await getUserData(response.originalOwnerId, response.belongsToId);
  };

  const getSingleItem = async (id) => {
    !loading && setLoading(true);
    const response = await getItem(id);
    checkForInexistent({ response });
    response && setSerial(response);
    if (response) {
      await populateItemData({ response });
    }
  };

  const getSingleBaseItem = async (id) => {
    !loading && setLoading(true);
    const response = await getBaseItem(id);
    checkForInexistent({ response });
    const productId = response?.productId;
    const productData = await getProductById({ productId });
    setProductData(productData);
    response && setSerial(response);
    if (response) {
      await populateItemData({ response });
    }
  };

  useEffect(() => {
    (async () => {
      if (!baseItemId) return;
      const isItem = location?.pathname?.includes("item-details-01");
      if (isItem) {
        await getSingleItem(baseItemId);
      } else {
        await getSingleBaseItem(baseItemId);
      }
    })();
  }, [baseItemId, location]);

  useEffect(() => {
    const viewAtHomeUrl = " https://playcanv.as/p/SI8CMY1q/?id=";
    setViewAtHomeUrl(viewAtHomeUrl + element.image);
  }, [element]);

  return (
    <Layout>
      <BaseItemPage>
        <div id="spacer"></div>
        <div className="item-details">
          <div className="tf-section tf-item-details style-2">
            <div className="themesflat-container">
              {loading ? (
                <div className="w-100 vh-100 d-flex justify-content-center align-items-center ">
                  <Spinner />
                </div>
              ) : (
                <>
                  <section className="row my-5">
                    {!isMobile && (
                      <ItemVisual
                        serial={serial}
                        element={element}
                        videoHeight={videoHeight}
                      />
                    )}

                    <div className="col-md-6 col-sm-12">
                      <InfoNFT
                        metadata={element}
                        serial={serial}
                        user={ownerInfo ? ownerInfo : creatorInfo}
                        wallet={wallets}
                        productName={productData?.name}
                        ref={itemDetailsRef}
                        setVideoHeight={setVideoHeight}
                        collectionInfo={collectionInfo}
                      />

                      {isMobile && (
                        <>
                          <ItemVisual
                            serial={serial}
                            element={element}
                            videoHeight={videoHeight}
                          />

                          {isMobile &&
                            (isBuyableBaseItem({ item: serial }) ||
                              isBuyableItem({ item: serial })) && (
                              <>
                                <div className="row m-0 justify-content-center m-4">
                                  <div className="btn-container">
                                    <BuyBtn
                                      card={serial}
                                      walletConnected={wallets}
                                    />
                                  </div>
                                </div>

                                <div className="price-container">
                                  <h3 className="price-label">
                                    Current Price:
                                  </h3>
                                  <h2 className="price">
                                    {priceFormatter({
                                      currencyType: isResaleItem({
                                        item: serial,
                                      })
                                        ? serial?.saleCurrencyType
                                        : serial?.currencyType,
                                      price: isResaleItem({ item: serial })
                                        ? serial?.salePrice
                                        : serial?.price,
                                    })}
                                  </h2>
                                </div>
                              </>
                            )}
                        </>
                      )}

                      {!isMobile && (
                        <div className="row m-0 justify-content-center justify-content-lg-between mb-2 m-lg-0">
                          {IS_VIRTUAL_SPACE_ENABLED && spaceUrl && (
                            <a
                              href={spaceUrl}
                              rel="noreferrer"
                              target="_blank"
                              className="mb-2 m-lg-0"
                            >
                              <div>
                                <button className="filled-button">
                                  <span>launch virtual space</span>
                                </button>
                              </div>
                            </a>
                          )}

                          {IS_VIEW_AT_HOME_ENABLED && viewAtHomeUrl && (
                            <a
                              href={viewAtHomeUrl}
                              target="popup"
                              onClick={() => {
                                window.open(
                                  { viewAtHomeUrl },
                                  "popup",
                                  "width=600,height=600,scrollbars=no,resizable=no"
                                );
                                return false;
                              }}
                            >
                              <div>
                                <button className="filled-button">
                                  <span>View at Home</span>
                                </button>
                              </div>
                            </a>
                          )}
                        </div>
                      )}

                      <div className="content-right">
                        <TabsNFT
                          collectionInfo={collectionInfo}
                          serial={serial}
                          creator={creatorInfo}
                          spaceInfo={spaceInfo}
                          productData={productData}
                        />
                      </div>
                    </div>
                  </section>
                </>
              )}
            </div>
          </div>

          {items && items.length > 0 && (
            <section className="row m-0 related-items">
              <div className="themesflat-container">
                <div className="header-container">
                  <div className="page-title-heading">
                    <div className="vertical-line"></div>
                    <h1 className="heading">You may also like:</h1>
                  </div>
                </div>

                <div className="">
                  <ItemsByCollection
                    items={items}
                    stop={4}
                    measures={{ sm: 4, md: 3 }}
                  />
                </div>
              </div>
            </section>
          )}
        </div>
      </BaseItemPage>
    </Layout>
  );
};

export default BaseItemDetails;
