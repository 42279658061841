import LinkWithQueryParams from "../../components/LinkWithQueryParams";
import { useRole } from "../../hooks/useRole";

const Actions = () => {
  const { isGallery } = useRole();
  return (
    <div id="side-bar" className="side-bar style-2">
      <div className="widget widget-filter style-2 mgbt-0">
        <h3 className="title-widget">Actions</h3>

        {isGallery() && (
          <>
            <LinkWithQueryParams
              path="/my-items"
              className="clear-check btn-filter style-2"
            >
              My Items
            </LinkWithQueryParams>
            <br />
            <LinkWithQueryParams
              path="/my-collections"
              className="clear-check btn-filter style-2"
            >
              My Collections
            </LinkWithQueryParams>
            <br />
            <LinkWithQueryParams
              path="/my-artists"
              className="clear-check btn-filter style-2"
            >
              My Artists
            </LinkWithQueryParams>
            <br />
            <LinkWithQueryParams
              path="/create-artist"
              className="clear-check btn-filter style-2"
            >
              Create an Artist
            </LinkWithQueryParams>
            <br />
            <LinkWithQueryParams
              path="/create-collection"
              className="clear-check btn-filter style-2"
            >
              Create Collection
            </LinkWithQueryParams>
            <br />
            <LinkWithQueryParams
              path="/select-product"
              className="clear-check btn-filter style-2"
            >
              Create Item
            </LinkWithQueryParams>
            <br />
          </>
        )}
      </div>
    </div>
  );
};

export default Actions;
