import React from "react";
import AnnouncementCard from "./AnnouncementCard/AnnouncementCard";
import leftArrow from "../../assets/images/icon/leftArrow.png";
import rightArrow from "../../assets/images/icon/rightArrow.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar, A11y, Pagination } from "swiper";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

const AnnouncementsSlider = (props) => {
  const data = props.data;
  const [swiper, setSwiper] = React.useState();
  const prevRef = React.useRef();
  const nextRef = React.useRef();

  React.useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  return (
    <>
      <img
        className="swiper-button custom-prev-button"
        ref={prevRef}
        src={leftArrow}
        alt="swiper-left-arrow"
      />
      <Swiper
        modules={[Navigation, Scrollbar, A11y, Pagination]}
        spaceBetween={20}
        slidesPerView={3}
        navigation={{
          prevEl: prevRef?.current,
          nextEl: nextRef?.current,
        }}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={setSwiper}
      >
        {data?.length > 0 &&
          data.map((item, index) => (
            <SwiperSlide key={index}>
              <AnnouncementCard
                title={item?.title}
                description={item?.description}
                img={item?.activityImage}
                link={item?.cmsLink}
                id={item?.id}
              />
            </SwiperSlide>
          ))}
      </Swiper>
      <img
        className="swiper-button custom-next-button"
        ref={nextRef}
        src={rightArrow}
        alt="swiper-right-arrow"
      />
    </>
  );
};

export default AnnouncementsSlider;
