const icons = {
  twitterIcon: require("./images/icon/twitter.png"),
  facebookIcon: require("./images/icon/facebook.png"),
  linkedinIcon: require("./images/icon/linkedin.png"),
  instagramIcon: require("./images/icon/instagram.png"),
  discordIcon: require("./images/icon/discord.png"),
  tiktokIcon: require("./images/icon/tiktok.png"),
  copyIcon: require("./images/icon/copy-link.png"),
  hashpackIcon: require("./images/wallet/hashpackIcon.png"),
  hashpackBrand: require("./images/wallet/hashpackBrand.png"),
  metamaskIcon: require("./images/wallet/metamaskIcon.png"),
  metamaskBrand: require("./images/wallet/metamaskBrand.png"),
  acmeVaultIcon: require("./images/wallet/acmeVaultIcon.png"),
  acmeIcon: require("./images/wallet/acmeIcon.png"),
  acmeVaultBrand: require("./images/wallet/acmeVaultBrand.png"),
  venlyIcon: require("./images/wallet/venlyIcon.png"),
  venlyBrand: require("./images/wallet/venlyBrand.png"),
  errorIcon: require("./images/icon/error_icon.png"),
  expirationIcon: require("./images/icon/expiration_icon.png"),
  succesfulIcon: require("./images/icon/succesful_icon.png"),
  whoAreWeImage: require("./images/about-us/whoAreWe.png"),
  ourMissionImage: require("./images/about-us/ourMission.png"),
  walletIcon: require("./images/icon/walletIcon.png"),
};

const images = { ...icons };

export default images;
