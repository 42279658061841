import React from "react";

const ItemVideo = ({ element }) => {
  return (
    <video
      id="desktop-video"
      width="100%"
      height="100%"
      style={{
        position: "absolute",
        objectFit: "contain",
        backgroundColor: element?.backgroundColor
          ? element?.backgroundColor
          : "",
      }}
      autoPlay
      muted
      loop
      poster={element.image}
    >
      <source
        src={element.image}
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
  );
};

export default ItemVideo;
