const { REACT_APP_API_URL } = process.env;

const getPopularCollections = async (artistsIds, minlikes, maxlikes) => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}collection/popular`, {
      method: "GET",
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export default getPopularCollections;
