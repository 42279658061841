import { useSelector } from "react-redux";

const {
  REACT_APP_USER_ROLE_ID,
  REACT_APP_GALLERY_ROLE_ID,
  REACT_APP_ADMIN_ROLE_ID,
} = process.env;

export const useRole = () => {
  const user = useSelector((state) => state.user);

  const isUser = () => {
    return user.roleId === REACT_APP_USER_ROLE_ID;
  };

  const isGallery = () => {
    return user.roleId === REACT_APP_GALLERY_ROLE_ID || isAdmin();
  };

  const isAdmin = () => {
    return user.roleId === REACT_APP_ADMIN_ROLE_ID;
  };

  return {
    isUser,
    isGallery,
    isAdmin,
  };
};
