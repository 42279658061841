import { useState } from 'react';
import { useApi } from './useApi';

const { REACT_APP_API_URL } = process.env;

const useProdigi = () => {
  const [quote, setQuote] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sku, setSku] = useState(null);
  const [attributes, setAttributes] = useState(null);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  const { executeApiRequestTwo } = useApi();

  const calculateSku = async (sku) => {
    setLoading(true);
    setSku(sku);
    const url = `${REACT_APP_API_URL}prodigi/attributes/sku/${sku.id}`;
    const options = {
      method: 'GET',
    };
    try {
      const response = await executeApiRequestTwo(url, options);
      setAttributes(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(true);
      setLoading(false);
    }
  };

  const quoteProduct = async (obj, method = 'default') => {
    setLoading(true);
    const endpoint = `${REACT_APP_API_URL}prodigi/order/quote`;
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        shippingMethod: obj.shippingMethod,
        prodigiProductSKUId: obj.prodigiProductSKUId,
        quantity: obj.quantity, /*currently hardcoded value, update to support multi-qty*/
        attributes: obj.attributes,
      }),
    };
    try {
      const response = await executeApiRequestTwo(endpoint, options);
      if (response.statusCode === 500) {
        setLoading(false);
        setError(true);
        return;
      }

      if (method !== 'default') {
        setLoading(false);
        return response;
      }

      setQuote(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(true);
      setLoading(false);
    }
  };

  const orderProduct = async (obj, itemID, method = 'default') => {
    setLoading(true);
    const endpoint = `${REACT_APP_API_URL}prodigi/order`;
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        shippingMethod: obj.shippingMethod,
        sizing: obj.sizing,
        attributes: obj.attributes,
        prodigiProductSKUId: obj.prodigiProductSKUId,
        quantity: 1, /*currently hardcoded value, update to support multi-qty*/
        itemId: itemID,
      }),
    };
    try {
      const response = await executeApiRequestTwo(endpoint, options);
      if (method !== 'default') {
        setLoading(false);
        return response;
      }
      setData(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(true);
      setLoading(false);
    }
  };

  return {
    quote,
    loading,
    attributes,
    sku,
    error,
    data,
    calculateSku,
    quoteProduct,
    orderProduct,
  };
};

export default useProdigi;
