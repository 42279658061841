import React from "react";
import { priceFormatter } from "../../../helpers/formatters";

const ValidSkuData = ({
  totalPrice,
  decrementQty,
  itemQty,
  handleChange,
  incrementQty,
  skuData,
  handleSubmit,
  addToCart,
}) => {
  return (
    <>
      {totalPrice >= 0 && (
        <div
          className="row m-0   align-content-center  align-items-center mb-4"
          style={{ justifyContent: "space-between" }}
        >
          <div className="input-group">
            <span className="input-group-btn">
              <button
                type="button"
                className="quantity-left-minus btn btn-danger btn-number"
                data-type="minus"
                data-field=""
                onClick={() => decrementQty()}
              >
                <span className="fa fa-minus"></span>
              </button>
            </span>
            <span id="quantity-label">QTY:</span>
            <input
              type="number"
              id="quantity"
              name="quantity"
              className="form-control input-number"
              value={itemQty}
              onChange={handleChange}
              min="1"
              max="100"
            />
            <span className="input-group-btn">
              <button
                type="button"
                className="quantity-right-plus btn btn-success btn-number"
                data-type="plus"
                data-field=""
                onClick={() => incrementQty()}
              >
                <span className="fa fa-plus"></span>
              </button>
            </span>
          </div>
          <p className="price m-0">
            {priceFormatter({
              currencyType: skuData?.currencyType,
              price: totalPrice,
            })}
          </p>
        </div>
      )}

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          className="filled-button"
          style={{ width: "30%" }}
          onClick={handleSubmit(addToCart)}
        >
          Add to Cart
        </button>
      </div>
    </>
  );
};

export default ValidSkuData;
