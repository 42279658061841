import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { getCOATemplates } from "../../api/Item/getCOA";
import getCategories from "../../api/category/getCategories";
import { getProducts } from "../../api/products/getProducts";
import Layout from "../../components/layouts/Layout";
import Spinner from "../../components/layouts/Spinner/Spinner";
import {
  ERROR_CREATING_ITEM,
  REACT_APP_HEDERA_ACCOUNT_ID,
  SUCCESFUL_ITEM_CREATION,
} from "../../constants/constants";
import { notify, notifyError } from "../../helpers/toastNotificationService";
import { useApi } from "../../hooks/useApi";
import { useApi2 } from "../../hooks/useApi2";
import { useRole } from "../../hooks/useRole";
import CreateItemForm from "./CreateItemForm";
import ItemImagePreview from "./ItemImagePreview/ItemImagePreview";
import { StyledCreateItemPage } from "./styles";

const { REACT_APP_API_URL } = process.env;

const CreateItem = () => {
  const { productType } = useParams();
  const [isloading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [uniqueList, setUniqueList] = useState(null);
  const [crateDrop, setCrateDrop] = useState(null);
  const [highRes, setHighRes] = useState(null);
  const [itemImage, setItemImage] = useState(null);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [categories, setCategories] = useState(null);
  const [rarities, setRarities] = useState([]);
  const [productData, setProductData] = useState();
  const [COATemplates, setCOATemplates] = useState();

  // const { wallets } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { isGallery } = useRole();
  const { data, loading } = useApi2("collection/my", {
    method: "GET",
  });
  const { executeApiRequestTwo } = useApi();
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const [img, setImg] = useState(null);

  const getProductData = async () => {
    const products = await getProducts();
    const templates = await getCOATemplates();
    setCOATemplates(templates);
    const productData = products.filter(
      (product) => product.id === productType
    );
    setProductData(productData[0]);
  };

  const fetchCategories = async () => {
    const categories = await getCategories();
    setCategories(categories);
  };

  !isGallery() && navigate("/no-result");

  const createItemRequest = async ({ postFormData }) => {
    try {
      const itemRes = await executeApiRequestTwo(`${REACT_APP_API_URL}item`, {
        method: "POST",
        body: postFormData,
      });
      if (itemRes?.statusCode === 404 || itemRes?.statusCode === 400) {
        notifyError(ERROR_CREATING_ITEM);
      } else {
        notify(SUCCESFUL_ITEM_CREATION);
      }
    } catch (error) {
      console.log(error);
      notifyError(ERROR_CREATING_ITEM);
      // setError(true);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const traits =
      rarities?.length > 0
        ? JSON.parse(JSON.stringify(rarities)).map((rarity) => {
            delete rarity.id;
            return rarity;
          })
        : [];

    const formData = new FormData();
    formData.append("crates", data.crates ? "Y" : "N");
    if (data.crates && data?.crateFile[0]) {
      formData.append("crateFile", data.crateFile[0]);
    }
    if (data.highRes && data?.highResFile[0]) {
      formData.append("highResFile", data.highResFile[0]);
    }
    formData.append("file", data.file[0]);
    if (thumbnailImage && itemImage?.type?.includes("image")) {
      formData.append("thumbnailImage", thumbnailImage);
    }
    if (data?.properties?.length > 0) {
      formData.append("properties", data.properties);
    }
    formData.append("walletIdForMoney", REACT_APP_HEDERA_ACCOUNT_ID);
    formData.append("collectionId", data.collectionId);
    formData.append("price", Number(data.price));
    formData.append("currencyType", data.currencyType);
    formData.append("productId", productType);
    formData.append("quantity", Number(data.quantity));
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("propertyObject", JSON.stringify({}));
    formData.append("specialInvite", data.specialInvite);
    formData.append("uniqueItems", data.uniqueItems ? data.uniqueItems : "N");
    formData.append("traits", JSON.stringify(traits));

    if (data.saleStartDate)
      formData.append("saleStartDate", data.saleStartDate);
    if (data.saleEndDate) formData.append("saleEndDate", data.saleEndDate);
    if (data.shippingRequiredEmailAddress)
      formData.append(
        "shippingRequiredEmailAddress",
        data.shippingRequiredEmailAddress
      );

    formData.append(
      "acf",
      JSON.stringify({
        producttype: "artwork_framed_nocanvas",
        group: "0",
        threed_asset: "",
      })
    );
    if (data.categoryIds) {
      const categoriesIds = data.categoryIds.map((c) => c.value);
      formData.append("categoryIds", categoriesIds);
    }
    if (data.uniqueItems && data?.uniqueItemList?.[0]) {
      formData.append("uniqueItemList", data.uniqueItemList[0]);
      formData.append(
        "randomSelection",
        data.randomSelection ? data.randomSelection : "N"
      );
    }

    if (productData?.coaAvailable === "Y") {
      formData.append("isCOA", data.isCOA ? data.isCOA : "N");
      if (data?.isCOA === "Y") {
        formData.append("coaIssuer", data.coaIssuer);
        if (data.coaTemplateId) {
          const coaTemplateId = [data.coaTemplateId].map((c) => c.value);
          formData.append("coaTemplateId", coaTemplateId[0]);
        }
        formData.append("coaCopyrightStatement", data.coaCopyrightStatement);
        formData.append("coaEditionDetails", data.coaEditionDetails);
      }
    }

    if (productData?.prodigiPrint === "Y") {
      formData.append("whoPays", data.whoPays);
    }

    // formData.append("categories[]", data.category);
    createItemRequest({ postFormData: formData });
  };

  useEffect(() => {
    getProductData();
    fetchCategories();
  }, []);

  const createItemFormProps = {
    isGallery,
    loading,
    data,
    register,
    control,
    errors,
    watch,
    setUniqueList,
    uniqueList,
    setCrateDrop,
    crateDrop,
    highRes,
    setHighRes,
    categories,
    productData,
    rarities,
    setRarities,
    COATemplates,
  };

  return (
    <Layout>
      <StyledCreateItemPage>
        <div id="spacer"></div>

        {isloading && (
          <div className="progress-overlay">
            <div className="progess-status">Creating Item</div>
            <Spinner />
          </div>
        )}

        <section id="create-item">
          <form
            className="row m-0 justify-content-md-center justify-content-xl-center justify-content-lg-center"
            action="#"
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* <div className="row m-0"> */}
            <div className="col-3">
              <ItemImagePreview
                register={register}
                errors={errors}
                watch={watch}
                crateDrop={crateDrop}
                setItemImage={setItemImage}
                setThumbnailImage={setThumbnailImage}
                itemImage={itemImage}
              />
            </div>

            <div id="right-section" className="col-9">
              <div className="header-container">
                <div className="page-title-heading">
                  <div className="vertical-line"></div>
                  {productData?.name && (
                    <h1 className="heading">{productData?.name}</h1>
                  )}
                </div>
              </div>

              <CreateItemForm {...createItemFormProps} />
            </div>
          </form>
        </section>
      </StyledCreateItemPage>
    </Layout>
  );
};

export default CreateItem;
