import React from "react";
import Assets from "../../../assets/Assets";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";

const AcmeVaultWallet = () => {
  const isMobile = useCheckMobileScreen();

  return (
    <>
      <div className="wallet sc-box-icon-inner style-2">
        <>
          <div className="image" style={{ marginBottom: "16px" }}>
            <img src={Assets.acmeVaultIcon} alt={"w.altIcon"} />
          </div>
          <div className="image" style={{ marginBottom: "20px" }}>
            <img src={Assets.acmeVaultBrand} alt={"w.altBrand"} />
          </div>

          {isMobile ? (
            <p
              className="logout-note"
              style={{ color: "#2159A2", textDecoration: "underline" }}
            >
              Always Connected.
            </p>
          ) : (
            <p className="logout-note" style={{ color: "#000000" }}>
              Your ACME™ Vault is always Connected.
            </p>
          )}
        </>
      </div>
      {!isMobile && <div style={{ borderRight: "1px solid #9A9A9A" }}></div>}
    </>
  );
};

export default AcmeVaultWallet;
