import LinkWithQueryParams from "../../components/LinkWithQueryParams";

const MyArtistCard = ({ artist }) => {
  return (
    <div className="sc-card-activity style-2">
      <div className="content">
        <div className="infor">
          <br />
          <h4>
            &nbsp;
            <LinkWithQueryParams path={`#`}>
              {artist.displayName}
            </LinkWithQueryParams>
          </h4>
          <br />
        </div>
      </div>
    </div>
  );
};

export default MyArtistCard;
