import styled from "styled-components";
import { COMMON_STYLES } from "../../../../constants/genericStyles";

export const StyledNFT = styled.div`
  background-color: var(--primary-color);
  filter: drop-shadow(3px 4px 12px rgba(0, 0, 0, 0.25));
  border-radius: 28px;
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;

  .top-section {
    background-color: transparent;
  }

  .bottom-section {
    background: var(--primary-color);
    border-bottom-left-radius: 28px;
    border-bottom-right-radius: 28px;
    min-height: 180px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    .content {
      width: 100%;
    }
  }
  &:hover {
    ${COMMON_STYLES.LEVITATE_ANIMATION}
  }

  hr {
    border: solid 1px #ff7b7c;
    margin-bottom: 20px;
  }

  .image-container {
    width: 100%;
    position: relative;
    border-radius: 19px;
  }

  .image-container:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 90%;
  }

  .image-container img {
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 9;
  }

  .like-container {
    position: absolute;
    right: 5%;
    top: 5%;
    padding: 4px;
    background: #ffd061;
    border-radius: 5px;
    z-index: 10;
  }

  .like-container span {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
  }

  .image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .image .like-btn {
    position: absolute;
    top: 13px;
    right: 11px;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #000;
    padding: 2px 11px;
    border-radius: 8px;
    background-color: #f8d273;
    cursor: pointer;
    border: none;
  }

  .content {
    position: relative;
    height: 35%;
    margin: 10px;
  }
  .content .top-content {
    padding-bottom: 10px;
  }

  .content .title,
  .content .artist-name,
  .content .product-name {
    padding-left: 4%;
  }

  .content .title span {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #011126;
  }

  .content .artist {
    font-size: 14px;
    font-weight: bold;
  }

  .content .price {
    font-weight: bold;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    padding: 2px 11px;
    border-radius: 8px;
    background-color: #000;
    border: none;

    font-size: 20px;
    line-height: 20px;
    color: #ffffff;
    padding: 7px 20px;
  }

  .heart::before {
    color: #000000;
    font-weight: 700;
  }

  .heart::before {
    /* .wishlist-button.heart::before { */
    content: "\f004";
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    margin-right: 7px;
  }
  .artist-name {
    font-size: 20px;
    line-height: 20px;
    color: #247773;
  }
  .product-name {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #9a9a9a;
  }
  @media (max-width: 768px) {
    border-radius: 12px;
    margin-bottom: 0;

    .like-container {
      border-radius: 2px;
      padding: 2px;
      span {
        font-size: 8px;
        line-height: 8px;
      }
      .heart::before {
        margin-right: 1.5px;
      }
    }

    .image-container {
      border-radius: 14px;
      img {
        object-fit: contain;
      }
    }

    .image-container:before {
      padding-top: 80%;
    }

    .top-section {
      padding-bottom: 0;
    }

    .bottom-section {
      min-height: 80px;
      .top-content {
        padding-bottom: 0;
      }
    }

    .content {
      margin: 7px;
      hr {
        margin-bottom: 6px;
        margin-top: 6px;
      }
      .price {
        font-size: 10px;
        line-height: 10px;
        border-radius: 3px;
        padding: 3px 10px;
      }
      .title {
        margin-bottom: 4px;
        span {
          font-size: 12px;
          line-height: 12px;
        }
      }
    }

    .product-name,
    .artist-name {
      font-size: 10px;
      line-height: 10px;
    }
  }
`;
