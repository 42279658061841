import React, { useState } from "react";
import List from "./List";

const CreatorFinder = ({ data }) => {
  const [inputText, setInputText] = useState("");
  let inputHandler = (e) => {
    let lowerCase = e.target.value;
    setInputText(lowerCase);
  };

  return (
    <>
      {/*<h1 className="tf-title style2 pb-22 tex-center">search by creator</h1>

      <input
        type="search"
        onChange={inputHandler}
        placeholder="search by creator"
        style={{
          backgroundColor: "white",
          width: "100%",
          borderRadius: "15px",
        }}
      />

      <List text={inputText} data={data} />*/}
    </>
  );
};

export default CreatorFinder;
