import React from "react";
import CollectionHeader from "../../collection/CollectionHeader";
import { CREATE_SECTION_DATA } from "./createData";
import CreateItem from "./CreateItem";
import styled from "styled-components";
const StyledCreateSection = styled.section`
  @media (max-width: 768px) {
    padding: 20px 0px !important;
    padding-bottom: 0 !important;
    .exp {
      font-size: 12px !important;
    }
  }
`;
const Create = () => {
  return (
    <StyledCreateSection className="tf-box-icon create style1 tf-section">
      {/* <section className="tf-box-icon create style1 tf-section"> */}
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <CollectionHeader
              color="#333"
              show={false}
              title="How To Get Started"
            />
          </div>
          {CREATE_SECTION_DATA.map((item, index) => (
            <CreateItem key={index} item={item} />
          ))}
        </div>
      </div>
      {/* </section> */}
    </StyledCreateSection>
  );
};

export default Create;
