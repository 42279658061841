import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getProducts } from "../../api/products/getProducts";
import Layout from "../../components/layouts/Layout";
import { StyledSelectProductPage } from "./styles";

const SelectProduct = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  const navigateToCreateItems = ({ productType }) => {
    navigate(`/create-item/${productType}`);
  };

  const getProductsData = async () => {
    const products = await getProducts();
    setProducts(products);
  };

  useEffect(() => {
    getProductsData();
  }, []);

  return (
    <Layout>
      <StyledSelectProductPage>
        <div id="spacer"></div>
        <section id="select-product">
          <div className="section-title">Create item</div>
          <div className="buttons-container">
            {products.map((product) => {
              return (
                <div className="button-container" key={"product" + product.id}>
                  <button
                    type="button"
                    className="filled-button"
                    onClick={() =>
                      navigateToCreateItems({
                        productType: product.id,
                      })
                    }
                  >
                    {product.name}
                  </button>
                </div>
              );
            })}
          </div>
        </section>
      </StyledSelectProductPage>
    </Layout>
  );
};

export default SelectProduct;
