import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { calculateSalesTax, createOrder } from "../../../api/order/order";
import { getReservation } from "../../../api/order/reservation";
import getProducts from "../../../api/prodigi/getProducts";
import VenlyConnectIndications from "../../../components/Venly/VenlyConnectIndications";
import VenlyOverlay from "../../../components/Venly/VenlyOverlay";
import VenlyPayWithVenlyIndications from "../../../components/Venly/VenlyPayWithVenlyIndications";
import VenlySelectWalletIndications from "../../../components/Venly/VenlySelectWalletIndications";
import Spinner from "../../../components/layouts/Spinner/Spinner";
import {
  AFFILIATE_QUERY_STRING,
  BASE_ITEM_TYPE,
  HASHPACK_WALLET_TYPE,
  HBAR_CURRENCY_TYPE,
  REACT_APP_HEDERA_ACCOUNT_ID,
  SALE_TRANSACTION_FEE,
  TAX_CALCULATION_MESSAGE,
  USD_CURRENCY_TYPE,
  US_COUNTRY_CODE,
  VAULT_WALLET_TYPE,
  VENLY_WALLET_TYPE,
  ZIP_CODE_STATE_ERROR_MESSAGE,
} from "../../../constants/constants";
import {
  addOrderSaleDetails,
  generateMemoFromItems,
  getHbarPricedProducts,
  getResaleItems,
  isValidZip,
  populateItemsDetails,
  requiresProdigiValidation,
  requiresShippingValidation,
  requiresTaxCalculation,
  retrieveShippingInfo,
  retrieveTaxInfo,
} from "../../../helpers/orderHelpers";
import { notifyError } from "../../../helpers/toastNotificationService";
import useCheckout from "../../../hooks/checkoutMultiple/useCheckout";
import useCart from "../../../hooks/useCart";
import { useCustomNavigator } from "../../../hooks/useCustomNavigator";
import useHashconnect from "../../../hooks/useHashconnect";
import useProdigi from "../../../hooks/useProdigi";
import useVenly from "../../../hooks/useVenly";
import { generateHmac } from "../../../utils/sha256Encription";
import ConnectWallet from "../../Cart/Wallet/ConnectWallet";
import HashpackWalletConnected from "../../Cart/Wallet/HashpackWalletConnected";
import WalletConnected from "../../Cart/Wallet/WalletConnected";
import DefaultWalletDropdown from "../../editProfile/DefaultwalletDropdown/DefaultWalletDropdown";
import FormWrapper from "../FormWrapper";
import CheckoutItemList from "./CheckoutMultiple/CheckoutItemList";
import ReservationTimer from "./ReservationTimer";
import ShippingInfo from "./ShippingInfo";
import { StyledCheckout } from "./styles";

const CheckoutReservation = ({ reservationId }) => {
  const navigate = useNavigate();
  const { customNavigate } = useCustomNavigator();
  const [searchParams, setSearchParams] = useSearchParams();
  const methods = useForm({ mode: "onChange" });
  const {
    updateQuoteData,
    updateHBARQuoteData,
    updateNonProdigiQuoteData,
    calculateProductTotal,
    mintCOA,
    payWithVenly,
    associateCollections,
  } = useCheckout();

  const { payWithHashconnect } = useHashconnect();
  const { removeFromCartPersist } = useCart();
  const { calculateSku } = useProdigi();
  const user = useSelector((state) => state.user);
  const [requiresShipping, setRequiresShipping] = useState(false);
  const [isHBARPriced, setIsHBARPriced] = useState(false);
  const [taxCalculated, setTaxCalculated] = useState(false);
  const [connectedWallet, setConnectedWallet] = useState();

  const [enabledWalletTypes, setEnabledWalletTypes] = useState(["VAULT"]);

  const [stripeLoading, setStripeLoading] = useState(false);
  const [reservationData, setReservationData] = useState(false);
  const [checkoutInProgress, setCheckoutInProgress] = useState(false);
  const { manageWallets, callToVenly } = useVenly();

  const [item, setItem] = useState(null);
  const [items, setItems] = useState(null);
  const [prodigiProduct, setProdigiProduct] = useState(null);
  const [amount, setAmount] = useState(null);
  const [order, setOrder] = useState({});
  const overlayRef = useRef(null);
  const connectIndicationsRef = useRef(null);
  const selectIndicationsRef = useRef(null);
  const payIndicationsRef = useRef(null);
  const walletActionRef = useRef({
    status: null,
  });
  const stateField = methods?.watch("state");
  const zipCodeField = methods?.watch("zipCode");
  /*
    1. Get Reservation details getReservationData
    2. populate items details
    3. getHbarPricedProducts
    4. check for physical items
  */

  const selectWallet = async (event) => {
    event.preventDefault();
    overlayRef.current.classList.add("active");
    selectIndicationsRef.current.classList.add("active");
    walletActionRef.current.status = "CONNECTING_WALLET";
    await manageWallets();
  };

  const connectWallet = async (event) => {
    event.preventDefault();
    try {
      overlayRef.current.classList.add("active");
      connectIndicationsRef.current.classList.add("active");
      walletActionRef.current.status = "AUTHENTICATING_USER";
      await callToVenly();
    } catch (e) {
      console.log(e);
    }
  };

  const displayPayVenlyIndications = () => {
    /* Displays pay with venly background screen */
    overlayRef.current.classList.add("active");
    payIndicationsRef.current.classList.add("active");
  };

  const hidePayWithVenlyIndications = () => {
    /* Removes pay with venly background screen */
    overlayRef.current.classList.remove("active");
    payIndicationsRef.current.classList.remove("active");
  };

  const clearFromCart = async () => {
    await Promise.all(
      order.products.map(async (item) => {
        if (item.reservationType === BASE_ITEM_TYPE) {
          /* base item removal */
          return await removeFromCartPersist({ id: item.baseItemId });
        } else {
          /* resale item removal */
          return await removeFromCartPersist({ id: item.id });
        }
      })
    );
  };

  const coaFlow = async ({ itemId, baseItem }) => {
    await mintCOA({ itemId, baseItem });
  };

  const isHashpackSelectedAndConnected = () => {
    const values = methods.getValues();
    const hashpackWalletId = user?.hashpackWallets?.accountIds?.[0];

    return (
      hashpackWalletId && values?.defaultWalletType === HASHPACK_WALLET_TYPE
    );
  };

  const isVenlySelectedAndConnected = () => {
    const values = methods.getValues();
    const walletAddress = user?.wallets?.walletDetails?.address;

    return walletAddress && values?.defaultWalletType === VENLY_WALLET_TYPE;
  };

  const isVaultSelected = () => {
    const values = methods.getValues();
    return values?.defaultWalletType === VAULT_WALLET_TYPE;
  };

  const fetchTax = async () => {
    setTaxCalculated(false);

    const requiresTax = await requiresTaxCalculation({
      items,
    });

    /* Calculation of taxes is not requires */
    if (!requiresTax || isHBARPriced) {
      setTaxCalculated(true);
      return;
    }

    if (!order) return;
    const values = methods.getValues();

    if (
      !values?.state ||
      values?.zipCode?.length === 0 ||
      !isValidZip.test(values?.zipCode)
    ) {
      return;
    }

    const { hString, epochTimestamp } = generateHmac({
      userId: user.id,
      id: reservationId,
    });

    let shippingInfo;
    if (requiresShipping) {
      shippingInfo = retrieveShippingInfo({
        values,
        quoteData: order?.quote,
      });
    }

    let requestBody = {
      reservationId,
      hString,
      epochTimestamp,
      shippingCost: shippingInfo?.shippingCost ?? 0,
      state: values?.state,
      zipCode: values?.zipCode,
      countryCode: US_COUNTRY_CODE,
    };

    const taxResponse = await calculateSalesTax({ requestBody });

    if (
      !taxResponse?.success ||
      (taxResponse?.statusCode && taxResponse?.statusCode !== 200)
    ) {
      if (taxResponse?.errorDetails.includes("is not used within")) {
        notifyError(ZIP_CODE_STATE_ERROR_MESSAGE);
      } else {
        notifyError(TAX_CALCULATION_MESSAGE, () => {
          customNavigate({ path: "/cart" });
        });
      }
    }

    /* Update UI with tax information */
    if (taxResponse?.success && "amountToCollect" in taxResponse) {
      let { quotesData } = await getQuoteData({
        items,
        isHBARPriced,
      });

      const tax = taxResponse?.amountToCollect * 100;
      const newAmount = quotesData?.newAmount + tax;
      setAmount(parseInt(newAmount));
      setOrder({
        ...order,
        amount: newAmount,
        quote: {
          ...order.quote,
          totalTax: taxResponse?.amountToCollect,
        },
      });
      setTaxCalculated(true);
    }
  };

  const generateOrder = async ({ paymentIntent, shippingInfo }) => {
    const walletAddress = user?.wallets?.walletDetails?.address;
    const hashpackWalletId = user?.hashpackWallets?.accountIds?.[0];
    let connectedWallet;
    if (isHashpackSelectedAndConnected()) {
      connectedWallet = hashpackWalletId;
    }

    if (isVenlySelectedAndConnected()) {
      connectedWallet = walletAddress;
    }

    if (isVaultSelected()) {
      connectedWallet = user?.vaultWalletId;
    }

    const { hString, epochTimestamp } = generateHmac({
      userId: user.id,
      id: reservationId,
    });

    const taxInfo = retrieveTaxInfo({
      quoteData: order?.quote,
    });

    let requestBody = {
      reservationId,
      hString,
      epochTimestamp,
      currencyType: isHBARPriced ? HBAR_CURRENCY_TYPE : USD_CURRENCY_TYPE,
      saleAmount: isHBARPriced ? order?.subtotal : order?.subtotal,
      toWalletId: connectedWallet,
      saleTransactionId: paymentIntent?.id,
      salesTax: taxInfo?.salesTax ?? 0,
      shippingCost: 0,
    };
    requestBody = addOrderSaleDetails({ order, orderBody: requestBody });

    /* Add affiliate code to request body */
    const afflCode = searchParams.get(AFFILIATE_QUERY_STRING);
    if (afflCode) {
      requestBody.affiliateCode = afflCode;
    }

    /* If there is mandatory shipping information send it */
    if (shippingInfo?.shippingFirstName) {
      requestBody = { ...requestBody, ...shippingInfo };
    }

    /* If there is salesTax, shippingCountryCode is required */
    if (order?.quote?.totalTax > 0) {
      requestBody = { ...requestBody, shippingCountryCode: "US" };
    }

    const orderResponse = await createOrder({ requestBody });
    if (orderResponse?.statusCode && orderResponse?.statusCode !== 200) {
      notifyError(orderResponse?.message);
    } else {
      if (orderResponse?.id) {
        await clearFromCart();
        navigate(`/my-orders`);
      }
    }
  };

  const validateDigitalItemStorageSelection = () => {
    const values = methods.getValues();
    const walletId = user?.wallets?.walletDetails?.id;
    const hashpackWalletId = user?.hashpackWallets?.accountIds?.[0];
    /* Handle possibility of digital item storage selected but not connected */
    if (
      (values?.defaultWalletType === HASHPACK_WALLET_TYPE &&
        !hashpackWalletId) ||
      (values?.defaultWalletType === VENLY_WALLET_TYPE && !walletId)
    ) {
      notifyError("You must connect your wallet to buy, sell & trade items.");
      return false;
    }
    return true;
  };

  /* Square Payment Related */
  const paymentSucceeded = async ({ paymentIntent }) => {
    const values = methods.getValues();

    /* Handle possibility of digital item storage selected but not connected */
    const validSelection = validateDigitalItemStorageSelection();
    if (!validSelection) {
      return;
    }

    let orderBody = { paymentIntent };

    if (requiresShipping) {
      const shippingInfo = retrieveShippingInfo({
        values,
        quoteData: order?.quote,
      });
      orderBody = { paymentIntent, shippingInfo };
    }

    setCheckoutInProgress(true);

    if (!isVaultSelected()) {
      await associateCollections({
        user,
        items,
        values,
      });
    }

    await generateOrder(orderBody);
    setCheckoutInProgress(false);
  };

  const resaleHashpackFlow = async ({ items }) => {
    const values = methods.getValues();

    /* Currently working for only one resale item */
    const itemToBuy = order?.products[0];

    let orderBody = {
      paymentIntent: {
        id: "",
      },
    };

    const productString = generateMemoFromItems({ items: order?.products });
    /* pay 2.5% commission */
    let payWithHashpackData = {
      user,
      toAccount: REACT_APP_HEDERA_ACCOUNT_ID,
      amount: order?.amount * SALE_TRANSACTION_FEE,
      memo: `You will receive: ${productString}`,
    };
    const res = await payWithHashconnect(payWithHashpackData);

    /* pay remainder to seller account */
    payWithHashpackData = {
      user,
      toAccount: itemToBuy?.walletIdForMoney,
      amount: order?.amount * (1 - SALE_TRANSACTION_FEE),
      memo: `You will receive: ${productString}`,
    };
    const { response: r2 } = await payWithHashconnect(payWithHashpackData);

    const transactionHash = r2?.transactionId;
    const paymentIntent = {
      id: transactionHash,
    };
    orderBody = { paymentIntent };

    if (requiresShipping) {
      const values = methods.getValues();
      const shippingInfo = retrieveShippingInfo({
        values,
        quoteData: order?.quote,
      });
      orderBody = { paymentIntent, shippingInfo };
    }

    await associateCollections({ user, items, values });
    await generateOrder(orderBody);
  };

  /* Resale Payment */
  const resaleFlow = async ({ items }) => {
    const values = methods.getValues();
    /* Currently working for only one resale item */
    const itemToBuy = order?.products[0];
    let orderBody = {
      paymentIntent: {
        id: "",
      },
    };
    const fromWalletId = user?.wallets?.walletDetails?.id;

    /* pay 2.5% commission */
    let payWithVenlyData = {
      fromWalletId,
      toWalletId: REACT_APP_HEDERA_ACCOUNT_ID,
      value: order?.amount * SALE_TRANSACTION_FEE,
    };

    displayPayVenlyIndications();
    const { response: r1 } = await payWithVenly(payWithVenlyData);
    if (r1?.error) {
      console.log(r1.error);
      return;
    } else {
      hidePayWithVenlyIndications();
    }
    /* pay remainder to seller account */
    payWithVenlyData = {
      fromWalletId,
      toWalletId: itemToBuy?.walletIdForMoney,
      value: order?.amount * (1 - SALE_TRANSACTION_FEE),
    };
    displayPayVenlyIndications();
    const { response: r2 } = await payWithVenly(payWithVenlyData);
    if (r2?.error) {
      console.log(r1.error);
      return;
    } else {
      hidePayWithVenlyIndications();
    }

    const paymentIntent = {
      id: r2?.result?.transactionHash,
    };
    orderBody = { paymentIntent };

    if (requiresShipping) {
      const values = methods.getValues();
      const shippingInfo = retrieveShippingInfo({
        values,
        quoteData: order?.quote,
      });
      orderBody = { paymentIntent, shippingInfo };
    }

    await associateCollections({ user, items, values });
    await generateOrder(orderBody);
  };

  /* HashPack Related */
  const hashpackPayment = async () => {
    const values = methods.getValues();
    const resaleItems = await getResaleItems({ items: order?.products });
    if (resaleItems?.length > 0) {
      resaleHashpackFlow({ items: order?.products });
      return;
    }

    let orderBody = {
      paymentIntent: {
        id: "",
      },
    };
    const productString = generateMemoFromItems({ items: order?.products });

    const res = await payWithHashconnect({
      user,
      toAccount: REACT_APP_HEDERA_ACCOUNT_ID,
      amount: order?.amount,
      memo: `You will receive: ${productString}`,
    });

    if (res?.error) {
      if (res?.error === "USER_REJECT") {
        notifyError("Payment rejected by user, please try again ");
      } else {
        notifyError(res?.error);
      }
    } else {
      const transactionHash = res?.response?.transactionId;

      const paymentIntent = {
        id: transactionHash,
      };
      orderBody = { ...orderBody, paymentIntent };
      if (requiresShipping) {
        const values = methods.getValues();
        const shippingInfo = retrieveShippingInfo({
          values,
          quoteData: order?.quote,
        });
        orderBody = { ...orderBody, shippingInfo };
      }
      await associateCollections({ user, items, values });
      await generateOrder(orderBody);
    }
  };

  /* Venly Related */
  const venlyPayment = async () => {
    const values = methods.getValues();

    const walletId = user?.wallets?.walletDetails?.id;

    const resaleItems = await getResaleItems({ items: order?.products });
    if (resaleItems?.length > 0) {
      resaleFlow({ items: order?.products });
      return;
    }

    setCheckoutInProgress(true);

    let orderBody = {
      paymentIntent: {
        id: "",
      },
    };

    if (order?.amount > 0) {
      overlayRef.current.classList.add("active");
      payIndicationsRef.current.classList.add("active");

      const { response } = await payWithVenly({
        fromWalletId: walletId,
        toWalletId: REACT_APP_HEDERA_ACCOUNT_ID,
        value: order?.amount,
      });

      if (response?.error) {
        notifyError(response.error);
        return;
      }
      const paymentIntent = {
        id: response?.result?.transactionHash,
      };
      orderBody = { paymentIntent };
      if (requiresShipping) {
        const values = methods.getValues();
        const shippingInfo = retrieveShippingInfo({
          values,
          quoteData: order?.quote,
        });
        orderBody = { paymentIntent, shippingInfo };
      }
      await associateCollections({ user, items, values });
      await generateOrder(orderBody);
    } else {
      if (requiresShipping) {
        const values = methods.getValues();
        const shippingInfo = retrieveShippingInfo({
          values,
          quoteData: order?.quote,
        });
        orderBody = { ...orderBody, shippingInfo };
      }
      await associateCollections({ user, items, values });
      await generateOrder(orderBody);
    }

    setCheckoutInProgress(false);
  };

  /* General Payment */
  const walletPayment = async () => {
    const values = methods.getValues();
    const hashpackWalletId = user?.hashpackWallets?.accountIds?.[0];
    const walletAddress = user?.wallets?.walletDetails?.address;

    /* Handle Free HBAR Priced Orders */
    if (order?.amount === 0) {
      let orderBody = {
        paymentIntent: {
          id: "",
        },
      };
      await associateCollections({
        user,
        items,
        values,
      });
      await generateOrder(orderBody);
      return;
    }

    /* Handle possibility of digital item storage selected
       but not connected (includes notification)
    */
    const validSelection = validateDigitalItemStorageSelection();
    if (!validSelection) {
      return;
    }

    /* Succesful Scenarios */
    if (hashpackWalletId && !walletAddress) {
      hashpackPayment();
    }

    if (walletAddress && !hashpackWalletId) {
      venlyPayment();
    }

    /* Unsuccesful scenario acme lab vaults selected
      but no wallet connected to pay with (includes notification)
    */
    if (!walletAddress && !hashpackWalletId) {
      notifyError("You must connect your wallet to buy, sell & trade items.");
    }
  };

  const getQuoteData = async ({ items, isHBARPriced }) => {
    let quotesData;
    const requiresProdigi = await requiresProdigiValidation({
      items,
    });

    const prodigiProducts = await getProducts();
    prodigiProducts && setProdigiProduct(prodigiProducts[0]);
    prodigiProducts && (await calculateSku(prodigiProducts[0].skus[0]));

    if (isHBARPriced) {
      quotesData = updateHBARQuoteData({ products: items });
    } else if (!isHBARPriced && !requiresProdigi) {
      quotesData = updateNonProdigiQuoteData({ products: items });
    } else {
      quotesData = await updateQuoteData({
        value: "Standard",
        sku: prodigiProducts[0],
        products: items,
      });
    }
    return { quotesData, prodigiProducts };
  };

  const getProdigiProducts = async ({ items, isHBARPriced }) => {
    let { quotesData, prodigiProducts } = await getQuoteData({
      items,
      isHBARPriced,
    });

    setAmount(parseInt(quotesData?.newAmount));

    setOrder({
      products: items,
      quote: quotesData?.quoteResponse,
      amount: quotesData?.newAmount,
      subtotal: quotesData?.subtotal,
      prodigiProduct: prodigiProducts[0],
    });
  };

  const setDigitalOrderData = ({ items, isHBARPriced }) => {
    const { total } = calculateProductTotal({ products: items });
    const quoteResponse = {
      itemsCost: "0.00",
      totalTax: "0.00",
      shippingCost: "0.00",
      totalCost: "0.00",
    };
    const newAmount = isHBARPriced ? total : total * 100;

    setOrder({
      products: items,
      quote: quoteResponse,
      subtotal: total,
      amount: newAmount,
    });
    setAmount(parseInt(newAmount));
  };

  const checkForTaxRequirements = async ({ items, isHBARPriced }) => {
    const requiresTax = await requiresTaxCalculation({
      items,
    });
    if (!requiresTax || isHBARPriced) setTaxCalculated(true);
  };

  const checkForShippingRequirements = async ({ items, isHBARPriced }) => {
    const requiresShipping = await requiresShippingValidation({ items });
    if (requiresShipping) {
      getProdigiProducts({ items, isHBARPriced });
    } else {
      setDigitalOrderData({ items, isHBARPriced });
    }

    setRequiresShipping(requiresShipping);
  };

  const getReservationData = async () => {
    const reservationData = await getReservation({ reservationId });
    if (!reservationData?.id) {
      customNavigate({ path: `/out-of-stock` });
      return;
    }
    setReservationData(reservationData);
    const resItems = reservationData.orderReserveDetails;
    const items = await populateItemsDetails({ items: resItems });
    setItems(items);
    const hbarPricedProducts = await getHbarPricedProducts({ items });
    const isHBARPriced = hbarPricedProducts?.length > 0;
    setIsHBARPriced(isHBARPriced);

    checkForShippingRequirements({ items, isHBARPriced });
    checkForTaxRequirements({ items, isHBARPriced });
  };

  useEffect(() => {
    if (stateField && zipCodeField) {
      fetchTax();
      methods.trigger();
    }
  }, [stateField, zipCodeField]);

  useEffect(() => {
    getReservationData();
  }, []);

  useEffect(() => {
    /* Digital Item Storage Dropdown Values*/
    let enabledWalletTypes = [VAULT_WALLET_TYPE];
    const walletId = user?.wallets?.walletDetails?.id;
    const hashpackWalletId = user?.hashpackWallets?.accountIds?.[0];

    if (walletId) {
      enabledWalletTypes.push(VENLY_WALLET_TYPE);
      setConnectedWallet(VENLY_WALLET_TYPE);
    }

    if (hashpackWalletId) {
      enabledWalletTypes.push(HASHPACK_WALLET_TYPE);
      setConnectedWallet(HASHPACK_WALLET_TYPE);
    }

    setEnabledWalletTypes(enabledWalletTypes);
    /* Removes background if user is connected */
    if (
      user?.wallets?.owner?.email &&
      walletActionRef.current.status === "AUTHENTICATING_USER"
    ) {
      overlayRef.current.classList.remove("active");
      connectIndicationsRef.current.classList.remove("active");
    }

    /* Removes background if wallet is connected */
    if (
      user?.wallets?.walletDetails?.id &&
      walletActionRef.current.status === "CONNECTING_WALLET"
    ) {
      overlayRef.current.classList.remove("active");
      selectIndicationsRef.current.classList.remove("active");
    }
  }, [user]);

  if (!order?.products?.length > 0)
    return (
      <div className="w-100 vh-100 d-flex justify-content-center align-items-center ">
        <Spinner />
      </div>
    );
  return (
    <StyledCheckout>
      <VenlyOverlay overlayRef={overlayRef} />

      <VenlyConnectIndications
        connectIndicationsRef={connectIndicationsRef}
        connectWallet={connectWallet}
      />

      <VenlySelectWalletIndications
        selectIndicationsRef={selectIndicationsRef}
        selectWallet={selectWallet}
      />

      <VenlyPayWithVenlyIndications
        payIndicationsRef={payIndicationsRef}
        payWithVenly={walletPayment}
      />

      {checkoutInProgress && (
        <div className="progress-overlay">
          <div className="progess-status">Processing your order. This may take a few moments to complete.</div>
          <Spinner />
        </div>
      )}

      <ReservationTimer reservationData={reservationData} />
      <section className="tf-activity s1 tf-section">
        <FormProvider {...methods}>
          <form id="payment-form">
            <div className="themesflat-container">
              <br />
              <div className="row ">
                <div className="col-lg-5 offset-lg-1 col-md-12 col-12">
                  <CheckoutItemList
                    prodigiProduct={prodigiProduct}
                    order={order}
                    isHBARPriced={isHBARPriced}
                    requiresShipping={requiresShipping}
                    taxCalculated={taxCalculated}
                  />

                  <div className="mt-4">
                    <h4 id="shipping-header">Digital Items Storage:</h4>
                    <DefaultWalletDropdown
                      methods={methods}
                      user={user}
                      dropdownValues={enabledWalletTypes}
                      connectedWallet={connectedWallet}
                    />
                  </div>
                </div>

                <div className="col-lg-5  col-md-12 col-12">
                  {requiresShipping && (
                    <>
                      <h4 id="shipping-header">Shipping Method</h4>
                      {/* <AttributesSection
                        requireSizing={true}
                        attributes={{}}
                        sku={prodigiProduct.skus[0]}
                        itemID={item.id}
                        action={updateQuote}
                      /> */}
                      <ShippingInfo user={user} />
                    </>
                  )}

                  <h4 id="shipping-header">Payment Method</h4>

                  {/* USD Priced Product Logic */}
                  {!isHBARPriced && order?.amount > 0 && (
                    /* {!isHBARProduct && item?.price > 0 && ( */
                    <div id="stripe-form-container">
                      {stripeLoading ? (
                        <Spinner />
                      ) : (
                        <FormWrapper
                          item={item}
                          endpoint={"prodigi-payment-intent"}
                          initialValidation={
                            validateDigitalItemStorageSelection
                          }
                          action={paymentSucceeded}
                          amount={amount}
                          user={user}
                          requiresShipping={requiresShipping}
                          taxCalculated={taxCalculated}
                        />
                      )}
                    </div>
                  )}

                  {/* Free non-shippable USD product button */}
                  {!isHBARPriced && order?.amount === 0 && (
                    <div>
                      <button
                        id="submit"
                        className="sc-button"
                        type="submit"
                        onClick={(event) => {
                          event.preventDefault();
                          paymentSucceeded({ paymentIntent: { id: "" } });
                        }}
                      >
                        <h5 id="button" style={{ padding: "0 40px" }}>
                          Place Order
                        </h5>
                      </button>
                    </div>
                  )}

                  {/* HBAR Priced Product Logic */}
                  {isHBARPriced && (
                    <div>
                      <button
                        id="submit"
                        className="sc-button"
                        type="submit"
                        onClick={methods.handleSubmit(walletPayment)}
                      >
                        <h5 id="button" style={{ padding: "0 40px" }}>
                          {amount > 0 ? "Pay with wallet" : "Place Order"}
                        </h5>
                      </button>

                      <div className="wallet-section">
                        {user?.hashpackWallets?.accountIds && (
                          <>
                            <HashpackWalletConnected
                              hashAccountId={
                                user?.hashpackWallets?.accountIds?.[0]
                              }
                            />
                          </>
                        )}

                        {user?.wallets.owner.email && (
                          <WalletConnected
                            wallets={user?.wallets}
                            selectWallet={selectWallet}
                          />
                        )}

                        {!user?.hashpackWallets?.accountIds &&
                          !user?.wallets.walletDetails.id && (
                            <ConnectWallet
                              connectWallet={() => {
                                customNavigate({
                                  path: `/wallet-connect`,
                                  state: {
                                    intendedRoute: `/checkout/reservation/${reservationId}`,
                                  },
                                });
                              }}
                            />
                          )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </section>
    </StyledCheckout>
  );
};

export default CheckoutReservation;
