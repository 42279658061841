import React, { useEffect, useState } from "react";
import { getProductById } from "../../../../api/products/getProducts";
import getOneSpace from "../../../../api/spaces/getSpaceByUserId";
import getUser from "../../../../api/user/getUser";
import { priceFormatter } from "../../../../helpers/formatters";
import {
  isResaleItem,
  itemDetailsBaseRoute,
} from "../../../../helpers/itemHelpers";
import useIPFS from "../../../../hooks/useIPFS";
import LinkWithQueryParams from "../../../LinkWithQueryParams";
import Spinner from "../../Spinner/Spinner";
import Like from "../Like";
import NFTCardVideo from "./NFTCardVideo";
import { StyledNFT } from "./styles";
import NFTCardVisual from "./NFTCardVisual";

const NFTCard = ({
  card,
  isPreview = false,
  previewType,
  isBaseItem = false,
  artistNameShown = false,
}) => {
  const [dataUser, setDataUser] = useState({});
  const [space, setSpace] = useState({});
  const [loading, setLoading] = useState(true);
  const { element, getData } = useIPFS();
  const [productName, setProductName] = useState(null);

  const getDataUser = async (userId) => {
    try {
      if (userId) {
        const response = await getUser(userId);
        response && setDataUser(response);
        const userSpace = await getOneSpace(userId);
        userSpace && setSpace(userSpace);
        if (!isPreview)
          await getData(card.finalCid ? card?.finalCid : card.cid);
        const productId = card?.productId;
        const { name } = await getProductById({ productId });
        setProductName(name);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    (async () => {
      isBaseItem
        ? await getDataUser(card.originalOwnerId)
        : await getDataUser(card.belongsToId);
    })();
  }, [card.id]);

  return (
    <StyledNFT className="nft-card">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
            width: "300px",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          <div className="top-section">
            {isBaseItem && !isPreview && (
              <Like
                elementId={card.id}
                endpoint="item"
                likes={card.numberOfLikes}
                liked={card.liked}
                isCard={true}
              />
            )}

            <NFTCardVisual
              element={element}
              previewType={previewType}
              isPreview={isPreview}
              itemData={card}
            />
          </div>

          <div className="bottom-section">
            <div className="content">
              <div className="top-content">
                <div className="title">
                  <LinkWithQueryParams
                    path={`/${itemDetailsBaseRoute({ item: card })}/${card.id}`}
                  >
                    <span>{isPreview ? card.name : element.name}</span>
                  </LinkWithQueryParams>
                </div>

                {artistNameShown && dataUser?.displayName && (
                  <div className="artist-name">
                    <span>{dataUser?.displayName}</span>
                  </div>
                )}

                {productName && (
                  <div className="product-name">{productName}</div>
                )}
              </div>

              <hr />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span className="price">
                  {priceFormatter({
                    currencyType: isResaleItem({ item: card })
                      ? card?.saleCurrencyType
                      : card?.currencyType,
                    price: isResaleItem({ item: card })
                      ? card?.salePrice
                      : card?.price,
                  })}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </StyledNFT>
  );
};

export default NFTCard;
