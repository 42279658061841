import React from 'react';
import { Modal } from 'react-bootstrap';
import InfoAfterBuy from './InfoAfterBuy';
import InfoAfterShip from './InfoAfterShip';

const ModalAfterAction = ({ show, onHide, modalType }) => {
  const modalRedirector = {
    BUY: <InfoAfterBuy />,
    SHIP: <InfoAfterShip />,
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>
      {modalRedirector[modalType]}
    </Modal>
  );
};

export default ModalAfterAction;
