import styled from "styled-components";
export const StyledCartCard = styled.div`
  border-bottom: 1px solid #cdcdcd !important;
  margin-bottom: 20px;

  .sc-card-activity {
    background: transparent !important;
    box-shadow: none;
    border: none !important;
    margin-bottom: 20px;
  }

  .delete-button {
    font-size: 36px;
    font-weight: 400;
    color: black;
    /* padding: 10px; */
    padding: 0;
    background-color: transparent;
    font-weight: normal;
    border: none;
    margin-right: 30px;
  }

  .sc-card-activity .content .infor h3 {
    margin-bottom: 5px;
  }
  .infor h3 a {
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
    color: #000000;
  }
  .infor .artistName {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #1b70d0;
    margin-bottom: 8px;
  }

  .infor .product-name {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #9a9a9a;
    margin-bottom: 26px;
  }

  .sc-button-3 {
    background: #ff7b7c;
    border: none;
    padding: 13px 28px;
    border-radius: 0;
    font-weight: 700;
    font-size: 15px;
    line-height: 15px;
    color: #ffffff;
  }

  .input-group {
    width: 200px;
    display: flex;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .input-group-btn {
    height: 100%;
    height: 40px;
  }
  .input-group-btn button {
    height: 100%;
  }
  #quantity-label {
    background: #ff7b7c;
    border: none;
    border-radius: 0;
    font-weight: 700;
    font-size: 15px;
    line-height: 15px;
    color: #ffffff;
    padding-left: 10px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  #quantity {
    background: #ff7b7c;
    border: none;
    padding: 13px 10px;
    border-radius: 0;
    font-weight: 700;
    font-size: 15px;
    line-height: 15px;
    color: #ffffff;
    height: 40px;
  }
  .quantity-left-minus,
  .quantity-right-plus {
    background: #ff7b7c;
    border: none;
    border-radius: 0;
  }
  .input-group .form-control {
    width: auto;
  }

  .price h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
  }

  @media (max-width: 768px) {
    .delete-button {
      margin-right: 12px;
    }

    .sc-card-activity.style-2 .content .media {
      width: 62px;
      height: 62px;
      margin-right: 12px;
    }

    .infor h3,
    .infor h3 a {
      font-size: 16px;
      line-height: 16px;
    }

    .sc-card-activity .content .infor h3 {
      margin-bottom: 4px;
    }

    .infor .artistName {
      font-size: 10px;
      line-height: 10px;
      margin-bottom: 4px;
    }

    .infor .product-name {
      font-size: 10px;
      line-height: 10px;
    }

    .price h5 {
      font-size: 10px;
      line-height: 10px;
    }

    .input-group {
      width: 100%;
      .input-group-btn,
      #quantity-label,
      #quantity {
        height: 20px;
        font-size: 10px;
        line-height: 10px;
      }
      #quantity {
        padding: 0px;
        padding-left: 13px;
      }
    }
  }
`;
