import React from "react";
import { useForm } from "react-hook-form";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import {
  EMAIL_INVALID_MESSAGE,
  EMAIL_PATTERN,
  FORGOT_PASSWORD_EMAIL_SENT,
  REQUIRED_MESSAGE,
} from "../../constants/constants";
import { auth0ResetPassword } from "../../helpers/auth0Service";
import { useCustomNavigator } from "../../hooks/useCustomNavigator";
import { ForgotPasswordPage } from "./styles";

const ForgotPassword = () => {
  const { customNavigate } = useCustomNavigator();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const email = data?.email;
      const result = await auth0ResetPassword({ email });
      if (result && result.includes(FORGOT_PASSWORD_EMAIL_SENT)) {
        customNavigate({
          path: "/forgot-password-sent",
          state: { userEmail: data?.email },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ForgotPasswordPage>
      <div>
        <Header />

        <section className="tf-forgot-password tf-section">
          <div className="centered-content">
            <div className="row justify-content-center m-0 w-100">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Forgot your password?</h1>
              </div>
            </div>

            <div className="row justify-content-center m-0">
              <div className="instructions-container">
                no worries, type in your email address and we'll send you a link
                to reset your password.
              </div>
            </div>

            <div className="form-inner">
              <form
                id="contactform"
                noValidate="novalidate"
                className="form-submit"
                onSubmit={handleSubmit(onSubmit)}
              >
                <fieldset className="mb-4">
                  <input
                    id="email"
                    name="email"
                    tabIndex="2"
                    aria-required="true"
                    required
                    type="email"
                    placeholder="email address"
                    className="form-field-input"
                    {...register("email", {
                      required: REQUIRED_MESSAGE,
                      pattern: {
                        value: EMAIL_PATTERN,
                        message: EMAIL_INVALID_MESSAGE,
                      },
                    })}
                  />
                  <p className="error-message">{errors.email?.message}</p>
                </fieldset>

                <div className="row justify-content-center m-0">
                  <button className="submit">send link</button>
                </div>
              </form>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </ForgotPasswordPage>
  );
};

export default ForgotPassword;
