const { REACT_APP_API_URL } = process.env;

export const getProductById = async ({ productId }) => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}product`);
    const products = await response.json();
    const product = products.find((product) => product.id === productId);

    return product;
  } catch (error) {
    console.log(error);
  }
};

export const getProducts = async () => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}product`);
    const products = await response.json();
    return products;
  } catch (error) {
    console.log(error);
  }
};

export const getProductAddons = async () => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}product/addons`);
    const products = await response.json();
    return products;
  } catch (error) {
    console.log(error);
  }
};

export const getProductAddon = async ({ id }) => {
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}product/addons/one/${id}`
    );
    const products = await response.json();
    return products;
  } catch (error) {
    console.log(error);
  }
};

export const getProductSku = async ({ requestBody }) => {
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}product/addons/sku-lookup`,
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "content-type": "application/json",
        },
      }
    );
    const products = await response.json();
    return products;
  } catch (error) {
    console.log(error);
  }
};
