import React from "react";
import { Modal } from "react-bootstrap";

const COAModal = ({ show, onHide, img, alt }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>
        <div className="image-container">
          <div className="image">
            <img src={img} alt={alt} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default COAModal;
