import styled from "styled-components";
import { COMMON_STYLES } from "../../constants/genericStyles";
import { HEADER_HEIGHT } from "../../constants/constants";

export const customSelectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: "18px",
    color: "var(--primary-color4)",
    padding: "10px 15px",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "18px",
    padding: "13px 15px",
  }),
};

export const CreateCollectionPage = styled.div`
  .tf-create-collection {
    padding-top: ${(HEADER_HEIGHT + 27).toString() + "px"};
  }

  #right-section {
    border-left: 1px solid #cdcdcd;
    .header-description {
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      color: #000000;
      padding-bottom: 10px;
      border-bottom: 1px solid #cdcdcd;
      margin-bottom: 22px;
    }
  }
  .page-title-heading {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 10px;
    .vertical-line {
      height: 30px;
      border-left: 6px solid #ffd061;
    }

    .heading {
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
      color: #011126;
    }
  }
  .subheader {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    /* identical to box height */

    text-transform: capitalize;

    color: #000000;
    margin-bottom: 32px;
  }

  /* input,
  .tab-create-item form select,
  .tab-create-item form textarea,
  .tab-create-item form section { */
  form select {
    border: 1px solid rgba(138, 138, 160, 0.3);
    border-radius: 8px;
    color: var(--primary-color4);
    font-size: 14px;
  }

  form .react-select__input {
    border: 1px solid rgba(138, 138, 160, 0.3);
    border-radius: 8px;
    color: var(--primary-color4);
    font-size: 14px;
  }

  /* .tab-create-item form input,
  .tab-create-item form select { */
  form select {
    padding: 0px 20px;
    margin-bottom: 24px;
    line-height: 44px;
  }
  form fieldset {
    margin-bottom: 24px;
  }

  .field-label {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 8px;
  }

  .error-message,
  .card-error {
    color: #e42c2c;
    font-weight: 400;
    font-size: 15px;
  }
  #digital-right-section {
    border-left: 1px solid #cdcdcd;
  }
  #digital-collection {
    margin-bottom: 40px;
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #cdcdcd;
  }

  #collection-image-preview {
    .subheader {
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: #000000;
    }
    .image-indications {
      width: 100%;
      font-weight: 300;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      color: #000000;
    }
    .btn-upload {
      position: relative;
      width: 100%;
      height: 48px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      /* background-color: rgba(81,66,252,0.1); */
      /* border-radius: 30px; */
      background: #25a29d;
      border: none;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      border-radius: 8px;
      font-size: 14px;
      /* line-height: 22px; */
      font-weight: 700;
      color: #ffffff;
      margin-top: 21px;

      margin-top: 0;
      margin-bottom: 8px;
    }

    .collection-image-container {
      height: 220px;
      width: 220px;
      margin-bottom: 10px;
    }

    .collection-image {
      ${COMMON_STYLES.GENERIC_IMAGE_CONTAINER({
        paddingTop: "100%",
      })}
      border-radius: 19px;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      .gray-background {
        position: absolute;
        top: 0;
        width: 229px;
        height: 220px;
        background: #d9d9d9;
        border-radius: 19px;
      }
    }
  }

  .card-media {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    .image-container {
    }
    .gray-background {
      width: 285px;
      height: 264px;
      background-color: #d9d9d9;
      box-shadow: 4px 0px 12px rgba(0, 0, 0, 0.25);
      border-radius: 27px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
    }
    .btn-upload {
      margin-top: 0;
      margin-bottom: 8px;
    }
  }

  .submit {
    width: 100%;
    background: #ff7b7c;
    box-shadow: 5px 6px 16px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
  .uploadFile {
    height: 50px;
    width: 50%;
    background-color: #25a29d;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }
  .uploadFile input[type="file"]:before {
    color: white;
    background-color: transparent;
  }
  .uploadFile .form-control:focus {
    background-color: transparent;
  }
  .uploadFile input[type="file"] {
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
  }
  #collection-image {
    width: 100%;
    height: auto;
    max-width: 285px;
  }
  @media (max-width: 768px) {
  }
`;
