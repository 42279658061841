import { getBaseItem } from "../api/Item/baseItems";
import getItem from "../api/Item/getItem";
import getCollection from "../api/collections/getCollection";
import {
  getProductAddon,
  getProductById,
  getProductSku,
} from "../api/products/getProducts";
import {
  ADDON_TYPE,
  BASE_ITEM_TYPE,
  HBAR_CURRENCY_TYPE,
  REACT_APP_HEDERA_API,
} from "../constants/constants";
import { isAddonItem, isResaleItem } from "./itemHelpers";

const isValidNumber = ({ receivedNumber }) => {
  return (
    typeof Number(receivedNumber) === "number" &&
    !Number.isNaN(Number(receivedNumber))
  );
};

export const retrieveShippingInfo = ({ values, quoteData }) => {
  const shippingInfo = {
    prodigiShippingMethod: "Budget",
    shippingEmail: values?.email,
    shippingLastName: values?.lastName,
    shippingFirstName: values?.firstName,
    shippingStreetAddress: values?.streetAddress,
    shippingCity: values?.city,
    shippingState: values?.state,
    shippingZipCode: values?.zipCode,
    shippingCost: isValidNumber({ receivedNumber: quoteData?.totalCost })
      ? Number(quoteData?.totalCost)
      : 0,
  };
  if (values?.streetAddressContd)
    shippingInfo.shippingStreetAddressContd = values?.streetAddressContd;

  return shippingInfo;
};

export const retrieveTaxInfo = ({ quoteData }) => {
  const taxInfo = {
    salesTax: isValidNumber({ receivedNumber: quoteData?.totalTax })
      ? Number(quoteData?.totalTax)
      : 0,
  };
  return taxInfo;
};

export const getResaleItems = async ({ items }) => {
  return items.filter((item) => isResaleItem({ item }));
};

export const getHbarPricedProducts = async ({ items }) => {
  return items.filter((item) => {
    const currencyType = isResaleItem({ item })
      ? item?.saleCurrencyType
      : item?.currencyType;
    return currencyType === HBAR_CURRENCY_TYPE;
  });
};

export const itemRequiresProdigi = ({ productData }) =>
  productData?.prodigiPrint === "Y";

export const itemRequiresTax = ({ productData }) =>
  productData?.salesTax === "Y";

export const itemRequiresShipping = ({ item }) => {
  if (isAddonItem({ item })) {
    return item?.shippingRequired === "Y";
  } else {
    return (
      item?.productData?.prodigiPrint === "Y" ||
      item?.productData?.sendShippingRequiredNotification === "Y"
    );
  }
};

export const requiresShippingValidation = async ({ items }) => {
  /* Conditions considered to define shipping requirements are determined as follows
    sendShippingRequiredNotification = Y
    prodigiPrint = Y

    Important Note: items must have a productData property populated
    for this method to work correctly

    Added possibility for addons that require shipping(shippingRequired===Y)
    shippingRequired field must be populated
  */
  return items.filter((item) => itemRequiresShipping({ item })).length > 0;
};

export const requiresProdigiValidation = async ({ items }) => {
  return (
    items.filter((p) => itemRequiresProdigi({ productData: p?.productData }))
      .length > 0
  );
};

export const requiresTaxCalculation = async ({ items }) => {
  return (
    items.filter((p) => itemRequiresTax({ productData: p?.productData }))
      .length > 0
  );
};

const updateBaseItemQty = ({ baseItems }) => {
  const uniqueBaseItems = [
    ...new Set(baseItems.map((item) => item.baseItemId)),
  ];
  const updatedBaseItems = uniqueBaseItems.map((uniqueBaseItem) => {
    const filteredBaseItems = baseItems.filter(
      (item) => item.baseItemId === uniqueBaseItem
    );
    return {
      ...filteredBaseItems[0],
      quantity: filteredBaseItems.length,
    };
  });

  return updatedBaseItems;
};

export const generateMemoFromItems = ({ items }) => {
  /* FIXME: Currently we cannot render all the products (productString) because we are
    getting MEMO_TOO_LONG error, so just quantity matters */
  let productString = "";
  let quantity = 0;
  items.forEach((product, i) => {
    quantity = quantity + product.quantity;
    const productDescription =
      product.quantity +
      " x " +
      product.title +
      (product?.productIdName ? ` (${product?.productIdName})` : "");
    productString += productDescription;
    if (i < items?.length - 1) {
      productString += " , \n";
    }
  });

  // return productString;
  return `${quantity} items`;
};

export const addOrderSaleDetails = ({ order, orderBody }) => {
  let orderSaleDetails = [];
  order?.products.forEach((product) => {
    if (isResaleItem({ item: product })) {
      /* process done for ADDONS */
      const saleDetail = {
        orderReserveDetailId: product?.orderReserveDetailId,
        salePrice: product?.price,
        transactionFee: 0,
      };
      orderSaleDetails.push(saleDetail);
    } else {
      /* process done for baseItems */
      if (product?.orderReserveDetails) {
        product?.orderReserveDetails.forEach((orderReserveItem) => {
          const saleDetail = {
            orderReserveDetailId: orderReserveItem?.id,
            salePrice: isResaleItem({ item: product })
              ? product?.salePrice
              : product?.price,
            transactionFee: 0,
          };
          orderSaleDetails.push(saleDetail);
        });
      } else {
        /* Process done for  resale Items*/
        const saleDetail = {
          orderReserveDetailId: product?.orderReserveDetailId,
          salePrice: isResaleItem({ item: product })
            ? product?.salePrice
            : product?.price,
          transactionFee: 0,
        };
        orderSaleDetails.push(saleDetail);
      }
    }
  });
  orderBody.orderSaleDetails = orderSaleDetails;
  return orderBody;
};

/**
 * @description Get Details of items array.
 * @param {items} items
 * @Returns {Items[]} Returns an array with the details of the items
 */
export const populateItemsDetails = async ({ items }) => {
  let checkoutProductsData = [];
  const baseItems = [];
  const resaleItems = [];
  const addonItems = [];
  items.forEach((item) => {
    if (item?.reservationType === BASE_ITEM_TYPE) {
      baseItems.push(item);
    } else if (item?.reservationType === ADDON_TYPE) {
      addonItems.push(item);
    } else {
      resaleItems.push(item);
    }
  });

  if (baseItems?.length > 0) {
    /* We totalize the baseItems for rendering purposes */
    const updatedBaseItems = updateBaseItemQty({ baseItems });

    await Promise.all(
      updatedBaseItems.map(async (item) => {
        try {
          /* Fetch item data from db */
          const itemData =
            item?.reservationType === BASE_ITEM_TYPE
              ? await getBaseItem(item.baseItemId)
              : await getItem(item.itemId);

          /* Added for hashpack memo purposes */
          const productId = itemData?.productId;
          const productData = await getProductById({ productId });
          itemData.productIdName = productData?.name;
          itemData.productData = productData;

          /* preserv reservation ids for order creation */
          const relatedReservationItems = baseItems.filter(
            (relatedItem) => item.baseItemId === relatedItem.baseItemId
          );

          let checkoutItemData = {
            ...itemData,
            orderReserveDetails:
              relatedReservationItems /* Addition of reservation items (regular item ids) */,
            quantity: item.quantity /* Ensures cart quantity remains intact */,
          };
          checkoutProductsData.push(checkoutItemData);
        } catch (e) {}
      })
    );
  }

  if (addonItems?.length > 0) {
    /* ADDON logic */
    await Promise.all(
      addonItems.map(async (addonItem) => {
        try {
          /*Format reservation addon configDetails */
          const configDetails = Object.entries(
            addonItem.addonConfigDetails
          ).map((entry) => ({
            configQuestionId: entry[0],
            configValueId: entry[1],
          }));

          const requestBody = {
            addonId: addonItem.addonId,
            configDetails,
          };

          /* Done to recollect the addon price */
          const sku = await getProductSku({
            requestBody,
          });

          /* Done to recollect shipping Required flag */
          const addonConfig = await getProductAddon({ id: addonItem.addonId });

          if (sku?.success) {
            const itemData = await getItem(addonItem.itemId);
            let checkoutItemData = {
              ...itemData,
              ...addonItem,
              price: sku.price,
              productData: { salesTax: sku.salesTax },
              shippingRequired: addonConfig?.shippingRequired,
              orderReserveDetailId: addonItem.id, //Used for checkout purpose
            };
            checkoutProductsData.push(checkoutItemData);
          }
        } catch (e) {}
      })
    );
  }

  if (resaleItems?.length > 0) {
    /* Resale logic */
    await Promise.all(
      resaleItems.map(async (item) => {
        try {
          const itemData = await getItem(item.itemId);

          /* Added for hashpack memo purposes */
          const productId = itemData?.productId;
          const productData = await getProductById({ productId });
          itemData.productIdName = productData?.name;
          itemData.productData = productData;

          let checkoutItemData = {
            ...itemData,
            quantity: 1,
            orderReserveDetailId: item.id,
          };
          checkoutProductsData.push(checkoutItemData);
        } catch (e) {}
      })
    );
  }
  return checkoutProductsData;
};

/**
 * @description Get Not AssociatedCollections of items Array.
 * Items array must contain a collectionId
 * @param {items} items
 * @Returns {String[]} Array of notAssociatedCollections ids
 */
export const getNonAssocCol = async ({ items }) => {
  const collections = items.map((item) => item.collectionId);
  const uniqueCollections = [...new Set(collections)];
  const collectionsData = await Promise.all(
    uniqueCollections.map(
      async (uniqueCollection) => await getCollection(uniqueCollection)
    )
  );
  const notAssociatedCollections = collectionsData.filter(
    (c) => !c.isAssociated
  );

  return notAssociatedCollections;
};

const getAsocciatedTokens = async ({ accountId }) => {
  let tokens = [];
  let url = REACT_APP_HEDERA_API + "/accounts/" + accountId;
  const accountInfoResponse = await fetch(url, { method: "GET" });

  if (accountInfoResponse.ok) {
    let data = await accountInfoResponse.json();
    tokens = data.balance.tokens.map((token) => token.token_id);
  }

  return tokens;
};

export const getNonAssocCollectionHedera = async ({ items, accountId }) => {
  const tokens = await getAsocciatedTokens({ accountId });

  const collections = items.map((item) => item.collectionId);
  const uniqueCollections = [...new Set(collections)];
  const collectionsData = await Promise.all(
    uniqueCollections.map(
      async (uniqueCollection) => await getCollection(uniqueCollection)
    )
  );

  const notAssociatedCollections = collectionsData.filter(
    (c) => !tokens.includes(c.tokenId)
  );
  return notAssociatedCollections;
};

const states = [
  ["Alabama", "AL"],
  ["Alaska", "AK"],
  ["American Samoa", "AS"],
  ["Arizona", "AZ"],
  ["Arkansas", "AR"],
  ["Armed Forces Americas", "AA"],
  ["Armed Forces Europe", "AE"],
  ["Armed Forces Pacific", "AP"],
  ["California", "CA"],
  ["Colorado", "CO"],
  ["Connecticut", "CT"],
  ["Delaware", "DE"],
  ["District Of Columbia", "DC"],
  ["Florida", "FL"],
  ["Georgia", "GA"],
  ["Guam", "GU"],
  ["Hawaii", "HI"],
  ["Idaho", "ID"],
  ["Illinois", "IL"],
  ["Indiana", "IN"],
  ["Iowa", "IA"],
  ["Kansas", "KS"],
  ["Kentucky", "KY"],
  ["Louisiana", "LA"],
  ["Maine", "ME"],
  ["Marshall Islands", "MH"],
  ["Maryland", "MD"],
  ["Massachusetts", "MA"],
  ["Michigan", "MI"],
  ["Minnesota", "MN"],
  ["Mississippi", "MS"],
  ["Missouri", "MO"],
  ["Montana", "MT"],
  ["Nebraska", "NE"],
  ["Nevada", "NV"],
  ["New Hampshire", "NH"],
  ["New Jersey", "NJ"],
  ["New Mexico", "NM"],
  ["New York", "NY"],
  ["North Carolina", "NC"],
  ["North Dakota", "ND"],
  ["Northern Mariana Islands", "NP"],
  ["Ohio", "OH"],
  ["Oklahoma", "OK"],
  ["Oregon", "OR"],
  ["Pennsylvania", "PA"],
  ["Puerto Rico", "PR"],
  ["Rhode Island", "RI"],
  ["South Carolina", "SC"],
  ["South Dakota", "SD"],
  ["Tennessee", "TN"],
  ["Texas", "TX"],
  ["US Virgin Islands", "VI"],
  ["Utah", "UT"],
  ["Vermont", "VT"],
  ["Virginia", "VA"],
  ["Washington", "WA"],
  ["West Virginia", "WV"],
  ["Wisconsin", "WI"],
  ["Wyoming", "WY"],
];

export const STATES_SELECTION_DROPDOWN = states.map((s) => ({
  label: s[0],
  value: s[1],
}));

export const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
