import styled from "styled-components";
export const StyledModal = styled.div`
  background-color: #011126;
  padding: 30px 50px;

  input {
    max-width: 300px;
    color: white;
  }

  .qr-container {
    width: 300px;
    height: 300px;
    margin-bottom: 20px;
  }

  .qr-label,
  .pairing-code {
    font-size: 20px;
    margin-bottom: 8px;
    color: white;
  }

  .sc-button-3 {
    border: 3px solid var(--button-green);
    color: var(--button-green);
    transition: all 0.3s ease;
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    .qr-label,
    .pairing-code,
    input {
      font-size: 12px;
    }
    .qr-container {
      width: 120px;
      height: 120px;
      margin-bottom: 0px;
    }
  }
`;
