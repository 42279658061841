import { getHeaders } from "../Item/utils";
const { REACT_APP_API_URL } = process.env;

const getUser = async (id) => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}user/one/${id}`);
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

export const getMyUser = async () => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}user/my`, {
      method: "GET",
      headers,
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};
export default getUser;
