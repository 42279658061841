const { REACT_APP_API_URL } = process.env;

const getCollectionsByUserId = async (userId) => {
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}collection/user/${userId}`
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export default getCollectionsByUserId;
