import { CUSTOM_PAGE_SIZE } from "../../constants/constants";
import { getHeaders } from "./utils";
const { REACT_APP_API_URL } = process.env;

export const getBaseItems = async () => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(
      `${REACT_APP_API_URL}item/base-items?` +
        new URLSearchParams({
          customPageSize: CUSTOM_PAGE_SIZE,
        }),
      {
        method: "GET",
        headers,
      }
    );

    return response.json();
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getBaseItem = async (id) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(
      `${REACT_APP_API_URL}item/base-item/one/${id}`,
      {
        method: "GET",
        headers,
      }
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const getBaseItemAvailability = async (id) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(
      `${REACT_APP_API_URL}item/base-item/available-to-buy/${id}`,
      {
        method: "GET",
        headers,
      }
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const getBaseItemsByUser = async (userId) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(
      `${REACT_APP_API_URL}item/base/user/${userId}`,
      {
        method: "GET",
        headers,
      }
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};
