import React from "react";
import styled from "styled-components";
const StyledPayWithVenlyIndications = styled.div`
  .indications {
    display: none;
    width: 22%;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;

    position: fixed;
    z-index: 2147483649;
    right: 7%;
    top: 12%;
  }

  .indications p {
    color: white;
    margin-bottom: 12px;
  }

  .indications .title {
    font-weight: 700;
  }

  .indications.active {
    display: block;
  }

  .popup-btn {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
  }
`;
const VenlyPayWithVenlyIndications = ({ payIndicationsRef, payWithVenly }) => {
  return (
    <StyledPayWithVenlyIndications>
      <div
        id="connect-indications"
        className="indications"
        ref={payIndicationsRef}
      >
        <p className="title">Pop-ups windows are required for Venly Wallet</p>

        <p>
          Please ensure you have disabled your pop-up blocker temporarily to
          support the Venly wallet.
        </p>
        <p>
          If you are seeing this screen, the Venly wallet should be displayed.
          If it is not, please disable your pop-up blocker and click the link
          below.
        </p>
        <p>
          Please note: you will not be able to proceed without enabling the
          Venly wallet.
        </p>

        <div className="popup-btn" onClick={payWithVenly}>
          Click to re-launch the Venly wallet
        </div>
      </div>
    </StyledPayWithVenlyIndications>
  );
};

export default VenlyPayWithVenlyIndications;
