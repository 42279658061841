import React, { Fragment, useState } from "react";
import Collection03 from "../../components/collection/Collection03";
import Spinner from "../../components/layouts/Spinner/Spinner";

const ItemsByCollection = ({ items, stop, measures }) => {
  const [loading] = useState(false);

  return (
    <Fragment>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center w-100"
          style={{
            height: "400px",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          {items?.length > 0 && (
            <Collection03
              data={items}
              stop={stop}
              measures={measures}
              isBaseItem={true}
              artistNameShown={true}
            />
          )}
        </>
      )}
    </Fragment>
  );
};

export default ItemsByCollection;
