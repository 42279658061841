import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import {
  getProductAddon,
  getProductSku,
} from "../../../api/products/getProducts";
import Spinner from "../../../components/layouts/Spinner/Spinner";
import {
  INVALID_CONFIGURATION_COMBINATION,
  OUT_OF_STOCK_ADDON,
  VALID_COMBINATION,
} from "../../../constants/constants";
import {
  categorizeSelection,
  formatFormData,
  getAllSKUs,
} from "../../../helpers/addonHelpers";
import useCart from "../../../hooks/useCart";
import { useCustomNavigator } from "../../../hooks/useCustomNavigator";
import HeadingText from "../../editProfile/HeadingText";
import ValidSkuData from "./ValidSkuData";
import { StyledModal, customSelectStyles } from "./styles";

const ProductOptionsModal = ({
  show,
  handleCloseModal,
  selectedAddon,
  itemId,
}) => {
  const { addToCartPersistWithQuantity } = useCart();
  const { customNavigate } = useCustomNavigator();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const totalAddonQuestions = React.useRef(null);
  const skus = React.useRef(null);
  const itemQtyCpy = React.useRef(null);

  const [isLoading, setIsLoading] = useState();
  const [addonInfo, setAddonInfo] = useState([]);
  const [category, setCategory] = useState();
  const [skuData, setSkuData] = useState();
  const [itemQty, setItemQty] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [cartData, setCartData] = useState();
  const [addonQuestions, setAddonQuestions] = useState([]);
  const [noConfigProduct, setNoConfigProduct] = useState([]);

  const getProductAddonConfig = async () => {
    if (!selectedAddon) return;
    setIsLoading(true);
    const addonConfig = await getProductAddon({ id: selectedAddon });
    setAddonInfo(addonConfig);
    if (addonConfig?.productAddonConfigQuestions?.length > 0) {
      const skusCategories = await getAllSKUs({
        addonConfig,
        addonId: selectedAddon,
      });
      setAddonQuestions(addonConfig?.productAddonConfigQuestions);
      totalAddonQuestions.current =
        addonConfig?.productAddonConfigQuestions?.length;
      skus.current = skusCategories;
    } else {
      const requestBody = {
        addonId: addonConfig?.id,
        configDetails: [],
      };

      const sku = await getProductSku({
        requestBody,
      });
      if (sku?.price >= 0) {
        setNoConfigProduct(sku);
        setSkuData(sku);
        setTotalPrice(sku?.price);
        setCartData({
          addonConfigDetails: [],
          id: selectedAddon,
          addonId: selectedAddon,
          addonSKUId: sku?.id,
          itemId: itemId,
          quantity: 1,
        });
      }
    }
    setIsLoading(false);
  };

  const addToCart = async (data) => {
    await addToCartPersistWithQuantity(cartData);
    customNavigate({ path: "/cart" });
  };

  const resetAddonInfo = () => {
    setAddonInfo([]);
    setItemQty(1);
    setAddonQuestions([]);
    setCategory();
    skus.current = null;
  };

  const onHide = async () => {
    handleCloseModal();
  };

  const decrementQty = async (item) => {
    if (itemQty > 1) {
      setItemQty((count) => count - 1);
    }
  };

  const incrementQty = async () => {
    setItemQty((count) => count + 1);
  };

  const handleChange = (e) => {
    let newValue = e.target.value;
    setItemQty(newValue);
  };

  useEffect(() => {
    const total = skuData?.price * itemQty;
    setTotalPrice(total);
    setCartData({ ...cartData, quantity: itemQty });
    itemQtyCpy.current = itemQty;
  }, [itemQty]);

  useEffect(() => {
    watch((value) => {
      const formData = formatFormData({ data: value });
      if (Object.keys(formData).length === totalAddonQuestions.current) {
        const { category } = categorizeSelection({
          skus: skus.current,
          formData,
        });

        setCategory(category);
        if (category) {
          const retrievedCombination = category.combination;

          const skudata = retrievedCombination.filter((combination) =>
            combination.hasOwnProperty("skuData")
          );

          const addonConfigDetails = retrievedCombination.filter(
            (combination) => !combination.hasOwnProperty("skuData")
          );

          if (skudata?.length > 0) {
            setSkuData(skudata[0].skuData);
            setTotalPrice(skudata[0].skuData?.price);
            setCartData({
              addonConfigDetails,
              id: selectedAddon,
              addonId: selectedAddon,
              addonSKUId: skudata[0].skuData?.id,
              itemId: itemId,
              quantity: 1,
            });
          }
        }
      }
    });
  }, [selectedAddon]);
  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={resetAddonInfo}
      onEnter={getProductAddonConfig}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="popup-blocker-modal"
    >
      <Modal.Body>
        <StyledModal>
          {isLoading && (
            <div className="spinner-overlay">
              <Spinner />
            </div>
          )}

          {addonQuestions?.length > 0 && addonInfo && (
            <>
              <HeadingText
                title={`Product Options (${addonInfo.description})`}
                variant="small"
              />

              <form
                id={"addonForm-" + selectedAddon}
                noValidate="novalidate"
                className="form-submit"
                onSubmit={handleSubmit(addToCart)}
              >
                <div className="row m-0 mb-4">
                  {addonQuestions.length > 0 &&
                    addonQuestions.map((addonQuestion, id) => {
                      return (
                        <div className="col-4 p-0 pr-2" key={"addon" + id}>
                          <label className="field-label">
                            {addonQuestion.description}
                          </label>
                          <Controller
                            control={control}
                            name={`${addonQuestion.id}`}
                            shouldUnregister={true}
                            rules={{
                              required: `Selecting ${addonQuestion.description} is required`,
                            }}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                            }) => (
                              <Select
                                tabIndex={id}
                                styles={customSelectStyles}
                                inputRef={ref}
                                options={addonQuestion?.productAddonConfigValues?.map(
                                  (addonConfig) => ({
                                    label: addonConfig.description,
                                    value: addonConfig.id,
                                  })
                                )}
                                onChange={(val) => {
                                  onChange(val.value);
                                }}
                              />
                            )}
                          />

                          <p className="error-message">
                            {errors[addonQuestion.description]?.message}
                          </p>
                        </div>
                      );
                    })}
                </div>

                {category &&
                  (category?.message === OUT_OF_STOCK_ADDON ||
                    category?.message ===
                      INVALID_CONFIGURATION_COMBINATION) && (
                    <div className="row m-0 mb-4">
                      <p className="error-message">{category.message}</p>
                    </div>
                  )}

                {skuData && category?.message === VALID_COMBINATION && (
                  <ValidSkuData
                    totalPrice={totalPrice}
                    decrementQty={decrementQty}
                    itemQty={itemQty}
                    handleChange={handleChange}
                    incrementQty={incrementQty}
                    skuData={skuData}
                    handleSubmit={handleSubmit}
                    addToCart={addToCart}
                  />
                )}
              </form>
            </>
          )}

          {noConfigProduct && (
            <>
              <HeadingText
                title={`Product Options (${addonInfo.description})`}
                variant="small"
              />

              <form
                id={"addonForm-" + selectedAddon}
                noValidate="novalidate"
                className="form-submit"
                onSubmit={handleSubmit(addToCart)}
              >
                <ValidSkuData
                  totalPrice={totalPrice}
                  decrementQty={decrementQty}
                  itemQty={itemQty}
                  handleChange={handleChange}
                  incrementQty={incrementQty}
                  skuData={skuData}
                  handleSubmit={handleSubmit}
                  addToCart={addToCart}
                />
              </form>
            </>
          )}
        </StyledModal>
      </Modal.Body>
    </Modal>
  );
};

export default ProductOptionsModal;
