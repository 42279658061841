import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Assets from "../../../assets/Assets";
import { BLOCKCHAIN, TOKEN_STANDARD } from "../../../constants/constants";
import TraitsTable from "./TraitsTable";

const TabsNFT = ({
  serial,
  creator,
  spaceInfo,
  collectionInfo,
  productData,
}) => {
  return (
    <div className="flat-tabs themesflat-tabs">
      <Tabs>
        <TabList>
          {serial?.properties && <Tab>Properties</Tab>}

          <Tab>Creator Info</Tab>
          {productData?.onBlockchain === "Y" && <Tab>Blockchain Info</Tab>}
        </TabList>

        {serial?.properties && (
          <TabPanel>
            <div className="properties-container">
              <p className="properties-text">{serial?.properties}</p>
            </div>
          </TabPanel>
        )}

        <TabPanel>
          <div className="creator-container">
            <div className="table-row">
              <h6>Creator Name:</h6>
              <p>{creator.displayName}</p>
            </div>
            <div className="table-row social-media">
              <h6>Available Social Media Links:</h6>
              <ul>
                {creator.twitterUrl && (
                  <li>
                    <img src={Assets.twitterIcon} alt={"twitter icon"} />
                    <a href={creator.twitterUrl}>{creator.twitterUrl}</a>
                  </li>
                )}
                {creator.facebookUrl && (
                  <li>
                    <img src={Assets.facebookIcon} alt={"facebook icon"} />
                    <a href={creator.facebookUrl}>{creator.facebookUrl}</a>
                  </li>
                )}
                {creator.discordUrl && (
                  <li>
                    <img src={Assets.discordIcon} alt={"discord icon"} />
                    <a href={creator.discordUrl}>
                      <p>{creator.discordUrl}</p>
                    </a>
                  </li>
                )}
                {creator.instagramUrl && (
                  <li>
                    <img src={Assets.instagramIcon} alt={"instagram icon"} />
                    <a href={creator.instagramUrl}>
                      <p>{creator.instagramUrl}</p>
                    </a>
                  </li>
                )}
                {creator.tikTokUrl && (
                  <li>
                    <img src={Assets.tiktokIcon} alt={"tiktok Icon"} />
                    <a href={creator.tikTokUrl}>
                      <p>{creator.tikTokUrl}</p>
                    </a>
                  </li>
                )}
              </ul>
            </div>
            {spaceInfo.bio && (
              <p className="creator-description">{spaceInfo.bio}</p>
            )}
          </div>
        </TabPanel>

        {productData?.onBlockchain === "Y" && (
          <TabPanel>
            <div className="blockchain-container">
              {collectionInfo.tokenId && (
                <div className="table-row">
                  <h6>Token ID:</h6>
                  <p>{collectionInfo.tokenId}</p>
                </div>
              )}

              {TOKEN_STANDARD && (
                <div className="table-row">
                  <h6>Token Standard:</h6>
                  <p>{TOKEN_STANDARD}</p>
                </div>
              )}

              {BLOCKCHAIN && (
                <div className="table-row">
                  <h6>Blockchain:</h6>
                  <p>{BLOCKCHAIN}</p>
                </div>
              )}

              {serial.serial && (
                <div className="table-row">
                  <h6>Serial #</h6>
                  <p>{serial.serial}</p>
                </div>
              )}

              {"royaltyFee" in collectionInfo && (
                <div className="table-row">
                  <h6>Creator Fees:</h6>
                  <p>{collectionInfo.royaltyFee} %</p>
                </div>
              )}

              {serial?.traits && serial?.traits?.length > 0 && (
                <div className="table-row">
                  <h6>Traits:</h6>
                  <TraitsTable traitObject={serial?.traits} />
                </div>
              )}
            </div>
          </TabPanel>
        )}
      </Tabs>
    </div>
  );
};

export default TabsNFT;
