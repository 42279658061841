import React, { useEffect, useState } from "react";
import styled from "styled-components";
import getPopularCollections from "../../../api/collections/getPopularCollections";
import { COMMON_STYLES } from "../../../constants/genericStyles";
import LinkWithQueryParams from "../../LinkWithQueryParams";
import Spinner from "../../layouts/Spinner/Spinner";
import ArtistCard from "../ArtistCard/ArtistCard";
import CollectionHeader from "../CollectionHeader";

const StyledPopularCollectionsSection = styled.section`
  position: relative;

  .overlay {
    /* Image set on shortcodes */
    ${COMMON_STYLES.OVERLAY}
    background-color: #285599;
    background-size: 150%;
    background-position: 60% 60%;
  }
  .exp.style2 {
    color: white;
  }
  .like-container {
    display: none;
  }

  @media (max-width: 768px) {
    padding: 20px 0px !important;
    .exp {
      font-size: 12px !important;
    }
  }
`;
const PopularCollection = () => {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAllCollections = async () => {
    let response = await getPopularCollections();
    // filters out collections with no cids
    const filteredCollections =
      response?.length > 0
        ? response.filter((collection) => collection?.cids?.length > 0)
        : [];

    filteredCollections && setCollections(filteredCollections);
    setLoading(false);
  };

  useEffect(() => {
    getAllCollections();
  }, []);

  return (
    <div>
      <StyledPopularCollectionsSection className="tf-section live-auctions popular-collections">
        <div className="overlay"></div>

        <div className="themesflat-container">
          <div className="row m-0">
            <CollectionHeader
              title="Popular Collections"
              navigateTo="/explore-items"
              show={collections.length > 6}
            />
          </div>

          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spinner />
            </div>
          ) : (
            <>
              <section className="row sc-cards-container">
                {collections &&
                  collections.length > 0 &&
                  collections.slice(0, 12).map((collection, id) => (
                    <div
                      className="col-6 col-sm-6 col-md-4 p-3"
                      key={collection.id}
                    >
                      <ArtistCard
                        card={collection}
                        artistPage={true}
                        isCollection={true}
                      />
                    </div>
                  ))}
              </section>
              <div className="row justify-content-center">
                <LinkWithQueryParams
                  className="exp style2"
                  path="/explore-items"
                >
                  Explore More
                </LinkWithQueryParams>
              </div>
            </>
          )}
        </div>
      </StyledPopularCollectionsSection>
    </div>
  );
};

export default PopularCollection;
