import React from "react";
import imgdetail1 from "../../assets/images/box-item/images-item-details.jpg";
import Like from "../../components/layouts/cards/Like";
import useIPFS from "../../hooks/useIPFS";
import { GLTFScene } from "./ItemMedia/GLTFScene";
import ItemImage from "./ItemMedia/ItemImage";
import ItemVideo from "./ItemMedia/ItemVideo";
const ItemVisual = ({ serial, element, videoHeight }) => {
  const { convertIPFSUrl } = useIPFS();

  return (
    <div className="col-md-6 ">
      <div
        className="content-left position-relative"
        style={{ height: "100%" }}
      >
        <div
          style={{
            position: "absolute",
            top: "11px",
            right: "13px",
            zIndex: 30,
          }}
        >
          <Like
            elementId={serial.id}
            endpoint="item"
            likes={serial.numberOfLikes}
            liked={serial.liked}
          />
        </div>

        <div
          className="media position-relative shadow rounded-"
          style={{
            borderRadius: "30px",
            display: "flex",
            justifyContent: "center",
            alignContent: "flex-start",
            height:
              element?.type?.includes("video") ||
              element?.type?.includes("model")
                ? `${videoHeight}`
                : "auto",
          }}
        >
          {element?.type?.includes("video") && <ItemVideo element={element} />}

          {(element?.type?.includes("image") || !element?.type) && (
            <ItemImage element={element} imgdetail1={imgdetail1} />
          )}

          {element?.type?.includes("model") && element?.model && (
            <GLTFScene gltfFile={convertIPFSUrl(element.model)} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemVisual;
