import { REACT_APP_API_URL } from "../../constants/constants";
import { getHeaders } from "../Item/utils";

export const createReservation = async ({ requestBody }) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}order/reserve`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify(requestBody),
    });
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

export const getReservation = async ({ reservationId }) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(
      `${REACT_APP_API_URL}order/reserve/one/${reservationId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log(error);
  }
};
