import React, { useState, useEffect } from 'react';
import getProducts from '../../api/prodigi/getProducts';
import ProdigiCollection from '../../components/collection/ProdigiCollection/ProdigiCollection';
import Spinner from '../../components/layouts/Spinner/Spinner';
import ProdigiFilters from './ProdigiFilters';

const ProdigiItems = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('');

  const getAllProducts = async (filter = '') => {
    !loading && setLoading(true);
    try {
      const response = await getProducts(filter);
      response.length && setData(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeFilters = (text) => {
    setFilter(text);
  };

  useEffect(() => {
    getAllProducts(filter);
  }, [filter]);

  return (
    <div className='tf-section tf-item-details style-2'>
      <div className='themesflat-container mt-5'>
        <div className='row mb-5'>
          <div className='col-xl-3 col-lg-3 col-md-12'>
            <ProdigiFilters action={onChangeFilters} />
          </div>
          {loading ? (
            <div className='col-lg-9 col-md-12 flex justify-content-center align-items-start'>
              <Spinner />
            </div>
          ) : !data.length ? (
            <div className='col-lg-9 col-md-12 flex justify-content-center align-items-start'>
              <h4>No items to show!</h4>
            </div>
          ) : (
            <div className='col-lg-9 col-md-12'>
              <ProdigiCollection
                data={data}
                stop={6}
                measures={{ md: 4, sm: 6 }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProdigiItems;
