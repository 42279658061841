import styled from "styled-components";
import { COMMON_STYLES } from "../../../constants/genericStyles";

export const StyledSellModal = styled.div`
  padding: 40px;
  padding-top: 20px;

  .form-inner button {
    background: initial;
  }
  .form-inner form input {
    margin-bottom: 0px;
  }

  .heading {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
  }

  .heading .tf-title {
    font-size: 48px;
    line-height: 100%;
    color: #011126;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .heading .vertical {
    margin-right: 10px;
    border-left: 8px solid #ffd061;
    height: 30px;
    display: inline-block;
  }

  .heading .span-container {
    display: flex;
    align-items: center;
    color: #011126;
  }

  .heading.small .tf-title {
    font-size: 32px;
  }

  .edit-profile .heading.small .vertical {
    height: 32px;
  }

  .form-field-label {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 8px;
  }
  .form-field-input,
  .react-datepicker-wrapper {
    margin-bottom: 20px;
  }

  .rate {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #2159a2;
    text-align: center;
  }
  .rate-label {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-transform: capitalize;
    color: #000000;
    text-align: center;
  }
  .fieldset-half {
    flex: 1;
  }
  .digital-currency-note {
    font-weight: 400;
    font-size: 14px;
    line-height: 106%;
    color: #9a9a9a;
    margin-bottom: 20px;
  }

  .transaction-charge-note,
  .royalty-fee-note,
  .status-modification-note {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
  .transaction-charge-note {
    margin-bottom: 12px;
  }
  .royalty-fee-note {
    margin-bottom: 16px;
  }
  button.filled-button {
    ${COMMON_STYLES.FILLED_BUTTON({
      fillColor: "#ff7b7c",
      borderSize: "10px",
    })}
    width: 100%;
    padding: 15px;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  button.react-datepicker__navigation {
    width: 32px;
  }
  .error-message {
    color: #e42c2c;
    font-weight: 400;
    font-size: 12px;
  }
  .price-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    width: 60%;
    margin-bottom: 12px;
    #hbarPrice {
      width: 40%;
    }
    .currency-label {
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      color: #000000;
      padding: 10px;
    }
    input {
      border: none;
      border-radius: 0px;
      border-left: 1px solid #cdcdcd;
      font-size: 20px;
    }
  }

  .escrow-note {
    font-family: "Inter";
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
  }

  .status-modification-note {
    font-weight: 900;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #000000;
  }

  .fees-container {
    margin-bottom: 40px;
  }
  .fee-notes {
    margin-bottom: 16px;
  }
  .fee-notes,
  .fee-note-row {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
  .fee-note-row {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px;
    .fee-label {
      width: 40%;
    }
    .fee-price {
    }
  }

  .currency-label {
    flex: 1;
  }
  @media (max-width: 768px) {
    .price-input-container {
      width: 100%;
      .currency-label{

      }
      #hbarPrice{
        width: 75%;
      }
    }
  }
`;
