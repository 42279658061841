import React, { useState } from "react";
import LinkWithQueryParams from "../../components/LinkWithQueryParams";
import { useApi2 } from "../../hooks/useApi2";
import CollectionCard from "./CollectionCard";

const ListMyCollections = (props) => {
  const { data, loading } = useApi2("collection/my", {
    method: "GET",
  });

  const [visible, setVisible] = useState(5);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 5);
  };

  if (loading) return <h4>Loading...</h4>;
  return (
    <div>
      {!data ? (
        <h4>An Error has Ocurred</h4>
      ) : !data.length ? (
        <h4>No Items!</h4>
      ) : (
        <>
          {data.slice(0, visible).map((collection) => (
            <div key={collection.id}>
              <CollectionCard collection={collection} />
            </div>
          ))}

          {visible < data.length && (
            <div className="col-md-12 wrap-inner load-more text-center">
              <LinkWithQueryParams
                path="#"
                id="load-more"
                className="sc-button loadmore fl-button pri-3 mt-10"
                onClick={showMoreItems}
              >
                <span>Load More</span>
              </LinkWithQueryParams>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ListMyCollections;
