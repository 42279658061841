import React from "react";
import Assets from "../../../assets/Assets";

const HashpackWalletConnected = ({ hashAccountId }) => {
  return (
    <div className="wallet-connected">
      <p className="title">Wallet connected: </p>
      <div className="image">
        <img src={Assets.hashpackIcon} alt="hashpackIcon" />
      </div>
      <div className="image">
        <img src={Assets.hashpackBrand} alt="hashpackBrand" />
      </div>
      <p className="wallet-name">{hashAccountId}</p>
    </div>
  );
};

export default HashpackWalletConnected;
