import React from "react";
import { useSelector } from "react-redux";
import Assets from "../../../assets/Assets";
import WalletCardV2 from "./WalletCardV2";

const VenlyWallet = ({ selectWallet, handleLogout, connectWallet }) => {
  const user = useSelector((state) => state.user);

  return (
    <div className="wallet sc-box-icon-inner style-2">
      <>
        <div className="image">
          <img src={Assets.venlyIcon} alt="venlyIcon" />
        </div>
        <div className="image">
          <img src={Assets.venlyBrand} alt="venlyBrand" />
        </div>

        {user?.wallets?.owner?.email && (
          <WalletCardV2
            wallets={user?.wallets}
            manageWallets={selectWallet}
            handleLogout={handleLogout}
          />
        )}

        {!user?.wallets?.owner?.email && !user?.hashpackWallets?.accountIds && (
          <div className="w-100 flex justify-content-center">
            <button className="sc-button-3 no-bg" onClick={connectWallet}>
              connect
            </button>
          </div>
        )}

        {user?.hashpackWallets?.accountIds && (
          <p className="logout-note">
            To connect, first logout from your hashpack wallet
          </p>
        )}
      </>
    </div>
  );
};

export default VenlyWallet;
