import Spinner from '../../Spinner/Spinner';
import 'react-tabs/style/react-tabs.css';
import SkuTable from './SkuTable';
import AttributesSection from './AttributesSection';
import useProdigi from '../../../../hooks/useProdigi';
import { useNavigate } from 'react-router-dom';

const OrderTab = ({ token, itemId }) => {
  const { sku, loading, attributes, data, error, calculateSku, quoteProduct } =
    useProdigi();

  const navigate = useNavigate();

  const divStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const orderProductTwo = async (obj, itemId) => {
    const quote = await quoteProduct(obj, 'return');
    sessionStorage.setItem(
      `prodigi-order-${itemId}`,
      JSON.stringify({
        shippingCost: quote.shippingCost,
        shippingMethod: obj.shippingMethod,
        sizing: obj.sizing,
        prodigiProductSKUId: obj.prodigiProductSKUId,
      })
    );
    navigate(`/checkout/prodigi-shipping/${itemId}`);
  };

  return (
    <>
      <SkuTable action={calculateSku} skus={token.skus} />

      {attributes && (
        <AttributesSection
          action={orderProductTwo}
          requireSizing={true}
          attributes={attributes}
          sku={sku}
          itemID={itemId}
        />
      )}

      {loading ? (
        <div style={divStyle}>
          <Spinner />
        </div>
      ) : error ? (
        <div style={divStyle}>
          <p>error trying to execute the order</p>
        </div>
      ) : (
        data && (
          <div style={divStyle}>
            <p>Order created successfully {data.createdAt}</p>
          </div>
        )
      )}
    </>
  );
};

export default OrderTab;
