import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import Assets from "../../../assets/Assets";
import { customSelectStyles } from "../../CreateCollection/styles";
import { StyledDropdown } from "./styles";

const DefaultWalletDropdown = ({
  methods,
  user,
  connectedWallet,
  dropdownValues,
}) => {
  const BRAND_OPTIONS_SELECTION_DROPDOWN = [
    {
      value: "VAULT",
      label: (
        <div className="dropdown-label">
          <img src={Assets.acmeIcon} alt="acmeVaultBrand" />
          <div className="dropdown-label-text">ACME™ Vault</div>
        </div>
      ),
    },

    {
      value: "HASHPACK",
      label: (
        <div className="dropdown-label">
          <img src={Assets.hashpackIcon} alt="hashpackBrand" />
          <div className="dropdown-label-text">Hashpack</div>
        </div>
      ),
    },
    {
      value: "VENLY",
      label: (
        <div className="dropdown-label">
          <img
            src={Assets.venlyIcon}
            alt="venlyBrand"
            height="30px"
            width="100%"
          />
          <div className="dropdown-label-text">Venly</div>
        </div>
      ),
    },
  ];

  const [walletOptions, setWalletOptions] = useState(
    BRAND_OPTIONS_SELECTION_DROPDOWN
  );
  const { control, errors } = methods;

  useEffect(() => {
    if (dropdownValues?.length > 0) {
      const walletOptions = BRAND_OPTIONS_SELECTION_DROPDOWN.filter((option) =>
        dropdownValues.includes(option.value)
      );
      setWalletOptions(walletOptions);
    }
  }, [dropdownValues]);

  return (
    <StyledDropdown>
      <fieldset>
        <div style={{ display: "flex" }}>
          <div className="dropdown">
            <Controller
              control={control}
              name="defaultWalletType"
              defaultValue={
                connectedWallet ? connectedWallet : user?.defaultWalletType
              }
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Select
                  tabIndex="3"
                  styles={customSelectStyles}
                  inputRef={ref}
                  options={walletOptions}
                  value={walletOptions.find((c) => c.value === value)}
                  onChange={(val) => {
                    onChange(val.value);
                  }}
                />
              )}
            />
          </div>
        </div>
        <p className="error-message">
          {errors?.formState?.defaultWalletType?.message}
        </p>
      </fieldset>
    </StyledDropdown>
  );
};

export default DefaultWalletDropdown;
