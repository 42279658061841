import React from "react";

const WalletCardV2 = ({ wallets, manageWallets, handleLogout }) => {
  return (
    <div className="w-100 flex  flex-wrap justify-content-center">
      <h5 className="">
        {wallets.owner.given_name} {wallets.owner.family_name}
      </h5>

      {wallets.walletDetails.id && (
        <div className="wallet-details">
          <p>
            Wallet connected: <br /> {wallets?.walletDetails?.description}{" "}
            <br />
          </p>
          <p>
            balance: {wallets?.walletDetails?.balance?.balance}{" "}
            {wallets?.walletDetails?.balance?.symbol}
          </p>
        </div>
      )}

      <button className="sc-button-3 no-bg" onClick={manageWallets}>
        {!wallets.walletDetails.id ? "select" : "change"} wallet
      </button>

      <button className="sc-button-3 no-bg" onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
};

export default WalletCardV2;
