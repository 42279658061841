import { OrbitControls } from "@react-three/drei";
import { Canvas, useThree } from "@react-three/fiber";
import React, { Suspense, useEffect, useState } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Spinner from "../../../components/layouts/Spinner/Spinner";
import * as THREE from "three";

const Loader = () => {
  return (
    <div className="w-100 d-flex justify-content-center align-items-center ">
      <Spinner />
    </div>
  );
};

const addIlumination = ({ sceneToTreat }) => {
  const ambientLight = new THREE.AmbientLight(0xffffff, 1);
  const dirLight = new THREE.DirectionalLight(0xefefff, 1.5);
  dirLight.position.set(10, 10, 10);
  sceneToTreat.add(ambientLight);
  sceneToTreat.add(dirLight);
  return sceneToTreat;
};

export const GLTFScene = ({ gltfFile }) => {
  const Model = () => {
    const scene = new THREE.Scene();

    const gltfLoader = new GLTFLoader();
    const [loadedScene, setLoadedScene] = useState(null);

    useEffect(() => {
      gltfLoader.load(gltfFile, (gltf) => {
        const root = gltf.scene;
        scene.add(root);
        const treatedScene = addIlumination({ sceneToTreat: scene });
        setLoadedScene(treatedScene);
      });
    }, []);

    useThree(({ camera }) => {
      camera.position.X = 0;
      camera.position.y = 2;
      camera.position.z = 3;
      camera.lookAt(0, 0, 0);
    });
    return (
      loadedScene && <primitive object={loadedScene} dispose={null} scale={2} />
    );
  };
  return (
    <Canvas style={{ height: "100%" }}>
      <Suspense fallback={<Loader />}>
        <OrbitControls
          target={[0, 0, 0]}
          enablePan={false}
          maxPolarAngle={Math.PI / 2}
        />
        <Model />
      </Suspense>
    </Canvas>
  );
};
