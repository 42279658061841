import React from 'react';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import OrderTab from './OrderTab';
import QuoteTab from './QuoteTab';

const ProdigiModal = ({ show, token, onHide }) => {
  const { itemId } = useParams();

  return (
    <Modal show={show} onHide={onHide} size='lg'>
      <Modal.Header className='p-5' closeButton>
        <h3>{token.name}</h3>
      </Modal.Header>
      <Modal.Body>
        <div className='flat-tabs themesflat-tabs'>
          <Tabs>
            <TabList>
              <Tab>order</Tab>
              <Tab>quote</Tab>
            </TabList>
            <TabPanel>
              <OrderTab token={token} itemId={itemId} />
            </TabPanel>
            <TabPanel>
              <QuoteTab token={token} />
            </TabPanel>
          </Tabs>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProdigiModal;
