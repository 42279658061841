import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { getTodayPicks } from "../../api/Item/getItems";
import {
  EXPLORE_COLLECTIONS_URL,
  TODAYS_PICKS_DESCRIPTION,
} from "../../constants/constants";
import { COMMON_STYLES } from "../../constants/genericStyles";
import LinkWithQueryParams from "../LinkWithQueryParams";
import CollectionHeader from "../collection/CollectionHeader";
import Spinner from "./Spinner/Spinner";
import TodayPickCard from "./TodayPickCard/TodayPickCard";

const StyledSection = styled.section`
  .main-container {
    display: block;
    position: relative;
    padding: 49px 0 78px;
    background-color: #fff;
  }
  a {
    width: 100%;
  }

  @media (max-width: 768px) {
    .main-container {
      padding: 0;
    }
    .container-cards .card_item .fs-16 {
      font-weight: 700;
    }
    .container-cards .card_item p {
      font-weight: 400;
    }
    .container-cards .card_item .fs-16,
    .container-cards .card_item p {
      font-size: 8px;
      line-height: 8px;
    }
  }
  .card-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 440px;
    width: 100%;
  }
  .container-cards {
    width: 100%;
  }
  .container-cards .card_item {
    display: block;
    width: 100%;
    position: relative;
  }
  .container-cards .card_item a {
    display: inline-block;
    width: 100%;
  }
  .container-cards .card_item a .inner-card {
    width: 100%;
    box-shadow: 14px 12px 15px 6px rgba(0, 0, 0, 0.22);
    position: relative;
    border-radius: 15px;
  }
  .container-cards .card_item a .inner-card:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 120.25%;
  }
  .container-cards .card_item a .inner-card img {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  .container-cards .card_item a .inner-card:hover img {
    ${COMMON_STYLES.ZOOM_ANIMATION}
  }
  .container-cards .card_item .inner-card .content2 {
    padding-left: 7%;
    padding-bottom: 7%;
  }
`;

const SectionContainer = styled.section`
  padding: 49px 15px;
  padding-bottom: 79px;
  @media (max-width: 768px) {
    padding: 30px 0px;
    .container-cards {
      height: initial;
    }
  }
`;

const StyledDiv = styled.div`
  width: 25%;
  &:hover {
  }
  @media (max-width: 768px) {
    width: 40%;
  }
`;
const InstructionsMobile = styled.div`
  display: none;
  flex-wrap: wrap;
  width: 100%;
  .heading-live-auctions h2 {
    padding-bottom: 0 !important;
    margin-bottom: 0;
  }
  p {
    color: #011126;
    font-weight: 400;
    font-size: 12px;
    width: 100%;
  }
  @media (max-width: 768px) {
    display: flex;
  }
  .btn-auction.center {
    width: 100%;
    margin-bottom: 28px;
  }
  .sc-button-2 {
    margin-top: 0;
    padding: 10px 23px;
    font-size: 12px;
    line-height: 12px;
    border: 3px solid #ff7b7c;
    border-radius: 13px;
  }
  .sc-button-2 span {
    color: #ff7b7c;
  }
`;

const InstructionsDesktop = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 24%;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    display: none;
  }
  .btn-auction.center {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .sc-button-2 {
    ${COMMON_STYLES.BUTTON}
  }
  .sc-button-2 span {
    color: #f1a09f;
  }
  .sc-button-2.button__bottom-top {
    ${COMMON_STYLES.BOTTOM_TOP_ANIMATION({
      initialButtonFill: "#fff",
      finalButtonFill: "#FF7B7C",
    })}
  }
`;

const TodayPicks = () => {
  const [value, setValue] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchTodayPicks = async () => {
    const response = await getTodayPicks();
    const todayPicks = response?.length > 0 ? response : [];
    setValue(todayPicks);
    setLoading(false);
  };

  useEffect(() => {
    fetchTodayPicks();
  }, []);

  return (
    <Fragment>
      <StyledSection>
        {loading ? (
          <div className="main-container">
            <div className="spinner-container">
              <Spinner />
            </div>
          </div>
        ) : (
          value?.length > 0 && (
            <div className="main-container">
              <SectionContainer>
                <div className="themesflat-container">
                  <div className="row justify-content-between m-0">
                    <InstructionsMobile>
                      <CollectionHeader
                        title="Today's Picks"
                        show={false}
                        color="#214377"
                      />
                      <p>{TODAYS_PICKS_DESCRIPTION}</p>
                      <LinkWithQueryParams path={EXPLORE_COLLECTIONS_URL}>
                        <div className="btn-auction center">
                          <button
                            to="#"
                            id="load-more"
                            className="sc-button-2 no-bg button__bottom-top"
                          >
                            <span>Explore More</span>
                          </button>
                        </div>
                      </LinkWithQueryParams>
                    </InstructionsMobile>

                    {value.slice(0, 2).map((item) => (
                      <StyledDiv key={item.id}>
                        <TodayPickCard card={item} />
                      </StyledDiv>
                    ))}

                    <InstructionsDesktop>
                      <CollectionHeader
                        title="Today's Picks"
                        show={false}
                        color="#214377"
                      />

                      <p
                        style={{
                          color: "#011126",
                          fontWeight: 400,
                          fontSize: 24,
                          marginBottom: 24,
                        }}
                      >
                        {TODAYS_PICKS_DESCRIPTION}
                      </p>

                      <LinkWithQueryParams path={EXPLORE_COLLECTIONS_URL}>
                        <div className="btn-auction center">
                          <button
                            to="#"
                            id="load-more"
                            className="sc-button-2 no-bg button__bottom-top"
                          >
                            <span>Explore More</span>
                          </button>
                        </div>
                      </LinkWithQueryParams>
                    </InstructionsDesktop>
                  </div>
                </div>
              </SectionContainer>
            </div>
          )
        )}
      </StyledSection>
    </Fragment>
  );
};

export default TodayPicks;
