import LinkWithQueryParams from "../../components/LinkWithQueryParams";

const CollectionCard = ({ collection }) => {
  return (
    <div className="sc-card-activity style-2">
      <div className="content">
        <div className="infor">
          <h4>
            <LinkWithQueryParams path={`/collection/${collection.id}`}>
              {collection.tokenName} : {collection.tokenSymbol}
            </LinkWithQueryParams>
          </h4>
          <div className="status">
            Artist:{" "}
            <span className="author">{collection.artist.displayName}</span>
          </div>
          <div className="time">Token Id: {collection.tokenId}</div>
          <div className="time"># Likes: {collection.numberOfLikes}</div>
          <div className="time">{collection.description}</div>
        </div>
      </div>
    </div>
  );
};

export default CollectionCard;
