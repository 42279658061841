import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import HeadingText from "../../editProfile/HeadingText";
import SellModalForm from "./SellModalForm";
import { StyledSellModal } from "./styles";

const SellModal = ({
  show,
  onHide,
  itemId,
  walletId,
  item,
  collectionData,
  user,
  transferToEscrow,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body
        style={{
          maxHeight: "calc(100vh)",
          overflowY: "auto",
        }}
      >
        <StyledSellModal>
          <HeadingText title={"Sell Item"} variant="small" />
          <SellModalForm
            register={register}
            handleSubmit={handleSubmit}
            errors={errors}
            setLoading={setLoading}
            control={control}
            itemId={itemId}
            item={item}
            walletId={walletId}
            collectionData={collectionData}
            user={user}
            transferToEscrow={transferToEscrow}
          />
        </StyledSellModal>
      </Modal.Body>
    </Modal>
  );
};

export default SellModal;
