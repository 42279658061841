import React, { useState, Fragment } from "react";
import CardModal from "../CardModal";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const BuyBtn = (props) => {
  const { card } = props;
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  return (
    <Fragment>
      {!showModal ? null : (
        <CardModal
          show={showModal}
          token={card}
          onHide={() => setShowModal(false)}
        />
      )}
      <button
        className="sc-button style bag fl-button pri-3 no-bg"
        style={{ color: "black" }}
        onClick={() => user.id.length ? setShowModal(true) : navigate(`/login`)}
      >
        <span>Buy</span>
      </button>
    </Fragment>
  );
};

export default BuyBtn;
