import { useState } from "react";

const initialElementState = {
  description: "",
  image: "",
  name: "",
  properties: {},
};

const useIPFS = () => {
  const [element, setElement] = useState(initialElementState);

  const getThumbnail = async (cid) => {
    const response = await fetch(convertIPFSUrl(cid));
    const data = await response.json();
    setElement({
      description: data.description,
      name: data.name,
      properties: data.properties,
      image: "",
    });
    return convertIPFSUrl(data.image);
  };

  const convertIPFSUrl = (ipfsUrl) => {
    const subUrl = ipfsUrl.replace("ipfs://", "");
    return `https://nftstorage.link/ipfs/${subUrl}`;
  };

  const getImage = async (cid) => {
    const urlImage = await getThumbnail(cid);
    setElement({
      ...element,
      image: urlImage,
    });
  };

  const getImageTwo = async (cid) => {
    return getThumbnail(cid);
  };

  const getData = async (cid) => {
    try {
      const response = await fetch(convertIPFSUrl(cid));
      const data = await response.json();
      const elementData = {
        description: data.description,
        name: data.name,
        properties: data.properties,
        image: convertIPFSUrl(data.image),
        model: data?.model,
        type: data?.type,
        backgroundColor: data?.backgroundColor,
      };
      setElement(elementData);
      return elementData;
    } catch (e) {
      console.log(e);
    }
  };

  return {
    element,
    getImage,
    getData,
    getThumbnail,
    convertIPFSUrl,
    getImageTwo,
  };
};

export default useIPFS;
