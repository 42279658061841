import React from "react";
import { REQUIRED_MESSAGE } from "../../constants/constants";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import moment from "moment";

const CreateActivityForm = ({ methods }) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = methods;
  const todayDate = new Date();
  const sixMonthsDate = moment().add(6, "M").toDate();

  return (
    <>
      <fieldset>
        <label className="field-label">Title</label>
        <input
          {...register("title", {
            required: REQUIRED_MESSAGE,
          })}
          name="title"
          type="text"
          max={100}
          placeholder="ex. new artwork dropping"
        />
        <p className="error-message">{errors.title?.message}</p>
      </fieldset>

      <fieldset>
        <label className="field-label">Description</label>
        <textarea
          {...register("description", {
            required: REQUIRED_MESSAGE,
          })}
          name="description"
          placeholder="limit to 350 characters."
        ></textarea>
        <p className="error-message">{errors.description?.message}</p>
      </fieldset>

      <div className="row m-0 mb-4">
        <div className="col-3 pl-0">
          <fieldset className="mb-2">
            <h6 className="form-field-label">start date </h6>
            <Controller
              control={control}
              name="startDate"
              rules={{
                required: REQUIRED_MESSAGE,
              }}
              defaultValue={todayDate}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  placeholderText="Select date"
                  selected={value}
                  onChange={(date) => onChange(date)}
                />
              )}
            />
            <p className="error-message">{errors.startDate?.message}</p>
          </fieldset>
        </div>

        <div className="col-3">
          <fieldset className="mb-2">
            <h6 className="form-field-label">exp. date </h6>
            <Controller
              control={control}
              name="endDate"
              rules={{
                required: REQUIRED_MESSAGE,
              }}
              defaultValue={sixMonthsDate}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  placeholderText="Select date"
                  selected={value}
                  onChange={(date) => onChange(date)}
                />
              )}
            />
            <p className="error-message">{errors.endDate?.message}</p>
          </fieldset>
        </div>

        <div className="col-6 pr-0">
          <fieldset>
            <label className="field-label">CMS Web Link</label>
            <input
              {...register("cmsLink", {
                required: REQUIRED_MESSAGE,
              })}
              name="cmsLink"
              type="text"
              max={100}
              placeholder="URL"
            />
            <p className="error-message">{errors.cmsLink?.message}</p>
          </fieldset>
        </div>
      </div>

      <div className="row-form style-3 justify-content-center">
        <div className="inner-row-form">
          <button className="submit filled-button">Create Activity</button>
        </div>
      </div>
    </>
  );
};

export default CreateActivityForm;
