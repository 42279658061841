import React from "react";
import Layout from "../../components/layouts/Layout";
import { StyledAboutUsPage } from "./styles";
import Assets from "../../assets/Assets";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";

const AboutUs = () => {
  const isMobile = useCheckMobileScreen();

  return (
    <Layout>
      <StyledAboutUsPage>
        <div id="spacer"></div>
        <section id="about-us">
          <div className="who-are-we-container">
            {isMobile && (
              <>
                <div className="header-container">
                  <div className="page-title-heading">
                    <div className="vertical-line"></div>
                    <h1 className="heading">Who Are We?</h1>
                  </div>
                </div>
                <div className="image-section">
                  <div className="image-container">
                    <img src={Assets.whoAreWeImage} alt={"who are we"} />
                  </div>
                </div>
              </>
            )}

            <div className="left-section">
              {!isMobile && (
                <div className="header-container">
                  <div className="page-title-heading">
                    <div className="vertical-line"></div>
                    <h1 className="heading">Who Are We?</h1>
                  </div>
                </div>
              )}
              <div className="who-are-we">
                <div>At Acme Labs™, we're constantly pushing forward.</div>
                <div>We're creating portals to new worlds.</div>
                <div>
                  We believe the best doors are the ones that haven't been
                  opened yet.
                </div>
                <div>
                  While other media companies are fighting over existing IP and
                  old technology, we're building the future because we believe
                  the next Pixar, Marvel, or Game of Thrones is out there,
                  waiting to be discovered.
                </div>
                <div>
                  We believe in people who create and giving them the freedom to
                  express their genius without red tape and homogenization.
                </div>
                <div>
                  We're a rocket-powered birthday cake with TNT candles waiting
                  to explode under the nose of some very sleepy giants.
                </div>
              </div>
            </div>

            {!isMobile && (
              <div className="right-section">
                <div className="image-container">
                  <img src={Assets.whoAreWeImage} alt={"who are we"} />
                </div>
              </div>
            )}
          </div>

          <div className="our-mission-container">
            {!isMobile && (
              <div className="left-section">
                <div className="image-container">
                  <img src={Assets.ourMissionImage} alt={"our mission"} />
                </div>
              </div>
            )}

            <div className="right-section">
              <div className="header-container">
                <div className="page-title-heading">
                  <div className="vertical-line"></div>
                  <h1 className="heading">Our Mission</h1>
                </div>
              </div>
              <div className="our-mission">
                <div>
                  Our mission at Acme Labs™ is to empower creators to tell their
                  stories and bring their vision to life using the latest
                  technologies.
                </div>
                <div>
                  We believe that by putting creators first, we can
                  revolutionize the media industry and create a more equitable
                  and sustainable ecosystem for all.
                </div>
              </div>
            </div>

            {isMobile && (
              <div className="image-section">
                <div className="image-container">
                  <img src={Assets.ourMissionImage} alt={"our mission"} />
                </div>
              </div>
            )}
          </div>
        </section>
      </StyledAboutUsPage>
    </Layout>
  );
};

export default AboutUs;
