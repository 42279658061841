import React, { useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NFTCard from "../../../components/layouts/cards/NFTCard/NFTCard";
import {
  CRATE_IMAGE,
  MAX_ALLOWED_FILE_SIZE,
} from "../../../constants/constants";
import { notifyError } from "../../../helpers/toastNotificationService";
import CrateImage from "../CrateImage";
import CrateVideo from "../CrateVideo";
import ImageFileLoader from "./ImageFileLoader";
import {
  canvasPreview,
  centerAspectCrop,
  compressImage,
  dataURLtoBlob,
} from "./cropHelpers";
import { StyledItemPreview } from "./styles";
import { useDebounceEffect } from "./useDebounceEffect";

const ItemImagePreview = ({
  register,
  errors,
  watch,
  crateDrop,
  itemImage,
  setItemImage,
  setThumbnailImage,
}) => {
  const { productType } = useParams();
  const user = useSelector((state) => state.user);
  const titleField = watch("title", false);
  const priceField = watch("price", false);
  const currencyField = watch("currencyType", false);
  const cratesField = watch("crates", false);
  const coaTemplateField = watch("coaTemplateId", false);
  const isCOAField = watch("isCOA", false);
  const [testItem, setTestItem] = useState();
  const [aspect, setAspect] = useState(1);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const handleChange = (element) => {
    const [img] = element.target.files;
    if (img.size > MAX_ALLOWED_FILE_SIZE) {
      element.target.value = null;
      notifyError("Maximum allowed file size is 3 MB");
    } else {
      setItemImage({ image: URL.createObjectURL(img), type: img.type });
    }
  };

  useEffect(() => {
    setTestItem((testItem) => ({
      ...testItem,
      image: itemImage?.image,
      type: itemImage?.type,
      price: priceField,
      currencyType: currencyField,
      originalOwnerId: user.id,
      productId: productType,
      name: titleField,
    }));
  }, [itemImage, priceField, titleField, productType, currencyField, user]);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
        // compress image
        const dataUrl = compressImage(previewCanvasRef.current);
        // convert to blob
        const blob = dataURLtoBlob(dataUrl);
        const blobObjectURL = URL.createObjectURL(blob);
        // display and create binary
        setTestItem((testItem) => ({
          ...testItem,
          croppedImage: blobObjectURL,
        }));
        let file = new File([blob], "fileName.jpg", { type: "image/jpeg" });

        setThumbnailImage(file);
      }
    },
    100,
    [completedCrop]
  );

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  return (
    <StyledItemPreview>
      <div id="item-image-preview">
        <div className="subheader">Item Preview</div>

        {testItem?.image ? (
          <>
            {/* enables cropping for images */}
            {testItem?.image && !testItem?.type?.includes("mp4") && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => {
                  setCompletedCrop(c);
                }}
                aspect={aspect}
                style={{
                  marginBottom: "10px",
                }}
              >
                <img
                  alt="img-to-crop"
                  ref={imgRef}
                  src={testItem?.image}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}

            {/* canvas object to allow blob creation on cropping */}
            {!!completedCrop && (
              <>
                <div>
                  {/* hidden canvas element to get blob from */}
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: "1px solid black",
                      objectFit: "contain",
                      width: completedCrop.width,
                      height: completedCrop.height,
                      display: "none",
                    }}
                  />
                </div>
              </>
            )}

            {(!!completedCrop || testItem?.type?.includes("mp4")) && (
              <NFTCard
                card={testItem}
                isBaseItem={true}
                artistNameShown={"oscar"}
                isPreview={true}
                previewType={testItem?.type}
              />
            )}
          </>
        ) : (
          // renders image file input in case no image has been selected yet
          <ImageFileLoader register={register} handleChange={handleChange} />
        )}
        <p className="error-message">{errors.file?.message}</p>

        {cratesField && crateDrop?.image && (
          <>
            <div className="subheader">Crate Drop Preview</div>
            <div id="crate-preview">
              {crateDrop?.type.includes("image") && (
                <CrateImage img={crateDrop ? crateDrop?.image : CRATE_IMAGE} />
              )}

              {crateDrop?.type.includes("video") && (
                <CrateVideo card={crateDrop ? crateDrop : CRATE_IMAGE} />
              )}
            </div>
          </>
        )}

        {isCOAField === "Y" &&
          coaTemplateField &&
          coaTemplateField?.exampleFile && (
            <>
              <div className="subheader">
                Certificate of Authenticity Example
              </div>
              <div
                className="image-container"
                style={{
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  display: "flex",
                }}
              >
                <img
                  src={coaTemplateField?.exampleFile}
                  alt={`template ${coaTemplateField.label}`}
                />
              </div>
            </>
          )}
      </div>
    </StyledItemPreview>
  );
};

export default ItemImagePreview;
