import React from "react";
import { useFormContext } from "react-hook-form";

const SpecialInvitation = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <section id="special-invitation" className=" col-12 pl-md-4">
      <fieldset>
        <div className="row m-0 w-100">
          <div className=" col-12 col-md-5">
            <h6>Special Invitation access code</h6>
          </div>
          <div className="col-12 col-md-7">
            <input
              id="specialInvitation"
              {...register("specialInvitation")}
              type="text"
              placeholder="Introduce your special invitation code here"
            />
            <p className="error-message">{errors.specialInvitation?.message}</p>
          </div>
        </div>
      </fieldset>
    </section>
  );
};

export default SpecialInvitation;
