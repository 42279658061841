import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import useIbenda from "../../hooks/useIbenda";
import IbendaComponent from "./IbendaComponent";

const CookieSettings = () => {
  const { isLoading } = useIbenda();

  return (
    <div>
      <Header />
      <div className="tf-connect-wallet tf-section">
        {!isLoading && (
          <IbendaComponent
            title={"Cookie Policy"}
            id={"privacy-policy/87038604/cookie-policy"}
          />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default CookieSettings;
