import styled from "styled-components";
import { COMMON_STYLES } from "../../constants/genericStyles";
import { HEADER_HEIGHT } from "../../constants/constants";

export const StyledSelectProductPage = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }
  section#select-product {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .section-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    text-transform: lowercase;
    color: #011126;
    margin-bottom: 2%;
  }
  .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 2%;
  }
  .filled-button {
    ${COMMON_STYLES.FILLED_BUTTON({ fillColor: "#ff7b7c", borderSize: "10px" })}
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    width: 35%;
  }
`;
