import React from "react";
import { priceFormatter } from "../../helpers/formatters";

const TotalCalculations = ({ subtotal, proceedToCheckout, currencyType }) => {
  return (
    <div className="right-section">
      <div className="d-flex justify-content-end">
        <div className="total-container">
          <div className="price-2">
            <span className="subtotal-label">Subtotal: </span>
            {priceFormatter({ price: subtotal, currencyType })}
          </div>
        </div>
      </div>

      <div className="action-buttons">
        <div>
          <button className="filled-button" onClick={proceedToCheckout}>
            <span>Proceed To Checkout</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TotalCalculations;
