import styled from "styled-components";
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from "../../constants/constants";

export const StyledAdditionalPurchaseOptionsPage = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }

  section#additional-purchase-options {
    padding: 4%;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .header-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .page-title-heading {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 10px;
    .vertical-line {
      height: 38px;
      border-left: 6px solid #ffd061;
    }

    .heading {
      font-weight: 700;
      font-size: 40px;
      line-height: 40px;
      color: #011126;
    }
  }

  .purchase-options {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    #spacer {
      padding-top: ${MOBILE_HEADER_HEIGHT.toString() + "px"};
    }
    section#contact-us {
      padding: 5%;
    }
    .header-container {
      justify-content: flex-start;
    }
    .page-title-heading {
      .vertical-line {
        height: 24px;
        border-left: 4px solid #ffd061;
      }
      .heading {
        font-size: 24px;
        line-height: 24px;
      }
    }
    .salutation {
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
    }

    .indications {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      margin-bottom: 20px;
      text-align: center;
    }

    .form-inner form input {
      font-size: 10px;
      line-height: 10px;
    }

    fieldset .dropdown {
      div {
        padding: 0;
        div {
          div {
            padding-left: 8px;
          }
        }
      }
      #react-select-3-placeholder {
        font-size: 12px;
      }
    }

    form fieldset {
      margin-bottom: 16px;
    }

    .inner-row-form {
      display: flex;
      justify-content: center;
    }

    .filled-button {
      font-size: 14px;
      line-height: 14px;
      width: 160px;
    }
  }
`;
