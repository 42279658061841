import React, { useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import {
  CUSTOM_URL_INVALID_MESSAGE,
  CUSTOM_URL_PATTERN,
  LIMITED_COLLECTION_DROPDOWN,
  REQUIRED_MESSAGE,
  ROYALTY_FEES,
} from "../../constants/constants";
import { customSelectStyles } from "./styles";

const CreateCollectionForm = ({
  isGallery,
  loading,
  data,
  register,
  control,
  errors,
  watch,
  limited,
  setLimited,
  royalty,
  setRoyalty
}) => {


  const royaltyFeeField = watch("royaltyFee");
  const validateRoyaltyFee = (value) => {
    if (royaltyFeeField > 0 && !value) {
      return "Royalty Fee Account is required";
    }
  };

  const newData =
    data?.length > 0
      ? data.map((artist) => ({ value: artist.id, label: artist.displayName }))
      : [];

  const options = [
    { value: "1", label: "Apple" },
    { value: "2", label: "Ball" },
    { value: "3", label: "Cat" },
  ];

  const default_value = "";
  const royalty_default_value = 0;

  return (
    <>
      <div className="form-create-item">
        <div className="flat-tabs tab-create-item">
          {isGallery() ? (
            <>
              <fieldset>
                <label className="field-label">Artist</label>
                <div className="select-box">
                  <div className="dropdown">
                    <Controller
                      control={control}
                      defaultValue={default_value}
                      name="artistId"
                      rules={{
                        required: REQUIRED_MESSAGE,
                      }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                      }) => (
                        <Select
                          styles={customSelectStyles}
                          inputRef={ref}
                          options={newData}
                          value={options.find((c) => c.value === value)}
                          onChange={(val) => {
                            onChange(val.value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <p className="error-message">{errors.artistId?.message}</p>
              </fieldset>
            </>
          ) : (
            <></>
          )}

          <fieldset>
            <label className="field-label">Collection Name</label>
            <input
              {...register("tokenName", {
                required: REQUIRED_MESSAGE,
              })}
              name="tokenName"
              type="text"
              max={100}
              placeholder="Enter the Name of your Collection"
            />
            <p className="error-message">{errors.tokenName?.message}</p>
          </fieldset>

          <fieldset>
            <label className="field-label">Collection Symbol</label>
            <input
              {...register("tokenSymbol", {
                required: REQUIRED_MESSAGE,
              })}
              name="tokenSymbol"
              type="text"
              max={100}
              placeholder="Enter the Symbol of your Collection"
            />
            <p className="error-message">{errors.tokenSymbol?.message}</p>
          </fieldset>

          <fieldset>
            <label className="field-label">Description</label>
            <textarea
              {...register("description", {
                required: REQUIRED_MESSAGE,
              })}
              name="description"
              placeholder="e.g. “This is very limited collection, Lorem”"
            ></textarea>
            <p className="error-message">{errors.description?.message}</p>
          </fieldset>
        </div>
      </div>

      <fieldset>
        <label className="field-label">Custom Collection URL</label>
        <input
          {...register("customUrl", {
            pattern: {
              value: CUSTOM_URL_PATTERN,
              message: CUSTOM_URL_INVALID_MESSAGE,
            },
          })}
          name="customUrl"
          type="text"
          max={100}
          placeholder="http://acmelabs.xyz/collection/mycollection"
        />
        <p className="error-message">{errors.customUrl?.message}</p>
      </fieldset>

      <section id="digital-collection">
        <div className="row m-0">
          <div className="subheader">For Digital Collections Only</div>
        </div>
        <div className="row m-0">
          <div className="col-6 pl-0">
            <fieldset>
              <label className="field-label">
                Is this for a limited collection?
              </label>
              <div className="select-box">
                <Select
                  styles={customSelectStyles}
                  options={LIMITED_COLLECTION_DROPDOWN}
                  onChange={(val) => {
                    setLimited(val.value);
                  }}
                />
              </div>
            </fieldset>

            {limited && (
              <fieldset>
                <label className="field-label">
                  Limit of items in collection
                </label>
                <input
                  {...register("maxSupply", {
                    required: REQUIRED_MESSAGE,
                  })}
                  name="maxSupply"
                  type="number"
                  max={15000}
                  placeholder="Maximum 15,000"
                />
                <p className="error-message">{errors.maxSupply?.message}</p>
              </fieldset>
            )}
          </div>
          <div id="digital-right-section" className="col-6">
            <fieldset>
              <label className="field-label">
                What Royalty percentage would you collect on resold digital
                items?
              </label>

              <div className="dropdown">
                <Controller
                  control={control}
                  defaultValue={royalty_default_value}
                  name="royaltyFee"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      styles={customSelectStyles}
                      inputRef={ref}
                      options={ROYALTY_FEES}
                      value={options.find((c) => c.value === value)}
                      onChange={(val) => {
                        onChange(val.value);
                        setRoyalty(val.value);
                      }}
                    />
                  )}
                />
              </div>
            </fieldset>
            {royalty > 0 && (
              <fieldset>
                <label className="field-label">Royalty Fee Account</label>
                <input
                  {...register("royaltyFeeTokenId", {
                    validate: {
                      value: (value) => validateRoyaltyFee(value),
                    },
                  })}
                  name="royaltyFeeTokenId"
                  type="text"
                  placeholder=""
                />
                <p className="error-message">
                  {errors.royaltyFeeTokenId?.message}
                </p>
              </fieldset>
            )}
          </div>
        </div>
      </section>
      <div className="row-form style-3 justify-content-center">
        <div className="inner-row-form">
          <button className="submit">Create Collection</button>
        </div>
      </div>
    </>
  );
};

export default CreateCollectionForm;
