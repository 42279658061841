import React, { useState, useEffect } from "react";
import avt from "../../assets/images/avatar/avatar-profile.png";
import { useS3Url } from "../../hooks/useS3Url";
const UserImage = ({ imgProfile }) => {
  const { getS3Url } = useS3Url();

  const [img, setImg] = useState(imgProfile);
  useEffect(() => {
    if (!imgProfile) {
      /* updates image in case it was deleted from edit profile */
      setImg(null);
      return;
    }
    if (imgProfile.includes("amazonaws")) {
      setImg(imgProfile);
      return;
    }
    const profileFileNameS3 = getS3Url(imgProfile, "profilePicture");
    if (profileFileNameS3.length !== 0) {
      setImg(profileFileNameS3);
    }
  }, [imgProfile, getS3Url]);

  return (
    <img
      className="avatar"
      src={img && img.length !== 0 ? img : avt}
      alt="avatar"
    />
  );
};

export default UserImage;
