import copy from "copy-to-clipboard";
import React, { forwardRef, useEffect, useState } from "react";
import getOneSpace from "../../../api/spaces/getSpaceByUserId";
import { getMyUser } from "../../../api/user/getUser";
import Assets from "../../../assets/Assets";
import avt from "../../../assets/images/avatar/avatar-profile.png";
import LinkWithQueryParams from "../../../components/LinkWithQueryParams";
import {
  IS_OTHER_OPTIONS_ENABLED,
  IS_SHARE_ENABLED,
  REACT_APP_FRONTEND_URL,
} from "../../../constants/constants";
import { priceFormatter } from "../../../helpers/formatters";
import {
  copyToClipboard,
  iOSversion,
  isBuyableBaseItem,
  isBuyableItem,
  isResaleItem,
  itemDetailsBaseRoute,
} from "../../../helpers/itemHelpers";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";
import { useS3Url } from "../../../hooks/useS3Url";
import BuyBtn from "./BuyBtn";

const InfoNFT = forwardRef((props, ref) => {
  const {
    serial,
    metadata,
    user,
    wallet,
    productName,
    setVideoHeight,
    collectionInfo,
  } = props;
  const isMobile = useCheckMobileScreen();

  console.log("🚀 ~ file: InfoNFT.jsx ~ line 8 ~ InfoNFT ~ serial", serial);
  const [space, setSpace] = useState({});
  const [copied, setCopied] = useState(false);

  const { getS3Url } = useS3Url();
  const [img, setImg] = useState(user.profileFileName);
  const [visible, setVisible] = useState(false);

  const getSpace = async (userId) => {
    const response = await getOneSpace(userId);
    response && setSpace(response);
  };

  const getEncodedShareableURL = async () => {
    const itemSeparator = itemDetailsBaseRoute({ item: serial });
    const encodedURL = encodeURI(
      `${REACT_APP_FRONTEND_URL}${itemSeparator}/${serial?.id}`
    );
    const myUser = await getMyUser();
    const shareableURL = encodeURI(
      encodedURL +
        (myUser?.affiliateCode ? `?affl=${myUser?.affiliateCode}` : "")
    );
    return shareableURL;
  };

  const onCopyLink = async () => {
    const shareableURL = await getEncodedShareableURL();
    setCopied(true);
    const ver = iOSversion();
    if (ver && ver?.[0] >= 10) {
      setTimeout(() => {
        copyToClipboard(shareableURL);
      });
    } else {
      copy(shareableURL);
    }
    setTimeout(() => {
      setCopied(false);
      setVisible(false);
    }, "2000");
  };

  const onTwitterShare = async () => {
    const shareableURL = await getEncodedShareableURL();
    const encodedMessage = encodeURI(
      `Check out what I've been curating at Acme Labs!\n`
    );
    const twitterURL = `https://twitter.com/intent/tweet?text=${encodedMessage}&url=${shareableURL}`;
    setTimeout(() => {
      window.open(twitterURL, "_blank");
    });
  };

  const onFacebookShare = async () => {
    const shareableURL = await getEncodedShareableURL();
    const facebookURL = `https://facebook.com/sharer/sharer.php?u=${shareableURL}`;
    setTimeout(() => {
      window.open(facebookURL, "_blank");
    });
  };

  useEffect(() => {
    getSpace(user.id);
  }, [user.id]);

  useEffect(() => {
    if (!user?.profileFileName) return;
    if (user?.profileFileName.includes("amazonaws")) {
      setImg(user?.profileFileName);
      return;
    }
    const profileFileNameS3 = getS3Url(user?.profileFileName, "profilePicture");
    if (profileFileNameS3.length !== 0) {
      setImg(profileFileNameS3);
    }
  }, [user.profileFileName, getS3Url]);

  useEffect(() => {
    if (ref.current) {
      const height = ref.current.offsetHeight;
      setVideoHeight(`${height}${"px"}`);
    }
  }, [ref]);

  return (
    <div className="item-card" ref={ref}>
      <div className="actions-container">
        {IS_SHARE_ENABLED && (
          <span
            className="fa-share-alt"
            onClick={() => setVisible(!visible)}
          ></span>
        )}

        {visible && (
          <div className="share_popup mt-20">
            <div className="share_option" onClick={onTwitterShare}>
              <img src={Assets.twitterIcon} alt={"twitter icon"} />
              <p>Twitter</p>
            </div>

            <div className="share_option" onClick={onFacebookShare}>
              <img src={Assets.facebookIcon} alt={"facebook icon"} />
              <p>Facebook</p>
            </div>

            <div className="share_option" onClick={onCopyLink}>
              <img src={Assets.copyIcon} alt={"copy icon"} />
              <p className="copy-link">
                {copied ? "Link Copied" : "Copy Link"}
              </p>
            </div>
          </div>
        )}

        {IS_OTHER_OPTIONS_ENABLED && (
          <span className="fa-elipsis-stroke"></span>
        )}
      </div>

      <div className="sc-item-details">
        <div className="header-container">
          {img && img.length !== 0 ? (
            <img src={img} alt="" className="avatar-image" />
          ) : (
            // <div className="gray-background"></div>
            <img src={avt} alt="" className="avatar-image" />
          )}
          <div className="d-flex flex-column justify-content-around p-1">
            <LinkWithQueryParams path={`/spaces/${space.customURL}`}>
              <h6 className="nft-artist">
                {collectionInfo?.artist?.displayName}
              </h6>
            </LinkWithQueryParams>

            <div className="page-title-heading">
              <div className="vertical-line"></div>
              <h1 className="heading">{metadata.name}</h1>
            </div>

            {productName && <p className="nft-product-name">{productName}</p>}
          </div>
        </div>

        <div className="my-5">
          <p className="nft-description">{metadata.description}</p>
          <br />
          {serial?.quantity > 0 && (
            <sub className="nft-left">{serial.quantity} left in stock</sub>
          )}
        </div>

        {!isMobile &&
          (isBuyableBaseItem({ item: serial }) ||
            isBuyableItem({ item: serial })) && (
            <div className="price-container">
              <h3 className="price-label"> Current Price: </h3>
              <h2 className="price">
                {priceFormatter({
                  currencyType: isResaleItem({ item: serial })
                    ? serial?.saleCurrencyType
                    : serial?.currencyType,
                  price: isResaleItem({ item: serial })
                    ? serial?.salePrice
                    : serial?.price,
                })}
              </h2>
              <div className="btn-container">
                <BuyBtn card={serial} walletConnected={wallet} />
              </div>
            </div>
          )}

        {collectionInfo?.tokenName?.length > 0 &&
          collectionInfo?.id?.length > 0 && (
            <div className="collection-info-container">
              <div className="collection-label">Collection:</div>
              <div className="collection-value">
                <LinkWithQueryParams path={`/collection/${collectionInfo?.id}`}>
                  {collectionInfo?.tokenName}
                </LinkWithQueryParams>
              </div>
            </div>
          )}
      </div>
    </div>
  );
});

export default InfoNFT;
