import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { AFFILIATE_QUERY_STRING } from "../constants/constants";

export const useCustomNavigator = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const navigateWithAfflCode = ({ navigateObj, afflCode, state }) => {
    // Logged in Redirection with query string
    navigateObj.search = `?${createSearchParams({
      [AFFILIATE_QUERY_STRING]: afflCode,
    }).toString()}`;

    // Done to preserve data if we are redirected to login page
    navigateObj.options = {
      state: {
        ...state,
        search: {
          [AFFILIATE_QUERY_STRING]: afflCode,
        },
      },
    };

    navigate(
      { pathname: navigateObj.to, search: navigateObj.search },
      navigateObj.options
    );
  };

  const customNavigate = ({ path, state }) => {
    let afflCode = searchParams.get(AFFILIATE_QUERY_STRING);

    /* If afflCode is present on location state */
    if (!afflCode && state?.search) {
      afflCode = state?.search?.[AFFILIATE_QUERY_STRING];
    }

    const navigateObj = {
      to: path,
    };

    if (state) {
      navigateObj.options = {
        state,
      };
    }

    if (afflCode) {
      navigateWithAfflCode({ navigateObj, afflCode, state });
    } else {
      navigate({ pathname: navigateObj.to }, { ...navigateObj.options });
    }
  };

  return {
    customNavigate,
  };
};
