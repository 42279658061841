import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import getItemsByUserId from "../../../api/Item/getItemsByUserId";
import item2 from "../../../assets/images/item-background/item-2.png";
import { COMMON_STYLES } from "../../../constants/genericStyles";
import useIPFS from "../../../hooks/useIPFS";
import LinkWithQueryParams from "../../LinkWithQueryParams";
import Spinner from "../Spinner/Spinner";

const ContainerCard = styled.div`
  height: initial;

  .content2 .fs-16 {
    font-size: 24px;
  }
  .card_item .inner-card {
    ${COMMON_STYLES.GENERIC_IMAGE_CONTAINER({ paddingTop: "120.25%" })}
  }
  .card_item .inner-card {
    width: 100%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    /* box-shadow: 14px 12px 15px 6px rgba(0, 0, 0, 0.22); */
    position: relative;
    border-radius: 67px;
  }
  .card_item .inner-card .content2 {
    padding-left: 10%;
    padding-bottom: 10%;
  }

  .inner-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 768px) {
    .card_item a .inner-card {
      border-radius: 15px !important;
    }
    .card_item .inner-card::before {
      padding-top: 100%;
    }
    .card_item .inner-card .content2 .fs-16 {
      font-size: 12px !important;
    }
    .card_item .inner-card .content2 {
      font-size: 12px !important;
      padding-left: 10% !important;
      padding-bottom: 0 !important;
    }
  }
`;
const PlaceCard = ({ card }) => {
  const { element, getData } = useIPFS();

  const [loading, setLoading] = useState(true);

  const getAllItems = async (id) => {
    try {
      const response = await getItemsByUserId(id);
      response.length && getData(response[0].cid);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllItems(card.userId);
  }, []);

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <ContainerCard className="container-cards">
          <div className="card_item">
            <LinkWithQueryParams path={`/spaces/${card.customURL}`}>
              <div className="inner-card">
                <img src={element.image ? element.image : item2} alt="Axies" />
                <div className="content2">
                  <div className="fs-16">{card.customURL}</div>
                </div>
              </div>
            </LinkWithQueryParams>
          </div>
        </ContainerCard>
      )}
    </Fragment>
  );
};

export default PlaceCard;
