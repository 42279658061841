import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getBaseItems } from "../../api/Item/baseItems";
import { getAllResaleItems } from "../../api/Item/getItems";
import Collection03 from "../../components/collection/Collection03";
import Layout from "../../components/layouts/Layout";
import Spinner from "../../components/layouts/Spinner/Spinner";
import {
  HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
  NEW_ID,
  RESALE_ID,
} from "../../constants/constants";
import {
  isResaleItem,
  joinResaleAndRegularItems,
} from "../../helpers/itemHelpers";
import Filters from "../Explore/Filters";

const StyledExplore = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }
  .filter-label {
    text-transform: capitalize;
  }
  @media (max-width: 768px) {
    #spacer {
      padding-top: ${MOBILE_HEADER_HEIGHT.toString() + "px"};
    }
    .sc-button-7 {
      padding: 12px 33px;
      border-radius: 10px;
      border: 2.5px solid #ff7b7c;

      a span {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
`;

const ExploreQuantityNFT = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);

  const onChangeFilters = (obj) => {
    if (obj.checked) {
      setFilteredItems(items);
      return;
    }
    const selectedValue = obj.value;
    let filteredItems = [];
    switch (selectedValue) {
      case NEW_ID:
        filteredItems = items.filter((item) => {
          return !isResaleItem({ item });
        });
        break;
      case RESALE_ID:
        filteredItems = items.filter((item) => {
          return isResaleItem({ item });
        });
        break;
      default:
        break;
    }
    setFilteredItems(filteredItems);
  };

  const getAllItems = async () => {
    try {
      const baseItems = await getBaseItems();
      const allResaleItems = await getAllResaleItems();

      let resaleAndRegularItems = joinResaleAndRegularItems({
        regularItems: baseItems,
        resaleItems: allResaleItems,
      });

      setItems(resaleAndRegularItems);
      setFilteredItems(resaleAndRegularItems);
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => await getAllItems())();
  }, []);

  return (
    <Layout>
      <StyledExplore>
        <div id="spacer"></div>
        <section>
          <div className="themesflat-container mt-5">
            <div className="row m-0">
              {error && (
                <div className="d-flex justify-content-center align-items-center vh-100 w-100">
                  <div className="d-flex flex-column">
                    <h3> An error has ocurred! </h3>
                    <button
                      className="sc-button-3 no-bg"
                      onClick={() => window.location.reload()}
                    >
                      {" "}
                      reload{" "}
                    </button>
                  </div>
                </div>
              )}
              {loading ? (
                <div className="d-flex justify-content-center align-items-center vh-100 w-100">
                  <Spinner />
                </div>
              ) : !items.length ? (
                <h3>No items to Show!</h3>
              ) : (
                <>
                  <div className="col-xl-3 col-lg-3 col-md-12">
                    <Filters
                      status={false}
                      categories={false}
                      blockchains={false}
                      itemType={true}
                      action={onChangeFilters}
                    />
                  </div>

                  <div className="col-xl-9 col-lg-9 col-md-12">
                    <Collection03
                      data={filteredItems}
                      stop={15}
                      measures={{ md: 4, sm: 6 }}
                      isBaseItem={true}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      </StyledExplore>
    </Layout>
  );
};

export default ExploreQuantityNFT;
