import React, { useState } from "react";
import ItemCard from "./ItemCard/ItemCard";

const ListMyItems = ({
  itemType,
  items,
  isGallery,
  itemsLimit,
  transferToEscrow,
  myVaultItems,
}) => {
  const [visible, setVisible] = useState(itemsLimit ? itemsLimit : 5);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 5);
  };

  return (
    <div>
      {!items ? (
        <h4>An Error has Ocurred</h4>
      ) : !items.length ? (
        <h4>No Items!</h4>
      ) : (
        <>
          {items.slice(0, visible).map((item) => (
            <div key={item.id}>
              <ItemCard
                item={item}
                type={itemType}
                isGallery={isGallery}
                transferToEscrow={transferToEscrow}
                myVaultItems={myVaultItems}
              />
            </div>
          ))}

          {visible < items.length && (
            <div className="col-md-12 wrap-inner load-more text-center">
              <button
                id="load-more"
                className="sc-button-2 no-bg"
                onClick={showMoreItems}
              >
                <span>Load More</span>
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ListMyItems;
