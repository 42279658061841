import React, { useState } from "react";
import {
  FILE_TYPES_ALLOWED_MESSAGE,
  MAX_ALLOWED_FILE_SIZE,
} from "../../constants/constants";
import { notifyError } from "../../helpers/toastNotificationService";
import CollectionImage from "../Collection/CollectionImage";
import CollectionVideo from "../Collection/CollectionVideo";

const CollectionImagePreview = ({ register, errors }) => {
  const [img, setImg] = useState(null);

  const handleChange = (element) => {
    const [img] = element.target.files;
    if (img.size > MAX_ALLOWED_FILE_SIZE) {
      element.target.value = null;
      notifyError("Maximum allowed file size is 3 MB");
    } else {
      setImg({ image: URL.createObjectURL(img), type: img.type });
    }
  };

  return (
    <div id="collection-image-preview">
      <div className="subheader">Collection Image Preview</div>
      <div className="card-media">
        <div className="image-container">
          {img?.image && img?.image?.length !== 0 ? (
            <>
              {img?.type.includes("image") && (
                <CollectionImage previewImage={img?.image} />
              )}

              {img?.type.includes("video") && (
                <CollectionVideo previewImage={img} />
              )}
            </>
          ) : (
            <>
              <div className="gray-background">
                <label className="uploadFile">
                  <input
                    type="file"
                    name="file"
                    className="inputfile form-control"
                    {...register("file", {
                      onChange: handleChange,
                    })}
                    accept="image/jpg,image/webp,image/jpeg,image/png,image/gif,video/mp4"
                  />
                </label>
                <div className="image-indications">
                  {FILE_TYPES_ALLOWED_MESSAGE}
                </div>
              </div>
              <p className="error-message">{errors.file?.message}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CollectionImagePreview;
