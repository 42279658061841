import React from "react";
import {
  statusClassSelector,
  statusFormatter,
} from "../../../helpers/formatters";

const ItemCardRightSection = ({
  item,
  handleExpand,
  needsExpansion,
  expanded,
  isMobile,
}) => {
  return (
    <div className="right-section" onClick={handleExpand}>
      {!isMobile && item?.status && (
        <div
          className={`status-label ${statusClassSelector({
            status: item?.status,
          })}`}
        >
          <span>{statusFormatter({ status: item?.status })}</span>
        </div>
      )}

      {needsExpansion && (
        <div className="chevron-container">
          <i
            className={`fal ${expanded ? "fa-chevron-up" : "fa-chevron-down"}`}
          ></i>
        </div>
      )}
    </div>
  );
};

export default ItemCardRightSection;
