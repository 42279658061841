import styled from "styled-components";
import { HEADER_HEIGHT } from "../../constants/constants";
export const LoginPage = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }
  .tf-login.tf-section {
    padding-top: 0;
  }
  .tf-login.tf-section .row-form.style-2 {
    margin-bottom: 1vw;
  }
  @media (max-width: 768px) {
    .tf-login.tf-section .page-title-heading .heading {
      font-weight: 700;
      font-size: 40px;
      line-height: 40px;
    }

    .page-title-heading .vertical-line {
      height: 21px;
      border-left: 5px solid #ffd061;
    }

    .tf-login.tf-section .header-container h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
    }

    .tf-login.tf-section .header-container.style2 h4 {
      font-size: 12px;
      line-height: 12px;
    }

    .journey-btn-link a {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
    }

    .left-background {
      padding-top: 50px;
      padding-bottom: 40px;
    }

    .tf-login.tf-section .right-container {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 20px;
      padding-top: 20px;
    }

    .right-container .form-field-label {
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
      color: #000000;
    }

    .tf-login.tf-section .form-inner form label {
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
      color: #000000;
      padding-left: 16px;
    }

    .form-inner .btn-checkbox:after {
      left: 0px;
      top: 0px;
    }

    .tf-login.tf-section .row-form.style-2 {
      margin-bottom: 20px;
    }

    .tf-login.tf-section .row-form .forgot-pass {
      font-weight: 400;
      font-size: 8px;
      line-height: 8px;
      text-decoration-line: underline;
      margin-bottom: 20px;
    }

    .tf-login.tf-section .form-inner button {
      box-shadow: 5px 6px 16px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      padding: 10px 16%;
      font-size: 10px;
      line-height: 10px;
    }

    .tf-login.tf-section input {
      font-size: 10px;
      line-height: 10px;
      font-weight: 600;
    }

    .tf-login.tf-section input::placeholder {
      font-size: 10px;
      line-height: 10px;
    }

    .right-container .error-message {
      font-size: 10px;
      line-height: 10px;
    }

    .error-message .forgot-pass {
      font-size: 10px;
      line-height: 10px;
    }

    #incorrect-credentials {
      margin-top: 16px;
    }

    .form-inner .btn-checkbox {
      width: 10px;
      height: 10px;
      border-radius: 0px;
    }
  }
`;
