import { getProductAddon, getProductById } from "../api/products/getProducts";

export const isItem = ({ item }) => item.baseItemId;

export const isBuyableBaseItem = ({ item }) =>
  !isItem({ item }) && item.quantity >= 1;

export const isBuyableItem = ({ item }) =>
  isItem({ item }) && item.status === "FOR_SALE";

export const isResaleItem = ({ item }) => {
  const isResaleItem =
    isItem && item.status === "FOR_SALE" && item.saleStartDate;
  return isResaleItem;
};

export const isAddonItem = ({ item }) => {
  const isAddonItem =
    item.hasOwnProperty("addonConfigDetails") && item.addonConfigDetails;
  return isAddonItem;
};

export const getProductName = async ({ item }) => {
  const productId = item?.productId;
  let productName;
  const isAddon = isAddonItem({ item });
  if (isAddon) {
    const addonConfig = await getProductAddon({ id: item.addonId });
    productName = addonConfig?.description;
  } else {
    const productData = await getProductById({ productId });
    productName = productData?.name;
  }
  return productName;
};

export const joinResaleAndRegularItems = ({ regularItems, resaleItems }) => {
  let resaleAndRegularItems = [];
  if (resaleItems?.length > 0) {
    const resaleItemsIds = resaleItems.map((resaleItem) => resaleItem.id);

    const nonResaleItems = regularItems.filter(
      (item) => !resaleItemsIds.includes(item.id)
    );

    resaleAndRegularItems = [...nonResaleItems, ...resaleItems];
  } else {
    resaleAndRegularItems = [...regularItems];
  }

  return resaleAndRegularItems;
};

export const itemDetailsBaseRoute = ({ item }) =>
  isItem({ item }) ? "item-details-01" : "item-details";

export const iOSversion = () => {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
  }
};

export const copyToClipboard = (shareableURL) => {
  let textarea;
  let result;

  try {
    textarea = document.createElement("textarea");
    textarea.setAttribute("readonly", true);
    textarea.setAttribute("contenteditable", true);
    textarea.style.position = "fixed"; // prevent scroll from jumping to the bottom when focus is set.
    textarea.value = shareableURL;

    document.body.appendChild(textarea);

    textarea.focus();
    textarea.select();

    const range = document.createRange();
    range.selectNodeContents(textarea);

    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);

    textarea.setSelectionRange(0, textarea.value.length);
    result = document.execCommand("copy");
  } catch (err) {
    console.error(err);
    result = null;
  } finally {
    document.body.removeChild(textarea);
  }
};
