import React from "react";
import { Modal } from "react-bootstrap";
import { StyledModal } from "./PopupModal/styles";

const PopupModal = ({ show, onHide }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="popup-blocker-modal"
    >
      <Modal.Body
        style={{
          backgroundColor: "#011126",
        }}
      >
        <StyledModal>
          <div className="modal-title">POPUP BLOCKER DETECTED</div>
          <div className="modal-content-text">
            Your popup blocker is preventing your wallet from working. please
            disable your popup blocker and try again.
          </div>
          <div className="button-container">
            <button type="button" className="filled-button" onClick={onHide}>
              OK
            </button>
          </div>
        </StyledModal>
      </Modal.Body>
    </Modal>
  );
};

export default PopupModal;
