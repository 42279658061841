import React from "react";
import styled from "styled-components";
const StyledBanner = styled.div`
  background-color: #010204;
  padding: 2px 0px;
  .banner-indications-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .banner-indications {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
  }
  .beta-button {
    background: #1b70d0;
    box-shadow: 8px 7px 12px 8px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding: 6px 20px;
    margin-right: 6px;
    a {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: #ffffff;
    }
  }
  .important-button {
    background: #FF7B7C;
    box-shadow: 8px 7px 12px 8px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding: 6px 20px;
    margin-right: 6px;
    a {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: #ffffff;
    }
  }
  .banner-container {
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 1440px;
    max-width: 100%;
  }
  @media (max-width: 1199px) {
    .banner-container {
      width: 1200px;
      max-width: 100%;
    }
  }

  @media (max-width: 767px) {
    .banner-container {
      width: 750px;
      height: 40px;
      display: flex;
      align-items: center;

      .banner-indications {
        font-weight: 400;
        font-size: 11px;
        line-height: 11px;
      }

      .beta-button {
        border-radius: 8px;
        padding: 3px 16px;
        a {
          font-weight: 400;
          font-size: 11px;
          line-height: 11px;
        }
      }
    }
  }
`;
const BetaBanner = () => {
  return (
    <StyledBanner>
      <div className="row m-0">
        <div className="banner-container">
          <div className="banner-indications-container">
            <div className="beta-button">
              <a
                href="https://cms.acmelabs.xyz/feedback/"
                target="_blank"
                rel="noreferrer"
              >
                Beta
              </a>
            </div>
            <div className="banner-indications">
              This is a new service - your{" "}
              <a
                href="https://cms.acmelabs.xyz/feedback/"
                target="_blank"
                rel="noreferrer"
              >
                <span style={{ color: "#1B70D0" }}>feedback</span>{" "}
              </a>
              will help us improve.
            </div>
          </div>
        </div>
      </div>
    </StyledBanner>
  );
};

export default BetaBanner;
