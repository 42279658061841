import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import LinkWithQueryParams from "../../components/LinkWithQueryParams";
import { BASE_URL, VIRTUAL_PLACE_INDICATIONS } from "../../constants/constants";
import { useApi } from "../../hooks/useApi";
import HeadingText from "./HeadingText";

const { REACT_APP_API_URL } = process.env;
const Space = ({ user }) => {
  const { executeApiRequestTwo } = useApi();
  const [customURL, setCustomURL] = useState("");
  const [editing, setEditing] = useState(false);
  const { register, getValues } = useFormContext();
  const handleEditing = () => {
    setEditing(!editing);
    if (getValues("customURL")) {
      setCustomURL(getValues("customURL"));
    }
  };
  useEffect(async () => {
    const data = await executeApiRequestTwo(`${REACT_APP_API_URL}space/my`);
    if (data.customURL) {
      setCustomURL(data.customURL);
    }
  }, []);

  return (
    <section id="virtual-place" className="col-md-7 col-12">
      <HeadingText title={"Place URL"} variant="small" />
      <p className="indications">{VIRTUAL_PLACE_INDICATIONS}</p>
      <br />
      <h6 className="url-title">{`${
        customURL ? "actual Place URL" : "autogenerated Place URL"
      }:`}</h6>
      <p className="url-text">
        <LinkWithQueryParams
          path={`/spaces/${customURL ? customURL : user.id}`}
        >
          {`${BASE_URL}/spaces/${customURL ? customURL : user.id}`}
        </LinkWithQueryParams>
      </p>
      <br />
      <br />
      {!editing ? (
        <div className="row justify-content-center">
          <button
            type="button"
            className="btn-customize"
            onClick={handleEditing}
          >
            Customize URL
          </button>
        </div>
      ) : (
        <>
          <fieldset>
            <h6>new Place URI</h6>
            <input
              id="customURL"
              {...register("customURL")}
              type="text"
              defaultValue={`${customURL ? customURL : user.id}`}
              placeholder="Set custom Place URL"
            />
          </fieldset>
          <br />
          <button type="button" className="save-btn" onClick={handleEditing}>
            Confirm Changes
          </button>
        </>
      )}
      <br />
    </section>
  );
};

export default Space;
