import styled from "styled-components";
import { COMMON_STYLES } from "../../../constants/genericStyles";

export const StyledFeaturedSection = styled.section`
  position: relative;
  background-color: #fff;
  display: block;

  .swiper-pagination {
    bottom: 20px;
    padding: 0 !important;
    margin: 0;
  }

  .overlay {
    ${COMMON_STYLES.OVERLAY}
  }

  .swiper-button {
    cursor: pointer;
    color: #fff;
    position: absolute;
    width: 2%;
    height: auto;
    z-index: 100;
    bottom: 10px;
    &:hover {
      opacity: 0.8;
    }
  }
  .swiper-button img {
    width: 100%;
    height: auto;
  }
  .swiper-pagination-bullet {
    background-color: #ffffff;
    opacity: 1;
    width: 13px;
    height: 14px;
  }
  .swiper-pagination-bullet-active {
    background-color: #ffd061 !important;
  }

  .swiper-button.custom-prev-button {
    left: 43%;
  }
  .swiper-button.custom-next-button {
    right: 43%;
  }

  .swiper {
    padding-bottom: 100px !important;
  }

  .swiper-button-prev,
  .swiper-button-next{
    display: none !important;
  }
  
  @media (max-width: 768px) {
    .exp {
      font-size: 12px !important;
    }
    .tf-section.live-auctions {
      padding: 20px 0px !important;
      padding-bottom: 0px !important;
    }
    .swiper {
      padding-bottom: 60px !important;
    }
    ${COMMON_STYLES.SWIPER_NAVIGATION}
  }
`;
