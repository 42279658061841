import React from "react";
import Layout from "../../components/layouts/Layout";
import { StyledAdministrationPage } from "./styles";
import { useNavigate } from "react-router-dom";

const AdministrationPage = () => {
  const navigate = useNavigate();
  const administrationActions = [
    {
      id: 0,
      name: "Create an Activity or Announcement",
      buttonText: "Create Activity",
      link: "/create-activity",
    },
    {
      id: 1,
      name: "Administrative Action",
      buttonText: "Administrative Action",
      link: "/create-activity",
    },
    {
      id: 2,
      name: "Administrative Action",
      buttonText: "Administrative Action",
      link: "/create-activity",
    },
  ];
  return (
    <Layout>
      <StyledAdministrationPage>
        <div id="spacer"></div>
        <section id="administration-page">
          <div className="header-container">
            <div className="page-title-heading">
              <div className="vertical-line"></div>
              <h1 className="heading">Administration</h1>
            </div>
          </div>

          <div className="row m-0">
            {administrationActions.map((admin, i) => {
              return (
                <div className="col-4" key={"administrative-action " + i}>
                  <div className="administrative-card">
                    <div className="administrative-label">{admin.name}</div>
                    <button
                      type="button"
                      className="filled-button"
                      onClick={() => navigate(admin.link)}
                    >
                      {admin.buttonText}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </StyledAdministrationPage>
    </Layout>
  );
};

export default AdministrationPage;
