import { getIdTokenClaims, isAuthenticated } from "../../helpers/auth0Service";

export const getHeaders = async () => {
  const isTokenValid = isAuthenticated();
  const idToken = await getIdTokenClaims();
  const token = idToken?.__raw ?? localStorage.getItem("token");
  return (
    isTokenValid &&
    token && {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
