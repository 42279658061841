import styled from "styled-components";
import { COMMON_STYLES } from "../../constants/genericStyles";
import { HEADER_HEIGHT } from "../../constants/constants";

export const StyledCreateItemPage = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }
  .form-create-item {
    padding-left: 0;
  }
  #right-section {
    border-left: 1px solid #cdcdcd;
    .header-description {
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      color: #000000;
      padding-bottom: 10px;
      border-bottom: 1px solid #cdcdcd;
      margin-bottom: 22px;
    }
  }
  .page-title-heading {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 10px;
    .vertical-line {
      height: 30px;
      border-left: 6px solid #ffd061;
    }

    .heading {
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
      color: #011126;
    }
  }
  section#create-item {
    padding-top: 2%;
    padding-bottom: 2%;
  }

  /* form related */
  form select {
    padding: 0px 20px;
    margin-bottom: 24px;
    line-height: 44px;
  }
  form fieldset {
    margin-bottom: 24px;
  }

  .field-label,
  .form-field-label {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 8px;
    height: 40px;
  }

  .error-message,
  .card-error {
    color: #e42c2c;
    font-weight: 400;
    font-size: 15px;
  }

  .uploadFile {
    height: 50px;
    width: 50%;
    background-color: #9a9a9a;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }
  .uploadFile input[type="file"]:before {
    color: white;
    background-color: transparent;
    font-weight: 400;
    font-size: 20px;
    width: 100%;
    /* line-height: 20px; */
  }
  .uploadFile .form-control:focus {
    background-color: transparent;
  }
  .uploadFile input[type="file"] {
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
  }

  #collection-image-preview {
    .subheader {
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: #000000;
    }
    .image-indications {
      width: 100%;
      font-weight: 300;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      color: #000000;
    }
    .btn-upload {
      position: relative;
      width: 100%;
      height: 48px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      /* background-color: rgba(81,66,252,0.1); */
      /* border-radius: 30px; */
      background: #25a29d;
      border: none;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      border-radius: 8px;
      font-size: 14px;
      /* line-height: 22px; */
      font-weight: 700;
      color: #ffffff;
      margin-top: 21px;

      margin-top: 0;
      margin-bottom: 8px;
    }

    .collection-image-container {
      height: 220px;
      width: 220px;
      margin-bottom: 10px;
    }

    .collection-image {
      ${COMMON_STYLES.GENERIC_IMAGE_CONTAINER({
        paddingTop: "100%",
      })}
      border-radius: 19px;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      .gray-background {
        position: absolute;
        top: 0;
        width: 229px;
        height: 220px;
        background: #d9d9d9;
        border-radius: 19px;
      }
    }
  }

  .card-media {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    .image-container {
    }
    .gray-background {
      width: 285px;
      height: 264px;
      background-color: #d9d9d9;
      box-shadow: 4px 0px 12px rgba(0, 0, 0, 0.25);
      border-radius: 27px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
    }
    .btn-upload {
      margin-top: 0;
      margin-bottom: 8px;
    }
  }

  #item-image-preview {
    .no-image-preview {
      width: 100%;
      .gray-background {
        max-width: 100%;
      }
      .image-indications {
        width: 100%;
        text-align: center;
      }
    }
    .nft-card {
      width: 100%;
    }
  }
  .dropdown {
    width: 100%;
  }

  .progress-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    right: 0;
    bottom: 0;
    background: rgba(205, 205, 205, 0.8);
    z-index: 7777;
    position: fixed;

    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    .progess-status {
      display: block;
      width: 100%;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 8px;
      text-align: center;
      color: #011126;
    }
    .spinner {
      width: 50px;
      height: 50px;
    }
  }

  button.filled-button {
    ${COMMON_STYLES.FILLED_BUTTON({
      fillColor: "#ff7b7c",
      borderSize: "10px",
    })}
    width: 100%;
    padding: 16px 50px;
    font-size: 16px;
    line-height: 16px;
  }
`;
