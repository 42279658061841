import { CUSTOM_PAGE_SIZE, REACT_APP_API_URL } from "../../constants/constants";
import { getHeaders } from "../Item/utils";

export const createOrder = async ({ requestBody }) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify(requestBody),
    });
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

export const calculateSalesTax = async ({ requestBody }) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}taxjar/sales-tax-calculation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify(requestBody),
    });
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

export const getMyOrders = async () => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(
      `${REACT_APP_API_URL}order/my?` +
        new URLSearchParams({
          customPageSize: CUSTOM_PAGE_SIZE,
        }),
      {
        method: "GET",
        headers,
      }
    );

    return response.json();
  } catch (error) {
    console.log(error);
  }
};

export const getMySoldOrders = async () => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}order/my/sales`, {
      method: "GET",
      headers,
    });
    return response.json();
  } catch (error) {
    console.log(error);
  }
};
