import styled from "styled-components";
import { COMMON_STYLES } from "../../constants/genericStyles";
export const MainSlider = styled.div`
  position: relative;

  .flat-title-page {
    height: 800px;
    padding-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper-button-prev,
  .mainslider .swiper-button-next {
    width: 50px;
    height: 50px;
    background: var(--accent-button);
    border-radius: 50%;
  }
  .swiper-button-next:after,
  .mainslider .swiper-button-prev:after {
    font-size: 16px;
    color: #fff;
  }
  .slider-content {
    display: flex;
    justify-content: space-between;
    max-width: 1440px;
  }
  .swiper-pagination {
    bottom: 4%;
  }
  .swiper-pagination-bullet {
    background-color: #ffffff;
    opacity: 1;
    width: 13px;
    height: 14px;
    /* width: */
  }
  .swiper-pagination-bullet-active {
    background-color: #ffd061;
    /* width: */
  }
  .swiper-button {
    cursor: pointer;
    color: #fff;
    position: absolute;
    width: 2%;
    height: auto;
    z-index: 100;
    bottom: 3%;
    &:hover {
      opacity: 0.8;
    }
  }
  .swiper-button img {
    width: 100%;
    height: auto;
  }
  .swiper-button.custom-prev-button {
    left: 43%;
  }
  .swiper-button.custom-next-button {
    right: 43%;
  }
  .flat-title-page .slider-content .heading,
  .flat-title-page .slider-content .sub-heading,
  .flat-title-page .slider-content .btn-auction,
  .flat-title-page .slider-content .cards-container,
  .flat-title-page .slider-content .action-button,
  .flat-title-page .slider-content .slider-image-content,
  .flat-title-page .slider-centered-content {
    opacity: 0;
  }
  /* animation slider */
  .flat-title-page .slider-content .heading,
  .flat-title-page .slider-content .sub-heading,
  .flat-title-page .slider-content .btn-auction,
  .flat-title-page .slider-content .cards-container,
  .flat-title-page .slider-content .action-button,
  .flat-title-page .slider-content .slider-image-content,
  .flat-title-page .slider-centered-content {
    transform: translateY(50px);
  }
  .swiper-slide-active .flat-title-page .slider-content .heading,
  .swiper-slide-active .flat-title-page .slider-content .sub-heading,
  .swiper-slide-active .flat-title-page .slider-content .btn-auction,
  .swiper-slide-active .flat-title-page .slider-content .cards-container,
  .swiper-slide-active .flat-title-page .slider-content .action-button,
  .swiper-slide-active .flat-title-page .slider-content .slider-image-content,
  .swiper-slide-active .flat-title-page .slider-centered-content {
    opacity: 1;
    visibility: visible;
    -webkit-transition: transform 0.5s ease, opacity 0.5s ease;
    -moz-transition: transform 0.5s ease, opacity 0.5s ease;
    -ms-transition: transform 0.5s ease, opacity 0.5s ease;
    -o-transition: transform 0.5s ease, opacity 0.5s ease;
    transition: transform 0.5s ease, opacity 0.5s ease;
    transition-delay: 0.5s;
  }
  .swiper-slide-active .flat-title-page .slider-content .heading,
  .swiper-slide-active .flat-title-page .slider-content .sub-heading,
  .swiper-slide-active .flat-title-page .slider-content .btn-auction,
  .swiper-slide-active .flat-title-page .slider-content .cards-container,
  .swiper-slide-active .flat-title-page .slider-content .action-button,
  .swiper-slide-active .flat-title-page .slider-content .slider-image-content,
  .swiper-slide-active .flat-title-page .slider-centered-content {
    transform: translateY(0) !important;
  }
  .swiper-slide-active .flat-title-page .slider-content .heading,
  .swiper-slide-active .flat-title-page .slider-content .sub-heading,
  .swiper-slide-active .flat-title-page .slider-content .btn-auction,
  .swiper-slide-active .flat-title-page .slider-content .cards-container,
  .swiper-slide-active .flat-title-page .slider-content .action-button,
  .swiper-slide-active .flat-title-page .slider-centered-content {
    transition-delay: 900ms;
  }
  .swiper-slide-active .flat-title-page .slider-content .slider-image-content {
    transition-delay: 1400ms;
  }
  .slider-content .slider-text-content {
    width: 33%;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
  }
  .heading {
    font-family: "Josefin Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    color: #ffd061;
  }
  .sub-heading {
    font-size: 24px;
  }
  .sub-heading .tf-text {
    font-family: "Josefin Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;

    color: #ffffff;
  }

  .btn-auction.center {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .sc-button-2 {
    padding: 20px 25%;
    font-weight: 700;
    font-size: 24px;
    position: relative;
    cursor: pointer;
    z-index: 1;
    transition: 0.08s ease-in;
    -o-transition: 0.08s ease-in;
    -ms-transition: 0.08s ease-in;
    -moz-transition: 0.08s ease-in;
    -webkit-transition: 0.08s ease-in;
  }

  /* BOTTOM TO TOP */
  .sc-button-2.button__bottom-top {
    border: 5px solid #247773;
    color: #25a29d;
    background: linear-gradient(to top, #247773 50%, transparent 50%);
    background-size: 100% 200%;
    background-position: top;
    transition: all 0.35s ease-out;
  }

  .sc-button-2.button__bottom-top:hover {
    border: 5px solid #247773;
    background-position: bottom;
    color: #fff;
  }
  /* orange variation */
  .sc-button-2.button__bottom-top.orange {
    border-color: #ff7b7c;
    color: #ff7b7c;
    background: linear-gradient(to top, #ff7b7c 50%, transparent 50%);
    background-size: 100% 200%;
    background-position: top;
  }

  .sc-button-2.button__bottom-top.orange:hover {
    border-color: #ff7b7c;
    background-position: bottom;
    color: #fff;
  }

  .slider-content .slider-image-content {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .slider-content .slider-image-content video {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    clip-path: inset(1px 1px);
  }

  .slider-content .slider-image-content iframe {
    /* z-index: 1; */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .slider-content .slider-image-content video {
    pointer-events: none;
  }

  .slider-content .slider-image-content video::-webkit-media-controls-panel {
    display: none !important;
    opacity: 1 !important;
  }

  /* removes controls on safari  */
  .slider-content
    .slider-image-content
    video::-webkit-media-controls-start-playback-button {
    display: none;
  }

  .slider-content .slider-image-content video:focus {
    outline: none;
  }

  .slider-content .image-lines-container {
    width: 100%;
    display: inline-block;
    position: relative;
    padding: 1%;
    padding-bottom: 4%;
    padding-left: 6%;
    padding-right: 4%;
  }

  .slider-content .image-container {
    width: 100%;
    box-shadow: 14px 12px 15px 6px rgba(0, 0, 0, 0.22);
    position: relative;
    border-radius: 51px;
  }

  .slider-content .image-container:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 100.5%;
  }

  .slider-content .iframe-container {
    width: 100%;
    box-shadow: 14px 12px 15px 6px rgba(0, 0, 0, 0.22);
    position: relative;
    border-radius: 51px;
  }

  .slider-content .iframe-container:before {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 56.25%;
  }

  .slider-content .image-container img {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .slider-content .image-container:hover img {
    transform: scale(1.03);
    /* transform: scale(1.1) translate(-50%, -50%) ; */
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .slider-content .bottom-line {
    position: absolute;
    width: 50%;
    bottom: 0;
    border-top: 4px solid #ffd061;
    left: 50%;
    transform: translate(0%, -50%);
    -webkit-animation: linear infinite alternate;
    -webkit-animation-name: bottom-line;
    -webkit-animation-duration: 5s;
  }
  .slider-content .right-line {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 60%;
    border-right: 4px solid #ffd061;
    display: inline-block;
    -webkit-animation: linear infinite alternate;
    -webkit-animation-name: right-line;
    -webkit-animation-duration: 5s;
  }
  .slider-content .left-line {
    position: absolute;
    left: 0;
    top: 0;
    height: 60%;
    border-right: 4px solid #ffd061;
    display: inline-block;
    -webkit-animation: linear infinite alternate;
    -webkit-animation-name: left-line;
    -webkit-animation-duration: 5s;
  }

  @keyframes bottom-line {
    0% {
      left: 0;
    }
    50% {
      left: 10%;
    }
    100% {
      left: 0;
    }
  }

  @-webkit-keyframes bottom-line {
    0% {
      left: 0;
    }
    50% {
      left: 10%;
    }
    100% {
      left: 0;
    }
  }

  @keyframes left-line {
    0% {
      top: 0;
    }
    50% {
      top: 10%;
    }
    100% {
      top: 0;
    }
  }

  @-webkit-keyframes left-line {
    0% {
      top: 0;
    }
    50% {
      top: 10%;
    }
    100% {
      top: 0;
    }
  }

  @keyframes right-line {
    0% {
      top: 50%;
    }
    50% {
      top: 60%;
    }
    100% {
      top: 50%;
    }
  }

  @-webkit-keyframes right-line {
    0% {
      top: 50%;
    }
    50% {
      top: 60%;
    }
    100% {
      top: 50%;
    }
  }

  @media (max-width: 768px) {
    .flat-title-page {
      height: 440px !important;
      padding: 0 20px !important;
      padding-top: 20% !important;
      justify-content: center;
      background-size: 200%;
      display: flex;
      align-items: center;
      background-size: 300%;
    }
    .slider-content {
      width: 100%;
    }
    .slider-content .slider-text-content {
      width: 55%;
    }
    .slider-content .slider-text-content .heading {
      font-size: 20px;
      line-height: 20px;
    }

    .sub-heading,
    .sub-heading .tf-text {
      font-size: 12px;
      line-height: 12px;
    }
    ${COMMON_STYLES.SWIPER_NAVIGATION}
    .sc-button-2 {
      font-size: 12px;
      line-height: 12px;
      padding: 10px 20px;
    }
  }

  .slider-content .bottom-line {
    border-top: 2px solid #ffd061;
  }
  .slider-content .right-line {
    border-right: 2px solid #ffd061;
  }
  .slider-content .left-line {
    border-right: 2px solid #ffd061;
  }
`;
