import React from "react";
import { FILE_TYPES_ALLOWED_MESSAGE, REQUIRED_MESSAGE } from "../../../constants/constants";

const ImageFileLoader = ({ register, handleChange }) => {
  return (
    <div className="no-image-preview">
      <div className="gray-background">
        <label className="uploadFile">
          <input
            type="file"
            name="file"
            className="inputfile form-control"
            {...register("file", {
              onChange: handleChange,
              required: REQUIRED_MESSAGE,
            })}
            accept="image/jpg,image/webp,image/jpeg,image/png,image/gif,video/mp4"
          />
        </label>
        <div className="image-indications">{FILE_TYPES_ALLOWED_MESSAGE}</div>
      </div>
    </div>
  );
};

export default ImageFileLoader;
