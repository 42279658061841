import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getIdTokenClaims, isAuthenticated } from "../helpers/auth0Service";

const { REACT_APP_API_URL } = process.env;
/**
 *
 * @param {*} url: URL to hit in Database
 * @param options: you must include audience, scope and fetch options params
 * @returns state: {error: null | boolean, loading: boolean, data: null | Response}, refresh()
 */

export const useApi2 = (url, options = {}) => {
  const [data, setdata] = useState(null);
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState("");
  const executeApiRequest = async () => {
    try {
      const isTokenValid = isAuthenticated();
      if(!isTokenValid) {
        return;
      }
      const idToken = await getIdTokenClaims();
      const token = idToken?.__raw ?? localStorage.getItem("token");

      if (token) localStorage.setItem("token", token);
      const res = await fetch(`${REACT_APP_API_URL}${url}`, {
        method: options.method,
        body: options.body,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        },
      });
      return await res.json();
    } catch (error) {
      seterror(error);
    }
  };
  useEffect(() => {
    executeApiRequest(url).then((data) => {
      setdata(data);
      setloading(false);
    });
  }, [url]);

  return { data, loading, error };
};

export default useApi2;
