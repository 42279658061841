const { REACT_APP_API_URL } = process.env;

const getUserTop = async () => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}user/top`);
    return response.json();
  } catch (error) {
    console.log(error);
  }
};
export default getUserTop;
