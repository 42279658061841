import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { REQUIRED_MESSAGE } from "../../../constants/constants";
import HeadingText from "../../editProfile/HeadingText";
import { StyledRemoveModal } from "./styles";

const TransferFromVaultModal = ({
  show,
  onHide,
  item,
  transferItemFromVault,
  loading,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    transferItemFromVault({ itemId: item?.id, walletId: data?.walletId });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>
        <StyledRemoveModal>
          <form onSubmit={handleSubmit(onSubmit)}>
            <HeadingText title={"Transfer From Vault"} variant="small" />
            <p className="main-indications">
              ACME LABS™ will transfer your digital item to the wallet provided
              below.
            </p>
            <p className="secondary-indications">
              Please enter the wallet ID to transfer the item to.
            </p>

            <div className="row m-0 justify-content-between mb-4">
              <fieldset className="w-100">
                <div className="wallet-input-container">
                  <input
                    id="walletId"
                    name="walletId"
                    tabIndex="1"
                    aria-required="true"
                    required
                    type="text"
                    placeholder="0.0.1234567"
                    className="form-field-input"
                    {...register("walletId", {
                      required: REQUIRED_MESSAGE,
                    })}
                  />
                </div>
                <p className="error-message">{errors.walletId?.message}</p>
              </fieldset>
            </div>

            <p className="warning-indications">
              WARNING: This action is irreversible. Once transferred, ACME LABS™
              cannot recover the item.
            </p>
            <div className="main-btn-container">
              <button
                className="filled-button"
                disabled={loading}
                onClick={handleSubmit(onSubmit)}
              >
                {!loading ? "Transfer from Vault" : "Transferring from Vault"}
              </button>
            </div>
          </form>
        </StyledRemoveModal>
      </Modal.Body>
    </Modal>
  );
};

export default TransferFromVaultModal;
