import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { useDispatch } from "react-redux";
import { useAuth0 } from "../../../auth/Auth";
import useApi2 from "../../../hooks/useApi2";
import { useCustomNavigator } from "../../../hooks/useCustomNavigator";
import useHashconnect from "../../../hooks/useHashconnect";
import useVenly from "../../../hooks/useVenly";
import { userMenu } from "../../../pages/menu";
import { signout } from "../../../store/slices/user/user";
import LinkWithQueryParams from "../../LinkWithQueryParams";
import UserImage from "../UserImage";

const UserInfo = ({ user, displayName }) => {
  const { venlyLogout } = useVenly();
  const { disconnect } = useHashconnect();
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const { customNavigate } = useCustomNavigator();
  const { data } = useApi2("space/my", { method: "GET" });
  const handleLogout = async () => {
    localStorage.removeItem("persist:root");
    localStorage.removeItem("cart");
    await venlyLogout();
    await disconnect();
    dispatch(signout);
    await logout();
    customNavigate({ path: "/" });
    window.location.reload();
  };

  return (
    <div id="signed-in">
      <div className="user-image">
        <UserImage imgProfile={user.profileFileName} />
      </div>

      <Accordion allowZeroExpanded>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div className="user-info">
                <div className="user-salutation">
                  <span>Hi, {displayName}</span>
                </div>
                <div className="user-logout">
                  <span onClick={handleLogout}>log out</span>
                </div>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {userMenu(data).map((submenu) => (
              <div className="menu-option" key={submenu.id}>
                <LinkWithQueryParams path={submenu.links}>
                  {submenu.sub}
                </LinkWithQueryParams>
              </div>
            ))}
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default UserInfo;
