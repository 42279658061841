import React, { useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap-accordion';
import getProductTypes from '../../api/prodigi/getProductTypes';
import Spinner from '../../components/layouts/Spinner/Spinner';

const ProdigiFilters = ({ action }) => {
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState([]);

  const getAllTypeIds = async () => {
    const response = await getProductTypes();
    response &&
      setFilters(
        response.map((filter) => {
          return {
            ...filter,
            checked: false,
          };
        })
      );
    setLoading(false);
  };

  const manageChecks = (id) => {
    setFilters(
      filters.map((status) => {
        return status.id !== id
          ? { ...status, checked: false }
          : status.checked
          ? { ...status, checked: false }
          : { ...status, checked: true };
      })
    );
  };

  useEffect(() => {
    getAllTypeIds();
  }, []);

  return (
    <>
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div className="widget widget-filter style-1 ">
          <Accordion title="Product Types" show={true}>
            <form className="form-inner mt-3">
              {filters.map((item) => (
                <div key={item.id}>
                  <label>
                    {item.name}
                    <input
                      type="checkbox"
                      checked={item.checked}
                      onChange={() => {
                        manageChecks(item.id);
                        action(item.id);
                      }}
                    />
                    <span className="btn-checkbox"></span>
                  </label>
                  <br />
                </div>
              ))}
            </form>
          </Accordion>
          <hr />
        </div>
      )}
    </>
  );
};

export default ProdigiFilters;
