import React from "react";
import { Link } from "react-router-dom";

const GenericSliderItem = (props) => (
  <div className="flat-title-page">
    <div className="swiper-container mainslider home">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div className="slider-item">
            <div className="themesflat-container ">
              <div className="wrap-heading flat-slider flex">
                <div className="content">
                  <h2 className="heading">{props.item.title_1}</h2>
                  <h1 className="heading mb-style">
                    <span className="tf-text s1">{props.item.title_2}</span>
                  </h1>
                  <h1 className="heading">{props.item.title_3}</h1>
                  <p className="sub-heading">
                    {props.item.description} <br /> {props.item.description_2}{" "}
                  </p>
                  <div className="flat-bt-slider flex style2">
                    <Link
                      to={`${props.item.button.path}`}
                      className={`sc-button header-slider style style-1 ${props.item.button.icon} fl-button pri-1`}
                    >
                      <span>{props.item.button.name}</span>
                    </Link>
                  </div>
                </div>
                <div className="image">
                  <img src={props.item.img} alt="axies" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default GenericSliderItem;
