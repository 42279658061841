import styled from "styled-components";
import { COMMON_STYLES } from "../../../constants/genericStyles";

export const StyledRemoveModal = styled.div`
  padding: 40px;
  padding-top: 20px;

  .heading {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
  }

  .heading .tf-title {
    font-size: 48px;
    line-height: 100%;
    color: #011126;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .heading .vertical {
    margin-right: 10px;
    border-left: 8px solid #ffd061;
    height: 30px;
    display: inline-block;
  }

  .heading .span-container {
    display: flex;
    align-items: center;
    color: #011126;
  }

  .heading.small .tf-title {
    font-size: 32px;
  }

  .edit-profile .heading.small .vertical {
    height: 32px;
  }

  .main-indications {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    margin-bottom: 28px;
  }

  .warning-indications {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
    text-align: center;
  }

  .secondary-indications {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    text-align: center;
    margin-bottom: 10px;
  }

  .main-btn-container {
    display: flex;
    justify-content: center;
  }

  .wallet-input-container input {
    text-align: center;
    font-size: 18px;
    line-height: 28px;
  }

  button.filled-button {
    ${COMMON_STYLES.FILLED_BUTTON({
      fillColor: "#ff7b7c",
      borderSize: "10px",
    })}
    width: 100%;
    padding: 20px;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 16px;
  }
`;
