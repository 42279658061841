import { Link } from 'react-router-dom';
import img from '../../../../assets/images/box-item/card-item-1.jpg';
import ProdigiBtn from './ProdigiBtn';

const ProdigiCard = ({ card }) => {
  return (
    <div className='nft-card-container'>
      <section className='image'>
        <img
          src={card.imageUrl ? card.imageUrl : img}
          alt={`product ${card.id}`}
        />
      </section>
      <section className='content'>
        <div className='title'>
          <span className='title'>{card.name.toLowerCase()}</span>
        </div>
        <hr />
        <ProdigiBtn element={card} />
      </section>
    </div>
  );
};

export default ProdigiCard;
