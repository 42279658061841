import React, { useEffect, useState } from "react";
import ImageWithLoader from "../../../components/ImageWithLoader";
import { useCustomNavigator } from "../../../hooks/useCustomNavigator";
import { StyledAnnouncementCard } from "./AnnouncementCardStyles";

const AnnouncementCard = ({ description, title, img, link, id }) => {
  const { customNavigate } = useCustomNavigator();
  const characterLimit = 50;
  const [expanded, setExpanded] = useState(false);
  const [descriptionVisibleText, setDescriptionVisibleText] = useState("");
  const [descriptionExpandedText, setDescriptionExpandedText] = useState("");
  const [needsExpansion, setNeedsExpansion] = useState(false);

  useEffect(() => {
    if (description?.length > 0) {
      const descriptionVisibleText = description.slice(0, characterLimit);
      const descriptionExpandedText = description.slice(characterLimit, -1);
      const needsExpansion = description.length > characterLimit;
      setDescriptionVisibleText(descriptionVisibleText);
      setDescriptionExpandedText(descriptionExpandedText);
      setNeedsExpansion(needsExpansion);
    }
  }, [description]);

  const handleOnClick = () => {
    customNavigate({ path: `/announcement-details/${id}` });
  };

  const handleExpand = () => {
    if (needsExpansion) {
      setExpanded(!expanded);
    }
  };
  return (
    <StyledAnnouncementCard
      style={{ cursor: "pointer" }}
      onClick={handleExpand}
    >
      <ImageWithLoader img={img} alt={title} />
      <div className="content">
        <div className="announcement-title">{title}</div>
        <div className="announcement-description">
          <span>{descriptionVisibleText}</span>
          {needsExpansion && !expanded && (
            <>
              <span>...</span>
              <span className="learn-more">Click to expand</span>
            </>
          )}
          {(!needsExpansion || expanded) && (
            <>
              <span>{descriptionExpandedText}</span>
              {link && (
                <span className="learn-more" onClick={handleOnClick}>
                  Learn more
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </StyledAnnouncementCard>
  );
};

export default AnnouncementCard;
