import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  RESERVATION_TIMER_INTERVAL,
  RESERVATION_VALID_DURATION,
} from "../../../constants/constants";
import { notifyExpiration } from "../../../helpers/toastNotificationService";
import { useCustomNavigator } from "../../../hooks/useCustomNavigator";

const ReservationTimer = ({ reservationData }) => {
  const { customNavigate } = useCustomNavigator();
  const [reservationTimer, setReservationTimer] = useState();

  useEffect(() => {
    if (!reservationData) return;
    const eventTime = reservationData.validThroughTime;
    const currentTime = Math.floor(Date.now() / 1000);
    /* seconds left */
    let diffTime = eventTime - currentTime;
    /* Detect already expired reservations */
    if (diffTime <= 0) {
      setReservationTimer("00:00");
      customNavigate({ path: "/cart" });
      return;
    }
    /* Ignore RESERVATION_VALID_DURATION_BUFFER, otherwise it would show 05:15 */
    if (diffTime > RESERVATION_VALID_DURATION) {
      diffTime = RESERVATION_VALID_DURATION;
    }

    let duration = moment.duration(diffTime * 1000, "milliseconds");
    const intervalTimer = setInterval(function () {
      duration = moment.duration(
        duration - RESERVATION_TIMER_INTERVAL,
        "milliseconds"
      );
      let formattedTimeLeft = moment
        .utc(duration.asMilliseconds())
        .format("mm:ss");
      /* Redirect upon expiration */
      if (formattedTimeLeft === "00:00") {
        clearInterval(intervalTimer);
        setReservationTimer("00:00");
        notifyExpiration("Reservation has expired", () =>
          customNavigate({ path: "/cart" })
        );
      } else {
        setReservationTimer(formattedTimeLeft);
      }
    }, RESERVATION_TIMER_INTERVAL);

    return () => clearInterval(intervalTimer);
  }, [reservationData]);

  return (
    <div className="reservation-timer-container">
      <div className="row justify-content-center">
        <div className="col-10">
          <div className="reservation-note">
            Items will remain in your cart for 5 minutes
          </div>
          <div className="reservation-timer">
            Time remaining: {reservationTimer}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservationTimer;
