import React, { useEffect, useState } from "react";
import { getBaseItem } from "../../../api/Item/baseItems";
import getItem from "../../../api/Item/getItem";
import { getProductById } from "../../../api/products/getProducts";
import getOneSpace from "../../../api/spaces/getSpaceByUserId";
import getUser from "../../../api/user/getUser";
import ImageWithLoader from "../../../components/ImageWithLoader";
import LinkWithQueryParams from "../../../components/LinkWithQueryParams";
import Spinner from "../../../components/layouts/Spinner/Spinner";
import { priceFormatter } from "../../../helpers/formatters";
import { isResaleItem } from "../../../helpers/itemHelpers";
import useIPFS from "../../../hooks/useIPFS";

const SoldOrderItem = ({ item }) => {
  const [itemData, setItemData] = useState();
  const [productName, setProductName] = useState(null);
  const [ownerData, setOwnerData] = useState({});
  const [spaceData, setSpaceData] = useState({});
  const { getData } = useIPFS();

  const getProductName = async ({ item }) => {
    const productId = item?.productId;
    const productData = await getProductById({ productId });
    setProductName(productData?.name);
  };

  const getItemData = async () => {
    const [baseItemData, itemData] = await Promise.all([
      getBaseItem(item.itemId),
      getItem(item.itemId),
    ]);

    let orderItemData = baseItemData?.id ? baseItemData : itemData;

    // let orderItemData =
    //   item?.orderType === BASE_ITEM_TYPE
    //     ? await getBaseItem(item.itemId)
    //     : await getItem(item.itemId);

    // console.log({ orderItemData });

    const elementData = await getData(
      orderItemData.finalCid ? orderItemData?.finalCid : orderItemData.cid
    );

    orderItemData = { ...orderItemData, ...elementData };
    setItemData(orderItemData);
  };

  useEffect(() => {
    if (item) {
      getItemData();
    }
  }, [item]);

  const getSpaceData = async ({ item }) => {
    if (item.originalOwnerId) {
      await getData(item?.finalCid ? item?.finalCid : item.cid);
      const userData = await getUser(item.originalOwnerId);
      const spaceOwnerData = await getOneSpace(userData.id);
      setOwnerData(userData);
      setSpaceData(spaceOwnerData);
    }
  };

  useEffect(() => {
    if (itemData?.productId && itemData?.id) {
      getProductName({ item: itemData });
    }
    if (itemData?.id) {
      getSpaceData({ item: itemData });
    }
  }, [itemData]);

  return itemData ? (
    <div className="order-item-content-container">
      <div className="left-side">
        <div className="media">
          <LinkWithQueryParams path={`/my-items/#${itemData?.id}`}>
            <ImageWithLoader img={itemData.image} alt={itemData.name} />
          </LinkWithQueryParams>
        </div>
        <div className={`infor`}>
          <h4>1 x {itemData.name}</h4>
          <div className="author">
            <LinkWithQueryParams path={`/spaces/${spaceData.customURL}`}>
              <h6 style={{ color: "#6899FA" }}>{ownerData?.displayName}</h6>
            </LinkWithQueryParams>
          </div>
          {productName && <div className="product-name">{productName}</div>}
        </div>
      </div>
      <div className="right-side">
        <div className="price">
          {priceFormatter({
            currencyType: isResaleItem({ item: itemData })
              ? itemData?.saleCurrencyType
              : itemData?.currencyType,
            price: isResaleItem({ item: itemData })
              ? itemData?.salePrice
              : itemData?.price,
          })}
        </div>
        <div className="transaction-fees">
          <div className="transaction-fee-label">Transaction fees:</div>
          <div className="transaction-fee-price">{item?.transactionFee}</div>
          <div className="transaction-fee-currency">
            {isResaleItem({ item: itemData })
              ? itemData?.saleCurrencyType
              : itemData?.currencyType}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div style={{ width: "100%", marginBottom: "20px" }}>
      <Spinner />
    </div>
  );
};

export default SoldOrderItem;
