import styled from "styled-components";
export const StyledAnnouncementCard = styled.div`
  & {
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #c7c7c7;
    box-shadow: 4px 6px 13px rgba(0, 0, 0, 0.25);
    border-radius: 19px;
    padding: 19px 10px;
  }

  .main-image-container {
    width: 40%;
    margin-right: 16px;
  }

  .content {
    flex: 1;
  }

  .announcement-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 8px;
  }

  .announcement-description {
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;

    color: #717171;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding-top: 100%;
    position: relative;
  }

  .learn-more {
    color: #1b70d0;
    font-weight: 400;
    font-size: 15px;
    display: block;
  }

  @media (max-width: 768px) {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 9px;
    align-items: flex-start;

    .content {
      margin-top: 6px;
    }

    .announcement-title {
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
    }

    .announcement-description {
      font-weight: 400;
      font-size: 8px;
      line-height: 8px;
      color: #717171;
    }

    .learn-more {
      font-size: 8px;
      line-height: 8px;
    }

    .main-image-container {
      width: 30%;
      margin-right: 10px;
      .image-container .image {
        border-radius: 9px;
      }
    }
  }
`;
