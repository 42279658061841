import React, { useEffect, useState } from "react";
import getOneSpace from "../../api/spaces/getSpaceByUserId";
import LinkWithQueryParams from "../LinkWithQueryParams";

const ArtistButton = ({ item }) => {
  const [space, setSpace] = useState({});

  const getSpace = async () => {
    let userId = item?.artist?.userId ? item?.artist?.userId : item.id;
    const response = await getOneSpace(userId);
    response && setSpace(response);
  };

  useEffect(() => {
    getSpace();
  }, [item.id]);

  return (
    <div className="btn-auction center mb-md-4">
      <LinkWithQueryParams path={`/spaces/${space.customURL}`}>
        <button
          to="#"
          id="load-more"
          className={`sc-button-2 no-bg button__bottom-top orange`}
        >
          <span>Learn More</span>
        </button>
      </LinkWithQueryParams>
    </div>
  );
};

export default ArtistButton;
