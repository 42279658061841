import React from "react";
import { useForm } from "react-hook-form";
import {
  EMAIL_INVALID_MESSAGE,
  EMAIL_PATTERN,
  REQUIRED_MESSAGE,
  PASSWORD_INVALID_MESSAGE,
  SIGNUP_ERRORS_PRESENT,
} from "../../constants/constants";

import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { ResetPasswordPage } from "./styles";

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data);
  };

  const validateConfirm = (value) => {
    const passwordField = watch("password");
    if (value !== passwordField) {
      return "Passwords do not match";
    }
  };

  return (
    <ResetPasswordPage>
      <div>
        <Header />

        <section className="tf-reset-password tf-section">
          <div className="centered-content">
            {Object.entries(errors).length > 0 && (
              <div className="general-error-container">
                <p className="error-message">{SIGNUP_ERRORS_PRESENT}</p>
              </div>
            )}

            <div className="row justify-content-start m-0 mb-2 w-100">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Reset your password</h1>
              </div>
            </div>

            <div className="form-inner">
              <form
                id="contactform"
                noValidate="novalidate"
                className="form-submit"
                onSubmit={handleSubmit(onSubmit)}
              >
                <fieldset className="mb-4">
                  <h6 className="form-field-label">email address</h6>
                  <input
                    id="email"
                    name="email"
                    tabIndex="2"
                    aria-required="true"
                    required
                    type="email"
                    placeholder="email address"
                    className="form-field-input"
                    {...register("email", {
                      required: REQUIRED_MESSAGE,
                      pattern: {
                        value: EMAIL_PATTERN,
                        message: EMAIL_INVALID_MESSAGE,
                      },
                    })}
                  />
                  <p className="error-message">{errors.email?.message}</p>
                </fieldset>

                <fieldset className="mb-4">
                  <h6 className="form-field-label">create password</h6>
                  <input
                    id="currentPassword"
                    type="password"
                    placeholder="create password"
                    className="form-field-input"
                    {...register("password", {
                      required: REQUIRED_MESSAGE,
                      minLength: {
                        value: 4,
                        message: PASSWORD_INVALID_MESSAGE,
                      },
                      maxLength: {
                        value: 20,
                        message: PASSWORD_INVALID_MESSAGE,
                      },
                    })}
                  />
                  <p className="error-message">{errors.password?.message}</p>
                </fieldset>

                <fieldset style={{ marginBottom: "3vw" }}>
                  <h6 className="form-field-label">confirm password</h6>
                  <input
                    id="confirmPassword"
                    type="password"
                    placeholder="confirm password"
                    className="form-field-input"
                    {...register("confirm", {
                      required: REQUIRED_MESSAGE,
                      validate: {
                        value: (value) => validateConfirm(value),
                      },
                    })}
                  />
                  <p className="error-message">{errors.confirm?.message}</p>
                </fieldset>

                <div className="row justify-content-center m-0">
                  <button className="submit">change password</button>
                </div>
              </form>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </ResetPasswordPage>
  );
};

export default ResetPassword;
