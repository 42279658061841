import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAuth0 } from "../../auth/Auth";
import { displayNameFormatter } from "../../helpers/formatters";
import useApi2 from "../../hooks/useApi2";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import { useCustomNavigator } from "../../hooks/useCustomNavigator";
import useHashconnect from "../../hooks/useHashconnect";
import { useRole } from "../../hooks/useRole";
import useVenly from "../../hooks/useVenly";
import { userMenu } from "../../pages/menu";
import { signout } from "../../store/slices/user/user";
import LinkWithQueryParams from "../LinkWithQueryParams";
import UserImage from "./UserImage";

const MenuProfile = () => {
  const isMobile = useCheckMobileScreen();
  const { customNavigate } = useCustomNavigator();
  const [visible, setVisible] = useState(false);
  const { isAdmin } = useRole();
  const { venlyLogout } = useVenly();
  const { disconnect } = useHashconnect();
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const user = useSelector((state) => state.user);
  const { data } = useApi2("space/my", { method: "GET" });
  const displayName = displayNameFormatter({ user });
  const handleMenu = () => {
    setVisible(!visible);
  };

  const handleLogout = async () => {
    localStorage.removeItem("persist:root");
    localStorage.removeItem("cart");
    await disconnect();
    await venlyLogout();
    dispatch(signout);
    await logout();
    customNavigate({ path: "/" });
    window.location.reload();
  };

  const arrow = !visible ? <span>&#8964;</span> : <span>&#8963;</span>;

  return (
    !isMobile && (
      <div id="header_admin" onClick={handleMenu}>
        <div className="header_avatar">
          <div className="price">
            <span>
              Hi, {displayName} {arrow}{" "}
            </span>
          </div>

          <UserImage imgProfile={user.profileFileName} />

          {visible && (
            <div className="avatar_popup mt-20">
              <div className="hr"></div>
              <div className="links mt-20">
                {userMenu(data).map((submenu) => (
                  <div className="menu-option" key={submenu.id}>
                    <LinkWithQueryParams className="mt-10" path={submenu.links}>
                      <i className={submenu.icon}></i>
                      <span> {submenu.sub}</span>
                    </LinkWithQueryParams>
                  </div>
                ))}
                {isAdmin() && (
                  <LinkWithQueryParams
                    className="mt-10"
                    path={"/administrative"}
                  >
                    <i className="fas fa-pencil-alt"></i> <span> Admin</span>
                  </LinkWithQueryParams>
                )}
                <Link
                  className="mt-10"
                  to="#"
                  onClick={handleLogout}
                  id="logout"
                >
                  <i className="fal fa-sign-out"></i> <span> Logout</span>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default MenuProfile;
