import React from 'react';
import { Table } from 'react-bootstrap';

const SkuTable = ({ skus, action }) => {
  return (
    <Table striped bordered hover variant='dark'>
      <thead>
        <tr>
          <th>
            <h6>SKU</h6>
          </th>
          <th>
            <h6>Price</h6>
          </th>
          <th>
            <h6>Size</h6>
          </th>
        </tr>
      </thead>
      <tbody>
        {skus.map((sku) => (
          <tr
            key={sku.id}
            onClick={() => action(sku)}
            style={{ cursor: 'pointer' }}
          >
            <td>
              <h6>{sku.sku}</h6>
            </td>
            <td>
              <h6>{sku.price}</h6>
            </td>
            <td>
              <h6>{sku.size}</h6>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SkuTable;
