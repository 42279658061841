const { REACT_APP_API_URL } = process.env;

const getSpaceByCustomUrl = async (customUrl) => {
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}space/one-by-custom-url/${customUrl}`
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export default getSpaceByCustomUrl;
