import getCollection from "../../api/collections/getCollection";
import {
  FIXED_SHIPPING_HBAR_COST,
  FIXED_SHIPPING_USD_COST,
  HASHPACK_WALLET_TYPE,
  REACT_APP_API_URL,
  VAULT_WALLET_TYPE,
  VENLY_WALLET_TYPE,
} from "../../constants/constants";
import { isResaleItem } from "../../helpers/itemHelpers";
import {
  getNonAssocCol,
  getNonAssocCollectionHedera,
  itemRequiresShipping,
} from "../../helpers/orderHelpers";
import { generateHmac } from "../../utils/sha256Encription";
import { useApi } from "../useApi";
import useHashconnect from "../useHashconnect";
import useProdigi from "../useProdigi";
import useVenly from "../useVenly";

const useCheckout = () => {
  const { executeApiRequest, executeApiRequestTwo } = useApi();
  const { executeTransaction, executeTokenAssociation } = useVenly();
  const { payWithHashconnect, associateCollectionHashpack, associateToken } =
    useHashconnect();
  const { calculateSku, quoteProduct } = useProdigi();

  const calculateProductTotal = ({ products }) => {
    const initialPrice = 0;
    const total = products.reduce((prev, current) => {
      /* Establishes correctly price detecting if it is
        a base item or a resale item */
      const price = isResaleItem({ item: current })
        ? current?.salePrice
        : current?.price;
      return prev + current.quantity * price;
    }, initialPrice);
    return { total };
  };

  const updateHBARQuoteData = ({ products }) => {
    const shippingCost = FIXED_SHIPPING_HBAR_COST;
    const { total } = calculateProductTotal({
      products,
    });
    const finalCost = total + shippingCost;

    return {
      quoteResponse: {
        itemsCost: "0.00",
        totalTax: "0.00",
        shippingCost: `${FIXED_SHIPPING_HBAR_COST}.00`,
        totalCost: `${FIXED_SHIPPING_HBAR_COST}.00`,
      },
      subtotal: total,
      newAmount: finalCost,
    };
  };
  const getHardcodedShipping = () => {
    return {
      itemsCost: "0.00",
      totalTax: "0.00",
      shippingCost: `${FIXED_SHIPPING_USD_COST}.00`,
      totalCost: `${FIXED_SHIPPING_USD_COST}.00`,
    };
  };

  const updateNonProdigiQuoteData = ({ products }) => {
    const { total } = calculateProductTotal({
      products,
    });
    /* Hardcoding to 8 USD */
    const quoteResponse = getHardcodedShipping();
    const shippingTotal = parseInt(quoteResponse.totalCost.replace(".", ""));
    const finalCost = total * 100 + shippingTotal;
    return {
      quoteResponse,
      subtotal: total,
      newAmount: finalCost,
    };
  };

  const updateQuoteData = async ({ value, sku, products }) => {
    const { total } = calculateProductTotal({
      products,
    });

    const physicalProducts = products.filter((product) =>
      itemRequiresShipping({ item: product })
    );

    const quantityTotal = physicalProducts.reduce(
      (prev, current) => prev + current.quantity,
      0
    );

    let quoteResponse = await quoteProduct(
      {
        shippingMethod: value,
        prodigiProductSKUId: sku?.skus[0]?.id,
        quantity: quantityTotal ? quantityTotal : 1,
        attributes: {},
      },
      "return"
    );

    /* Hardcoding to 8 USD */
    quoteResponse = getHardcodedShipping();
    const shippingTotal = parseInt(quoteResponse.totalCost.replace(".", ""));
    const finalCost = total * 100 + shippingTotal;
    return { subtotal: total, newAmount: finalCost, quoteResponse };
  };

  const preAssociate = async ({
    collection,
    collectionId,
    toWalletId,
    user,
  }) => {
    console.log("🚀 ~ preAssociate");
    if (!collection.isAssociated) {
      const { hString, epochTimestamp } = generateHmac({
        userId: user.id,
        id: collectionId,
      });
      const requestBody = { walletId: toWalletId, hString, epochTimestamp };
      await executeApiRequest(
        `${REACT_APP_API_URL}collection/preAssociation/${collectionId}`,
        {
          method: "PUT",
          body: JSON.stringify(requestBody),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }
  };

  const associateCollection = async ({ collection, wallets }) => {
    console.log("🚀 ~ associateCollection");
    console.log(collection.isAssociated);
    if (!collection.isAssociated) {
      const signerResult = await executeTokenAssociation(
        wallets.walletDetails.id,
        [collection.tokenId]
      );

      if (signerResult.status !== "SUCCESS") {
        console.warn(`Something went wrong while associating the tokens`);
        return;
      }

      const res = await executeApiRequest(
        `${REACT_APP_API_URL}collection/associationState/${collection.id}`,
        {
          method: "PUT",
        }
      );
    }
  };

  const associateCollections = async ({ user, items, values }) => {
    const walletAddress = user?.wallets?.walletDetails?.address;
    const hashpackWalletId = user?.hashpackWallets?.accountIds?.[0];

    if (values?.defaultWalletType === VAULT_WALLET_TYPE) return;
    /* If we are selecting Vault, ignores all of the following logic */

    let connectedWallet;
    if (
      hashpackWalletId &&
      values?.defaultWalletType === HASHPACK_WALLET_TYPE
    ) {
      connectedWallet = "hashpack";
    }
    if (walletAddress && values?.defaultWalletType === VENLY_WALLET_TYPE) {
      connectedWallet = "venly";
    }

    const nonAssocCol = await getNonAssocCol({ items });
    let nonAssocColHedera;
    switch (connectedWallet) {
      case "venly":
        nonAssocColHedera = await getNonAssocCollectionHedera({
          items,
          accountId: walletAddress,
        });

        /* First time associating a user to a collection */
        await Promise.all(
          nonAssocCol.map(async (collection) => {
            await preAssociate({
              collection,
              collectionId: collection.id,
              toWalletId: walletAddress,
              user,
            });

            await associateCollection({ collection, wallets: user?.wallets });
          })
        );

        if (nonAssocCol?.length === 0 && nonAssocColHedera?.length > 0) {
          /* Second time associating a user to a collection
             no preassociation
          */
          await Promise.all(
            nonAssocColHedera.map(async (collection) => {
              try {
                const signerResult = await executeTokenAssociation(
                  user?.wallets?.walletDetails?.id,
                  [collection.tokenId]
                );
              } catch (e) {}
            })
          );
        }

        break;
      case "hashpack":
        nonAssocColHedera = await getNonAssocCollectionHedera({
          items,
          accountId: hashpackWalletId,
        });

        /* First time associating a user to a collection */
        await Promise.all(
          nonAssocCol.map(async (collection) => {
            await preAssociate({
              collection,
              collectionId: collection.id,
              toWalletId: hashpackWalletId,
              user,
            });

            /* This ensures isAssociated field modification */
            const res = await associateCollectionHashpack({
              collectionId: collection.id,
            });

            /* This triggers hashpack wallet association */
            await associateToken({
              collectionId: collection.id,
              tokenId: collection.tokenId,
            });
          })
        );

        if (nonAssocCol?.length === 0 && nonAssocColHedera?.length > 0) {
          /* Second time associating a user to a collection
             no preassociation
          */
          await Promise.all(
            nonAssocColHedera.map(async (collection) => {
              const res = await associateToken({
                collectionId: collection.id,
                tokenId: collection.tokenId,
              });
            })
          );
        }
        break;
      default:
        console.log("unknown wallet");
    }
  };

  const mintItem = async ({ itemId }) => {
    console.log("🚀 ~ mintItem");
    const mintedItem = await executeApiRequestTwo(
      `${REACT_APP_API_URL}item/mint/${itemId}`,
      {
        method: "PUT",
      }
    ); // Mints the item.
    return { mintedItem };
  };

  const transferFromTreasuryNewlyMinted = async ({
    mintedItem,
    toWalletId,
    collection,
  }) => {
    console.log("🚀 ~ transferFromTreasuryNewlyMinted");
    if (!mintedItem.transferFromTreasury) {
      const transferFromTreasury = await executeApiRequest(
        `${REACT_APP_API_URL}transfer/nft-token-from-treasury/${mintedItem.id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "PATCH",
          body: JSON.stringify({ walletId: toWalletId }),
        }
      );
    }
  };

  const approveSale = async ({ user, item, orderData, toWalletId }) => {
    let response = {};
    try {
      const itemId = orderData.itemId;
      const wallets = user.wallets;
      const { mintedItem } = await mintItem({ itemId });
      if (
        mintedItem?.error ||
        (mintedItem.statusCode && mintedItem.statusCode !== 200)
      ) {
        response = { error: "Minting Process Failed" };
        return { response };
      }
      const collection = await getCollection(item.collectionId);
      await preAssociate({
        collection,
        collectionId: item.collectionId,
        toWalletId,
      });

      await associateCollection({ collection, wallets });
      await transferFromTreasuryNewlyMinted({
        mintedItem,
        toWalletId,
        collection,
      });
      await soldItem({ itemId: mintedItem.id });
      await mintCOA({ baseItem: item, itemId: mintedItem.id });
      return response;
    } catch (err) {
      console.error(err);
      const response = { error: err };
      return { response };
    }
  };

  const preOrder = async ({
    values,
    itemId,
    prodigiProduct,
    toWalletId,
    isPhysicalProduct,
  }) => {
    const preOrder = await executeApiRequestTwo(
      `${REACT_APP_API_URL}item/wants-to-buy-item-with-quantity/${itemId}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ toWalletId }),
      }
    );
    /*ord*/
    let orderDataObj = Object.assign(
      {},
      { city: values.city },
      { state: values.state },
      { zipCode: values.zipCode },
      { lastName: values.lastName },
      { firstName: values.firstName },
      { streetAddress: values.streetAddress },
      {
        streetAddressContd: values.streetAddressContd
          ? values.streetAddressContd
          : "n/a",
      },
      { shippingMethod: values.shippingMethod },
      { sizing: values.sizing },
      { prodigiProductSKUId: prodigiProduct.skus[0].id },
      { itemId: preOrder.itemId },
      { walletIdForMoney: preOrder.walletIdForMoney },
      { attributes: {} },
      {
        quantity: 1,
      } /*currently hardcoded value, update to support multi-qty*/,
      { email: values.email }
    );

    const orderData = isPhysicalProduct
      ? { ...orderDataObj }
      : {
          itemId: preOrder.itemId,
          walletIdForMoney: preOrder.walletIdForMoney,
        };

    return { orderData };
  };

  const payWithVenly = async ({ fromWalletId, toWalletId, value }) => {
    try {
      let response = {};
      const signerResult = await executeTransaction({
        walletId: fromWalletId,
        value: value,
        to: toWalletId,
      });
      if (signerResult.status !== "SUCCESS") {
        response = { error: "payWithVenly Process Failed" };
        return { response };
      }
      return { response: signerResult };
    } catch (error) {
      const response = { error };
      return { response };
    }
  };

  const soldItem = async ({ itemId }) => {
    console.log("🚀 ~ soldItem");
    try {
      await executeApiRequestTwo(`${REACT_APP_API_URL}item/sold/${itemId}`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
      }); // change status from Selling in progress to Sold.
    } catch (err) {
      console.error(err);
    }
  };

  const mintCOA = async ({ itemId, baseItem }) => {
    console.log("🚀 ~ mintCOA");
    console.log({ itemId, baseItem });
    if (baseItem.isCOA === "Y") {
      try {
        const response = await executeApiRequestTwo(
          `${REACT_APP_API_URL}item/mint/coa/${itemId}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
          }
        ); // Generates and mints a COA for an item.
        console.log({ response });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const createProdigiOrder = async ({ orderData }) => {
    // only for physical products
    console.log(
      "🚀 ~ file: Checkout.jsx ~ line 53 ~ paymentSuccedded ~ orderData",
      orderData
    );
    const finalOrder = await executeApiRequestTwo(
      `${REACT_APP_API_URL}prodigi/order`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(orderData),
      }
    );
    return { finalOrder };
  };

  return {
    updateQuoteData,
    updateHBARQuoteData,
    updateNonProdigiQuoteData,
    calculateProductTotal,
    mintCOA,
    payWithVenly,
    associateCollections,
  };
};

export default useCheckout;
