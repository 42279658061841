import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import Select from "react-select";
import getCollectionsMy from "../../api/collections/getCollectionsMy";
import {
  COA_SELECTION_DEFAULT_VALUE,
  COA_SELECTION_DROPDOWN,
  CURRENCY_SELECTION_DROPDOWN,
  DATES_ENABLED,
  HIGH_RES_SIZE_ERROR,
  MAX_ALLOWED_FILE_SIZE,
  MAX_ALLOWED_HIGH_RES_FILE_SIZE,
  RANDOM_SELECTION_DROPDOWN,
  REQUIRED_MESSAGE,
  TRAITS_ENABLED,
  UNIQUE_SELECTION_DEFAULT_VALUE,
  UNIQUE_SELECTION_DROPDOWN,
  WHO_PAYS_DEFAULT_VALUE,
  WHO_PAYS_SELECTION_DROPDOWN,
} from "../../constants/constants";
import { notifyError } from "../../helpers/toastNotificationService";
import { customSelectStyles } from "../CreateCollection/styles";
import TraitsList from "./TraitsList";

const CreateItemForm = (createItemProps) => {
  const {
    isGallery,
    loading,
    data,
    register,
    control,
    errors,
    watch,
    setUniqueList,
    uniqueList,
    highRes,
    setHighRes,
    setCrateDrop,
    crateDrop,
    categories,
    productData,
    rarities,
    setRarities,
    COATemplates,
  } = createItemProps;

  const { productType } = useParams();

  const [myCollections, setMyCollections] = useState([]);
  const isCOAField = watch("isCOA");
  const cratesField = watch("crates", false);
  const highResField = watch("highRes", false);
  const qtyField = watch("quantity", false);
  const uniqueField = watch("uniqueItems", false);

  const todayDate = new Date();
  const sixMonthsDate = moment().add(6, "M").toDate();

  const newData =
    data?.length > 0
      ? data.map((artist) => ({ value: artist.id, label: artist.displayName }))
      : [];

  const getCollections = async () => {
    const collections = await getCollectionsMy();
    setMyCollections(collections);
  };

  const handleChange = (element) => {
    const [img] = element.target.files;
    setUniqueList(URL.createObjectURL(img));
  };

  const handleChangeCrate = (element) => {
    /* Handles cancel button while selecting image*/
    setCrateDrop();

    const [img] = element.target.files;
    if (img.size > MAX_ALLOWED_FILE_SIZE) {
      element.target.value = null;
      notifyError("Maximum allowed file size is 3 MB");
    } else {
      setCrateDrop({ image: URL.createObjectURL(img), type: img.type });
    }
  };

  const handleChangeHighRes = (element) => {
    /* Handles cancel button while selecting image*/
    setHighRes();

    const [img] = element.target.files;
    if (img.size > MAX_ALLOWED_HIGH_RES_FILE_SIZE) {
      element.target.value = null;
      notifyError(HIGH_RES_SIZE_ERROR);
    } else {
      setHighRes({ image: URL.createObjectURL(img), type: img.type });
    }
  };

  useEffect(() => {
    getCollections();
    setRarities([]);
  }, []);

  const validateCoaIssuer = (value) => {
    if (isCOAField === "Y" && !value) {
      return "COA Issuer is required";
    }
  };

  return (
    <>
      <div className="form-create-item">
        <div className="flat-tabs tab-create-item">
          {isGallery() ? (
            <>
              <fieldset>
                <label className="field-label">Title</label>
                <input
                  {...register("title", {
                    required: REQUIRED_MESSAGE,
                  })}
                  name="title"
                  type="text"
                  max={100}
                  placeholder="Sample Title Name"
                />
                <p className="error-message">{errors.title?.message}</p>
              </fieldset>

              <fieldset>
                <label className="field-label">Description</label>
                <textarea
                  {...register("description", {
                    required: REQUIRED_MESSAGE,
                  })}
                  name="description"
                  placeholder="Sample description. Character limit goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                ></textarea>
                <p className="error-message">{errors.description?.message}</p>
              </fieldset>

              <fieldset>
                <label className="field-label">Properties</label>
                <textarea
                  name="properties"
                  placeholder="Sample properties. Character limit goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                ></textarea>
                <p className="error-message">{errors.properties?.message}</p>
              </fieldset>

              <div className="row m-0">
                <div className="col-3 pl-2">
                  <fieldset>
                    <label className="field-label">Price</label>
                    <div style={{ display: "flex" }}>
                      <div className="dropdown" style={{ flex: 4 }}>
                        <Controller
                          control={control}
                          name="currencyType"
                          rules={{
                            required: "Selecting a currency type is required",
                          }}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                          }) => (
                            <Select
                              styles={customSelectStyles}
                              inputRef={ref}
                              options={CURRENCY_SELECTION_DROPDOWN}
                              value={CURRENCY_SELECTION_DROPDOWN.find(
                                (c) => c.value === value
                              )}
                              onChange={(val) => {
                                onChange(val.value);
                              }}
                            />
                          )}
                        />
                      </div>

                      <input
                        {...register("price", {
                          required: "Selecting a price is required",
                        })}
                        name="price"
                        type="text"
                        max={100}
                        placeholder="00"
                        style={{ flex: 1 }}
                      />
                    </div>
                    <p className="error-message">
                      {errors.currencyType?.message}
                    </p>
                    <p className="error-message">{errors.price?.message}</p>
                  </fieldset>
                </div>

                <div className="col-5 pl-2">
                  <div style={{ display: "flex" }}>
                    <fieldset className="pr-4" style={{ flex: 1 }}>
                      <label className="field-label">Qty</label>
                      <input
                        {...register("quantity", {
                          required: REQUIRED_MESSAGE,
                        })}
                        name="quantity"
                        type="text"
                        max={100}
                        placeholder="00"
                      />
                      <p className="error-message">
                        {errors.quantity?.message}
                      </p>
                    </fieldset>

                    {categories && (
                      <fieldset className="pr-4" style={{ flex: 3 }}>
                        <label className="field-label">Category</label>
                        <div style={{ display: "flex" }}>
                          <div className="dropdown" style={{ flex: 1 }}>
                            <Controller
                              control={control}
                              name="categoryIds"
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                              }) => (
                                <Select
                                  styles={customSelectStyles}
                                  inputRef={ref}
                                  isMulti
                                  options={categories.map((category) => ({
                                    label: category.name,
                                    value: category.id,
                                  }))}
                                  value={value}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </fieldset>
                    )}

                    <fieldset style={{ flex: 3 }}>
                      <label className="field-label">Collection</label>
                      <div style={{ display: "flex" }}>
                        <div className="dropdown" style={{ flex: 1 }}>
                          <Controller
                            control={control}
                            name="collectionId"
                            rules={{
                              required: REQUIRED_MESSAGE,
                            }}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                            }) => (
                              <Select
                                styles={customSelectStyles}
                                inputRef={ref}
                                options={myCollections.map((collection) => ({
                                  label: collection.tokenName,
                                  value: collection.id,
                                }))}
                                value={myCollections.find(
                                  (c) => c.value === value
                                )}
                                onChange={(val) => {
                                  onChange(val.value);
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <p className="error-message">
                        {errors.collectionId?.message}
                      </p>
                    </fieldset>
                  </div>
                </div>

                <div className="col-4 pl-2">
                  <fieldset>
                    <div style={{ marginBottom: "8px" }}>
                      <input type="checkbox" {...register("crates")} />
                      <label
                        className="field-label"
                        style={{ width: "100%", display: "inline" }}
                      >
                        Crate Drop (opt.)
                      </label>
                    </div>

                    {cratesField && (
                      <label
                        className="uploadFile"
                        style={{
                          backgroundColor: `${
                            crateDrop?.image ? "red" : "#9a9a9a"
                          }`,
                        }}
                      >
                        <input
                          type="file"
                          name="file"
                          className="inputfile form-control"
                          {...register("crateFile", {
                            onChange: handleChangeCrate,
                          })}
                          accept="image/jpg,image/webp,image/jpeg,image/png,image/gif,video/mp4"
                        />
                      </label>
                    )}
                  </fieldset>
                </div>
              </div>

              {qtyField && parseInt(qtyField) > 1 && (
                <div className="row m-0">
                  <div className="col-4 pl-2">
                    <fieldset>
                      <label className="field-label">
                        Are items Unique or Generic?
                      </label>
                      <div className="dropdown">
                        <Controller
                          control={control}
                          defaultValue={UNIQUE_SELECTION_DEFAULT_VALUE}
                          name="uniqueItems"
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                          }) => (
                            <Select
                              styles={customSelectStyles}
                              inputRef={ref}
                              options={UNIQUE_SELECTION_DROPDOWN}
                              value={UNIQUE_SELECTION_DROPDOWN.find(
                                (c) => c.value === value
                              )}
                              onChange={(val) => {
                                onChange(val.value);
                              }}
                            />
                          )}
                        />
                      </div>
                    </fieldset>
                  </div>

                  {uniqueField === "Y" && (
                    <div className="col-4 pl-2">
                      <fieldset>
                        <label
                          className="field-label"
                          style={{ width: "100%" }}
                        >
                          Upload Unique List
                        </label>
                        <label
                          className="uploadFile"
                          style={{
                            backgroundColor: `${
                              uniqueList ? "red" : "#9a9a9a"
                            }`,
                          }}
                        >
                          <input
                            type="file"
                            name="file"
                            className="inputfile form-control"
                            {...register("uniqueItemList", {
                              onChange: handleChange,
                              required: REQUIRED_MESSAGE,
                            })}
                            accept="csv"
                          />
                        </label>
                        <p className="error-message">
                          {errors.uniqueItemList?.message}
                        </p>
                      </fieldset>
                    </div>
                  )}

                  {uniqueField === "Y" && (
                    <div className="col-4 pl-2">
                      <fieldset>
                        <label className="field-label">Select Randomly?</label>
                        <div className="dropdown">
                          <Controller
                            control={control}
                            name="randomSelection"
                            rules={{
                              required: REQUIRED_MESSAGE,
                            }}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                            }) => (
                              <Select
                                styles={customSelectStyles}
                                inputRef={ref}
                                options={RANDOM_SELECTION_DROPDOWN}
                                value={RANDOM_SELECTION_DROPDOWN.find(
                                  (c) => c.value === value
                                )}
                                onChange={(val) => {
                                  onChange(val.value);
                                }}
                              />
                            )}
                          />
                        </div>
                        <p className="error-message">
                          {errors.randomSelection?.message}
                        </p>
                      </fieldset>
                    </div>
                  )}
                </div>
              )}

              {productData?.onBlockchain === "Y" && TRAITS_ENABLED && (
                <TraitsList rarities={rarities} setRarities={setRarities} />
              )}

              <div className="row m-0">
                {productData?.coaAvailable === "Y" && (
                  <>
                    <div className="col-4 p-0 pr-2">
                      <fieldset>
                        <label className="field-label">
                          Issue Certificate of Authenticity?
                        </label>
                        <div className="dropdown">
                          <Controller
                            control={control}
                            defaultValue={COA_SELECTION_DEFAULT_VALUE}
                            name="isCOA"
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                            }) => (
                              <Select
                                styles={customSelectStyles}
                                inputRef={ref}
                                options={COA_SELECTION_DROPDOWN}
                                value={COA_SELECTION_DROPDOWN.find(
                                  (c) => c.value === value
                                )}
                                onChange={(val) => {
                                  onChange(val.value);
                                }}
                              />
                            )}
                          />
                        </div>
                      </fieldset>
                    </div>

                    {isCOAField === "Y" && (
                      <>
                        <div className="col-4">
                          <fieldset>
                            <label className="field-label">
                              Certificate of authenticity issuer
                            </label>
                            <input
                              {...register("coaIssuer", {
                                validate: {
                                  value: (value) => validateCoaIssuer(value),
                                },
                              })}
                              name="coaIssuer"
                              type="text"
                              max={100}
                              placeholder="Name Example"
                            />
                            <p className="error-message">
                              {errors.coaIssuer?.message}
                            </p>
                          </fieldset>
                        </div>

                        <div className="col-6 pl-2">
                          <fieldset className="pr-4" style={{ flex: 3 }}>
                            <label className="field-label">
                              Certificate of authenticity Template
                            </label>
                            <div style={{ display: "flex" }}>
                              <div className="dropdown" style={{ flex: 1 }}>
                                <Controller
                                  control={control}
                                  name="coaTemplateId"
                                  render={({
                                    field: {
                                      onChange,
                                      onBlur,
                                      value,
                                      name,
                                      ref,
                                    },
                                  }) => (
                                    <Select
                                      styles={customSelectStyles}
                                      inputRef={ref}
                                      options={COATemplates.map((template) => ({
                                        label: template.name,
                                        value: template.id,
                                        exampleFile: template.exampleFile,
                                      }))}
                                      value={value}
                                      onChange={onChange}
                                      onBlur={onBlur}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </fieldset>

                          <fieldset className="pr-4" style={{ flex: 3 }}>
                            <label className="field-label">
                              Certificate of authenticity Additional Trademark
                              or Copyright Verbiage
                            </label>
                            <textarea
                              {...register("coaCopyrightStatement", {
                                required: REQUIRED_MESSAGE,
                              })}
                              name="coaCopyrightStatement"
                              rows="4"
                              placeholder="Example Product name characters, names, and all related indica are trademarks of Example Company Inc. Copyright 2023"
                            ></textarea>
                            <p className="error-message">
                              {errors.coaCopyrightStatement?.message}
                            </p>
                          </fieldset>
                        </div>

                        <div className="col-6 pr-0">
                          <fieldset>
                            <label className="field-label">
                              Certificate of authenticity Edition Details
                            </label>
                            <textarea
                              {...register("coaEditionDetails", {
                                required: REQUIRED_MESSAGE,
                              })}
                              name="coaEditionDetails"
                              rows="4"
                              placeholder="Example Regular Edition: 100 Special Edition: 25 Total: 125"
                            ></textarea>
                            <p className="error-message">
                              {errors.coaEditionDetails?.message}
                            </p>
                          </fieldset>
                        </div>
                      </>
                    )}
                  </>
                )}

                {productData?.sendShippingRequiredNotification === "Y" && (
                  <div className="col-4 p-0 pl-2">
                    <fieldset>
                      <label className="field-label">
                        Seller email address
                      </label>
                      <input
                        {...register("shippingRequiredEmailAddress", {
                          required: REQUIRED_MESSAGE,
                        })}
                        name="shippingRequiredEmailAddress"
                        type="email"
                        max={100}
                        placeholder="Email Example"
                      />
                      <p className="error-message">
                        {errors.shippingRequiredEmailAddress?.message}
                      </p>
                    </fieldset>
                  </div>
                )}

                {productData?.prodigiPrint === "Y" && (
                  <>
                    <div className="col-4 pl-2">
                      <fieldset>
                        <label className="field-label">
                          Who pays for print?
                        </label>
                        <div className="dropdown">
                          <Controller
                            control={control}
                            defaultValue={WHO_PAYS_DEFAULT_VALUE}
                            name="whoPays"
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                            }) => (
                              <Select
                                styles={customSelectStyles}
                                inputRef={ref}
                                options={WHO_PAYS_SELECTION_DROPDOWN}
                                value={WHO_PAYS_SELECTION_DROPDOWN.find(
                                  (c) => c.value === value
                                )}
                                onChange={(val) => {
                                  onChange(val.value);
                                }}
                              />
                            )}
                          />
                        </div>
                      </fieldset>
                    </div>

                    {/* uniqueField === N when quantity is set to something bigger than 0,
                    otherwise false */}
                    {(uniqueField === "N" || !uniqueField) && (
                      <div className="col-4 pl-2">
                        <fieldset>
                          <div style={{ marginBottom: "8px" }}>
                            <input type="checkbox" {...register("highRes")} />
                            <label
                              className="field-label"
                              style={{ width: "100%", display: "inline" }}
                            >
                              Use High Res Print?
                            </label>
                          </div>

                          {highResField && (
                            <label
                              className="uploadFile"
                              style={{
                                backgroundColor: `${
                                  highRes?.image ? "red" : "#9a9a9a"
                                }`,
                              }}
                            >
                              <input
                                type="file"
                                name="file"
                                className="inputfile form-control"
                                {...register("highResFile", {
                                  onChange: handleChangeHighRes,
                                })}
                                accept="image/jpg,image/webp,image/jpeg,image/png,image/gif,video/mp4"
                              />
                            </label>
                          )}
                        </fieldset>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="row m-0">
                {DATES_ENABLED && (
                  <>
                    <div className="col-4 p-0 pr-2">
                      <fieldset className="mb-2">
                        <h6 className="form-field-label">Sale Start Date </h6>
                        <Controller
                          control={control}
                          name="saleStartDate"
                          rules={{
                            required: REQUIRED_MESSAGE,
                          }}
                          defaultValue={todayDate}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              placeholderText="Select date"
                              selected={value}
                              onChange={(date) => onChange(date)}
                            />
                          )}
                        />
                        <p className="error-message">
                          {errors.saleStartDate?.message}
                        </p>
                      </fieldset>
                    </div>

                    <div className="col-4 p-0 pl-2">
                      <fieldset className="mb-2">
                        <h6 className="form-field-label">Sale End Date </h6>

                        <Controller
                          control={control}
                          name="saleEndDate"
                          rules={{
                            required: REQUIRED_MESSAGE,
                          }}
                          defaultValue={sixMonthsDate}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              placeholderText="Select date"
                              selected={value}
                              onChange={(date) => onChange(date)}
                            />
                          )}
                        />

                        <p className="error-message">
                          {errors.saleEndDate?.message}
                        </p>
                      </fieldset>
                    </div>
                  </>
                )}

                <div className="col-4 pl-2">
                  <fieldset>
                    <label className="field-label">
                      Invite Only access code (opt.)
                    </label>
                    <input
                      {...register("specialInvite")}
                      name="specialInvite"
                      type="text"
                      max={100}
                      placeholder="Sample Title Name"
                    />
                    <p className="error-message">
                      {errors.specialInvite?.message}
                    </p>
                  </fieldset>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="row-form style-3 justify-content-center">
        <div className="inner-row-form">
          <button className="submit filled-button">Create Item</button>
        </div>
      </div>
    </>
  );
};

export default CreateItemForm;
