import React, { useEffect, useState } from "react";
import { priceFormatter } from "../../../helpers/formatters";
import {
  getProductName,
  isResaleItem,
  itemDetailsBaseRoute,
} from "../../../helpers/itemHelpers";
import useApi2 from "../../../hooks/useApi2";
import useCart from "../../../hooks/useCart";
import useIPFS from "../../../hooks/useIPFS";
import LinkWithQueryParams from "../../LinkWithQueryParams";
import CardModal from "../CardModal";
import Spinner from "../Spinner/Spinner";
import CartCardVisual from "./CartCardVisual";
import { StyledCartCard } from "./styles";

const CartCard = ({ item, actions, changeSaleStatus }) => {
  const { addToCartPersist, decrementQtyCartPersist, modifyQtyCartPersist } =
    useCart();
  const [itemQty, setItemQty] = useState(item.quantity);
  const [showModal, setShowModal] = useState(false);
  const [productName, setProductName] = useState(null);
  const { element, getData } = useIPFS();
  const { data, loading } = useApi2(`space/user/${item.originalOwnerId}`, {
    method: "GET",
  });
  const { data: userData } = useApi2(`user/one/${item.originalOwnerId}`, {
    method: "GET",
  });

  const fetchProductName = async () => {
    const productName = await getProductName({ item });
    setProductName(productName);
  };

  useEffect(() => {
    if (item) {
      // console.log("🚀 ~ file: CartCard.jsx ~ line 10 ~ CartCard ~ item", item);
      setItemQty(item.quantity);
      getData(item.finalCid ? item?.finalCid : item.cid);
      fetchProductName();
    }
  }, [item]);

  const decrementQty = async (item) => {
    if (item.quantity > 1) {
      await decrementQtyCartPersist(item);
    }
  };

  const incrementQty = async (item) => {
    if (!isResaleItem({ item })) {
      await addToCartPersist(item);
    }
  };

  const isNumeric = (num) =>
    (typeof num === "number" ||
      (typeof num === "string" && num.trim() !== "")) &&
    !isNaN(num);

  const handleChange = (e) => {
    let newValue = e.target.value;
    setItemQty(newValue);
    if (isNumeric(newValue) && newValue > 0) {
      newValue = parseInt(newValue);
      const newItem = { ...item, quantity: newValue };
      modifyQtyCartPersist(newItem);
    }
  };

  return (
    <StyledCartCard>
      <div className="sc-card-activity style-2">
        {!showModal ? null : (
          <CardModal
            show={showModal}
            token={item}
            onHide={() => setShowModal(false)}
            isBuy={true}
            changeSaleStatus={changeSaleStatus}
          />
        )}

        <div className="content">
          <button
            className="delete-button"
            onClick={() => {
              actions[0].action(item);
            }}
          >
            x
          </button>

          <div className="media">
            {loading ? (
              <Spinner />
            ) : (
              <LinkWithQueryParams
                path={`/${itemDetailsBaseRoute({ item })}/${item.id}`}
              >
                <CartCardVisual element={element} itemData={item} />
              </LinkWithQueryParams>
            )}
          </div>
          <div className="infor">
            <h3>
              <LinkWithQueryParams
                path={`/${itemDetailsBaseRoute({ item })}/${item.id}`}
              >
                {element.name}
              </LinkWithQueryParams>
            </h3>

            <LinkWithQueryParams path={`/spaces/${data?.customURL}`}>
              <p className="artistName">{userData?.displayName}</p>
            </LinkWithQueryParams>

            {productName && <div className="product-name">{productName}</div>}

            {/* <button className="sc-button-3 no-bg">QTY: {item.quantity}</button> */}

            <div className="input-group">
              <span className="input-group-btn">
                <button
                  type="button"
                  className="quantity-left-minus btn btn-danger btn-number"
                  data-type="minus"
                  data-field=""
                  onClick={() => decrementQty(item)}
                >
                  <span className="fa fa-minus"></span>
                </button>
              </span>
              <span id="quantity-label">QTY:</span>
              <input
                type="number"
                id="quantity"
                name="quantity"
                className="form-control input-number"
                value={itemQty}
                onChange={handleChange}
                min="1"
                max="100"
              />
              <span className="input-group-btn">
                <button
                  type="button"
                  className="quantity-right-plus btn btn-success btn-number"
                  data-type="plus"
                  data-field=""
                  onClick={() => incrementQty(item)}
                >
                  <span className="fa fa-plus"></span>
                </button>
              </span>
            </div>

            {/* <div style={{ display: "flex" }}>
              {item.status !== "FOR_SALE" ? null : (
                <button
                  onClick={() => setShowModal(true)}
                  style={{
                    fontSize: "16px",
                    color: "#6899FA",
                    padding: 10,
                    backgroundColor: "transparent",
                    fontWeight: "normal",
                    border: "none",
                  }}
                >
                  Buy now
                </button>
              )}
            </div> */}
          </div>
        </div>
        <div className="price pr-md-5">
          <h5>
            {priceFormatter({
              currencyType: isResaleItem({ item })
                ? item?.saleCurrencyType
                : item?.currencyType,
              price: isResaleItem({ item }) ? item?.salePrice : item?.price,
            })}
          </h5>
        </div>
      </div>
    </StyledCartCard>
  );
};

export default CartCard;
