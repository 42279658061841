import RankingTable from "./RankingTable";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import img1 from "../../assets/images/box-item/img3rank.jpg";
import img2 from "../../assets/images/box-item/img4rank.jpg";
import img3 from "../../assets/images/box-item/img5rank.jpg";
import img4 from "../../assets/images/box-item/img6rank.jpg";
import img5 from "../../assets/images/box-item/img1rank.jpg";
import img6 from "../../assets/images/box-item/img2rank.jpg";
import imga1 from "../../assets/images/avatar/author_rank.jpg";

const Ranking = () => {
  const rankingInfo = [
    {
      img: img1,
      title: "Hamlet Contemplates Yorick's Yorick's",
      imgAuthor: imga1,
      nameAuthor: "SalvadorDali",
      volume: "12,4353",
      day: "+3456%",
      week: "-564%",
      price: "12,4353 HBAR",
      owners: "3.3k",
      assets: "23k",
    },
    {
      img: img2,
      title: "Hamlet Contemplates Yorick's Yorick's",
      imgAuthor: imga1,
      nameAuthor: "SalvadorDali",
      volume: "12,4353",
      day: "+3456%",
      week: "-564%",
      price: "12,4353 HBAR",
      owners: "3.3k",
      assets: "23k",
    },
    {
      img: img3,
      title: "Hamlet Contemplates Yorick's Yorick's",
      imgAuthor: imga1,
      nameAuthor: "SalvadorDali",
      volume: "12,4353",
      day: "+3456%",
      week: "-564%",
      price: "12,4353 HBAR",
      owners: "3.3k",
      assets: "23k",
    },
    {
      img: img4,
      title: "Hamlet Contemplates Yorick's Yorick's",
      imgAuthor: imga1,
      nameAuthor: "SalvadorDali",
      volume: "12,4353",
      day: "+3456%",
      week: "-564%",
      price: "12,4353 HBAR",
      owners: "3.3k",
      assets: "23k",
    },
    {
      img: img5,
      title: "Hamlet Contemplates Yorick's Yorick's",
      imgAuthor: imga1,
      nameAuthor: "SalvadorDali",
      volume: "12,4353",
      day: "+3456%",
      week: "-564%",
      price: "12,4353 HBAR",
      owners: "3.3k",
      assets: "23k",
    },
    {
      img: img6,
      title: "Hamlet Contemplates Yorick's Yorick's",
      imgAuthor: imga1,
      nameAuthor: "SalvadorDali",
      volume: "12,4353",
      day: "+3456%",
      week: "-564%",
      price: "12,4353 HBAR",
      owners: "3.3k",
      assets: "23k",
    },
    {
      img: img1,
      title: "Hamlet Contemplates Yorick's Yorick's",
      imgAuthor: imga1,
      nameAuthor: "SalvadorDali",
      volume: "12,4353",
      day: "+3456%",
      week: "-564%",
      price: "12,4353 HBAR",
      owners: "3.3k",
      assets: "23k",
    },
    {
      img: img2,
      title: "Hamlet Contemplates Yorick's Yorick's",
      imgAuthor: imga1,
      nameAuthor: "SalvadorDali",
      volume: "12,4353",
      day: "+3456%",
      week: "-564%",
      price: "12,4353 HBAR",
      owners: "3.3k",
      assets: "23k",
    },
    {
      img: img3,
      title: "Hamlet Contemplates Yorick's Yorick's",
      imgAuthor: imga1,
      nameAuthor: "SalvadorDali",
      volume: "12,4353",
      day: "+3456%",
      week: "-564%",
      price: "12,4353 HBAR",
      owners: "3.3k",
      assets: "23k",
    },
    {
      img: img4,
      title: "Hamlet Contemplates Yorick's Yorick's",
      imgAuthor: imga1,
      nameAuthor: "SalvadorDali",
      volume: "12,4353",
      day: "+3456%",
      week: "-564%",
      price: "12,4353 HBAR",
      owners: "3.3k",
      assets: "23k",
    },
    {
      img: img5,
      title: "Hamlet Contemplates Yorick's Yorick's",
      imgAuthor: imga1,
      nameAuthor: "SalvadorDali",
      volume: "12,4353",
      day: "+3456%",
      week: "-564%",
      price: "12,4353 HBAR",
      owners: "3.3k",
      assets: "23k",
    },
    {
      img: img6,
      title: "Hamlet Contemplates Yorick's Yorick's",
      imgAuthor: imga1,
      nameAuthor: "SalvadorDali",
      volume: "12,4353",
      day: "+3456%",
      week: "-564%",
      price: "12,4353 HBAR",
      owners: "3.3k",
      assets: "23k",
    },
  ];

  return (
    <div>
      <Header />
      <section className="tf-section tf-rank">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <RankingTable data={rankingInfo} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Ranking;
