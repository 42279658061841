import React, { useState } from "react";
import LoadMore from "../../pages/ArtistPage/LoadMore";
import NFTCard from "../layouts/cards/NFTCard/NFTCard";

const Collection03 = ({
  data,
  stop,
  measures,
  isBaseItem = false,
  artistNameShown = false,
}) => {
  const [visible, setVisible] = useState(stop);

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + stop);
  };

  return (
    <section style={{ width: "100%" }}>
      <div className="row m-0">
        {data.length ? (
          data.slice(0, visible).map((item) => (
            <div
              key={item.id}
              className={`col-md-${measures.md} col-sm-${measures.sm} col-6 p-sm-4 p-3`}
            >
              <NFTCard
                card={item}
                isBaseItem={isBaseItem}
                artistNameShown={artistNameShown}
              />
            </div>
          ))
        ) : (
          <div
            className="d-flex justify-content-center align-items-center w-100"
            style={{ height: "400px" }}
          >
            <h3>No Items here!</h3>
          </div>
        )}
      </div>
      {visible < data.length && <LoadMore onClick={showMoreItems} />}
    </section>
  );
};

export default Collection03;
