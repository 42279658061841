import { createSlice } from "@reduxjs/toolkit";
import { removeFromArr } from "../../../helpers/commonOperations";

const initialState = {
  cart: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const itemInCart = state.cart.find(
        (item) => item.id === action.payload.id
      );
      if (itemInCart) {
        itemInCart.quantity++;
      } else {
        state.cart.push({ ...action.payload, quantity: 1 });
      }
    },
    modifyCartItem: (state, action) => {
      const itemIndexInCart = state.cart.findIndex(
        (item) => item.id === action.payload.id
      );

      if (itemIndexInCart >= 0) {
        const itemInCart = state.cart[itemIndexInCart];
        state.cart[itemIndexInCart] = { ...itemInCart, ...action.payload };
      }
    },
    removeFromCart: (state, action) => {
      const itemsToKeep = removeFromArr({
        arr: state.cart,
        itemToRemove: action.payload,
      });
      state.cart = itemsToKeep;
    },
    removeAllFromCart: (state, action) => {
      state.cart = [];
    },
    initializeCart: (state, action) => {
      state.cart = action.payload;
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  removeAllFromCart,
  getCart,
  modifyCartItem,
  initializeCart,
} = cartSlice.actions;
export default cartSlice.reducer;
