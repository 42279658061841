import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap-accordion";
import getCategories from "../../api/category/getCategories";
import Spinner from "../../components/layouts/Spinner/Spinner";
import {
  FOR_SALE_ID,
  NEW_ID,
  OWNED_ID,
  RESALE_ID,
  VAULT_ID,
} from "../../constants/constants";

const Filters = ({
  action,
  status = true,
  categories = true,
  blockchains = true,
  itemType = false,
  vaultStorage = false,
  // privateEndpoint = false,
}) => {
  const [filters, setFilters] = useState([]);
  const [statusFilter, setStatusFilter] = useState([
    {
      id: FOR_SALE_ID,
      name: "For Sale",
      checked: false,
    },
    {
      id: OWNED_ID,
      name: "Purchased",
      checked: false,
    },
     //{
       //id: 'HOLDED',
       //name: 'on hold',
       //checked: false,
     //},
  ]);
  const [chains] = useState([
    {
      id: 0,
      name: "Hedera",
      checked: true,
    },
    {
      id: 1,
      name: "Ethereum",
      checked: false,
    },
  ]);
  const [loading, setLoading] = useState(false);

  const [vaultFilter, setVaultFilter] = useState([
    {
      id: VAULT_ID,
      name: "ACME™ Vault",
      checked: false,
    },
  ]);

  const [itemTypeFilter, setItemTypeFilter] = useState([
    {
      id: NEW_ID,
      name: "New",
      checked: false,
    },
    {
      id: RESALE_ID,
      name: "Resale",
      checked: false,
    },
  ]);

  const getAllCategories = async () => {
    setLoading(true);
    try {
      const response = await getCategories();
      response && setFilters(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const manageChecks = (id) => {
    setStatusFilter(
      statusFilter.map((status) => {
        return status.id !== id
          ? { ...status, checked: false }
          : status.checked
          ? { ...status, checked: false }
          : { ...status, checked: true };
      })
    );
  };

  const manageChecksItemType = (id) => {
    setItemTypeFilter(
      itemTypeFilter.map((status) => {
        return status.id !== id
          ? { ...status, checked: false }
          : status.checked
          ? { ...status, checked: false }
          : { ...status, checked: true };
      })
    );
  };

  const manageChecksVaultType = (id) => {
    setVaultFilter(
      vaultFilter.map((status) => {
        return status.id !== id
          ? { ...status, checked: false }
          : status.checked
          ? { ...status, checked: false }
          : { ...status, checked: true };
      })
    );
  };

  useEffect(() => {
    if (categories) {
      (async () => {
        await getAllCategories();
      })();
    }
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="widget widget-filter style-1 ">
            {status && (
              <>
                <Accordion title="Status" show={true}>
                  <form className="form-inner mt-3">
                    {statusFilter.map((item) => (
                      <div key={item.id}>
                        <label className="filter-label">
                          {item.name}
                          <input
                            type="checkbox"
                            checked={item.checked}
                            onChange={() => {
                              manageChecks(item.id);
                              action({
                                name: "status",
                                value: item.id,
                                checked: item.checked,
                              });
                            }}
                          />
                          <span className="btn-checkbox"></span>
                        </label>
                        <br />
                      </div>
                    ))}
                  </form>
                </Accordion>
                <hr />
              </>
            )}

            {itemType && (
              <>
                <Accordion title="Status" show={true}>
                  <form className="form-inner mt-3">
                    {itemTypeFilter.map((item) => (
                      <div key={item.id}>
                        <label className="filter-label">
                          {item.name}
                          <input
                            type="checkbox"
                            checked={item.checked}
                            onChange={() => {
                              manageChecksItemType(item.id);
                              action({
                                name: "status",
                                value: item.id,
                                checked: item.checked,
                              });
                            }}
                          />
                          <span className="btn-checkbox"></span>
                        </label>
                        <br />
                      </div>
                    ))}
                  </form>
                </Accordion>
                <hr />
              </>
            )}

            {vaultStorage && (
              <>
                <Accordion title="Vault Storage" show={true}>
                  <form className="form-inner mt-3">
                    {vaultFilter.map((item) => (
                      <div key={item.id}>
                        <label className="filter-label">
                          {item.name}
                          <input
                            type="checkbox"
                            checked={item.checked}
                            onChange={() => {
                              manageChecksVaultType(item.id);
                              action({
                                name: "vault",
                                value: item.id,
                                checked: item.checked,
                              });
                            }}
                          />
                          <span className="btn-checkbox"></span>
                        </label>
                        <br />
                      </div>
                    ))}
                  </form>
                </Accordion>
                <hr />
              </>
            )}

            {categories && (
              <>
                <Accordion title={"Categories"} show={true}>
                  <form className="form-inner mt-3">
                    {filters.map((item, index) => (
                      <div key={index}>
                        <label>
                          {item.name}
                          <input
                            type="checkbox"
                            onChange={() =>
                              action({ name: "category", value: item.id })
                            }
                          />
                          <span className="btn-checkbox"></span>
                        </label>
                        <br />
                      </div>
                    ))}
                  </form>
                </Accordion>
                <hr />
              </>
            )}

            {blockchains && (
              <>
                <Accordion title="Chains" show={true}>
                  <form className="form-inner mt-3">
                    {chains.map((item) => (
                      <div key={item.id}>
                        <label>
                          {item.name}
                          <input
                            type="checkbox"
                            checked={item.checked}
                            disabled={true}
                            onChange={() =>
                              action({ name: "status", value: item.id })
                            }
                          />
                          <span className="btn-checkbox"></span>
                        </label>
                        <br />
                      </div>
                    ))}
                  </form>
                </Accordion>
                <hr />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Filters;
