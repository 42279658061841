import React from 'react';
import { useNavigate } from 'react-router-dom';

const InfoAfterBuy = () => {
  const navigate = useNavigate();
  return (
    <div className='modal-body space-y-20 pd-40'>
      <h3> Thank you for your purchase! </h3>

      <div className='hr'></div>

      <p>
        You already have your Nft saved in the "my items" section, if you want,
        you can put it on sale again by changing its status
      </p>
      <p>You can also print your new NFT using our Prodigi Integration.</p>

      <br />

      <button className='btn btn-primary' onClick={() => navigate('/my-items')}>
        <span>Go to items</span>
      </button>
    </div>
  );
};

export default InfoAfterBuy;
