import React from "react";
import { useFormContext } from "react-hook-form";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import {
  REACT_APP_SQUARE_APPLICATION_ID,
  REACT_APP_SQUARE_LOCATION_ID,
} from "../../constants/constants";
import { notifyError } from "../../helpers/toastNotificationService";
import { useApi } from "../../hooks/useApi";
import { generateHmac } from "../../utils/sha256Encription";

const { REACT_APP_API_URL } = process.env;

const FormWrapper = ({
  endpoint,
  action,
  initialValidation,
  amount = null,
  user,
  taxCalculated,
  requiresShipping,
}) => {
  const methods = useFormContext();

  const { executeApiRequestTwo } = useApi();

  const processPayment = async (token, verifiedBuyer) => {
    const initVali = await initialValidation();
    if (!initVali) {
      return;
    }

    if (!token?.token) {
      notifyError("Payment failed, please try again ");
      return;
    }

    const { hString, epochTimestamp } = generateHmac({
      userId: user.id,
      id: token?.token,
    });
    const payload = {
      sourceId: token?.token,
      amount: Number(amount),
      hString,
      epochTimestamp,
      verificationToken: "",
      idempotencyKey: "",
    };
    const data = await executeApiRequestTwo(
      `${REACT_APP_API_URL}square/create-payment`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    );
    if (data?.paymentDetails?.id) {
      action({ paymentIntent: { id: data?.paymentDetails?.id } });
    } else {
      notifyError("Payment failed, please try again ");
    }
  };

  // if (!promise || !clientSecret) return <h4>Loading</h4>;
  return (
    <>
      <PaymentForm
        applicationId={REACT_APP_SQUARE_APPLICATION_ID}
        cardTokenizeResponseReceived={(token, verifiedBuyer) =>
          processPayment(token, verifiedBuyer)
        }
        locationId={REACT_APP_SQUARE_LOCATION_ID}
      >
        <CreditCard
          buttonProps={{
            isLoading:
              (requiresShipping && !methods.formState.isValid) ||
              !taxCalculated,
          }}
        />
      </PaymentForm>
    </>
  );
};

export default FormWrapper;
