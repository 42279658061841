export const removeFromArr = ({ arr, itemToRemove }) => {
  const itemsToKeep = arr.filter((item) => item.id !== itemToRemove);
  return itemsToKeep;
};

export const filterFromArr = ({ arr, prop, conditionValue }) => {
  const itemsToKeep = arr.filter((item) => item[prop] === conditionValue);
  return itemsToKeep;
};

export const totalize = ({ products, initialPrice }) => {
  return products.reduce(
    (prev, current) =>
      prev +
      current.quantity *
        (current?.salePrice ? current?.salePrice : current.price),
    initialPrice
  );
};
