import { getHeaders } from "./utils";

const { REACT_APP_API_URL } = process.env;

const getItem = async (id) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}item/one/${id}`, {
      method: "GET",
      headers,
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export default getItem;
