import styled from "styled-components";

export const StyledItemPreview = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .subheader {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
    width: 100%;
  }
  #item-image-preview {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .nft-card {
      width: 60%;
    }
    #crate-preview {
      width: 100%;
      display: flex;
      justify-content: center;
      width: 285px;
      height: 285px;
      position: relative;
      .main-image-container {
        width: 100%;
        position: relative;
        padding-top: 100%;
        .image-container .image:before {
          padding-top: 100%;
        }
        .image-container .image {
          height: auto;
        }
        .image-container .image img {
          top: 0;
          object-fit: contain;
        }
      }
    }
  }

  .no-image-preview {
    margin-bottom: 20px;
    .gray-background {
      width: 285px;
      height: 264px;
      background-color: #d9d9d9;
      box-shadow: 4px 0px 12px rgba(0, 0, 0, 0.25);
      border-radius: 27px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
    }

    .btn-upload {
      margin-top: 0;
      margin-bottom: 8px;
    }
    .uploadFile {
      height: 50px;
      width: 50%;
      background-color: #25a29d;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
    }
    .uploadFile input[type="file"]:before {
      color: white;
      background-color: transparent;
    }
    .uploadFile .form-control:focus {
      background-color: transparent;
    }
    .uploadFile input[type="file"] {
      left: 50%;
      right: auto;
      transform: translate(-50%, -50%);
    }
  }
`;
