import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { useCustomNavigator } from "../../hooks/useCustomNavigator";
import { EmailVerifiedPage } from "./styles";

const EmailVerified = () => {
  const { customNavigate } = useCustomNavigator();
  return (
    <EmailVerifiedPage>
      <div>
        <Header />
        <section className="tf-email-verified tf-section">
          <div className="main-content">
            <div className="row justify-content-center m-0">
              <div className="page-title-heading mg-bt-12">
                <div className="vertical-line"></div>
                <h1 className="heading text-center">Welcome to ACME LABS™ </h1>
              </div>
            </div>
            <div className="instructions-container">
              You have sucessfully created your account.
            </div>
          </div>
          <div className="row m-0 w-100">
            <div
              className="col-md-6 col-12 left-background"
              onClick={() => {
                customNavigate({ path: "/explore-04" });
              }}
            >
              {/* <div className="overlay"></div> */}
              <div className="title">Explore Marketplace</div>
            </div>
            <div
              className="col-md-6 col-12 right-background"
              onClick={() => {
                customNavigate({ path: "/wallet-connect" });
              }}
            >
              <div className="overlay"></div>
              <div className="title">Set Up Your Wallet</div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </EmailVerifiedPage>
  );
};

export default EmailVerified;
