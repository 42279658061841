import styled from "styled-components";
export const StyledTopTrendingSection = styled.section`
  .top-trending {
    background: var(--header-background);
  }

  .nft-card {
    margin-bottom: 0%;
  }

  .nft-card-container {
    margin-right: 5.33%;
    margin-bottom: 5.33%;
    padding: 0;
    width: 21%;
  }

  .nft-card-container:nth-child(4n) {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    padding: 0px !important;
    .exp {
      font-size: 12px !important;
    }
    .tf-section.live-auctions {
      padding: 20px 0px !important;
    }

    .nft-card-container {
      width: 31%;
      margin-right: 3.5%;
      margin-bottom: 3.5%;
    }

    .nft-card-container:nth-child(4n) {
      margin-right: 3.5%;
    }

    .nft-card-container:nth-child(3n) {
      margin-right: 0;
    }
  }
`;
