import styled from "styled-components";
import { HEADER_HEIGHT } from "../../constants/constants";
import { COMMON_STYLES } from "../../constants/genericStyles";
export const CollectionSection = styled.section`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }
  .tf-section {
    padding: 0;
  }
  .themesflat-container {
    padding: 0;
    padding-top: 40px;
  }
  .profile-image-container {
    top: 130px;
    left: 4%;
    height: 200px;
    width: 200px;
  }

  .collection-image-container {
    height: 220px;
    width: 220px;
    margin-bottom: 10px;
  }

  .collection-image {
    ${COMMON_STYLES.GENERIC_IMAGE_CONTAINER({
      paddingTop: "100%",
    })}
    border-radius: 19px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .gray-background {
      position: absolute;
      top: 0;
      width: 229px;
      height: 220px;
      background: #d9d9d9;
      border-radius: 19px;
    }
  }

  .collection-name {
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    color: #000000;
  }
  .page-title-heading {
    display: flex;
    align-items: center;
    align-content: center;
  }
  .page-title-heading .heading {
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    color: #000000;
  }

  .page-title-heading .vertical-line {
    height: 42px;
    border-left: 8px solid #ffd061;
  }

  .collection-description {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 15px;
  }

  .curator-container {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #000000;
  }

  .curator-name {
    color: #1b70d0;
  }

  .faint-line {
    border: none;
    border-top: 1px solid #cdcdcd;
    margin-top: 9px;
    margin-bottom: 9px;
  }

  .filled-button {
    ${COMMON_STYLES.FILLED_BUTTON({ fillColor: "#25A29D", borderSize: "10px" })}
  }
  .outlined-button {
    ${COMMON_STYLES.OUTLINED_BUTTON({
      textColor: "#ff7b7c",
      borderSize: "10px",
    })}
  }

  .top-container,
  .bottom-container {
    padding-left: 15px;
    padding-right: 15px;
    padding-left: 4%;
    padding-right: 3%;
  }

  .filters-container {
    padding-right: 3%;
  }
  .divider-line {
    border: 0;
    border-bottom: 1px solid #cdcdcd;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .left-side-container {
    margin-right: 2%;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .right-side-container {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }

  .right-side-container .information-container {
    width: 100%;
  }
  .right-side-container .follow-container {
    width: 20%;
    display: flex;
    justify-content: flex-end;
  }

  .actions-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
  }

  .fa-heart,
  .fa-share-alt,
  .fa-elipsis-stroke {
    font-family: "Font Awesome 5 Pro";
    height: auto;
    font-size: 24px;
    margin-right: 26px;
    cursor: pointer;
  }
  .fa-elipsis-stroke {
    margin-right: 0px;
  }

  .fa-elipsis-stroke::before {
    content: "\f39b";
  }

  .stats-container {
    width: 70%;
    .stat-container {
      width: 25%;
    }
    .stat-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
      color: #011126;
    }
    .stat-number {
      font-size: 24px;
      line-height: 24px;
      color: #565656;
    }
  }

  .share_popup {
    position: absolute;
    right: 0;
    padding: 20px 10px;
    top: 60px;
    overflow: hidden;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    visibility: visible;
    -webkit-transform: translateY(-1rem);
    transform: translateY(-1rem);

    background-color: #ffffff;
    border: 1px solid #d7d7d7;
    box-shadow: 6px 6px 17px rgba(0, 0, 0, 0.25);
    border-radius: 18px;
    padding: 17px 17px;
    padding-bottom: 7px;
    z-index: 100;
    .share_option {
      display: flex;
      margin-bottom: 10px;
      cursor: pointer;
      img {
        width: 23px;
        height: 23px;
        margin-right: 6px;
      }
      p,
      a {
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        color: #011126;
      }
    }

    .links {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      a {
        color: var(--header-buttons-font);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }
    }
    .links a i {
      margin-right: 5px;
      font-size: 20px;
    }

    .links a:hover {
      color: var(--primary-color3);
    }
  }

  .share_popup.visible {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  @media (max-width: 768px) {
  }
`;
