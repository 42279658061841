import React from "react";

const NFTCardImage = ({ isPreview, itemData }) => {
  return (
    <img
      src={isPreview ? itemData.croppedImage : itemData.thumbnailImage}
      alt={`product ${itemData.id}`}
    />
  );
};

export default NFTCardImage;
