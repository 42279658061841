import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Assets from "../../assets/Assets";
import { useCustomNavigator } from "../../hooks/useCustomNavigator";

const StyledWalletBtn = styled.div`
  .sc-button {
    padding: 1px 4px;
    padding-right: 15px;
    display: flex;
    align-items: center;

    background-color: transparent;
  }
  img {
    width: 40px;
    margin-right: 5px;
  }
  .wallet-icon {
    width: 29px;
  }
  @media (max-width: 768px) {
    width: 100%;
    #site-header {
      display: flex;
      justify-content: center;
    }
  }
`;

const WalletBtn = () => {
  const user = useSelector((state) => state.user);
  const { customNavigate } = useCustomNavigator();
  return (
    <StyledWalletBtn>
      <div className="mr-3" id="site-header">
        <button
          className="sc-button header-slider fl-button pri-1"
          onClick={() => customNavigate({ path: "/wallet-connect" })}
        >
          <>
            <img alt="wallet" src={Assets.walletIcon} />
            {!user?.hashpackWallets?.accountIds &&
              !user?.wallets.walletDetails.id && <span>Connect</span>}

            {user?.hashpackWallets?.accountIds && (
              <img
                className="wallet-icon"
                alt="hashIcon"
                src={Assets.hashpackIcon}
              />
            )}

            {user?.wallets.walletDetails.id && (
              <img
                className="wallet-icon"
                alt="venlyIcon"
                src={Assets.venlyIcon}
              />
            )}
          </>
        </button>
      </div>
    </StyledWalletBtn>
  );
};

export default WalletBtn;
