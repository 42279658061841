import { clone, isEqual } from "lodash";
import { getProductSku } from "../api/products/getProducts";
import {
  INVALID_CONFIGURATION_COMBINATION,
  NOT_FOUND_CONFIGURATION_COMBINATION,
  OUT_OF_STOCK_ADDON,
  QDESC,
  QID,
  VALID_COMBINATION,
  VDESC,
  VID,
} from "../constants/constants";
import { isAddonItem } from "./itemHelpers";

const combine = ([head, ...[headTail, ...tailTail]], result = {}) => {
  if (!headTail) return head;

  const combined = headTail.reduce((acc, x) => {
    return acc.concat(head.map((h) => `${h}|${x}`));
  }, []);

  return combine([combined, ...tailTail]);
};

export const getValue = (str, initString, finalString) => {
  return str.substring(
    str.indexOf(initString) + initString.length,
    str.lastIndexOf(finalString)
  );
};

export const getQuestionValueCombinations = ({ addonConfig }) => {
  const masterQuestionValues = [];

  addonConfig.productAddonConfigQuestions.forEach((q) => {
    const questionValues = q.productAddonConfigValues.map((v) => {
      return (
        QID + q.id + QDESC + q.description + VID + v.id + VDESC + v.description
      );
    });
    masterQuestionValues.push(questionValues);
  });

  const combinedQuestionValues = combine(masterQuestionValues);

  const objCombinations = combinedQuestionValues.map((comb) => {
    const separated = comb.split("|");
    return separated.map((questionValue) => {
      const questionId = getValue(questionValue, QID, QDESC);
      const questionDescription = getValue(questionValue, QDESC, VID);
      const valueId = getValue(questionValue, VID, VDESC);
      const valueDescription = questionValue.split(VDESC)[1];
      return {
        configQuestionId: questionId,
        // questionDescription,
        configValueId: valueId,
        // valueDescription,
      };
    });
  });
  return objCombinations;
};

export const formatFormData = ({ data }) => {
  let formData = JSON.parse(JSON.stringify(data));

  /* Remove potentially undefined form data */
  Object.keys(formData).forEach((key) => {
    if (!formData[key]) {
      delete formData[key];
    }
  });

  /* Formats as sku data */
  const configDetails = Object.entries(formData).map((entry) => {
    return {
      configQuestionId: entry[0],
      configValueId: entry[1],
    };
  });

  return configDetails;
};

export const cleanFormData = ({ data, addonQuestions }) => {
  let formData = JSON.parse(JSON.stringify(data));

  /* remove fields from other addon modals (possibly selected an addon manipulated dropdowns and canceled)*/
  const addonQuestionsIds = addonQuestions.map((question) => question.id);
  Object.keys(formData).forEach((key) => {
    if (!addonQuestionsIds.includes(key)) {
      delete formData[key];
    }
  });

  const configDetails = Object.entries(formData).map((entry) => {
    return {
      configQuestionId: entry[0],
      configValueId: entry[1],
    };
  });

  return configDetails;
};

const combinationsWithoutIds = (combinations) =>
  JSON.parse(JSON.stringify(combinations)).map((sku) => {
    const newCombination = [];
    sku.forEach((prop) => {
      if (!prop["skuData"]) {
        newCombination.push(prop);
      }
    });
    return [...newCombination];
  });

export const categorizeSelection = ({ skus, formData }) => {
  const invalidCombinations = skus.invalidCombinations;
  const filteredInvalid = invalidCombinations.findIndex((comb) => {
    const diagnose = isEqual(comb, formData);
    return diagnose;
  });

  const validCombinations = skus.validCombinations;
  const validCombinationsWithoutIds = combinationsWithoutIds(validCombinations);
  const filteredValid = validCombinationsWithoutIds.findIndex((comb) => {
    const diagnose = isEqual(comb, formData);
    return diagnose;
  });

  const outOfStock = skus.outOfStock;
  const outOfStockCombinationsWithoutIds = combinationsWithoutIds(outOfStock);
  const filteredOutOfStock = outOfStockCombinationsWithoutIds.findIndex(
    (comb) => {
      const diagnose = isEqual(comb, formData);
      return diagnose;
    }
  );

  let category;
  if (filteredInvalid >= 0)
    category = {
      message: INVALID_CONFIGURATION_COMBINATION,
      combination: invalidCombinations[filteredInvalid],
    };
  if (filteredOutOfStock >= 0)
    category = {
      message: OUT_OF_STOCK_ADDON,
      combination: outOfStock[filteredOutOfStock],
    };
  if (filteredValid >= 0)
    category = {
      message: VALID_COMBINATION,
      combination: validCombinations[filteredValid],
    };

  return { category };
};

export const retrieveAddonProducts = ({ items }) => {
  const addonProducts = items.filter((item) => isAddonItem({ item }));
  const nonAddonProducts = items.filter((item) => !isAddonItem({ item }));
  return { addonProducts, nonAddonProducts };
};

export const getAllSKUs = async ({ addonConfig, addonId }) => {
  const outOfStock = [];
  const invalidCombinations = [];
  const validCombinations = [];

  const objCombinations = await getQuestionValueCombinations({
    addonConfig,
  });
  /* Get all SKUS */
  await Promise.all(
    objCombinations.map(async (combination) => {
      const requestBody = {
        addonId,
        configDetails: combination,
      };

      const sku = await getProductSku({
        addonId,
        requestBody,
      });

      if (sku?.errorDetails && sku?.errorDetails === OUT_OF_STOCK_ADDON) {
        const combinationWithSkuId = clone(combination);
        combinationWithSkuId.push({ skuData: { ...sku } });
        outOfStock.push(combinationWithSkuId);
      } else if (
        (sku?.errorDetails &&
          sku?.errorDetails.includes(INVALID_CONFIGURATION_COMBINATION)) ||
        (sku?.errorDetails &&
          sku?.errorDetails.includes(NOT_FOUND_CONFIGURATION_COMBINATION))
      ) {
        invalidCombinations.push(combination);
      } else {
        const combinationWithSkuId = clone(combination);
        combinationWithSkuId.push({ skuData: { ...sku } });
        validCombinations.push(combinationWithSkuId);
      }
    })
  );
  return { outOfStock, invalidCombinations, validCombinations };
};
