import React from "react";

const HeadingText = ({ title, variant = "" }) => {
  return (
    <div className={`heading ${variant}`}>
      <h2 className={`tf-title`}>
        <div className="vertical"></div>
        <div className="span-container">
          <span>{title}</span>
        </div>
      </h2>
    </div>
  );
};

export default HeadingText;
