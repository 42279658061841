import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
// import { auth0ConfirmationEmail } from "../../helpers/auth0Service";
import LinkWithQueryParams from "../../components/LinkWithQueryParams";
import { VerificationEmailPage } from "./styles";

const VerificationEmailSent = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const resendEmail = async () => {
    try {
      const userId = state?.userId;
      // const result = await auth0ConfirmationEmail({ user_id: userId });
      // console.log({ userId, result });
      // if (result && result.includes(FORGOT_PASSWORD_EMAIL_SENT)) {
      //   navigate("/forgot-password-sent", {
      //     state: { userEmail: email },
      //   });
      // }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <VerificationEmailPage>
      <div>
        <Header />
        <section className="tf-verification-email-sent tf-section">
          <div className="row m-0">
            <div className="col-md-6 col-12 left-background confirmation">
              <div className="overlay"></div>
            </div>

            <div className="col-md-6 col-12 right-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="instructions-container">
                      Welcome to ACME LABS™!
                    </h1>
                  </div>
                  <div className="instructions-container">
                    <h4 className="mb-4">
                      Ready to start your adventure?
                    </h4>
                    <br></br><br></br>
                    <div className="login-btn-link">
                      <LinkWithQueryParams path="/login">
                        {" "}
                        Continue to login
                      </LinkWithQueryParams>
                    </div>
                  </div>

                  <div className="instructions-container style2 mb-4">
                    <h4>Didn't recieve an email confirmation?</h4>
                  </div>

                  <div className="resend-btn-link" onClick={resendEmail}>
                    <LinkWithQueryParams path="#">
                      {" "}
                      Resend email
                    </LinkWithQueryParams>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </VerificationEmailPage>
  );
};

export default VerificationEmailSent;
