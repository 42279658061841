import styled from "styled-components";
export const StyledModal = styled.div`
  background-color: #011126;
  padding: 30px 50px;
  .modal-title {
    font-family: "Roboto Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 63px;
    text-align: center;
    text-transform: uppercase;

    color: #ffffff;

    margin-bottom: 50px;
  }
  .modal-content-text {
    font-family: "Roboto Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    text-transform: uppercase;

    color: #ffffff;

    margin-bottom: 120px;
  }
  .button-container {
    display: flex;
    justify-content: center;
  }
  .filled-button {
    border: none;
    background: #2159a2;
    border-radius: 10px;

    font-weight: 700;
    font-size: 36px;
    line-height: 47px;
    text-align: center;
    color: #ffffff;

    padding: 20px 100px;
  }
`;
