import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getRoomTemplates } from "../../api/room/getRooms";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Spinner from "../../components/layouts/Spinner/Spinner";
import {
  ERROR_CREATING_COLLECTION,
  REACT_APP_API_URL,
  ROOM_POST_DATA,
  SUCCESFUL_COLLECTION_CREATION,
} from "../../constants/constants";
import { notify, notifyError } from "../../helpers/toastNotificationService";
import { useApi } from "../../hooks/useApi";
import { useApi2 } from "../../hooks/useApi2";
import { useRole } from "../../hooks/useRole";
import CollectionImagePreview from "./CollectionImagePreview";
import CreateCollectionForm from "./CreateCollectionForm";
import { CreateCollectionPage } from "./styles";

const CreateCollection = () => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const { executeApiRequestTwo } = useApi();
  const { isGallery } = useRole();
  const [limited, setLimited] = useState();
  const [royalty, setRoyalty] = useState();
  const [processing, setProcessing] = useState(false);

  const { data, loading } = useApi2("artist/gallery", {
    method: "GET",
  });

  const getCollectionTemplateId = async () => {
    const templates = await getRoomTemplates();
    const filteredTemplates = templates.filter(
      (f) => f.name === "Collection_Template"
    );
    return filteredTemplates[0].id;
  };

  const onSubmit = async (formData) => {
    setProcessing(true);
    const postFormData = new FormData();
    postFormData.append("artistId", formData.artistId);
    postFormData.append("description", formData.description);
    if (formData.file[0]) postFormData.append("file", formData.file[0]);

    // if limited === Yes send maxSupply
    if (limited) {
      postFormData.append(
        "maxSupply",
        formData?.maxSupply ? Number(formData.maxSupply) : 50000
      );
    } else {
      postFormData.append("maxSupply", 50000);
    }

    // if royalty > 0% send royaltyFee and royaltyFeeTokenId
    if (royalty) {
      postFormData.append(
        "royaltyFee",
        formData.royaltyFee ? Number(formData.royaltyFee) : 0
      );
      if (Number(formData.royaltyFee)) {
        postFormData.append("royaltyFeeTokenId", formData.royaltyFeeTokenId);
      }
    } else {
      postFormData.append("royaltyFee", 0);
    }

    postFormData.append("tokenName", formData.tokenName);
    postFormData.append("tokenSymbol", formData.tokenSymbol);
    if (formData.customUrl)
      postFormData.append("customUrl", formData.customUrl);

    try {
      const colRes = await executeApiRequestTwo(
        `${REACT_APP_API_URL}collection`,
        {
          method: "POST",
          body: postFormData,
        }
      );

      if (colRes?.statusCode === 400) {
        notifyError(ERROR_CREATING_COLLECTION);
        return;
      }
      const roomTemplateId = await getCollectionTemplateId();

      const roomPostData = {
        collectionId: colRes.id,
        name: colRes.tokenName,
        description: formData.description,
        acf: { ...ROOM_POST_DATA.acf },
        image: { ...ROOM_POST_DATA.image },
        roomTemplateId,
        ...ROOM_POST_DATA.baseFields,
      };

      const roomRes = await executeApiRequestTwo(`${REACT_APP_API_URL}room`, {
        method: "POST",
        body: JSON.stringify(roomPostData),
        headers: {
          "content-type": "application/json",
        },
      });

      notify(SUCCESFUL_COLLECTION_CREATION, () =>
        navigate(`/collection/${colRes.id}`)
      );
    } catch (error) {
      setProcessing(false);
      console.log(error);
      notifyError(ERROR_CREATING_COLLECTION);
      // setLoading(false);
      // setError(true);
    }
  };

  return (
    <CreateCollectionPage>
      <div className="create-item">
        <Header />

        <div className="tf-create-collection tf-section">
          <div className="themesflat-container">
            <form
              className="row justify-content-md-center justify-content-xl-center justify-content-lg-center"
              action="#"
              onSubmit={handleSubmit(onSubmit)}
            >
              {processing && (
                <div className="spinner-overlay">
                  <Spinner />
                </div>
              )}

              <div className="col-4">
                <CollectionImagePreview register={register} errors={errors} />
              </div>
              <div id="right-section" className="col-8">
                <div className="header-container">
                  <div className="page-title-heading">
                    <div className="vertical-line"></div>
                    <h1 className="heading">Create Collection</h1>
                  </div>
                </div>
                <div className="header-description">
                  Small blurb about what this means. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua.
                </div>

                <CreateCollectionForm
                  isGallery={isGallery}
                  loading={loading}
                  data={data}
                  register={register}
                  control={control}
                  errors={errors}
                  watch={watch}
                  limited={limited}
                  setLimited={setLimited}
                  royalty={royalty}
                  setRoyalty={setRoyalty}
                />
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    </CreateCollectionPage>
  );
};

export default CreateCollection;
