import React, { useState, useCallback } from "react";
import LoadMore from "../../pages/ArtistPage/LoadMore";
import ArtistCard from "./ArtistCard/ArtistCard";
import CollectionCard from "./CollectionCard";
import CollectionHeader from "./CollectionHeader";

const Collection05 = ({
  data,
  stop,
  color,
  title,
  navigateTo,
  header,
  measures,
}) => {
  const [visible, setVisible] = useState(stop);

  const showMoreItems = useCallback(() => {
    setVisible((prev) => prev + stop);
  }, [visible]);

  return (
    <section className="tf-section our-creater dark-style2">
      <div className="themesflat-container">
        {header && (
          <CollectionHeader
            title={title}
            navigateTo={navigateTo}
            show={false}
            color={color}
          />
        )}
        <div className="row">
          {data.length ? (
            data.slice(0, visible).map((item, index) => (
              <div
                key={item.id + index}
                className={`col-6 col-md-${measures.md} col-sm-${measures.sm} col-lg-${measures.lg}`}
              >
                <ArtistCard card={item} artistPage isCollection={true} />
              </div>
            ))
          ) : (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ height: "400px" }}
            >
              <h3>No collections to show!</h3>
            </div>
          )}
        </div>
        {visible < data.length && <LoadMore onClick={showMoreItems} />}
      </div>
    </section>
  );
};

export default Collection05;
