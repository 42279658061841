import React from "react";

import {
  default as logodark,
  default as logodark2x,
  default as logoheader,
  default as logoheader2x,
} from "../../assets/images/logo/logo_dark.png";
import LinkWithQueryParams from "../LinkWithQueryParams";

const LogoHeader = () => {
  return (
    <div id="site-logo" className="clearfix">
      <div id="site-logo-inner">
        <LinkWithQueryParams path="/" rel="home" className="main-logo">
          <img
            className="logo-dark"
            id="logo_header"
            src={logodark}
            srcSet={`${logodark2x}`}
            alt="nft-gaming"
          />
          <img
            className="logo-light"
            id="logo_header"
            src={logoheader}
            srcSet={`${logoheader2x}`}
            alt="nft-gaming"
          />
        </LinkWithQueryParams>
      </div>
    </div>
  );
};

export default LogoHeader;
