import Assets from "../../assets/Assets";

export const routesList = [
  {
    title: 'Marketplace',
    routes: [
      {
        title: 'Art',
        link: '/explore-items',
      },
      {
        title: 'Creators',
        link: '/authors-01',
      },
      {
        title: 'Rankings',
        link: '/rankings',
        disabled: true,
      },
      {
        title: 'Auctions',
        link: '#',
        disabled: true,
      },
      {
        title: 'Games',
        link: '#',
        disabled: true,
      },
      {
        title: 'Collectibles',
        link: '#',
        disabled: true,
      },
      {
        title: 'Avatars',
        link: '#',
        disabled: true,
      },
    ],
  },
  {
    title: 'Community',
    routes: [
      {
        title: 'Activities',
        link: '/activities-01',
        disabled: true,
      },
      {
        title: 'Directory',
        link: '/explore-items',
	disabled: true,
      },
      {
        title: 'Blog',
        link: '/blog',
        disabled: true,
      },
    ],
  },
  {
    title: 'Resources',
    routes: [
      {
        title: 'Help Center',
        link: "https://cms.acmelabs.xyz/get-started-on-acme-labs/",
        disabled: true,
      },
      {
        title: 'FAQs',
        link: '/faq',
      },
    ],
  },
  {
    title: 'Company',
    routes: [
      {
        title: 'About',
        link: '/about-us',
      },
    ],
  },
];

export const socialList = [
  {
    image: Assets.instagramIcon,
    link: "https://www.instagram.com/acmelabs_tm/ ",
  },
  {
    image: Assets.twitterIcon,
    link: "https://twitter.com/acmelabs_tm ",
  },
  {
    image: Assets.facebookIcon,
    link: "https://www.facebook.com/Acmelabs_tm-101961962625470 ",
  },
  {
    image: Assets.linkedinIcon,
    link: "https://www.linkedin.com/company/acme-labs%E2%84%A2/about/",
  },
];

export const legalList = [
  {
    title: 'Privacy Policy',
    link: '/privacy-policy',
  },
  {
    title: "Terms of Service",
    link: "https://cms.acmelabs.xyz/terms-of-service/",
    externalSite: true,
  },
  {
    title: 'Cookie Settings',
    link: '/cookies',
  },
];
