import { useState } from "react";
import { useDispatch } from "react-redux";
import { venlyConnect } from "../helpers/venlyConnect";
import {
  updateOwnerWallet,
  updateWalletDetail,
} from "../store/slices/user/user";

const useVenly = () => {
  const [errorConnect, setErrorConnect] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();

  const secretType = "HEDERA";
  const type = "HEDERA_TOKEN_ASSOCIATION";

  const updateToken = (token) => {};

  const handleAuthenticated = (auth) => {
    try {
      updateToken(auth.token);
      venlyConnect.addOnTokenRefreshCallback(updateToken);
      setAuthenticated(auth.authenticated);
      setUser(auth.tokenParsed);
      dispatch(updateOwnerWallet(auth.tokenParsed));
    } catch (e) {
      setErrorConnect(true);
      setErrorMessage(e);
      console.log("Error authenticating with token: " + e);
    }
  };

  const initiateAuthenticatedFlow = async ({ result }) => {
    result
      .authenticated(handleAuthenticated)
      .notAuthenticated(() => console.log("Not authenticated yet"));
  };

  const initiateAuthenticationFlow = async () => {
    try {
      const result = await venlyConnect.flows.authenticate();
      if (result?.auth) {
        initiateAuthenticatedFlow({ result });
      }
    } catch (e) {
      setErrorConnect(true);
      setErrorMessage(e);
      console.log(e);
    }
  };

  const callToVenly = async () => {
    try {
      const result = await venlyConnect.checkAuthenticated();
      if (result?.auth) {
        initiateAuthenticatedFlow({ result });
      }
      await initiateAuthenticationFlow();
    } catch (e) {
      setErrorConnect(true);
      setErrorMessage(e);
      console.log(e);
    }
  };

  const venlyLogout = async () => {
    const result = await venlyConnect.checkAuthenticated();
    return result.isAuthenticated && venlyConnect.logout();
  };

  const getWalletsBySecretType = async (secret) => {
    try {
      const wallets = await venlyConnect.api.getWallets({ secretType: secret });
      dispatch(updateWalletDetail(wallets[0]));
    } catch (e) {
      setErrorConnect(true);
      setErrorMessage(e);
      console.error(e);
    }
  };

  const manageWallets = async () => {
    try {
      const result = await venlyConnect.checkAuthenticated();
      if (result?.auth) {
        await venlyConnect.flows.manageWallets(secretType);
        return getWalletsBySecretType(secretType);
      }
    } catch (e) {
      if (e?.errors && e?.errors.length > 0) console.log(e);
    }
  };

  const executeTokenAssociation = async (walletId, tokenIds) => {
    await callToVenly();
    const signer = venlyConnect.createSigner();
    return signer.executeNativeTransaction({
      secretType,
      type,
      walletId,
      tokenIds,
    });
  };

  const executeTransaction = async (executeData) => {
    console.log(
      "🚀 ~ file: useVenly.jsx ~ line 82 ~ executeTransaction ~ executeData",
      executeData
    );
    await callToVenly();
    const token = executeData.tokenAddress;

    if (token?.length > 0) {
      return executeTokenTransfer(executeData);
    } else {
      return executeTransfer(executeData);
    }
  };

  const executeTransfer = async (executeData) => {
    console.debug("Executing transaction", executeData);
    const { walletId, value, to } = executeData;

    return venlyConnect.createSigner().executeTransfer({
      walletId,
      to,
      value,
      secretType,
    });
  };

  const executeTokenTransferPromise = ({
    walletId,
    to,
    tokenAddress,
    tokenId,
    secretType,
  }) => {
    return new Promise((resolve, reject) => {
      const executeBody = {
        walletId,
        to,
        tokenAddress,
        tokenId,
        secretType,
      };

      venlyConnect
        .createSigner()
        .executeNftTransfer(executeBody)
        .then((signerResult) => {
          if (signerResult.success || signerResult.status === "SUCCESS") {
            console.log(
              `Transaction ${signerResult.result.transactionHash} has been successfully executed!`
            );
            resolve(signerResult);
          } else {
            const errorMessage = `Something went wrong while executing the transaction`;
            resolve({ error: true, message: [errorMessage] });
          }
        })
        .catch((reason) => {
          resolve({ error: true, message: [reason] });
        });
    });
  };

  const executeTokenTransfer = async (executeData) => {
    console.log("Executing token transaction", executeData);
    const { walletId, tokenId, to, tokenAddress } = executeData;
    await venlyConnect.checkAuthenticated();
    const response = await executeTokenTransferPromise({
      walletId,
      to,
      tokenAddress,
      tokenId,
      secretType,
    });
    return response;
  };

  return {
    callToVenly,
    venlyLogout,
    manageWallets,
    errorConnect,
    errorMessage,
    authenticated,
    executeTokenAssociation,
    executeTransaction,
    user,
    executeTokenTransfer,
  };
};

export default useVenly;
