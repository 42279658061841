import React from "react";
import LinkWithQueryParams from "../../components/LinkWithQueryParams";

const RankingResult = (props) => {
  const { item } = props;
  return (
    <div className="fl-item2">
      <div className="item flex">
        <div className="infor-item flex column1">
          <div className="media">
            <img src={item.img} alt="Axies" />
          </div>
          <div className="content-collection pad-t-4">
            <h5 className="title mb-15">
              <LinkWithQueryParams path="/item-detail">
                "{item.title}"
              </LinkWithQueryParams>
            </h5>
            <div className="author flex">
              <div className="author-avatar">
                <img src={item.imgAuthor} alt="Axies" />
                <div className="badge">
                  <i className="ripple"></i>
                </div>
              </div>
              <div className="content">
                <p>Owned By</p>
                <h6>
                  <LinkWithQueryParams path="/authors-01">
                    {item.nameAuthor}
                  </LinkWithQueryParams>
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <span>{item.volume}</span>
        </div>
        <div className="column td2">
          <span>{item.day}</span>
        </div>
        <div className="column td3">
          <span>{item.week}</span>
        </div>
        <div className="column td4">
          <span>{item.price}</span>
        </div>
        <div className="column td5">
          <span>{item.owners}</span>
        </div>
        <div className="column td6">
          <span>{item.assets}</span>
        </div>
      </div>
    </div>
  );
};

export default RankingResult;
