import React, { useRef, useEffect, memo } from "react";

import { useAuth0 } from "../../auth/Auth";
import LogoHeader from "./LogoHeader";
import Navbar from "./Navbar/Navbar";
// import SearchBar from "./SearchBar";
import MenuProfile from "./MenuProfile";
import styled from "styled-components";
import BetaBanner from "./Navbar/BetaBanner";

const HeaderWrapper = styled.div`
  .logo-light {
    width: 17%;
    height: auto;
  }
  @media (max-width: 768px) {
    .logo-light {
      width: 200px;
      height: auto;
    }
  }
`;

const Header = () => {
  const { isAuthenticated } = useAuth0();
  const headerRef = useRef(null);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector(".js-header");
    if (header) {
      const scrollTop = window.scrollY;
      scrollTop >= 300
        ? header.classList.add("is-fixed")
        : header.classList.remove("is-fixed");
      scrollTop >= 400
        ? header.classList.add("is-small")
        : header.classList.remove("is-small");
    }
  };

  return (
    <HeaderWrapper>
      <header id="header_main" className="header_1 js-header" ref={headerRef}>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div id="site-header-inner">
                <LogoHeader />
                <Navbar />
                {/* <SearchBar /> */}

                <div className="flat-search-btn flex">
                  {!isAuthenticated ? null : <MenuProfile />}
                </div>
              </div>
            </div>
          </div>
        </div>
        <BetaBanner />
      </header>
    </HeaderWrapper>
  );
};

export default memo(Header);
