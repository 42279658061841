import React, { useState } from "react";
import { useAuth0 } from "../../../auth/Auth.js";
import { notifyError } from "../../../helpers/toastNotificationService.js";
import { useApi } from "../../../hooks/useApi";

const { REACT_APP_API_URL } = process.env;

const Like = ({ liked, likes, elementId, endpoint, isCard = false }) => {
  const { isAuthenticated } = useAuth0();
  const { executeApiRequest } = useApi();
  const [likedItem, setLikedItem] = useState(liked);
  const [numberOfLikes, setNumberOfLikes] = useState(likes);

  const buttonStyle = {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000",
    padding: "2px 11px",
    borderRadius: "8px",
    backgroundColor: "#F8D273",
    cursor: "pointer",
    border: "none",
  };

  const giveALike = async (id, endpoint) => {
    const url = `${REACT_APP_API_URL}${endpoint}/like/${id}`;
    const options = {
      method: "PUT",
    };
    likedItem
      ? setNumberOfLikes((prev) => prev - 1)
      : setNumberOfLikes((prev) => prev + 1);
    setLikedItem(!likedItem);
    await executeApiRequest(url, options);
  };

  const manageLikes = () => {
    isAuthenticated
      ? giveALike(elementId, endpoint)
      : notifyError("You are not authenticated");
  };

  return !isCard ? (
    <div style={buttonStyle}>
      <span
        className={`wishlist-button ${likedItem ? "active heart" : "heart"}`}
        onClick={manageLikes}
      >
        <span className="number-like">
          {numberOfLikes ? numberOfLikes : ""}
        </span>
      </span>
    </div>
  ) : (
    <div className="like-container" onClick={manageLikes}>
      <span className="heart">{numberOfLikes ? numberOfLikes : ""}</span>
    </div>
  );
};

export default Like;
