import styled from "styled-components";
import { COMMON_STYLES } from "../../../constants/genericStyles";

export const StyledArtistCard = styled.div`
  .sc-card-collection.style-2 {
    padding: 20px 7%;
  }
  .sc-card-collection.style-2 .card-bottom .author .content h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;
  }
  .sc-card-collection.style-2 .card-bottom .author .infor span {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
  }
  .sc-card-collection .card-bottom .author .infor .name a {
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    color: #ebebeb;
    margin-left: 6px;
  }

  /* orange variation */
  .sc-card-collection.style-2 .sc-button-2 {
    padding: 10px 36px;
    color: #ffffff;
    border: 3px solid #ff7b7c;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
    margin-top: 0;
  }

  .sc-card-collection.style-2 .sc-button-2.button__bottom-top.orange {
    border-color: #ff7b7c;
    color: #ffffff;
    background: linear-gradient(to top, #ff7b7c 50%, transparent 50%);
    background-size: 100% 200%;
    background-position: top;
  }

  .sc-card-collection.style-2 .sc-button-2.button__bottom-top.orange:hover {
    border-color: #ff7b7c;
    background-position: bottom;
    color: #fff;
  }

  .sc-card-collection.style-2 .collection-card .item-collection .left-img,
  .sc-card-collection.style-2 .collection-card .item-collection .bottom-img,
  .sc-card-collection.style-2 .collection-card .item-collection .top-img,
  .sc-card-collection.style-2 .collection-card .item-collection .unique-img {
    height: auto;
    margin-bottom: 0;
  }

  .sc-card-collection.style-2
    .collection-card
    .item-collection
    .left-img
    .image-container,
  .sc-card-collection.style-2
    .collection-card
    .item-collection
    .bottom-img
    .image-container,
  .sc-card-collection.style-2
    .collection-card
    .item-collection
    .unique-img
    .image-container,
  .sc-card-collection.style-2
    .collection-card
    .item-collection
    .top-img
    .image-container {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 25px;
  }

  .sc-card-collection.style-2
    .collection-card
    .item-collection
    .left-img
    .image-container:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 150%;
  }

  .sc-card-collection.style-2
    .collection-card
    .item-collection
    .bottom-img
    .image-container:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 90%;
  }

  .sc-card-collection.style-2
    .collection-card
    .item-collection
    .top-img
    .image-container:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 50%;
  }

  .sc-card-collection.style-2
    .collection-card
    .item-collection
    .unique-img
    .image-container:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 71.15%;
  }

  .sc-card-collection.style-2
    .collection-card
    .item-collection
    .left-img
    .image-container
    img,
  .sc-card-collection.style-2
    .collection-card
    .item-collection
    .unique-img
    .image-container
    img,
  .sc-card-collection.style-2
    .collection-card
    .item-collection
    .bottom-img
    .image-container
    img,
  .sc-card-collection.style-2
    .collection-card
    .item-collection
    .top-img
    .image-container
    img {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .sc-card-collection.style-2
    .collection-card
    .item-collection
    .unique-img
    .image-container
    img {
    object-fit: contain;
  }

  .sc-card-collection.style-2
    .collection-card
    .item-collection
    .left-img
    .image-container:hover
    img,
  .sc-card-collection.style-2
    .collection-card
    .item-collection
    .bottom-img
    .image-container:hover
    img,
  .sc-card-collection.style-2
    .collection-card
    .item-collection
    .unique-img
    .image-container
    img,
  .sc-card-collection.style-2
    .collection-card
    .item-collection
    .top-img
    .image-container:hover
    img {
    transform: scale(1.1);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .sc-card-collection.style-2 .collection-card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    height: auto;
  }

  .sc-card-collection.style-2 .item-collection {
    flex: 1;
    margin-right: 2%;
  }

  .sc-card-collection.style-2 .item-collection.style3 {
    margin-right: 0%;
  }

  .sc-card-collection.style-2 .item-collection:nth-child(2n) {
    margin-right: 0;
  }

  .sc-card-collection.style-2 .item-collection.rows-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .sc-card-collection
    .card-bottom
    .sc-author-box.style-2
    .author-avatar
    .avatar-placeholder {
    background-color: hsl(200, 20%, 95%);
  }

  @media (max-width: 768px) {
    .sc-card-collection.style-2 {
      border-radius: 15px;
      padding: 9px 7%;
    }

    .sc-card-collection.style-2 .card-bottom .author .content h4 {
      font-size: 10px;
      line-height: 10px;
      margin-bottom: 5px;
    }
    .sc-card-collection.style-2 .card-bottom .author .infor span {
      font-size: 8px;
      line-height: 8px;
    }
    .sc-card-collection .card-bottom .author .infor .name a {
      font-size: 8px;
      line-height: 8px;
    }
    .sc-card-collection.style-2 .sc-button-2 {
      padding: 2px 20px;
      font-size: 10px;
      line-height: 10px;
      border-radius: 5px;
    }
    .sc-card-collection
      .card-bottom
      .sc-author-box.style-2
      .author-avatar
      .avatar {
      width: 25px;
      height: auto;
      border-radius: 2px;
    }

    .sc-card-collection.style-2
      .collection-card
      .item-collection
      .left-img
      .image-container,
    .sc-card-collection.style-2
      .collection-card
      .item-collection
      .bottom-img
      .image-container,
    .sc-card-collection.style-2
      .collection-card
      .item-collection
      .top-img
      .image-container {
      border-radius: 4px;
    }
    .sc-card-collection .card-bottom .author {
      margin-bottom: 0px;
    }
    .sc-card-collection.style-2 .collection-card {
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
`;
