import React, { useEffect, useState } from "react";
import { useS3Url } from "../../hooks/useS3Url";

const CollectionImage = ({ dbImage, previewImage }) => {
  const { getS3Url } = useS3Url();
  const [img, setImg] = useState(dbImage);

  useEffect(() => {
    if (previewImage) {
      setImg(previewImage);
      return;
    }
    if (!dbImage) return;
    if (dbImage.includes("amazonaws")) {
      setImg(dbImage);
      return;
    }
    const profileFileNameS3 = getS3Url(dbImage, "coverImage");
    if (profileFileNameS3.length !== 0) {
      setImg(profileFileNameS3);
    }
  }, [dbImage, getS3Url]);

  return (
    <div className="collection-image-container">
      <div className="collection-image">
        {img && img.length !== 0 ? (
          <img id="profileimg" src={img} alt="" />
        ) : (
          <div className="gray-background"></div>
        )}
      </div>
    </div>
  );
};

export default CollectionImage;
