import styled from "styled-components";
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from "../../constants/constants";
import { COMMON_STYLES } from "../../constants/genericStyles";

export const StyledMyOrdersPage = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }
  section#my-orders {
    padding: 2%;
    padding-top: 4%;
    padding-bottom: 4%;
  }
  ${COMMON_STYLES.HEADER_STYLES}

  @media (max-width: 768px) {
    #spacer {
      padding-top: ${MOBILE_HEADER_HEIGHT.toString() + "px"};
    }
    .page-title-heading {
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .page-title-heading .heading {
      font-size: 20px;
      line-height: 20px;
    }
    .page-title-heading .vertical-line {
      height: 20px;
      border-left: 4px solid #ffd061;
    }
    .themesflat-tabs .react-tabs__tab {
      font-size: 16px;
      line-height: 16px;
    }
  }
`;
