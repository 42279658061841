import React from "react";
import styled from "styled-components";
const StyledTable = styled.div`
  width: 70%;
  table,
  td {
    border: none;
  }
  .table-header {
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.019em;
    color: #000000;
    text-align: center;
    border: none;
    border-bottom: 1px solid #cdcdcd;
    text-transform: capitalize;
  }
  .table-cell {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.019em;
    color: #000000;
    text-align: center;
    text-transform: capitalize;
  }
`;

const TraitsTable = ({ traitObject }) => {
  return (
    <StyledTable>
      <table>
        <thead>
          <tr>
            <th className="table-header">Type</th>
            <th className="table-header">Value</th>
            <th className="table-header">Rarity</th>
          </tr>
        </thead>
        <tbody>
          {traitObject.map((trait, id) => {
            return (
              <tr key={"trait-" + id}>
                <td className="table-cell">{trait.type}</td>
                <td className="table-cell">{trait.value}</td>
                <td className="table-cell">{trait.occurrence}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </StyledTable>
  );
};

export default TraitsTable;
