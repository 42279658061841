import React, { useState } from "react";
import { Link } from "react-router-dom";
import RankingResult from "./RankingResult";

const RankingTable = (props) => {
  const { data } = props;
  const [visible, setVisible] = useState(6);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 3);
  };

  return (
    <div className="table-ranking my-5">
      <div className="flex th-title">
        <div className="column1">
          <h3>Collection</h3>
        </div>
        <div className="column">
          <h3>Volume</h3>
        </div>
        <div className="column">
          <h3>1 Day</h3>
        </div>
        <div className="column">
          <h3>7 Days</h3>
        </div>
        <div className="column">
          <h3>Floor Price</h3>
        </div>
        <div className="column">
          <h3>Owners</h3>
        </div>
        <div className="column">
          <h3>Assets</h3>
        </div>
      </div>
      {data.slice(0, visible).map((item, index) => (
        <RankingResult item={item} key={index} />
      ))}
      {visible < data.length && (
        <div className="col-md-12 wrap-inner load-more text-center">
          <Link
            to="#"
            id="load-more"
            className="sc-button loadmore fl-button pri-3"
            onClick={showMoreItems}
          >
            <span> More </span>
          </Link>
        </div>
      )}
    </div>
  );
};

export default RankingTable;
