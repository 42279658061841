import React from "react";
import LinkWithQueryParams from "../../../components/LinkWithQueryParams";
import {
  additionalPurchaseOptionsButtonValidator,
  removeFromSaleButtonValidator,
  sellItemButtonValidator,
  statusClassSelector,
  statusFormatter,
} from "../../../helpers/formatters";

const ItemCardLeftSection = ({
  itemDisplayName,
  spaceData,
  creatorDisplayName,
  productName,
  expanded,
  showRemoveModal,
  item,
  loading,
  sellItem,
  showTransferModal,
  productData,
  isMobile,
  myVaultItems,
  showAdditionalOptions,
}) => {
  let myVaultIds =
    myVaultItems?.length > 0 ? myVaultItems.map((item) => item.id) : [];

  return (
    <div className="left-section">
      {itemDisplayName && <h4>{itemDisplayName}</h4>}

      <div className="author">
        <LinkWithQueryParams path={`/spaces/${spaceData.customURL}`}>
          <h6 style={{ color: "#6899FA" }}>{creatorDisplayName}</h6>
        </LinkWithQueryParams>
      </div>

      {productName && <div className="product-name">{productName}</div>}

      {isMobile && item?.status && (
        <div
          className={`status-label ${statusClassSelector({
            status: item?.status,
          })}`}
        >
          <span>{statusFormatter({ status: item?.status })}</span>
        </div>
      )}

      {expanded &&
        additionalPurchaseOptionsButtonValidator({ productData }) && (
          <>
            <div onClick={showAdditionalOptions}>
              <button className="outlined-button button__bottom  mb-3">
                <span> Additional Purchase Options</span>
              </button>
            </div>
          </>
        )}

      {expanded &&
        (removeFromSaleButtonValidator({ item }) && showRemoveModal ? (
          <div onClick={showRemoveModal}>
            <button
              className="outlined-button button__bottom"
              disabled={loading}
            >
              <span>{loading ? "In process" : "Remove From Sale"}</span>
            </button>
          </div>
        ) : (
          sellItemButtonValidator({ item, productData, myVaultIds }) &&
          sellItem && (
            <div onClick={sellItem}>
              <button className="outlined-button button__bottom">
                <span> Sell Item</span>
              </button>
            </div>
          )
        ))}

      {expanded && myVaultIds?.length > 0 && myVaultIds.includes(item?.id) && (
        <div onClick={showTransferModal}>
          <button className="outlined-button button__bottom mt-3">
            <span> Transfer from Vault</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default ItemCardLeftSection;
