import React, { Fragment, useEffect, useState } from "react";
import getUser from "../../../api/user/getUser";
import item2 from "../../../assets/images/item-background/item-2.png";
import { itemDetailsBaseRoute } from "../../../helpers/itemHelpers";
import useIPFS from "../../../hooks/useIPFS";
import LinkWithQueryParams from "../../LinkWithQueryParams";
import Spinner from "../Spinner/Spinner";
import { StyledTrendingCard } from "./styles";

const TrendingCard = ({ card }) => {
  const { element, getData } = useIPFS();
  const [user, setUser] = useState({});

  const [loading, setLoading] = useState(true);

  const getDataUser = async (id) => {
    const response = await getUser(id);
    response && setUser(response);
    getData(card.cid);
    setLoading(false);
  };

  useEffect(() => {
    getDataUser(card.belongsToId);
  }, []);

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <StyledTrendingCard>
          <LinkWithQueryParams
            path={`/${itemDetailsBaseRoute({ item: card })}/${card.id}`}
          >
            <div className="inner-card">
              <div className="image-container">
                <img src={element.image ? element.image : item2} alt="Axies" />
                <div className="like-container">
                  <span className="heart">100</span>
                </div>
              </div>
              <div className="price desktop">${card.price}</div>
              <div className="content2">
                <div className="name-art">{element.name}</div>
                <div className="artist">{user.displayName}</div>
                <hr className="mobile" />
                <div className="price mobile">
                  <span>${card.price}</span>
                </div>
              </div>
            </div>
          </LinkWithQueryParams>
        </StyledTrendingCard>
      )}
    </Fragment>
  );
};

export default TrendingCard;
