const { REACT_APP_API_URL } = process.env;

const getProducts = async (filter = "") => {
  const endpoint = `${REACT_APP_API_URL}prodigi/products`;
  const params = filter.length ? `?productTypeId=${filter}` : "";
  const response = await fetch(`${endpoint + params}`);
  return response.json();
};

export default getProducts;
