import React from "react";
import Order from "./Order/Order";

const OrderList = ({ orders, orderType }) => {
  return (
    orders?.length > 0 &&
    orders.map((order, i) => (
      <Order order={order} orderType={orderType} key={"id " + i} />
    ))
  );
};

export default OrderList;
