import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import useIPFS from '../../hooks/useIPFS';
import Spinner from './Spinner/Spinner';
import useCart from '../../hooks/useCart';
import { useApi } from '../../hooks/useApi';
import useVenly from '../../hooks/useVenly';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const { REACT_APP_API_URL } = process.env;

const CardModal = ({ show, token, onHide, isBuy, changeSaleStatus }) => {
  console.log('🚀 ~ file: CardModal.jsx ~ line 14 ~ CardModal ~ token', token);
  const { wallets } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);
  const [associated, setAssociated] = useState(false);
  const [order, setOrder] = useState({
    venlyWalletId: wallets.walletDetails.id,
    to: wallets.walletDetails.address,
    tokenIds: [],
  });

  const navigate = useNavigate();

  const { addToCart, removeFromCart } = useCart();
  const { executeApiRequest, executeApiRequestTwo } = useApi();
  const { executeTokenAssociation, executeTransaction } = useVenly();
  const { element, getData } = useIPFS();

  const onSubmit = (serial) => {
    const actualCart = JSON.parse(localStorage.getItem('cart') || '[]');
    const verified =
      actualCart.filter((obj) => obj.id === serial.id).length > 0;
    if (!verified) {
      addToCart(serial);
      alert(`Added ${element.name} to cart`);
    } else {
      alert(`Item ${element.name} already in cart`);
    }
    onHide();
  };

  const wantsToBuy = async (id, to) => {
    const url = `${REACT_APP_API_URL}item/wants-to-buy/${id}`;
    const options = {
      headers: { 'Content-Type': 'application/json' },
      method: 'PATCH',
      body: JSON.stringify({ toWalletId: to }),
    };

    try {
      await executeApiRequest(url, options);
    } catch (error) {
      console.error(error);
    }
  };

  const payWithVenly = async (data, token) => {
    const { to, tokenIds, venlyWalletId } = data;
    try {
      if (!associated) {
        await executeApiRequest(
          `${REACT_APP_API_URL}collection/preAssociation/${token.collectionId}`,
          {
            method: 'PUT',
            body: JSON.stringify({
              walletId: to,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        const signerResult = await executeTokenAssociation(
          venlyWalletId,
          tokenIds
        );

        if (signerResult.status !== 'SUCCESS') {
          console.warn(`Something went wrong while associating the tokens`);
          return;
        }

        console.log(`The tokens have been successfuly associated`);

        await executeApiRequest(
          `${REACT_APP_API_URL}collection/associationState/${token.collectionId}`,
          {
            method: 'PUT',
          }
        );
      }

      if (token.status === 'FOR_SALE') {
        const signerResult = await executeTransaction({
          walletId: venlyWalletId,
          value: /* token.price */ 0.1,
          to: token.walletIdForMoney,
        });

        if (signerResult.status !== 'SUCCESS') {
          console.error(`Something went wrong while executing the transaction`);
          return;
        }

        await wantsToBuy(token.id, to);
        changeSaleStatus(true);
        removeFromCart(token);
        onHide();
      } else {
        console.log("It isn't in sell");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const payWithStripe = async (data, token) => {
    const { tokenIds, venlyWalletId } = data;
    try {
      if (!associated) {
        const signerResult = await executeTokenAssociation(
          venlyWalletId,
          tokenIds
        );

        if (signerResult.status !== 'SUCCESS') {
          console.warn(`Something went wrong while associating the tokens`);
          return;
        }

        console.log(`The tokens have been successfuly associated`);

        await executeApiRequest(
          `${REACT_APP_API_URL}collection/associationState/${token.collectionId}`,
          {
            method: 'PUT',
          }
        );
      }

      if (token.status === 'FOR_SALE') {
        navigate(`/checkout/item/${token.id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCollectionById = async (id) => {
    const response = await executeApiRequestTwo(
      `${REACT_APP_API_URL}collection/one/${id}`,
      {
        method: 'GET',
      }
    );

    if (response) {
      setOrder({ ...order, tokenIds: [response.tokenId] });
      setAssociated(response.isAssociated);
    }

    setLoading(false);
  };

  useEffect(() => {
    getData(token.cid);
    getCollectionById(token.collectionId);
  }, []);

  return (
    <Modal show={show} onHide={onHide}>
      {loading ? (
        <div
          style={{
            width: '100%',
            height: '330px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          <Modal.Header closeButton></Modal.Header>
          <div className='modal-body space-y-20 pd-40'>
            <h3> Buy </h3>

            <div className='hr'></div>
            <div className='d-flex justify-content-between'>
              <p> Token:</p>
              <p className='text-right price color-popup'>{element.name}</p>
            </div>

            <div className='d-flex justify-content-between'>
              <p> Total bid amount:</p>
              <p className='text-right price color-popup'> {token.price} </p>
            </div>

            {isBuy ? (
              <div>
                <button
                  className='btn btn-primary m-1'
                  onClick={() => payWithVenly(order, token)}
                >
                  Pay with Venly
                </button>
                <button
                  className='btn btn-primary m-1'
                  onClick={() => payWithStripe(order, token)}
                >
                  Pay with stripe
                </button>
              </div>
            ) : (
              <button
                className='btn btn-primary'
                onClick={() => onSubmit(token)}
              >
                Add to cart
              </button>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default CardModal;
